// src/components/pages/Lernplattform/VideoCard.js

import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardMedia,
  Typography,
  IconButton,
  Chip,
  Tooltip,
  Box,
  LinearProgress,
} from '@mui/material';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { motion } from 'framer-motion';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// Gestylter Card-Component (feste Höhe entfernt)
const StyledVideoCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  cursor: 'pointer',
  transition: 'box-shadow 0.5s ease-in-out',
  // height: 150,  <-- ENTFERNT
  width: '100%',
  boxSizing: 'border-box',
  backgroundColor: '#252833',
  borderRadius: theme.spacing(2),
  color: '#FFFFFF',
  '&:hover': {
    boxShadow: '0 0 8px 2px rgba(214,188,139,0.4)',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    // height: 'auto', <-- optional, falls du es explizit setzen willst
  },
}));

const ThumbnailContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  flexShrink: 0,
  width: 200,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: 120,
    overflow: 'hidden',
  },
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  width: 200,
  height: '100%',
  objectFit: 'cover',
  borderTopLeftRadius: theme.spacing(2),
  borderBottomLeftRadius: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    width: '120%',
    height: '100%',
    objectPosition: 'center',
    transform: 'translateX(-10%)',
    borderTopRightRadius: theme.spacing(0),
    borderBottomRightRadius: theme.spacing(0),
  },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: theme.spacing(2),
  position: 'relative',
  backgroundColor: '#252833',
  borderTopRightRadius: theme.spacing(2),
  borderBottomRightRadius: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(1.5),
  },
}));

const ControlsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const VideoCard = ({
  video,
  onClick,
  isBookmarked,
  onBookmarkToggle,
  progress = 0,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const formatDuration = (durationInSeconds) => {
    if (!durationInSeconds || isNaN(durationInSeconds)) return '00:00';
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = Math.floor(durationInSeconds % 60);

    const hoursDisplay = hours > 0 ? `${hours}:` : '';
    const minutesDisplay =
      minutes < 10 && hours > 0 ? `0${minutes}:` : `${minutes}:`;
    const secondsDisplay = seconds < 10 ? `0${seconds}` : seconds;

    return `${hoursDisplay}${minutesDisplay}${secondsDisplay}`;
  };

  return (
    <motion.div
      whileHover={{ scale: 1.01 }}
      whileTap={{ scale: 0.98 }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <StyledVideoCard onClick={onClick}>
        <ThumbnailContainer>
          <StyledCardMedia
            component="img"
            image={
              video.thumbnailUrl ||
              'https://via.placeholder.com/300x200.png?text=Video+Thumbnail'
            }
            alt={video.video_titel}
          />
          {video.duration && (
            <Chip
              label={formatDuration(video.duration)}
              size="small"
              sx={{
                position: 'absolute',
                bottom: 8,
                left: 8,
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                color: '#fff',
              }}
            />
          )}
        </ThumbnailContainer>

        <ContentBox>
          <ControlsBox>
            <Typography
              variant="h6"
              component="div"
              sx={{ fontWeight: 'bold', flexGrow: 1, color: '#d6bc8b' }}
            >
              {video.video_titel}
            </Typography>
            <Tooltip
              title={
                isBookmarked
                  ? 'Entfernen aus Lesezeichen'
                  : 'Zu Lesezeichen hinzufügen'
              }
            >
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  onBookmarkToggle();
                }}
                aria-label="bookmark"
                sx={{
                  color: isBookmarked ? '#d6bc8b' : '#FFFFFF',
                }}
              >
                {isBookmarked ? <BookmarkIcon /> : <BookmarkBorderIcon />}
              </IconButton>
            </Tooltip>
          </ControlsBox>

          <Typography
            variant="body2"
            sx={{
              mt: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              color: '#FFFFFF',
            }}
          >
            {video.text}
          </Typography>

          {/* Fortschrittsbalken, falls progress > 0 */}
          {progress > 0 && (
            <Box sx={{ mt: 2 }}>
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{
                  backgroundColor: '#3f4b54',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: '#d5bc8b',
                  },
                }}
              />
              <Typography
                variant="caption"
                sx={{ color: '#d6bc8b', fontWeight: 'bold' }}
              >
                Fortschritt: {progress}%
              </Typography>
            </Box>
          )}
        </ContentBox>
      </StyledVideoCard>
    </motion.div>
  );
};

VideoCard.propTypes = {
  video: PropTypes.shape({
    id: PropTypes.string.isRequired,
    video_titel: PropTypes.string.isRequired,
    text: PropTypes.string,
    vimeo_id: PropTypes.string,
    score: PropTypes.number,
    thumbnailUrl: PropTypes.string,
    duration: PropTypes.number,
    description: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  isBookmarked: PropTypes.bool.isRequired,
  onBookmarkToggle: PropTypes.func.isRequired,
  progress: PropTypes.number,
};

export default VideoCard;
