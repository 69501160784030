// src/components/Administration/dashboard_editor/SortableWidget.js

import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Box, Typography, Button } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import LongWidget from '../../widgets/LongWidget';
import ShortWidget from '../../widgets/ShortWidget';
import PropTypes from 'prop-types';

const SortableWidget = ({ widget, onEdit, onDelete }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: widget.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.8 : 1,
    cursor: 'grab',
    backgroundColor: '#252833', // Dunkler Hintergrund
    color: '#ffffff', // Weißer Text für bessere Lesbarkeit
    borderRadius: '8px', // Verkleinerter Border-Radius
    padding: '8px', // Reduziertes Padding
    boxShadow: isDragging
      ? '0 2px 8px rgba(0, 0, 0, 0.2)'
      : '0 2px 6px rgba(0, 0, 0, 0.2)', // Reduzierter Schatten
    position: 'relative',
    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
    '&:hover': {
      transform: isDragging ? 'none' : 'scale(1.02)',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)', // Leicht erhöhter Schatten beim Hover
    },
  };

  // Fallback-Image, falls imageUrl fehlt
  const defaultImageUrl = '/images/default-widget.jpg';

  return (
    <Box
      ref={setNodeRef}
      sx={{
        ...style,
        transition: style.transition,
        display: 'flex',
        flexDirection: 'column',
      }}
      {...attributes}
      {...listeners}
    >
      {/* Drag-Handle immer sichtbar in der oberen linken Ecke */}
      <Box
        sx={{
          position: 'absolute',
          top: '8px',
          left: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          borderRadius: '4px',
          padding: '2px',
          cursor: 'grab',
        }}
        {...listeners}
      >
        <DragIndicatorIcon sx={{ color: '#ffffff', fontSize: '1rem' }} />
      </Box>

      {/* Titelbild */}
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: widget.size === 'lang' ? '150px' : '75px', // Weitere Reduzierung der Bildhöhe
          overflow: 'hidden',
          borderRadius: '6px', // Kleinere Border-Radius
          marginBottom: '8px', // Reduziertes Margin
        }}
      >
        <img
          src={widget.imageUrl || defaultImageUrl}
          alt={widget.title}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      </Box>

      {/* Widget-Inhalt */}
      {widget.size === 'lang' ? (
        <LongWidget
          title={widget.title}
          shortText={widget.shortText}
          longText={widget.longText}
          sx={{ fontSize: '0.75rem' }} // Weitere Reduzierung der Schriftgröße
        />
      ) : (
        <ShortWidget
          title={widget.title}
          shortText={widget.shortText}
          sx={{ fontSize: '0.75rem' }} // Weitere Reduzierung der Schriftgröße
        />
      )}

      {/* Buttons */}
      <Box mt={1} display="flex" justifyContent="space-between">
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={(e) => {
            e.stopPropagation(); // Verhindert die Event-Propagation
            console.log(
              'Bearbeiten-Button geklickt für Widget:',
              widget.id,
              widget
            );
            onEdit(widget);
          }}
          sx={{
            padding: '2px 6px', // Weiter reduziertes Padding
            fontSize: '0.75rem', // Kleinere Schriftgröße
          }}
        >
          Bearbeiten
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={(e) => {
            e.stopPropagation(); // Verhindert die Event-Propagation
            console.log('Löschen-Button geklickt für Widget:', widget.id);
            onDelete(widget.id);
          }}
          sx={{
            padding: '2px 6px', // Weiter reduziertes Padding
            fontSize: '0.75rem', // Kleinere Schriftgröße
          }}
        >
          Löschen
        </Button>
      </Box>
    </Box>
  );
};

SortableWidget.propTypes = {
  widget: PropTypes.shape({
    id: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    imageUrl: PropTypes.string, // Optional gemacht
    title: PropTypes.string.isRequired,
    shortText: PropTypes.string.isRequired,
    longText: PropTypes.string,
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default SortableWidget;
