// src/components/functions/VideoUploadFromJson.js
import React, { useState } from 'react';
import { db } from '../../config/firebase'; // Importiere Firestore db aus firebase.js
import {
  collection,
  getDocs,
  doc,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import Papa from 'papaparse';

const VideoUpload = () => {
  const [file, setFile] = useState(null);
  const [outputData, setOutputData] = useState('');

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  const sanitizeFieldNames = (entry) => {
    const sanitizedEntry = {};
    for (const [key, value] of Object.entries(entry)) {
      const sanitizedKey = key.replace(/[~*/[\]]/g, ''); // Entfernt ungültige Zeichen
      sanitizedEntry[sanitizedKey] = value;
    }
    return sanitizedEntry;
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Bitte wählen Sie eine Datei aus!');
      return;
    }

    try {
      // Schritt 1: Bestehende Daten aus Firebase laden und nach vimeo_id strukturieren
      const videosCollectionRef = collection(db, 'videos');
      const querySnapshot = await getDocs(videosCollectionRef);
      const existingData = {};

      querySnapshot.forEach((docSnapshot) => {
        const data = docSnapshot.data();
        const vimeoId = data.vimeo_id;
        if (vimeoId) {
          existingData[vimeoId] = docSnapshot.id; // Speichern der existierenden vimeo_id mit Firebase-Dokument-ID
        }
      });

      // Schritt 2: CSV-Datei einlesen
      Papa.parse(file, {
        header: true,
        complete: async (results) => {
          const csvData = results.data;

          // Schritt 3: Verarbeiten der CSV-Daten
          for (const entry of csvData) {
            const sanitizedEntry = sanitizeFieldNames(entry);
            const vimeoId = sanitizedEntry['Vimeo-ID'] || ''; // CSV-Feld für die eindeutige ID

            if (vimeoId && existingData[vimeoId]) {
              // Falls das Dokument existiert, ergänzen
              const docRef = doc(db, 'videos', existingData[vimeoId]);
              await updateDoc(docRef, sanitizedEntry);
              setOutputData(
                (prev) => prev + `\nEintrag ${vimeoId} wurde aktualisiert.`
              );
            } else if (vimeoId) {
              // Neues Dokument mit vimeoId erstellen, falls nicht vorhanden
              const newDocRef = doc(db, 'videos', vimeoId);
              await setDoc(newDocRef, sanitizedEntry);
              setOutputData(
                (prev) => prev + `\nNeuer Eintrag ${vimeoId} wurde hinzugefügt.`
              );
            } else {
              // Fallback: Dokument mit generierter ID erstellen
              const newDocRef = doc(videosCollectionRef); // Erzeugt eine automatisch generierte ID
              await setDoc(newDocRef, sanitizedEntry);
              setOutputData(
                (prev) =>
                  prev + `\nNeuer Eintrag mit generierter ID wurde hinzugefügt.`
              );
            }
          }
        },
        error: (error) => {
          console.error('Fehler beim Einlesen der CSV-Datei:', error);
          setOutputData('Fehler beim Einlesen der Datei.');
        },
      });
    } catch (error) {
      console.error('Fehler beim Hochladen der Daten:', error);
      setOutputData('Fehler beim Hochladen der Daten.');
    }
  };

  return (
    <div>
      <h1>CSV- und JSON-Upload zu Firebase</h1>
      <input type="file" accept=".csv" onChange={handleFileChange} />
      <button onClick={handleUpload}>Dateien hochladen</button>
      <p>{outputData}</p>
    </div>
  );
};

export default VideoUpload;
