// src/serviceWorkerRegistration.js

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] ist die IPv6 localhost Adresse.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 entspricht der IPv4 localhost.
    window.location.hostname.match(/^127(?:\.\d+){3}$/)
);

/**
 * Registriert den Service Worker.
 * @param {object} config - Konfigurationsobjekt mit `onSuccess` und `onUpdate` Callback-Funktionen.
 */
export function register(config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // Der Service Worker URL
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Wenn PUBLIC_URL in einer anderen Domain liegt, dann registrieren wir keinen Service Worker.
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        // In einer localhost-Umgebung prüfen wir den Service Worker extra.
        checkValidServiceWorker(swUrl, config);

        // Logge Infos in der Console, damit Entwickler informiert sind.
        navigator.serviceWorker.ready.then(() => {
          console.log(
            'Diese App wird von einem Service Worker im Hintergrund bedient.'
          );
        });
      } else {
        // Nicht localhost: Einfach registrieren.
        registerValidSW(swUrl, config);
      }
    });
  }
}

/**
 * Registriert einen gültigen Service Worker.
 * @param {string} swUrl - URL des Service Workers.
 * @param {object} config - Konfigurationsobjekt mit `onSuccess` und `onUpdate` Callback-Funktionen.
 */
function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // Neue Inhalte sind verfügbar, zeige ggf. einen Hinweis.
              console.log(
                'Neue Inhalte sind verfügbar und werden im Hintergrund geladen.'
              );

              // Führe die `onUpdate` Callback aus, falls vorhanden
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // Inhalte wurden zwischengespeichert, App ist jetzt offlinefähig.
              console.log(
                'Die App wurde zum ersten Mal installiert und ist jetzt offlinefähig.'
              );

              // Führe die `onSuccess` Callback aus, falls vorhanden
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch((error) => {
      console.error('Fehler bei der Service Worker-Registrierung:', error);
    });
}

/**
 * Prüft, ob der Service Worker gültig ist. Falls nicht, löscht ihn und lädt die Seite neu.
 * @param {string} swUrl - URL des Service Workers.
 * @param {object} config - Konfigurationsobjekt mit `onSuccess` und `onUpdate` Callback-Funktionen.
 */
function checkValidServiceWorker(swUrl, config) {
  // Prüfe, ob der Service Worker existiert.
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then((response) => {
      // Service Worker gefunden?
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // Kein Service Worker. Vielleicht ältere Version im Cache -> clear.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Gefunden, normal registrieren.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log(
        'Es ist gerade keine Internetverbindung verfügbar. Die App läuft offline.'
      );
    });
}

/**
 * Unregistriert den Service Worker.
 */
export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        return registration.unregister();
      })
      .catch((error) => {
        console.error('Fehler beim Unregistern des Service Workers:', error);
      });
  }
}
