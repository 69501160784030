// src/components/pages/Lernplattform/VideoDetail.js

import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
  Divider,
  Card,
  CardMedia,
  Chip,
  Drawer,
  IconButton,
  useMediaQuery,
  useTheme,
  TextField,
  InputAdornment,
  Avatar,
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import Rating from '@mui/material/Rating';

import {
  getFirestore,
  doc,
  getDoc,
  collection,
  addDoc,
  updateDoc,
  query,
  orderBy,
  serverTimestamp,
  onSnapshot,
  setDoc,
  getDocs,
  where,
  documentId,
} from 'firebase/firestore';

import { useAuth } from '../../context/AuthContext';
import ReactPlayer from 'react-player';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { format, differenceInSeconds } from 'date-fns';
import { de } from 'date-fns/locale';

//   // <-- VideoIntroOverlay wurde entfernt

const COLORS = {
  darkBlue: '#252833',
  darkBlueRgba80: 'rgba(37, 40, 51, 0.8)',
  anthracitRgba10: 'rgba(54, 69, 79, 0.1)',
  anthracitRgba20: 'rgba(54, 69, 79, 0.2)',
  dunkelBlau: '#3f4b54',
  hellesBraun: '#d5bc8b',
};

// Formatierung der Videolänge
const formatDuration = (durationInSeconds) => {
  if (!durationInSeconds || isNaN(durationInSeconds)) return '00:00';
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = Math.floor(durationInSeconds % 60);

  const hoursDisplay = hours > 0 ? `${hours}h ` : '';
  const minutesDisplay = minutes > 0 ? `${minutes}m ` : '';
  const secondsDisplay = seconds > 0 ? `${seconds}s` : '';

  return `${hoursDisplay}${minutesDisplay}${secondsDisplay}`.trim();
};

// Mapping für verwandte Videos
const categoryTitleMap = {
  a_notwendige_uebung: 'Vorübungen',
  a_aehnliche_uebung: 'Ähnliche Übungen',
};

const formatTimestamp = (postDate, isEdited = false) => {
  const now = new Date();
  const diffInSeconds = differenceInSeconds(now, postDate);

  if (diffInSeconds < 60) {
    return isEdited
      ? 'bearbeitet vor wenigen Sekunden'
      : 'gepostet vor wenigen Sekunden';
  } else if (diffInSeconds < 3600) {
    const minutes = Math.floor(diffInSeconds / 60);
    return isEdited
      ? `bearbeitet vor ${minutes} Minute${minutes !== 1 ? 'n' : ''}`
      : `gepostet vor ${minutes} Minute${minutes !== 1 ? 'n' : ''}`;
  } else if (diffInSeconds < 86400) {
    const hours = Math.floor(diffInSeconds / 3600);
    return isEdited
      ? `bearbeitet vor ${hours} Stunde${hours !== 1 ? 'n' : ''}`
      : `gepostet vor ${hours} Stunde${hours !== 1 ? 'n' : ''}`;
  } else {
    return format(postDate, 'Pp', { locale: de });
  }
};

const VideoDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [video, setVideo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Kommentar-States
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [commentError, setCommentError] = useState('');
  const [commentSuccess, setCommentSuccess] = useState('');
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editedCommentText, setEditedCommentText] = useState('');
  const [isCommentBoxExpanded, setIsCommentBoxExpanded] = useState(false);

  // Info-Box
  const [isInfoBoxExpanded, setIsInfoBoxExpanded] = useState(false);
  const [trainerInfo, setTrainerInfo] = useState(null);

  // Bewertung
  const [userRating, setUserRating] = useState(0);

  // Video abgeschlossen
  const [hasCompleted, setHasCompleted] = useState(false);

  // Player
  const playerRef = useRef(null);

  // Kategorien & verwandte Videos
  const categories = useMemo(
    () => [
      'a_notwendige_uebung',
      'a_hilfreiche_voruebung',
      'a_aehnliche_uebung',
    ],
    []
  );
  const [relatedVideos, setRelatedVideos] = useState({
    a_notwendige_uebung: [],
    a_aehnliche_uebung: [],
  });

  // Firestore/Storage
  const db = getFirestore();
  const storage = getStorage();

  // Layout
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isPlaylistOpen, setIsPlaylistOpen] = useState(false);

  const userCache = useRef({});

  // -------------------------
  // STATES: Fortschritt + Resume-Dialog
  // -------------------------
  const [maxProgress, setMaxProgress] = useState(0);
  const [savedProgress, setSavedProgress] = useState(0);
  const [shouldPromptResume, setShouldPromptResume] = useState(false);
  const [resumeFromSaved, setResumeFromSaved] = useState(false);
  const [resumeDialogOpen, setResumeDialogOpen] = useState(false);

  /**
   * Speichert den Fortschritt in videos/{videoId}/progress/{userId}
   * UND im User-Dokument: lastWatchedVideo
   */
  const storeProgressInDB = async (pct) => {
    if (!currentUser || pct <= 0) return;
    try {
      // 1) Fortschritt in Video-Collection
      const docRef = doc(db, 'videos', id, 'progress', currentUser.uid);
      await setDoc(
        docRef,
        {
          progress: pct,
          updatedAt: serverTimestamp(),
        },
        { merge: true }
      );
      console.log('Fortschritt gespeichert:', pct, '%');

      // 2) Gleichzeitig "zuletzt angesehenes Video" im User-Dokument aktualisieren
      const userDocRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userDocRef, {
        lastWatchedVideo: {
          videoId: id,
          updatedAt: serverTimestamp(),
        },
      });
      console.log('lastWatchedVideo aktualisiert:', id);
    } catch (err) {
      console.error('Fehler beim Speichern des Fortschritts:', err);
    }
  };

  // Video pausiert -> sofort speichern
  const handlePause = () => {
    storeProgressInDB(maxProgress);
  };

  // ---------------------------------------
  // 1) Video aus DB laden
  // ---------------------------------------
  useEffect(() => {
    const fetchVideo = async () => {
      try {
        const videoRef = doc(db, 'videos', id);
        const videoSnap = await getDoc(videoRef);
        if (!videoSnap.exists()) {
          setError('Video nicht gefunden.');
          setLoading(false);
          return;
        }
        const videoData = { id: videoSnap.id, ...videoSnap.data() };

        // Falls vimeo_id => URL
        if (!videoData.url && videoData.vimeo_id) {
          const regex = /vimeo\.com\/(\d+)/;
          const match = videoData.vimeo_id.match(regex);
          if (match && match[1]) {
            videoData.url = `https://player.vimeo.com/video/${match[1]}`;
          } else {
            videoData.url = videoData.vimeo_id;
          }
        }

        setVideo(videoData);

        // Trainerinfo
        if (videoData.trainerId) {
          const trainerRef = doc(db, 'users', videoData.trainerId);
          const trainerSnap = await getDoc(trainerRef);
          if (trainerSnap.exists()) {
            const trainerData = trainerSnap.data();
            let profilePictureURL = trainerData.profilePictureURL || '';

            if (!profilePictureURL && trainerData.profilePicturePath) {
              try {
                const profilePicRef = ref(
                  storage,
                  trainerData.profilePicturePath
                );
                profilePictureURL = await getDownloadURL(profilePicRef);
              } catch (error) {
                console.error('Fehler beim Trainerbild:', error);
              }
            }
            setTrainerInfo({
              firstName: trainerData.firstName || '',
              lastName: trainerData.lastName || '',
              profilePictureURL,
            });
          }
        }

        // Benutzerbewertung
        if (currentUser) {
          const ratingRef = doc(db, 'videos', id, 'ratings', currentUser.uid);
          const ratingSnap = await getDoc(ratingRef);
          if (ratingSnap.exists()) {
            setUserRating(ratingSnap.data().rating);
          }
        }

        // Video-Abschluss
        if (currentUser) {
          const completionRef = doc(
            db,
            'videos',
            id,
            'completions',
            currentUser.uid
          );
          const completionSnap = await getDoc(completionRef);
          if (completionSnap.exists()) {
            setHasCompleted(completionSnap.data().completed);
          }
        }
      } catch (err) {
        console.error('Fehler beim Abrufen des Videos:', err);
        setError('Fehler beim Abrufen des Videos.');
      } finally {
        setLoading(false);
      }
    };

    fetchVideo();
  }, [id, db, storage, currentUser]);

  // ---------------------------------------
  // 2) Verwandte Videos
  // ---------------------------------------
  useEffect(() => {
    if (!video) return;
    const fetchRelated = async () => {
      try {
        const newRelated = {
          a_notwendige_uebung: [],
          a_aehnliche_uebung: [],
        };
        for (const category of categories) {
          const videoIds = video[category];
          if (Array.isArray(videoIds) && videoIds.length > 0) {
            const MAX_REL = 20;
            const limited = videoIds.slice(0, MAX_REL);
            const batches = [];
            for (let i = 0; i < limited.length; i += 10) {
              batches.push(limited.slice(i, i + 10));
            }
            const promises = batches.map(async (batch) => {
              const q = query(
                collection(db, 'videos'),
                where(documentId(), 'in', batch)
              );
              const qsnap = await getDocs(q);
              return qsnap.docs.map((docSnap) => ({
                id: docSnap.id,
                ...docSnap.data(),
              }));
            });
            const results = await Promise.all(promises);
            newRelated[category] = results.flat();
          }
        }
        setRelatedVideos(newRelated);
      } catch (err) {
        console.error('Fehler beim Abrufen der verwandten Videos:', err);
        setError('Fehler beim Abrufen der verwandten Videos.');
      }
    };
    fetchRelated();
  }, [video, db, categories]);

  // ---------------------------------------
  // 3) Kommentare (Echtzeit-Listener)
  // ---------------------------------------
  useEffect(() => {
    if (!video?.id) return;

    userCache.current = {};

    const cRef = collection(db, 'videos', video.id, 'comments');
    const cQ = query(cRef, orderBy('createdAt', 'asc'));

    const unsub = onSnapshot(cQ, async (snapshot) => {
      const commentsList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      if (commentsList.length === 0) {
        setComments([]);
        return;
      }

      const enrichedComments = await Promise.all(
        commentsList.map(async (comment) => {
          if (comment.userId) {
            if (userCache.current[comment.userId]) {
              return { ...comment, ...userCache.current[comment.userId] };
            }
            const userDocSnap = await getDoc(doc(db, 'users', comment.userId));
            if (userDocSnap.exists()) {
              const userData = userDocSnap.data();
              let profilePictureURL = userData.profilePictureURL || '';

              if (!profilePictureURL && userData.profilePicturePath) {
                try {
                  const profilePicRef = ref(
                    storage,
                    userData.profilePicturePath
                  );
                  profilePictureURL = await getDownloadURL(profilePicRef);
                } catch (error) {
                  console.error('Fehler beim Profilbild:', error);
                }
              }

              const enrichedData = {
                firstName: userData.firstName || '',
                lastName: userData.lastName || '',
                profilePictureURL,
              };

              userCache.current[comment.userId] = enrichedData;
              return { ...comment, ...enrichedData };
            }
          }
          // Fallback
          return {
            ...comment,
            firstName: comment.username.split(' ')[0] || 'Anonym',
            lastName: comment.username.split(' ').slice(1).join(' ') || '',
            profilePictureURL: '',
          };
        })
      );

      // Duplikate entfernen
      const uniqueComments = [];
      const seenIds = new Set();
      enrichedComments.forEach((c) => {
        if (!seenIds.has(c.id)) {
          seenIds.add(c.id);
          uniqueComments.push(c);
        }
      });
      setComments(uniqueComments);
    });

    return () => unsub();
  }, [video, db, storage]);

  // ---------------------------------------
  // 4) Fortschritt abrufen -> Resume-Dialog
  // ---------------------------------------
  useEffect(() => {
    if (!video?.id || !currentUser) return;

    const checkProgress = async () => {
      try {
        const docRef = doc(db, 'videos', video.id, 'progress', currentUser.uid);
        const snap = await getDoc(docRef);
        if (snap.exists()) {
          const data = snap.data();
          if (data.progress > 10) {
            setSavedProgress(data.progress);
            setShouldPromptResume(true);
          }
        }
      } catch (error) {
        console.error('Fehler beim Lesen des Fortschritts:', error);
      }
    };

    checkProgress();
  }, [video, currentUser]);

  // Falls >10% => Dialog öffnen
  useEffect(() => {
    if (shouldPromptResume) {
      setResumeDialogOpen(true);
    }
  }, [shouldPromptResume]);

  // Beim Verlassen => höchsten Fortschritt speichern
  useEffect(() => {
    return () => {
      storeProgressInDB(maxProgress);
    };
  }, [maxProgress]);

  // Player-Fortschritt
  const handleProgress = (state) => {
    const watchedPercentage = Math.round(state.played * 100);
    if (watchedPercentage > maxProgress) {
      setMaxProgress(watchedPercentage);
    }
    if (watchedPercentage >= 95 && !hasCompleted && currentUser) {
      markAsCompleted();
    }
  };

  // Video als abgeschlossen markieren
  const markAsCompleted = async () => {
    if (!currentUser) return;
    try {
      const completionRef = doc(
        db,
        'videos',
        id,
        'completions',
        currentUser.uid
      );
      await setDoc(completionRef, {
        completed: true,
        completedAt: serverTimestamp(),
      });
      setHasCompleted(true);
    } catch (err) {
      console.error('Fehler beim Markieren des Videos als abgeschlossen:', err);
    }
  };

  // Resume-Dialog: "Weiterschauen"
  const handleResumeYes = () => {
    setResumeFromSaved(true);
    setResumeDialogOpen(false);
    setShouldPromptResume(false);

    // Sofort vorspulen
    if (playerRef.current && savedProgress > 0) {
      const fraction = savedProgress / 100;
      playerRef.current.seekTo(fraction, 'fraction');
      console.log('Weiterschauen ab:', fraction);
    }
  };

  // Resume-Dialog: "Neu starten"
  const handleResumeNo = () => {
    setResumeFromSaved(false);
    setResumeDialogOpen(false);
    setShouldPromptResume(false);
  };

  // InfoBox
  const toggleInfoBox = () => {
    setIsInfoBoxExpanded((prev) => !prev);
  };

  // Playlist Drawer (mobil)
  const handleOpenPlaylist = () => {
    setIsPlaylistOpen(true);
  };

  // Kommentare
  const handleAddComment = async () => {
    if (newComment.trim() === '') {
      setCommentError('Kommentar darf nicht leer sein.');
      return;
    }
    if (!currentUser) {
      setCommentError('Bitte melde dich an, um zu kommentieren.');
      return;
    }
    if (!video?.id) {
      setCommentError('Video ist nicht verfügbar.');
      return;
    }
    try {
      const commentsRef = collection(db, 'videos', video.id, 'comments');
      const userDocRef = doc(db, 'users', currentUser.uid);
      const userSnap = await getDoc(userDocRef);

      let profilePictureURL = '';
      let firstName = '';
      let lastName = '';
      if (userSnap.exists()) {
        const userData = userSnap.data();
        profilePictureURL = userData.profilePictureURL || '';
        firstName = userData.firstName || '';
        lastName = userData.lastName || '';

        if (!profilePictureURL && userData.profilePicturePath) {
          try {
            const profilePicRef = ref(storage, userData.profilePicturePath);
            profilePictureURL = await getDownloadURL(profilePicRef);
          } catch (error) {
            console.error('Fehler beim Profilbild:', error);
          }
        }
      }
      await addDoc(commentsRef, {
        text: newComment.trim(),
        createdAt: serverTimestamp(),
        userId: currentUser.uid,
        username: `${firstName} ${lastName}`.trim() || 'Anonym',
        profilePictureURL: profilePictureURL,
      });

      setNewComment('');
      setCommentSuccess('Kommentar erfolgreich hinzugefügt.');
      setCommentError('');
      setIsCommentBoxExpanded(false);
    } catch (err) {
      console.error('Fehler beim Hinzufügen des Kommentars:', err);
      setCommentError('Fehler beim Hinzufügen des Kommentars.');
      setCommentSuccess('');
    }
  };

  // Kommentar bearbeiten
  const handleEditComment = (commentId, currentText) => {
    setEditingCommentId(commentId);
    setEditedCommentText(currentText);
  };
  const handleSaveEdit = async (commentId) => {
    if (editedCommentText.trim() === '') {
      setCommentError('Kommentar darf nicht leer sein.');
      return;
    }
    try {
      const commentRef = doc(db, 'videos', video.id, 'comments', commentId);
      await updateDoc(commentRef, {
        text: editedCommentText.trim(),
        editedAt: serverTimestamp(),
      });
      setEditingCommentId(null);
      setEditedCommentText('');
      setCommentSuccess('Kommentar erfolgreich bearbeitet.');
      setCommentError('');
    } catch (err) {
      console.error('Fehler beim Bearbeiten des Kommentars:', err);
      setCommentError('Fehler beim Bearbeiten des Kommentars.');
      setCommentSuccess('');
    }
  };
  const handleCancelEdit = () => {
    setEditingCommentId(null);
    setEditedCommentText('');
    setCommentError('');
  };

  // Video bewerten
  const handleRateVideo = async (event, newValue) => {
    if (!currentUser) {
      setCommentError('Bitte melde dich an, um das Video zu bewerten.');
      return;
    }
    try {
      const ratingRef = doc(db, 'videos', id, 'ratings', currentUser.uid);
      await setDoc(ratingRef, {
        rating: newValue,
        ratedAt: serverTimestamp(),
      });
      setUserRating(newValue);
      setCommentSuccess('Video erfolgreich bewertet.');
      setCommentError('');
    } catch (err) {
      console.error('Fehler beim Bewerten des Videos:', err);
      setCommentError('Fehler beim Bewerten des Videos.');
      setCommentSuccess('');
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress style={{ color: '#d6bc8b' }} />
      </Box>
    );
  }
  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        marginTop: '60px',
        backgroundImage: 'url(/images/backgrounds/kth_fasern.png)',
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        height: 'auto',
        padding: '20px',
        overflowY: 'auto',
        color: '#252833',
      }}
    >
      {/* Resume-Dialog */}
      <Dialog
        open={resumeDialogOpen}
        onClose={handleResumeNo}
        PaperProps={{
          sx: {
            backgroundColor: '#252833',
            color: '#ffffff',
            borderRadius: '16px',
            maxWidth: '500px',
            width: '100%',
          },
        }}
      >
        <DialogTitle
          sx={{ textAlign: 'center', fontWeight: 'bold', color: '#d5bc8b' }}
        >
          {/* Logo oben */}
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            <img
              src="/images/logos/kth_logo_learning.png"
              alt="KTH Logo Learning"
              style={{ width: '80px', height: 'auto' }}
            />
          </Box>
          Du hast dieses Video bereits gestartet
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ color: '#fff' }}>
            Möchtest du weiterschauen oder neu starten?
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center',
            pb: 2,
          }}
        >
          <Button
            variant="outlined"
            onClick={handleResumeNo}
            sx={{
              textTransform: 'none',
              borderColor: '#d5bc8b',
              color: '#d5bc8b',
              '&:hover': {
                borderColor: '#ffffff',
                color: '#ffffff',
              },
            }}
          >
            Neu starten
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setResumeFromSaved(true);
              setResumeDialogOpen(false);
              setShouldPromptResume(false);
              // Direkt vorspulen
              if (playerRef.current && savedProgress > 0) {
                const fraction = savedProgress / 100;
                playerRef.current.seekTo(fraction, 'fraction');
                console.log('Weiterschauen ab:', fraction);
              }
            }}
            sx={{
              textTransform: 'none',
              backgroundColor: '#d5bc8b',
              color: '#252833',
              '&:hover': {
                backgroundColor: '#c0a57b',
                color: '#252833',
              },
            }}
          >
            Weiter ab letzter Position
          </Button>
        </DialogActions>
      </Dialog>

      {/* 2-spaltiges Layout */}
      <Box
        sx={{
          maxWidth: '1400px',
          margin: '0 auto',
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: '8fr 4fr' },
          gap: '40px',
        }}
      >
        {/* Linke Spalte */}
        <Box>
          {/* Videoplayer (kein Overlay mehr) */}
          <Box
            sx={{
              width: '100%',
              mb: 0,
              height: { xs: '300px', md: '550px' },
              position: 'relative',
            }}
          >
            {video.url ? (
              <ReactPlayer
                url={video.url}
                controls
                width="100%"
                height="100%"
                style={{ position: 'absolute', top: 0, left: 0 }}
                playing={false}
                ref={playerRef}
                onProgress={handleProgress}
                onPause={handlePause}
                onReady={() => {
                  if (
                    resumeFromSaved &&
                    savedProgress > 0 &&
                    playerRef.current
                  ) {
                    const fraction = savedProgress / 100;
                    playerRef.current.seekTo(fraction, 'fraction');
                    console.log('onReady -> spule zu:', fraction);
                  }
                }}
              />
            ) : (
              <Typography variant="body1" color="error">
                Video-URL nicht verfügbar.
              </Typography>
            )}
          </Box>

          {/* Zugehörige Übungen nur auf Mobile als Chip */}
          {isMobile && (
            <Box sx={{ mb: 0.5, display: 'flex', justifyContent: 'center' }}>
              <Chip
                label="zugehörige Übungen"
                onClick={handleOpenPlaylist}
                sx={{
                  backgroundColor: COLORS.dunkelBlau,
                  color: '#ffffff',
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  transition: 'background-color 0.3s, color 0.3s',
                  '&:hover': {
                    backgroundColor: COLORS.hellesBraun,
                    color: COLORS.dunkelBlau,
                  },
                }}
                clickable
              />
            </Box>
          )}

          {/* Video Details & Sterne-Bewertung */}
          <Box
            sx={{
              mb: 2,
              border: '1px solid transparent',
              borderRadius: '8px',
              transition:
                'background-color 0.3s ease, max-height 0.3s ease, padding 0.3s ease',
              backgroundColor: isInfoBoxExpanded
                ? COLORS.darkBlue
                : 'transparent',
              padding: '16px',
              cursor: 'pointer',
              maxHeight: isInfoBoxExpanded ? '500px' : 'auto',
              overflow: 'hidden',
            }}
            onClick={toggleInfoBox}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'space-between',
                alignItems: isMobile ? 'flex-start' : 'center',
                gap: isMobile ? 2 : 0,
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: '#d6bc8b', mb: isMobile ? 0 : 0.5 }}
              >
                {video.video_titel}
              </Typography>
              {!isMobile && (
                <Rating
                  name="video-rating"
                  value={userRating}
                  onChange={handleRateVideo}
                  precision={1}
                  sx={{ color: '#d5bc8b' }}
                />
              )}
            </Box>
            {isMobile && (
              <Box sx={{ mt: 1 }}>
                <Rating
                  name="video-rating-mobile"
                  value={userRating}
                  onChange={handleRateVideo}
                  precision={1}
                  sx={{ color: '#d5bc8b' }}
                />
              </Box>
            )}
            <Divider sx={{ backgroundColor: '#3f4b54', my: 1 }} />

            {video.text ? (
              <Typography variant="body1" sx={{ color: '#ffffff' }}>
                {video.text}
              </Typography>
            ) : (
              <Typography variant="body1" sx={{ color: '#ffffff' }}>
                Keine Beschreibung verfügbar.
              </Typography>
            )}

            {isInfoBoxExpanded && trainerInfo && (
              <Box
                sx={{
                  mt: 2,
                  transition: 'opacity 0.3s ease',
                  opacity: 1,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Avatar
                    src={trainerInfo.profilePictureURL || ''}
                    alt={`${trainerInfo.firstName} ${trainerInfo.lastName}`}
                    sx={{ mr: 2 }}
                  >
                    {trainerInfo.firstName?.charAt(0) || 'T'}
                  </Avatar>
                  <Typography variant="body1">
                    Trainer: {trainerInfo.firstName} {trainerInfo.lastName}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>

          {/* Kommentarsektion */}
          <Box sx={{ mb: 4 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                mb: 4,
                mt: 4,
              }}
            >
              {!isCommentBoxExpanded ? (
                <Button
                  variant="outlined"
                  startIcon={<SendIcon />}
                  onClick={() => setIsCommentBoxExpanded(true)}
                  sx={{
                    color: COLORS.dunkelBlau,
                    borderColor: COLORS.dunkelBlau,
                    textTransform: 'none',
                    justifyContent: 'flex-start',
                    width: 'auto',
                    minWidth: '200px',
                    '&:hover': {
                      borderColor: COLORS.hellesBraun,
                      color: COLORS.hellesBraun,
                    },
                  }}
                >
                  Video kommentieren
                </Button>
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                  <TextField
                    variant="outlined"
                    multiline
                    minRows={4}
                    maxRows={8}
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    placeholder="Dein Kommentar..."
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            color="primary"
                            aria-label="Kommentar hinzufügen"
                            onClick={handleAddComment}
                            edge="end"
                          >
                            <SendIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        handleAddComment();
                      }
                    }}
                    sx={{
                      backgroundColor: '#ffffff',
                      borderRadius: '4px',
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#ccc',
                        },
                        '&:hover fieldset': {
                          borderColor: '#d6bc8b',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#d6bc8b',
                        },
                      },
                    }}
                  />
                  <IconButton
                    color="secondary"
                    aria-label="Bearbeitung abbrechen"
                    onClick={() => setIsCommentBoxExpanded(false)}
                  >
                    <CancelIcon />
                  </IconButton>
                </Box>
              )}
              {commentError && (
                <Alert severity="error" onClose={() => setCommentError('')}>
                  {commentError}
                </Alert>
              )}
              {commentSuccess && (
                <Alert severity="success" onClose={() => setCommentSuccess('')}>
                  {commentSuccess}
                </Alert>
              )}
            </Box>
            <Typography variant="h6" gutterBottom sx={{ color: '#d6bc8b' }}>
              Kommentare
            </Typography>
            {comments.length === 0 ? (
              <Typography variant="body2">
                Keine Kommentare vorhanden.
              </Typography>
            ) : (
              <Box>
                {comments.map((comment) => {
                  const isEditing = editingCommentId === comment.id;
                  return (
                    <Card
                      key={comment.id}
                      sx={{
                        backgroundColor: COLORS.darkBlue,
                        borderRadius: '16px',
                        padding: '16px',
                        marginBottom: '16px',
                        position: 'relative',
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                        <Avatar
                          src={comment.profilePictureURL || ''}
                          alt={`${comment.firstName} ${comment.lastName}`}
                          sx={{ mr: 2 }}
                        >
                          {comment.firstName?.charAt(0) || 'A'}
                        </Avatar>
                        <Box sx={{ flex: 1 }}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              mb: 1,
                              justifyContent: 'space-between',
                            }}
                          >
                            <Box>
                              <Typography
                                variant="subtitle2"
                                sx={{ color: '#d6bc8b', mr: 1 }}
                              >
                                {`${comment.firstName.trim()} ${comment.lastName}` ||
                                  'Anonym'}
                              </Typography>
                              <Typography
                                variant="caption"
                                sx={{ color: '#3f4b54' }}
                              >
                                {comment.editedAt
                                  ? formatTimestamp(
                                      comment.editedAt.toDate(),
                                      true
                                    )
                                  : comment.createdAt
                                    ? formatTimestamp(
                                        comment.createdAt.toDate(),
                                        false
                                      )
                                    : 'Unbekannt'}
                              </Typography>
                            </Box>
                            {!isEditing &&
                              currentUser &&
                              currentUser.uid === comment.userId && (
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    handleEditComment(comment.id, comment.text)
                                  }
                                  aria-label="Kommentar bearbeiten"
                                  sx={{
                                    color: '#3f4b54',
                                    '&:hover': {
                                      color: COLORS.hellesBraun,
                                    },
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                              )}
                          </Box>
                          {isEditing ? (
                            <Box>
                              <TextField
                                variant="outlined"
                                fullWidth
                                multiline
                                minRows={2}
                                maxRows={6}
                                value={editedCommentText}
                                onChange={(e) =>
                                  setEditedCommentText(e.target.value)
                                }
                                placeholder="Dein Kommentar..."
                                sx={{
                                  backgroundColor: '#ffffff',
                                  borderRadius: '4px',
                                  mb: 1,
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      borderColor: '#ccc',
                                    },
                                    '&:hover fieldset': {
                                      borderColor: '#d6bc8b',
                                    },
                                    '&.Mui-focused fieldset': {
                                      borderColor: '#d6bc8b',
                                    },
                                  },
                                }}
                              />
                              <Box sx={{ display: 'flex', gap: 1 }}>
                                <IconButton
                                  color="primary"
                                  aria-label="Kommentar speichern"
                                  onClick={() => handleSaveEdit(comment.id)}
                                >
                                  <SaveIcon />
                                </IconButton>
                                <IconButton
                                  color="secondary"
                                  aria-label="Bearbeitung abbrechen"
                                  onClick={handleCancelEdit}
                                >
                                  <CancelIcon />
                                </IconButton>
                              </Box>
                            </Box>
                          ) : (
                            <Typography
                              variant="body2"
                              sx={{ color: '#ffffff' }}
                            >
                              {comment.text}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Card>
                  );
                })}
              </Box>
            )}
          </Box>
        </Box>

        {/* Rechte Spalte: Playlist (Desktop) */}
        {!isMobile && (
          <Box sx={{ mt: { md: '100px' } }}>
            <Typography variant="h6" gutterBottom sx={{ color: '#d6bc8b' }}>
              Playlist
            </Typography>
            {categories.map(
              (category) =>
                relatedVideos[category] &&
                relatedVideos[category].length > 0 && (
                  <Box key={category} sx={{ mb: 3 }}>
                    {categoryTitleMap[category] && (
                      <Typography
                        variant="subtitle1"
                        sx={{ color: '#d6bc8b', mb: 0.5 }}
                      >
                        {categoryTitleMap[category]}
                      </Typography>
                    )}
                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr',
                        gap: 2,
                      }}
                    >
                      {relatedVideos[category].map((vid) => (
                        <Card
                          key={vid.id}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            backgroundColor: COLORS.anthracitRgba10,
                            '&:hover': {
                              backgroundColor: COLORS.anthracitRgba20,
                            },
                            padding: '8px',
                          }}
                          onClick={() => navigate(`/video/${vid.id}`)}
                        >
                          <CardMedia
                            component="img"
                            sx={{
                              width: 100,
                              height: 56,
                              objectFit: 'cover',
                              borderRadius: '4px',
                            }}
                            image={vid.thumbnailUrl}
                            alt={vid.video_titel}
                          />
                          <Box sx={{ ml: 2, flex: 1 }}>
                            <Typography
                              variant="subtitle1"
                              sx={{ color: '#d6bc8b', fontWeight: 'bold' }}
                            >
                              {vid.video_titel}
                            </Typography>
                          </Box>
                          {vid.duration && (
                            <Chip
                              label={formatDuration(vid.duration)}
                              size="small"
                              sx={{
                                backgroundColor: '#333',
                                color: '#fff',
                              }}
                            />
                          )}
                        </Card>
                      ))}
                    </Box>
                  </Box>
                )
            )}
          </Box>
        )}
      </Box>

      {/* Drawer (Playlist) mobil */}
      <Drawer
        anchor="right"
        open={isPlaylistOpen}
        onClose={() => setIsPlaylistOpen(false)}
        ModalProps={{ keepMounted: true }}
      >
        <Box
          sx={{
            width: 300,
            p: 2,
            backgroundColor: COLORS.darkBlueRgba80,
            height: '100%',
            color: '#ffffff',
            position: 'relative',
          }}
        >
          <IconButton
            onClick={() => setIsPlaylistOpen(false)}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: '#ffffff',
            }}
          >
            <CloseIcon />
          </IconButton>

          <Typography
            variant="h6"
            gutterBottom
            sx={{ color: '#d6bc8b', mt: 4 }}
          >
            Playlist
          </Typography>

          {categories.map(
            (category) =>
              relatedVideos[category] &&
              relatedVideos[category].length > 0 && (
                <Box key={category} sx={{ mb: 3 }}>
                  {categoryTitleMap[category] && (
                    <Typography
                      variant="subtitle1"
                      sx={{ color: '#d6bc8b', mb: 0.5 }}
                    >
                      {categoryTitleMap[category]}
                    </Typography>
                  )}
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr',
                      gap: 2,
                    }}
                  >
                    {relatedVideos[category].map((vid) => (
                      <Card
                        key={vid.id}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                          backgroundColor: COLORS.anthracitRgba10,
                          '&:hover': {
                            backgroundColor: COLORS.anthracitRgba20,
                          },
                          p: '8px',
                        }}
                        onClick={() => {
                          navigate(`/video/${vid.id}`);
                          setIsPlaylistOpen(false);
                        }}
                      >
                        <CardMedia
                          component="img"
                          sx={{
                            width: 80,
                            height: 45,
                            objectFit: 'cover',
                            borderRadius: '4px',
                          }}
                          image={vid.thumbnailUrl}
                          alt={vid.video_titel}
                        />
                        <Box sx={{ ml: 2, flex: 1 }}>
                          <Typography
                            variant="subtitle1"
                            sx={{ color: '#d6bc8b', fontWeight: 'bold' }}
                          >
                            {vid.video_titel}
                          </Typography>
                        </Box>
                        {vid.duration && (
                          <Chip
                            label={formatDuration(vid.duration)}
                            size="small"
                            sx={{ backgroundColor: '#333', color: '#fff' }}
                          />
                        )}
                      </Card>
                    ))}
                  </Box>
                </Box>
              )
          )}
        </Box>
      </Drawer>

      {/* Fehler-Snackbar */}
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setError(null)}
          severity="error"
          sx={{ width: '100%' }}
        >
          {error}
        </Alert>
      </Snackbar>

      {/* Erfolgs-Snackbar */}
      <Snackbar
        open={!!commentSuccess}
        autoHideDuration={3000}
        onClose={() => setCommentSuccess('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setCommentSuccess('')}
          severity="success"
          sx={{ width: '100%' }}
        >
          {commentSuccess}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default VideoDetail;
