import React, { useEffect, useState } from 'react';
import { db } from '../../config/firebase';
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  deleteDoc,
} from 'firebase/firestore';

import { DataGrid } from '@mui/x-data-grid';

import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  Alert,
  IconButton,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Paper,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

export default function VideosEditor() {
  // Lade-/Update-Status
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);

  // Videos
  const [videos, setVideos] = useState([]);
  const [originalVideos, setOriginalVideos] = useState([]);
  const [filteredVideos, setFilteredVideos] = useState([]);
  const [fetchedVideosCount, setFetchedVideosCount] = useState(0);

  // Suchfilter
  const [searchQuery, setSearchQuery] = useState('');

  // Updated Count
  const [updatedCount, setUpdatedCount] = useState(null);

  // Auswahl in DataGrid (Single-Selection)
  const [selectedRowId, setSelectedRowId] = useState(null);

  // UNDO-States
  const [lastVideos, setLastVideos] = useState([]);

  // Fehlernachrichten
  const [error, setError] = useState(null);

  // Zustand zur Überprüfung von Änderungen
  const [hasChanges, setHasChanges] = useState(false);

  // Dynamische Spalten und Sichtbarkeit (exklusive 'id')
  const [allFieldNames, setAllFieldNames] = useState([]);
  const [visibleFields, setVisibleFields] = useState([]);

  useEffect(() => {
    fetchAllVideos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Überprüfe, ob es Änderungen gibt
    const isDifferent = videos.some((video) => {
      const original = originalVideos.find((o) => o.id === video.id);
      if (!original) return true;
      for (let key in video) {
        if (key === 'id') continue;
        if (Array.isArray(video[key]) && Array.isArray(original[key])) {
          // Vergleiche Arrays als kommagetrennte Strings
          if (
            video[key].split(',').join(',') !==
            original[key].split(',').join(',')
          ) {
            return true;
          }
        } else {
          if (video[key] !== original[key]) {
            return true;
          }
        }
      }
      return false;
    });
    setHasChanges(isDifferent);
  }, [videos, originalVideos]);

  async function fetchAllVideos() {
    setLoading(true);
    setUpdating(false);
    setUpdatedCount(null);
    setError(null);

    try {
      const videosSnap = await getDocs(collection(db, 'videos'));
      const videosTemp = [];
      const fieldNamesSet = new Set();

      videosSnap.forEach((docSnap) => {
        const d = docSnap.data();
        const video = {
          id: docSnap.id,
          ...d,
        };

        // Sammle alle Feldnamen außer 'id'
        Object.keys(d).forEach((key) => {
          if (key !== 'id') {
            fieldNamesSet.add(key);
          }
        });

        // Handhabe Array-Felder als kommagetrennte Strings
        for (let key in video) {
          if (key === 'id') continue;
          if (Array.isArray(video[key])) {
            video[key] = video[key].join(', ');
          } else if (typeof video[key] !== 'string') {
            // Fallback für andere Typen
            video[key] = String(video[key]);
          }
        }

        videosTemp.push(video);
      });

      const fieldsArray = Array.from(fieldNamesSet);
      setAllFieldNames(fieldsArray);

      // Setze alle Felder als sichtbar, standardmäßig
      setVisibleFields(fieldsArray);

      setVideos(videosTemp);
      setOriginalVideos(videosTemp);
      setFilteredVideos(videosTemp);
      setFetchedVideosCount(videosTemp.length);

      // Reset Undo-Stores
      setLastVideos([]);
    } catch (err) {
      console.error('Fehler beim Laden der Videos:', err);
      setError(
        'Fehler beim Laden der Videos. Bitte überprüfen Sie die Konsole für Details.'
      );
    }
    setLoading(false);
  }

  // Such-Logik: Suche in allen string Feldern
  useEffect(() => {
    if (!searchQuery.trim()) {
      setFilteredVideos(videos);
      return;
    }

    const lowerCaseQuery = searchQuery.toLowerCase();

    const filtered = videos.filter((video) =>
      Object.keys(video).some(
        (key) =>
          key !== 'id' &&
          typeof video[key] === 'string' &&
          video[key].toLowerCase().includes(lowerCaseQuery)
      )
    );

    setFilteredVideos(filtered);
  }, [searchQuery, videos]);

  const handleCellEditCommit = (params) => {
    setLastVideos(JSON.parse(JSON.stringify(videos)));

    const { id, field, value } = params;

    // Hier könnte eine spezifische Logik für bestimmte Felder eingefügt werden
    // Für eine vollständig dynamische Lösung ist dies komplexer
    // Daher lassen wir es flexibel und lassen den Benutzer selbst die Eingabe formatieren

    let updatedValue = value;

    setFilteredVideos((prev) =>
      prev.map((video) =>
        video.id === id ? { ...video, [field]: updatedValue } : video
      )
    );
    setVideos((prev) =>
      prev.map((video) =>
        video.id === id ? { ...video, [field]: updatedValue } : video
      )
    );
  };

  const handleSelectionChange = (newSelection) => {
    if (!newSelection || newSelection.length === 0) {
      setSelectedRowId(null);
      return;
    }
    setSelectedRowId(newSelection[0]);
  };

  const handleUpdateAll = async () => {
    setUpdating(true);
    setUpdatedCount(null);
    setError(null);

    try {
      const changed = videos.filter((video) => {
        const orig = originalVideos.find((o) => o.id === video.id);
        if (!orig) return false;
        // Vergleichen Sie hier alle relevanten Felder
        for (let key in video) {
          if (key === 'id') continue;
          if (Array.isArray(video[key]) && Array.isArray(orig[key])) {
            // Vergleiche Arrays als kommagetrennte Strings
            if (
              video[key].split(',').join(',') !== orig[key].split(',').join(',')
            ) {
              return true;
            }
          } else {
            if (video[key] !== orig[key]) {
              return true;
            }
          }
        }
        return false;
      });

      let updatesCount = 0;
      for (const video of changed) {
        const docRef = doc(db, 'videos', video.id);
        const updateData = { ...video };
        delete updateData.id; // 'id' darf nicht aktualisiert werden

        await updateDoc(docRef, updateData);
        updatesCount++;
      }
      setUpdatedCount(updatesCount);
      setOriginalVideos(JSON.parse(JSON.stringify(videos)));
    } catch (err) {
      console.error('Fehler beim Speichern:', err);
      setError(
        'Fehler beim Aktualisieren. Bitte überprüfen Sie die Konsole für Details.'
      );
    }
    setUpdating(false);
  };

  const handleUndo = () => {
    setVideos(JSON.parse(JSON.stringify(lastVideos)));
    setFilteredVideos(JSON.parse(JSON.stringify(lastVideos)));
  };

  const handleDelete = async (id) => {
    if (!window.confirm('Möchten Sie dieses Video wirklich löschen?')) return;

    try {
      await deleteDoc(doc(db, 'videos', id));
      setVideos((prev) => prev.filter((video) => video.id !== id));
      setOriginalVideos((prev) => prev.filter((video) => video.id !== id));
      setFilteredVideos((prev) => prev.filter((video) => video.id !== id));
      setFetchedVideosCount((prev) => prev - 1);
      setUpdatedCount((prev) => (prev !== null ? prev + 1 : 1));
    } catch (err) {
      console.error('Fehler beim Löschen des Videos:', err);
      setError(
        'Fehler beim Löschen des Videos. Bitte überprüfen Sie die Konsole für Details.'
      );
    }
  };

  // Erstellen der dynamischen Spalten basierend auf den sichtbaren Feldern, plus 'id' als feste Spalte
  const getDynamicColumns = () => {
    // 'id' Spalte hinzufügen
    const idColumn = {
      field: 'id',
      headerName: 'Dokument ID',
      width: calculateColumnWidth('Dokument ID'),
      editable: false,
      flex: 1,
    };

    // Dynamische Spalten basierend auf sichtbaren Feldern
    const dynamicColumns = visibleFields.map((field) => ({
      field: field,
      headerName: field.replace(/_/g, ' ').toUpperCase(),
      width: calculateColumnWidth(field.replace(/_/g, ' ')),
      editable: true,
      flex: 1,
    }));

    // Aktionsspalte für Löschfunktion
    const actionsColumn = {
      field: 'actions',
      headerName: 'Aktionen',
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <IconButton
          color="error"
          onClick={() => handleDelete(params.row.id)}
          aria-label="delete"
        >
          <DeleteIcon />
        </IconButton>
      ),
    };

    // Rückgabe der Spalten: 'id' Spalte zuerst, dann dynamische, dann Aktionen
    return [idColumn, ...dynamicColumns, actionsColumn];
  };

  // Funktion zur Berechnung der Spaltenbreite basierend auf der Länge des Feldnamens
  const calculateColumnWidth = (headerName) => {
    const baseWidth = 10; // Grundbreite pro Zeichen
    const maxWidth = 300; // Maximale Breite
    const width = headerName.length * baseWidth;
    return width > maxWidth ? maxWidth : width;
  };

  // Handhabung der Checkbox-Änderungen
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setVisibleFields((prev) => [...prev, name]);
    } else {
      setVisibleFields((prev) => prev.filter((field) => field !== name));
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5" gutterBottom>
        Videos-Datenbank durchsuchen & bearbeiten
      </Typography>

      {/* Abschnitt für die Spaltenauswahl */}
      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="h6" gutterBottom>
          Sichtbare Spalten auswählen
        </Typography>
        <FormGroup row>
          {allFieldNames.map((field) => (
            <FormControlLabel
              key={field}
              control={
                <Checkbox
                  checked={visibleFields.includes(field)}
                  onChange={handleCheckboxChange}
                  name={field}
                />
              }
              label={field.replace(/_/g, ' ')}
            />
          ))}
        </FormGroup>
      </Paper>

      {/* Such- und Aktionsbereich */}
      <Box sx={{ display: 'flex', gap: 2, mb: 2, flexWrap: 'wrap' }}>
        <TextField
          size="small"
          label="Suche"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ minWidth: 200 }}
        />
        <Button
          variant="contained"
          onClick={fetchAllVideos}
          disabled={loading || updating}
        >
          Neu laden
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={handleUpdateAll}
          disabled={loading || updating || !hasChanges}
        >
          Änderungen speichern
        </Button>
        <Button
          variant="outlined"
          onClick={handleUndo}
          disabled={loading || updating || lastVideos.length === 0}
        >
          Rückgängig
        </Button>
      </Box>

      {/* Status- und Fehleranzeigen */}
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <Typography variant="body1">
          {loading ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CircularProgress size={18} /> Lade Videos...
            </Box>
          ) : (
            <>
              {fetchedVideosCount} Videos geladen
              {updatedCount !== null &&
                ` – ${updatedCount} Videos aktualisiert`}
              {updating && ' (aktualisiere...)'}
            </>
          )}
        </Typography>
      </Box>

      {error && (
        <Box sx={{ mb: 2 }}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}

      {/* DataGrid mit dynamischen Spalten */}
      <Box
        sx={{
          height: 800,
          width: '100%',
          overflowX: 'auto', // Ermöglicht seitliches Scrollen
        }}
      >
        <DataGrid
          rows={filteredVideos}
          columns={getDynamicColumns()}
          getRowId={(row) => row.id}
          editMode="cell"
          onCellEditCommit={handleCellEditCommit}
          rowSelectionModel={selectedRowId ? [selectedRowId] : []}
          onRowSelectionModelChange={(newSelection) =>
            handleSelectionChange(newSelection)
          }
          loading={loading}
          disableSelectionOnClick
          pageSize={25}
          rowsPerPageOptions={[25, 50, 100]}
          autoHeight
          sx={{
            '& .MuiDataGrid-cell': {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }}
        />
      </Box>
    </Box>
  );
}
