// src/components/pages/Lernplattform/MeetingOverview.js

import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Collapse,
  Avatar,
  Divider,
} from '@mui/material';
import {
  collection,
  query,
  where,
  orderBy,
  getDocs,
  limit,
} from 'firebase/firestore';
import { db } from '../../../config/firebase';
import { format } from 'date-fns';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';

// Styled Components

// Hintergrundbild, das den gesamten Bildschirm abdeckt
const Background = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  backgroundImage: 'url(/images/backgrounds/kth_fasern.png)', // Pfad zum Hintergrundbild
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  zIndex: -1, // Hintergrund hinter dem Inhalt
});

// Container für den Inhalt, zentriert mit maximaler Breite
const Container = styled(Box)({
  padding: '20px',
  maxWidth: '1200px',
  margin: 'auto',
  position: 'relative',
  zIndex: 1, // Inhalt über dem Hintergrund
  paddingBottom: '80px', // Hinzugefügtes Padding für zusätzlichen Platz
  boxSizing: 'border-box',
});

// Hero-Bereich für jedes Meeting
const HeroBox = styled(Box)(({ expanded }) => ({
  position: 'relative',
  width: '100%',
  minHeight: '300px', // Mindesthöhe hinzugefügt
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: '8px 8px 0 0',
  cursor: 'pointer',
  overflow: 'hidden',
  '&:hover .watermark': {
    opacity: 0,
  },
}));

// Overlay im Hero-Bereich
const HeroOverlay = styled(motion.div)(({ expanded }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  padding: '10px',
  background: expanded
    ? 'linear-gradient(to bottom, rgba(0, 0, 0, 0.7), #3f4b54)'
    : 'linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3))',
  transition: 'background 0.8s ease',
}));

// Wasserzeichen im Hero-Bereich
const Watermark = styled(Box)(({ isHovered, isExpanded }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  height: '100%',
  aspectRatio: '1 / 2', // Seitenverhältnis beibehalten
  backgroundImage: 'url(/images/logos/kth_logo_livecall.png)', // Pfad zum Wasserzeichen
  backgroundSize: '200% auto', // Doppelte Breite für den Effekt
  backgroundPosition: 'left center', // Linke Hälfte anzeigen
  backgroundRepeat: 'no-repeat',
  opacity: isHovered || isExpanded ? 0 : 0.3, // Ausblenden bei Hover oder geöffnet
  pointerEvents: 'none', // Keine Interaktion mit dem Wasserzeichen
  transition: 'opacity 0.8s ease', // Übergangseffekt
}));

// Details des Meetings
const MeetingDetails = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px',
  backgroundColor: '#3f4b54', // Hintergrundfarbe der Details
  borderRadius: '0 0 8px 8px', // Nur die unteren Ecken abgerundet
  boxShadow: '0 4px 12px rgba(0,0,0,0.3)',
  color: '#fff',
});

// Abschnitt innerhalb der Meeting-Details
const DetailsSection = styled(Box)({
  flex: 1,
  padding: '10px',
});

// Trainer-Abschnitt innerhalb der Meeting-Details
const TrainerSection = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
});

// Platzhalter für vergangene Meetings
const PlaceholderBox = styled(Box)({
  padding: '20px',
  textAlign: 'center',
  backgroundColor: '#3f4b54',
  color: '#fff',
  borderRadius: '8px',
  marginTop: '20px',
  boxShadow: '0 4px 12px rgba(0,0,0,0.3)',
});

// Fehleranzeige
const ErrorBox = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: '20px',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 1300,
}));

const MeetingOverview = () => {
  const [meetings, setMeetings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedMeeting, setExpandedMeeting] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMeetings = async () => {
      try {
        console.log('Fetching meetings...');
        const q = query(
          collection(db, 'meetings'),
          where('start_time', '>=', new Date()), // Nur zukünftige Meetings
          orderBy('start_time', 'asc'),
          limit(10) // Begrenze auf die nächsten 10 Meetings
        );
        const querySnapshot = await getDocs(q);
        const meetingsData = querySnapshot.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));
        console.log('Fetched Meetings:', meetingsData);
        setMeetings(meetingsData);
      } catch (error) {
        console.error('Fehler beim Abrufen der Meetings:', error);
        setError(
          'Fehler beim Abrufen der Meetings. Bitte versuche es später erneut.'
        );
      } finally {
        setLoading(false);
        console.log('Loading state set to false');
      }
    };

    fetchMeetings();
  }, []);

  const toggleExpandMeeting = (id) => {
    setExpandedMeeting((prev) => (prev === id ? null : id));
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          minHeight: '50vh',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ position: 'relative', minHeight: '100vh' }}>
      {/* Hintergrundbild */}
      <Background />
      <Container>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ textAlign: 'center', mb: 4, color: '#d6bc8b' }}
        >
          Nächste Meetings
        </Typography>

        {/* Fehleranzeige */}
        {error && (
          <Box sx={{ mt: 2 }}>
            <Typography
              variant="body1"
              color="error"
              sx={{ textAlign: 'center' }}
            >
              {error}
            </Typography>
          </Box>
        )}

        {/* Meetings anzeigen */}
        {meetings.length === 0 ? (
          <Typography
            sx={{
              color: '#ffffff',
              textAlign: 'center',
              mt: 2,
              fontSize: { xs: '1rem', sm: '1.2rem' },
            }}
          >
            Keine bevorstehenden Meetings gefunden.
          </Typography>
        ) : (
          meetings.map((meeting) => (
            <Box key={meeting.id} sx={{ marginBottom: '20px' }}>
              {/* Hero-Bereich */}
              <HeroBox
                expanded={expandedMeeting === meeting.id}
                sx={{
                  backgroundImage: `url(${meeting.headerImageUrl || '/images/default-meeting.jpg'})`,
                }}
                onClick={() => toggleExpandMeeting(meeting.id)}
              >
                {/* Watermark */}
                <Watermark
                  className="watermark"
                  isHovered={expandedMeeting === meeting.id}
                  isExpanded={expandedMeeting === meeting.id}
                />
                <HeroOverlay
                  expanded={expandedMeeting === meeting.id}
                  animate={{
                    background:
                      expandedMeeting === meeting.id
                        ? 'linear-gradient(to bottom, rgba(0, 0, 0, 0.7), #3f4b54)'
                        : 'linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3))',
                  }}
                  transition={{ duration: 0.8 }}
                >
                  <Typography variant="h5" sx={{ color: '#d5bc8b' }}>
                    {meeting.title || 'Untitled Meeting'}
                  </Typography>
                </HeroOverlay>
              </HeroBox>

              {/* Meeting-Details */}
              <Collapse
                in={expandedMeeting === meeting.id}
                timeout="auto"
                unmountOnExit
              >
                <MeetingDetails>
                  {/* Datum & Uhrzeit */}
                  <DetailsSection>
                    <Typography variant="body1">
                      <strong>Datum:</strong>{' '}
                      {meeting.start_time &&
                      typeof meeting.start_time.toDate === 'function'
                        ? format(
                            new Date(meeting.start_time.toDate()),
                            'dd.MM.yyyy'
                          )
                        : 'Unbekannt'}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Uhrzeit:</strong>{' '}
                      {meeting.start_time &&
                      typeof meeting.start_time.toDate === 'function'
                        ? format(new Date(meeting.start_time.toDate()), 'HH:mm')
                        : 'Unbekannt'}{' '}
                      Uhr
                    </Typography>
                  </DetailsSection>

                  {/* Beschreibung */}
                  <DetailsSection>
                    <Typography variant="body1">
                      {meeting.longDescription ||
                        'Keine Beschreibung verfügbar.'}
                    </Typography>
                  </DetailsSection>

                  {/* Trainer */}
                  <DetailsSection>
                    <TrainerSection>
                      <Avatar
                        src={meeting.avatarUrl || '/images/default-avatar.png'}
                        alt={meeting.trainerName || 'Trainer'}
                        sx={{ width: 50, height: 50 }}
                        loading="lazy" // Lazy Loading für Avatar
                      />
                      <Box>
                        <Typography variant="body1">
                          <strong>
                            {meeting.trainerName || 'Nicht zugewiesen'}
                          </strong>
                        </Typography>
                        <Typography variant="body2">
                          {meeting.trainerBio ||
                            'Keine Beschreibung verfügbar.'}
                        </Typography>
                      </Box>
                    </TrainerSection>
                  </DetailsSection>
                </MeetingDetails>
              </Collapse>
            </Box>
          ))
        )}

        {/* Abschnitt für vergangene Meetings */}
        <Divider sx={{ my: 4, backgroundColor: '#d5bc8b' }} />
        <Typography
          variant="h4"
          gutterBottom
          sx={{ textAlign: 'center', mb: 2, color: '#d6bc8b' }}
        >
          Aufzeichnungen vergangener Livestreams
        </Typography>
        <PlaceholderBox>
          <Typography variant="body1">
            Hier werden später die Aufzeichnungen vergangener Livestreams
            angezeigt.
          </Typography>
        </PlaceholderBox>
      </Container>
    </Box>
  );
};

export default MeetingOverview;
