import React, { useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  TextField,
  IconButton,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import {
  AdminPanelSettings as AdminIcon,
  Person as UserIcon,
  Delete as DeleteIcon,
  Description as InvoiceIcon, // Icon für Rechnung
  Close as CloseIcon,
  Security as RoleIcon, // Icon für Rollenänderung
  DoNotDisturbAlt as CancelSubscriptionIcon, // Icon für Abonnement kündigen
  LockReset as PasswordResetIcon, // Icon für Passwort Rücksetzen
  TrendingUp as UpgradeIcon, // Icon für Upgrade vorschlagen
  Info as InfoIcon, // Icon für Alle Informationen anzeigen
} from '@mui/icons-material';
import { httpsCallable } from 'firebase/functions';
import { auth, functions, db } from '../../config/firebase'; // Stelle sicher, dass 'db' korrekt importiert ist
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore'; // Firestore-Funktionen importieren

const UserManagement = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [error, setError] = useState('');
  const [deletingUserId, setDeletingUserId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showAdminsOnly, setShowAdminsOnly] = useState(false);
  const [openInvoiceDialog, setOpenInvoiceDialog] = useState(false);
  const [selectedInvoiceUrl, setSelectedInvoiceUrl] = useState('');
  const [roleMenuAnchorEl, setRoleMenuAnchorEl] = useState(null);
  const [selectedUserForRole, setSelectedUserForRole] = useState(null);
  const [updatingUserId, setUpdatingUserId] = useState(null);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [selectedUserInfo, setSelectedUserInfo] = useState(null);

  // Auth-Listener
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  // Benutzer abrufen, wenn ein aktueller Benutzer vorhanden ist
  useEffect(() => {
    if (currentUser) {
      fetchUsers();
    }
  }, [currentUser]);

  // Funktion zum Abrufen der Benutzer
  const fetchUsers = async () => {
    setLoadingUsers(true);
    setError('');
    try {
      const listAllUsers = httpsCallable(functions, 'listAllUsers');
      const result = await listAllUsers();
      const usersFromAuth = result.data.users;

      // Fetch additional user data from Firestore
      const usersWithAdditionalData = await Promise.all(
        usersFromAuth.map(async (user) => {
          const userDocRef = doc(db, 'users', user.uid);
          const userDocSnap = await getDoc(userDocRef);

          let eventLabel = null;
          let billingStatus = null;
          let productName = null;
          let subscriptionEndDate = null;
          let subscriptionStatus = null;
          let createdAt = null;

          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            console.log(`Benutzerdaten für ${user.uid}:`, userData);

            // Mapping der benötigten Felder
            eventLabel = userData.event_label || 'Nicht verfügbar';
            billingStatus = userData.billing_status || 'Nicht verfügbar';
            productName = userData.product_name || 'Nicht verfügbar';
            subscriptionEndDate =
              userData.subscriptionEndDate || 'Nicht verfügbar';
            subscriptionStatus =
              userData.subscriptionStatus || 'Nicht verfügbar';
            createdAt = userData.createdAt
              ? new Date(userData.createdAt.seconds * 1000).toLocaleDateString()
              : 'Nicht verfügbar';
          } else {
            console.log(`Kein Benutzerdokument für UID ${user.uid}`);
          }

          return {
            ...user,
            event_label: eventLabel,
            billing_status: billingStatus,
            product_name: productName,
            subscriptionEndDate,
            subscriptionStatus,
            createdAt,
          };
        })
      );

      setUsers(usersWithAdditionalData);
    } catch (err) {
      console.error('Fehler beim Abrufen der Benutzerdaten:', err);
      setError('Fehler beim Laden der Benutzerdaten.');
    } finally {
      setLoadingUsers(false);
    }
  };

  // Funktion zum Löschen eines Benutzers
  const handleDeleteUser = async (uid) => {
    const confirmDelete = window.confirm(
      'Sind Sie sicher, dass Sie diesen Benutzer löschen möchten?'
    );
    if (!confirmDelete) return;

    setDeletingUserId(uid);
    setError('');

    try {
      const deleteUser = httpsCallable(functions, 'deleteUser');
      const result = await deleteUser({ uid });
      alert(result.data.message || 'Benutzer erfolgreich gelöscht.');
      setUsers((prevUsers) => prevUsers.filter((user) => user.uid !== uid));
    } catch (err) {
      console.error(err);
      setError('Fehler beim Löschen des Benutzers.');
    } finally {
      setDeletingUserId(null);
    }
  };

  // Funktion zum Öffnen des Rechnungs-Popups
  const handleOpenInvoice = (invoiceUrl) => {
    setSelectedInvoiceUrl(invoiceUrl);
    setOpenInvoiceDialog(true);
  };

  // Funktion zum Schließen des Rechnungs-Popups
  const handleCloseInvoiceDialog = () => {
    setOpenInvoiceDialog(false);
    setSelectedInvoiceUrl('');
  };

  // Funktionen zum Öffnen und Schließen des Rollen-Menüs
  const handleRoleMenuOpen = (event, user) => {
    setRoleMenuAnchorEl(event.currentTarget);
    setSelectedUserForRole(user);
  };

  const handleRoleMenuClose = () => {
    setRoleMenuAnchorEl(null);
    setSelectedUserForRole(null);
  };

  // Funktion zum Ändern der Benutzerrolle
  const handleRoleChange = async (role) => {
    if (!selectedUserForRole) return;

    const uid = selectedUserForRole.uid;

    setUpdatingUserId(uid);
    setError('');

    try {
      const setUserRole = httpsCallable(functions, 'setUserRole');
      const result = await setUserRole({ uid, role });
      alert(result.data.message || 'Benutzerrolle erfolgreich geändert.');
      fetchUsers();
    } catch (err) {
      console.error(err);
      setError('Fehler beim Setzen der Benutzerrolle.');
    } finally {
      setUpdatingUserId(null);
      handleRoleMenuClose();
    }
  };

  // Funktion zum Anzeigen aller Informationen eines Benutzers
  const handleShowAllInfo = (user) => {
    setSelectedUserInfo(user);
    setInfoDialogOpen(true);
  };

  const handleCloseInfoDialog = () => {
    setInfoDialogOpen(false);
    setSelectedUserInfo(null);
  };

  // Erweiterte Filterung der Benutzer basierend auf Suchbegriff und Admin-Filter
  const filteredUsers = users.filter((user) => {
    if (showAdminsOnly && user.role !== 'admin') {
      return false;
    }
    if (searchTerm) {
      const searchableString = `
        ${user.firstName} 
        ${user.lastName} 
        ${user.email} 
        ${user.product_name} 
        ${user.subscriptionEndDate} 
        ${user.event_label} 
        ${user.billing_status}
      `.toLowerCase();
      return searchableString.includes(searchTerm.toLowerCase());
    }
    return true;
  });

  // Anzeige, wenn kein aktueller Benutzer vorhanden ist
  if (!currentUser) {
    return (
      <Typography variant="h6" sx={{ textAlign: 'center', mt: 4 }}>
        Bitte melden Sie sich als Administrator an.
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        p: 3,
        maxWidth: '1600px',
        margin: '0 auto',
        backgroundColor: 'rgba(255, 255, 255, 0.9)', // Halbtransparenter weißer Hintergrund für bessere Lesbarkeit
        borderRadius: '8px',
        boxShadow: 3, // Schatten für modernen Look
        minHeight: '100vh', // Hintergrund über die gesamte Höhe des Bildschirms
      }}
    >
      <Typography variant="h4" gutterBottom>
        Benutzerverwaltung
      </Typography>
      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}
      <Box
        sx={{
          mb: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 2,
        }}
      >
        <TextField
          label="Nach Namen, E-Mail, Abo-Variante etc. suchen"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ width: '300px' }}
        />
        <Button
          variant={showAdminsOnly ? 'contained' : 'outlined'}
          onClick={() => setShowAdminsOnly(!showAdminsOnly)}
        >
          {showAdminsOnly
            ? 'Alle Benutzer anzeigen'
            : 'Nur Administratoren anzeigen'}
        </Button>
      </Box>
      {loadingUsers ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer
          component={Paper}
          sx={{
            border: '1px solid #d5bc8b',
            backgroundColor: 'white',
          }}
        >
          <Table>
            <TableHead sx={{ backgroundColor: '#303e49' }}>
              <TableRow>
                <TableCell sx={{ color: 'white' }}>Rolle</TableCell>
                <TableCell sx={{ color: 'white' }}>Name</TableCell>
                <TableCell sx={{ color: 'white' }}>Email-Adresse</TableCell>
                <TableCell sx={{ color: 'white' }}>
                  Registrierungsdatum
                </TableCell>
                <TableCell sx={{ color: 'white' }}>Abo-Variante</TableCell>
                <TableCell sx={{ color: 'white' }}>Zugriff endet am</TableCell>
                <TableCell sx={{ color: 'white' }}>Status</TableCell>
                <TableCell sx={{ color: 'white' }}>Billing-Status</TableCell>
                <TableCell sx={{ color: 'white' }}>Aktionen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    Keine Benutzer gefunden.
                  </TableCell>
                </TableRow>
              ) : (
                filteredUsers.map((user) => (
                  <TableRow
                    key={user.uid}
                    sx={{
                      '&:hover': { backgroundColor: '#f5f5f5' },
                      transition: 'background-color 0.3s',
                    }}
                  >
                    {/* Rolle */}
                    <TableCell>
                      <Tooltip
                        title={
                          user.role === 'admin' ? 'Administrator' : 'Benutzer'
                        }
                      >
                        {user.role === 'admin' ? <AdminIcon /> : <UserIcon />}
                      </Tooltip>
                    </TableCell>

                    {/* Name */}
                    <TableCell>
                      {user.firstName || 'Nicht verfügbar'}{' '}
                      {user.lastName || 'Nicht verfügbar'}
                    </TableCell>

                    {/* Email-Adresse */}
                    <TableCell>{user.email || 'Nicht verfügbar'}</TableCell>

                    {/* Registrierungsdatum */}
                    <TableCell>{user.createdAt || 'Nicht verfügbar'}</TableCell>

                    {/* Abo-Variante */}
                    <TableCell>
                      {user.product_name || 'Nicht verfügbar'}
                    </TableCell>

                    {/* Zugriff endet am */}
                    <TableCell>
                      {user.subscriptionEndDate || 'Nicht verfügbar'}
                    </TableCell>

                    {/* Status */}
                    <TableCell>
                      {user.event_label ? (
                        <Typography color="blue">{user.event_label}</Typography>
                      ) : (
                        'Nicht verfügbar'
                      )}
                    </TableCell>

                    {/* Billing-Status */}
                    <TableCell>
                      {user.billing_status ? (
                        <Typography
                          color={
                            user.billing_status.toLowerCase() === 'paid'
                              ? 'green'
                              : user.billing_status.toLowerCase() === 'pending'
                                ? 'orange'
                                : 'red'
                          }
                        >
                          {user.billing_status.charAt(0).toUpperCase() +
                            user.billing_status.slice(1)}
                        </Typography>
                      ) : (
                        'Nicht verfügbar'
                      )}
                    </TableCell>

                    {/* Aktionen */}
                    <TableCell>
                      {/* Aktionen innerhalb der Tabelle */}
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {/* Icon zum Ändern der Rolle */}
                        <Tooltip title="Rolle ändern">
                          <IconButton
                            color="primary"
                            onClick={(e) => handleRoleMenuOpen(e, user)}
                            sx={{ ml: 0 }}
                          >
                            <RoleIcon />
                          </IconButton>
                        </Tooltip>

                        {/* Invoice IconButton */}
                        {user.invoice_url && (
                          <Tooltip title="Letzte Rechnung anzeigen">
                            <IconButton
                              color="primary"
                              onClick={() =>
                                handleOpenInvoice(user.invoice_url)
                              }
                              sx={{ ml: 1 }}
                            >
                              <InvoiceIcon />
                            </IconButton>
                          </Tooltip>
                        )}

                        {/* Abonnement kündigen IconButton */}
                        {user.isActive && (
                          <Tooltip title="Abonnement kündigen">
                            <IconButton
                              color="primary"
                              onClick={() => {
                                // Implement handleCancelSubscription
                                console.log('Abonnement kündigen', user.uid);
                              }}
                              sx={{ ml: 1 }}
                            >
                              <CancelSubscriptionIcon />
                            </IconButton>
                          </Tooltip>
                        )}

                        {/* Passwort Rücksetzen IconButton */}
                        <Tooltip title="Passwort zurücksetzen">
                          <IconButton
                            color="primary"
                            onClick={() => {
                              // Implement handlePasswordReset
                              console.log('Passwort zurücksetzen', user.uid);
                            }}
                            sx={{ ml: 1 }}
                          >
                            <PasswordResetIcon />
                          </IconButton>
                        </Tooltip>

                        {/* Upgrade vorschlagen IconButton */}
                        <Tooltip title="Upgrade vorschlagen">
                          <IconButton
                            color="primary"
                            onClick={() => {
                              // Implement handleUpgrade
                              console.log('Upgrade vorschlagen', user.uid);
                            }}
                            sx={{ ml: 1 }}
                          >
                            <UpgradeIcon />
                          </IconButton>
                        </Tooltip>

                        {/* Alle Informationen anzeigen IconButton */}
                        <Tooltip title="Alle Informationen anzeigen">
                          <IconButton
                            color="primary"
                            onClick={() => handleShowAllInfo(user)}
                            sx={{ ml: 1 }}
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>

                        {/* Delete IconButton */}
                        <Tooltip title="Benutzer löschen">
                          <IconButton
                            color="error"
                            onClick={() => handleDeleteUser(user.uid)}
                            disabled={deletingUserId === user.uid}
                            sx={{ ml: 1 }}
                          >
                            {deletingUserId === user.uid ? (
                              <CircularProgress size={24} />
                            ) : (
                              <DeleteIcon />
                            )}
                          </IconButton>
                        </Tooltip>
                      </Box>

                      {/* Menü zum Auswählen der Rolle */}
                      {selectedUserForRole &&
                        selectedUserForRole.uid === user.uid && (
                          <Menu
                            anchorEl={roleMenuAnchorEl}
                            open={Boolean(roleMenuAnchorEl)}
                            onClose={handleRoleMenuClose}
                          >
                            <MenuItem onClick={() => handleRoleChange('admin')}>
                              Admin
                            </MenuItem>
                            <MenuItem onClick={() => handleRoleChange('user')}>
                              Benutzer
                            </MenuItem>
                          </Menu>
                        )}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Dialog zum Anzeigen der Rechnung */}
      <Dialog
        open={openInvoiceDialog}
        onClose={handleCloseInvoiceDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          Letzte Rechnung
          <IconButton
            aria-label="close"
            onClick={handleCloseInvoiceDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {selectedInvoiceUrl ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.open(selectedInvoiceUrl, '_blank')}
              sx={{ mb: 2 }}
            >
              Rechnung öffnen
            </Button>
          ) : (
            <Typography>Keine Rechnung verfügbar.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInvoiceDialog} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog zum Anzeigen aller Informationen */}
      <Dialog
        open={infoDialogOpen}
        onClose={handleCloseInfoDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          Alle Informationen
          <IconButton
            aria-label="close"
            onClick={handleCloseInfoDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {selectedUserInfo ? (
            <Box>
              {/* Basisinformationen */}
              <Typography variant="body1">
                <strong>UID:</strong> {selectedUserInfo.uid}
              </Typography>
              <Typography variant="body1">
                <strong>Name:</strong> {selectedUserInfo.firstName}{' '}
                {selectedUserInfo.lastName}
              </Typography>
              <Typography variant="body1">
                <strong>Email:</strong> {selectedUserInfo.email}
              </Typography>
              <Typography variant="body1">
                <strong>Rolle:</strong> {selectedUserInfo.role}
              </Typography>
              <Typography variant="body1">
                <strong>Registrierungsdatum:</strong>{' '}
                {selectedUserInfo.createdAt}
              </Typography>
              <Typography variant="body1">
                <strong>Abo-Variante:</strong> {selectedUserInfo.product_name}
              </Typography>
              <Typography variant="body1">
                <strong>Zugriff endet am:</strong>{' '}
                {selectedUserInfo.subscriptionEndDate}
              </Typography>
              <Typography variant="body1">
                <strong>Status:</strong> {selectedUserInfo.event_label}
              </Typography>
              <Typography variant="body1">
                <strong>Billing-Status:</strong>{' '}
                {selectedUserInfo.billing_status}
              </Typography>
              {/* Dynamisches Hinzufügen weiterer Felder */}
              {Object.entries(selectedUserInfo).map(([key, value]) => {
                // Ausschließen der bereits angezeigten Felder
                const excludedFields = [
                  'uid',
                  'role',
                  'firstName',
                  'lastName',
                  'email',
                  'createdAt',
                  'product_name',
                  'subscriptionEndDate',
                  'event_label',
                  'billing_status',
                  'invoice_url',
                ];
                if (excludedFields.includes(key)) return null;
                if (!value) return null;

                // Formatieren der Feldnamen
                const formattedFieldName = formatFieldName(key);

                // Datum- und Zeitfelder formatieren
                let displayValue = value;
                if (
                  key === 'order_date' ||
                  key === 'order_time' ||
                  key.includes('date') ||
                  key.includes('time') ||
                  key.includes('EndDate') ||
                  key.includes('LoginDate')
                ) {
                  displayValue = new Date(
                    value.seconds * 1000
                  ).toLocaleString();
                }

                // Link-Felder als Buttons darstellen
                if (typeof value === 'string' && value.startsWith('http')) {
                  return (
                    <Typography variant="body1" key={key} sx={{ mb: 1 }}>
                      <strong>{formattedFieldName}:</strong>{' '}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => window.open(value, '_blank')}
                      >
                        Link öffnen
                      </Button>
                    </Typography>
                  );
                }

                return (
                  <Typography variant="body1" key={key} sx={{ mb: 1 }}>
                    <strong>{formattedFieldName}:</strong> {displayValue}
                  </Typography>
                );
              })}
            </Box>
          ) : (
            <Typography>Keine Informationen verfügbar.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInfoDialog} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

// Helper function to format field names (convert snake_case or camelCase to readable format)
const formatFieldName = (fieldName) => {
  // Replace underscores with spaces and capitalize first letters
  return fieldName
    .replace(/_/g, ' ')
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

export default UserManagement;
