import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Checkbox,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Slider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  FormControlLabel,
  Divider,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../config/firebase'; // Pfad entsprechend anpassen

const SimilarVideosList = ({ criteria, open, onClose, onSelect }) => {
  const [loading, setLoading] = useState(true);
  const [videos, setVideos] = useState([]);
  const [error, setError] = useState(null);
  const [selectedVideoIds, setSelectedVideoIds] = useState([]);

  // Filterzustände
  const [titleFilter, setTitleFilter] = useState('');
  const [themenbereichFilter, setThemenbereichFilter] = useState('');
  const [kapitelFilter, setKapitelFilter] = useState('');

  // Gewichtungen und Modalzustand
  const [weightings, setWeightings] = useState({
    'Was wird gefördert': 1,
    'Körperlicher Anspruch': 1,
    'Elemente der Übung': 1,
    'Elemente der Übung Reiten': 1,
  });
  const [weightModalOpen, setWeightModalOpen] = useState(false);

  // Zustand für zusätzliche Bewertungskriterienaktivierung
  const [additionalCriteria, setAdditionalCriteria] = useState({
    'Mindestalter des Pferdes': false,
    Sicherheit: false,
    Level: false,
  });

  const calculateMatchScore = (
    video,
    criteria,
    weightings,
    additionalCriteria
  ) => {
    let score = 0;
    let totalCategories = 0;
    let matchPercentages = {};

    const calculateForCategory = (categoryKey, videoField, criteriaField) => {
      if (!criteriaField || criteriaField.length === 0) {
        matchPercentages[categoryKey] = { matches: 0, total: 0, percentage: 0 };
        return;
      }
      const videoArray = Array.isArray(videoField) ? videoField : [];
      const videoSet = new Set(videoArray.map((item) => item.toLowerCase()));
      const matches = criteriaField.filter((item) =>
        videoSet.has(item.toLowerCase())
      ).length;
      const total = criteriaField.length;
      const percentage = total > 0 ? Math.round((matches / total) * 100) : 0;
      matchPercentages[categoryKey] = { matches, total, percentage };
      const weight = weightings[categoryKey] || 1;
      score += isNaN(percentage) ? 0 : percentage * weight;
      totalCategories += 1;
    };

    // Hauptkategorien
    calculateForCategory(
      'Was wird gefördert',
      video.was_gefoerdert,
      criteria.was_gefoerdert
    );
    calculateForCategory(
      'Körperlicher Anspruch',
      video.p_koerperlicher_anspruch,
      criteria.p_koerperlicher_anspruch
    );
    calculateForCategory(
      'Elemente der Übung',
      video.p_elemente,
      criteria.p_elemente
    );
    calculateForCategory(
      'Elemente der Übung Reiten',
      video.p_elemente_reiten,
      criteria.p_elemente_reiten
    );

    // Zusätzliche Kriterien
    if (additionalCriteria['Mindestalter des Pferdes']) {
      calculateForCategory(
        'Mindestalter des Pferdes',
        video.mindestalter,
        criteria.mindestalter
      );
    }
    if (additionalCriteria['Sicherheit']) {
      calculateForCategory('Sicherheit', video.sicherheit, criteria.sicherheit);
    }
    if (additionalCriteria['Level']) {
      calculateForCategory('Level', video.level, criteria.level);
    }

    const overallPercentage =
      totalCategories > 0 ? Math.round(score / totalCategories) : 0;
    video.matchScore = isNaN(overallPercentage) ? 0 : overallPercentage;
    video.matchPercentages = matchPercentages;

    return overallPercentage;
  };

  const recalculateScores = (videosList, weightings, additionalCriteria) => {
    return videosList
      .map((video) => ({
        ...video,
        matchScore: calculateMatchScore(
          video,
          criteria,
          weightings,
          additionalCriteria
        ),
      }))
      .sort((a, b) => b.matchScore - a.matchScore);
  };

  useEffect(() => {
    const fetchVideos = async () => {
      setLoading(true);
      try {
        const videosRef = collection(db, 'videos');
        const videosSnapshot = await getDocs(videosRef);
        const fetchedVideos = videosSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const videosWithScore = recalculateScores(
          fetchedVideos,
          weightings,
          additionalCriteria
        );
        setVideos(videosWithScore);
      } catch (err) {
        console.error('Fehler beim Laden der Videos:', err);
        setError('Fehler beim Laden der Videos.');
      } finally {
        setLoading(false);
      }
    };

    const hasCriteria = Object.values(criteria).some((value) => {
      if (Array.isArray(value)) return value.length > 0;
      return value !== null && value !== undefined && value !== '';
    });

    if (hasCriteria && open) {
      fetchVideos();
    } else {
      setVideos([]);
      setLoading(false);
    }
  }, [criteria, open]); // weightings und additionalCriteria entfernt, um Neuberechnung manuell zu triggern

  const handleToggle = (videoId) => {
    setSelectedVideoIds((prev) => {
      if (prev.includes(videoId)) {
        return prev.filter((id) => id !== videoId);
      } else {
        return [...prev, videoId];
      }
    });
  };

  const handleConfirmSelection = () => {
    const selectedVideos = videos.filter((video) =>
      selectedVideoIds.includes(video.id)
    );
    onSelect(selectedVideos);
    onClose();
  };

  // Dropdown-Inhalte
  const uniqueThemenbereiche = Array.from(
    new Set(videos.map((v) => v.themenbereich).filter(Boolean))
  );
  const uniqueKapitel = Array.from(
    new Set(videos.map((v) => v.kapitel).filter(Boolean))
  );

  const filteredVideos = videos.filter((video) => {
    const matchesTitle = video.video_titel
      ?.toLowerCase()
      .includes(titleFilter.toLowerCase());
    const matchesThemenbereich = themenbereichFilter
      ? video.themenbereich === themenbereichFilter
      : true;
    const matchesKapitel = kapitelFilter
      ? video.kapitel === kapitelFilter
      : true;
    return matchesTitle && matchesThemenbereich && matchesKapitel;
  });

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" sx={{ mt: 2 }}>
        {error}
      </Typography>
    );
  }

  if (videos.length === 0) {
    return <Typography sx={{ mt: 2 }}>Keine Videos verfügbar.</Typography>;
  }

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: { xs: '100%', sm: '1600px', md: '1800px' },
        maxHeight: '85vh',
        display: 'flex',
        flexDirection: 'column',
        p: 2,
        overflow: 'hidden',
      }}
    >
      {/* Hauptinhalte mit bedingtem Blur-Effekt */}
      <Box sx={{ filter: weightModalOpen ? 'blur(5px)' : 'none' }}>
        {/* Filterfelder und Zahnradsymbol in einer Zeile */}
        <Box
          sx={{
            mb: 2,
            display: 'flex',
            flexWrap: 'wrap',
            gap: 2,
            alignItems: 'center',
          }}
        >
          <TextField
            label="Videotitel filtern"
            variant="outlined"
            size="small"
            value={titleFilter}
            onChange={(e) => setTitleFilter(e.target.value)}
          />
          <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
            <InputLabel>Themenbereich</InputLabel>
            <Select
              label="Themenbereich"
              value={themenbereichFilter}
              onChange={(e) => setThemenbereichFilter(e.target.value)}
            >
              <MenuItem value="">Alle</MenuItem>
              {uniqueThemenbereiche.map((area) => (
                <MenuItem key={area} value={area}>
                  {area}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
            <InputLabel>Kapitel</InputLabel>
            <Select
              label="Kapitel"
              value={kapitelFilter}
              onChange={(e) => setKapitelFilter(e.target.value)}
            >
              <MenuItem value="">Alle</MenuItem>
              {uniqueKapitel.map((chapter) => (
                <MenuItem key={chapter} value={chapter}>
                  {chapter}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <IconButton
            onClick={() => setWeightModalOpen(true)}
            aria-label="Bewertungskriterien anpassen"
          >
            <SettingsIcon />
          </IconButton>
        </Box>

        <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: '50vh',
              overflowY: 'auto',
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="center" width="5%">
                    <Checkbox
                      size="small"
                      indeterminate={
                        selectedVideoIds.length > 0 &&
                        selectedVideoIds.length < filteredVideos.length
                      }
                      checked={
                        filteredVideos.length > 0 &&
                        selectedVideoIds.length === filteredVideos.length
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedVideoIds(
                            filteredVideos.map((video) => video.id)
                          );
                        } else {
                          setSelectedVideoIds([]);
                        }
                      }}
                      inputProps={{ 'aria-label': 'Alle Videos auswählen' }}
                    />
                  </TableCell>
                  <TableCell>
                    <strong>Themenbereich</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Kapitel</strong>
                  </TableCell>
                  <TableCell sx={{ minWidth: 300 }}>
                    <strong>Videotitel</strong>
                  </TableCell>
                  <TableCell align="center">
                    <strong>Score</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredVideos.map((video) => (
                  <TableRow key={video.id} hover>
                    <TableCell align="center">
                      <Checkbox
                        size="small"
                        checked={selectedVideoIds.includes(video.id)}
                        onChange={() => handleToggle(video.id)}
                        inputProps={{
                          'aria-label': `Video auswählen: ${video.video_titel}`,
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {video.themenbereich || 'N/A'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {video.kapitel || 'N/A'}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ minWidth: 300 }}>
                      <Typography variant="subtitle1">
                        {video.video_titel || 'Untitled Video'}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="h6" color="success.main">
                        {video.matchScore}%
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 2,
            gap: 2,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirmSelection}
          >
            Bestätigen
          </Button>
          <Button variant="outlined" color="secondary" onClick={onClose}>
            Abbrechen
          </Button>
        </Box>
      </Box>

      {/* Bewertungskriterien-Modal */}
      <Dialog
        open={weightModalOpen}
        onClose={() => setWeightModalOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
            <img
              src="/images/logos/kth_logo_learning.png"
              alt="Logo"
              style={{ height: '30px', marginRight: '16px' }}
            />
            Bewertungskriterien anpassen
          </Box>
        </DialogTitle>
        <DialogContent dividers sx={{ maxHeight: '60vh', overflowY: 'auto' }}>
          {/* Abschnitt für Gewichtungen */}
          <Typography variant="h6" gutterBottom>
            Gewichtungen anpassen
          </Typography>
          {Object.keys(weightings).map((category) => (
            <Box key={category} sx={{ mb: 2 }}>
              <Typography gutterBottom>
                {category}: {weightings[category]}
              </Typography>
              <Slider
                value={weightings[category]}
                onChange={(e, newValue) => {
                  setWeightings((prev) => ({ ...prev, [category]: newValue }));
                }}
                aria-labelledby={`${category}-slider`}
                step={0.1}
                min={0}
                max={3}
                valueLabelDisplay="auto"
              />
            </Box>
          ))}

          <Divider sx={{ my: 2 }} />

          {/* Abschnitt für zusätzliche Bewertungskriterien */}
          <Typography variant="h6" gutterBottom>
            Weitere Bewertungskriterien
          </Typography>
          {Object.keys(additionalCriteria).map((crit) => (
            <Box key={crit} sx={{ mb: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={additionalCriteria[crit]}
                    onChange={(e) =>
                      setAdditionalCriteria((prev) => ({
                        ...prev,
                        [crit]: e.target.checked,
                      }))
                    }
                    name={crit}
                    color="primary"
                  />
                }
                label={crit}
              />
              <Slider
                value={weightings[crit] || 1}
                onChange={(e, newValue) => {
                  setWeightings((prev) => ({ ...prev, [crit]: newValue }));
                }}
                aria-labelledby={`${crit}-slider`}
                step={0.1}
                min={0}
                max={3}
                valueLabelDisplay="auto"
                disabled={!additionalCriteria[crit]}
                sx={{ mt: 1 }}
              />
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              // Manuell neu berechnen
              setVideos((prevVideos) =>
                recalculateScores(prevVideos, weightings, additionalCriteria)
              );
              setWeightModalOpen(false);
            }}
            color="primary"
          >
            Übernehmen
          </Button>
          <Button onClick={() => setWeightModalOpen(false)} color="secondary">
            Abbrechen
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SimilarVideosList;
