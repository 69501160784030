// src/components/Administration/AdminInfoBox.jsx

import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Avatar,
  Tooltip,
  Fab,
  Drawer,
  IconButton,
  Divider,
} from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { auth, db, database } from '../../config/firebase';
import { collection, query, getDocs } from 'firebase/firestore';
import { ref as dbRef, onValue } from 'firebase/database';
import GroupIcon from '@mui/icons-material/Group';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ErrorIcon from '@mui/icons-material/Error';
import BuildIcon from '@mui/icons-material/Build';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';

// Importiere Version und Release-Typ aus package.json
import packageJson from '../../../package.json'; // Passe den Pfad entsprechend deiner Projektstruktur an

// Styling für den Floating Action Button (FAB)
const StyledFab = styled(Fab)(({ theme, isMobile }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  left: isMobile ? '50%' : 'auto',
  right: isMobile ? 'auto' : theme.spacing(2),
  transform: isMobile ? 'translateX(-50%)' : 'none',
  zIndex: 1300, // Höher als andere Elemente
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

// Styling für den Drawer Header
const DrawerHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  justifyContent: 'flex-end',
}));

// Komponenten für die Informationspunkte
const InfoItem = ({ icon, label, count, tooltip }) => (
  <Tooltip title={tooltip || ''} placement="left">
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <Avatar sx={{ bgcolor: 'primary.main', mr: 2 }}>{icon}</Avatar>
      <Box>
        <Typography variant="body2">{label}</Typography>
        <Typography variant="h6">{count}</Typography>
      </Box>
    </Box>
  </Tooltip>
);

function AdminInfoBox() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [newRegistrations, setNewRegistrations] = useState(0);
  const [onlineUsers, setOnlineUsers] = useState(0);
  const [totalVideos, setTotalVideos] = useState(0);
  const [uiWarnings, setUiWarnings] = useState(0);
  const [uiErrors, setUiErrors] = useState(0);
  const [apiWarnings, setApiWarnings] = useState(0);
  const [apiErrors, setApiErrors] = useState(0);

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Definiert mobile Geräte als kleiner als 'sm'

  // Versions- und ReleaseType aus package.json
  const appVersion = packageJson.version;
  const releaseType = packageJson.releaseType;

  // State für den Drawer
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    let unsubscribeStatus = null;

    const fetchAdminStatusAndData = async () => {
      const user = auth.currentUser;
      console.log('Aktueller Benutzer:', user);

      if (user) {
        try {
          // Admin-Check über Custom Claims
          const idTokenResult = await user.getIdTokenResult(true); // Token aktualisieren
          const adminRole = idTokenResult.claims.role === 'admin';
          console.log('Admin-Status:', adminRole);
          setIsAdmin(adminRole);

          if (adminRole) {
            // 1. Abfragen der Gesamtzahl der Nutzer
            try {
              const usersCollection = collection(db, 'users');
              const usersQuery = query(usersCollection);
              const usersSnapshot = await getDocs(usersQuery);
              setTotalUsers(usersSnapshot.size); // Anzahl der Dokumente
              console.log('Gesamtzahl der Nutzer:', usersSnapshot.size);
            } catch (error) {
              console.error(
                'Fehler beim Abrufen der Gesamtzahl der Nutzer:',
                error
              );
            }

            // 2. Abfragen der Anzahl der Neuanmeldungen (Platzhalter)
            // Hier kannst du die tatsächliche Logik hinzufügen
            setNewRegistrations(0);
            console.log('Neuanmeldungen:', 0);

            // 3. Abfragen der Anzahl der Online Nutzer
            try {
              const usersRef = dbRef(database, 'users');
              unsubscribeStatus = onValue(
                usersRef,
                (snapshot) => {
                  try {
                    const usersData = snapshot.val();
                    console.log('Snapshot für Benutzer:', usersData);
                    if (usersData) {
                      let count = 0;
                      Object.entries(usersData).forEach(([uid, userData]) => {
                        if (
                          userData.status &&
                          userData.status.state === 'online'
                        ) {
                          count += 1;
                          console.log(`Benutzer ${uid} ist online.`);
                        } else {
                          console.log(
                            `Benutzer ${uid} ist offline oder hat keinen Status.`
                          );
                        }
                      });
                      console.log('Anzahl der online Benutzer:', count);
                      setOnlineUsers(count);
                    } else {
                      setOnlineUsers(0);
                      console.log('Keine Benutzerdaten gefunden.');
                    }
                    setLoading(false);
                  } catch (error) {
                    console.error('Fehler im onValue Callback:', error);
                    setLoading(false);
                  }
                },
                (error) => {
                  console.error('onValue Error:', error);
                  setLoading(false);
                }
              );
            } catch (error) {
              console.error('Fehler beim Abrufen der online Nutzer:', error);
              setLoading(false);
            }

            // 4. Abfragen der Anzahl der Videos
            try {
              const videosCollection = collection(db, 'videos');
              const videosQuery = query(videosCollection);
              const videosSnapshot = await getDocs(videosQuery);
              setTotalVideos(videosSnapshot.size); // Anzahl der Dokumente
              console.log('Anzahl der Videos:', videosSnapshot.size);
            } catch (error) {
              console.error(
                'Fehler beim Abrufen der Gesamtzahl der Videos:',
                error
              );
            }

            // 5. Platzhalterwerte für Warnungen und Fehler
            // Diese werden später durch tatsächliche Funktionen ersetzt
            setUiWarnings(0);
            setUiErrors(0);
            setApiWarnings(0);
            setApiErrors(0);
            console.log('Warnungen UI:', 0);
            console.log('Fehler UI:', 0);
            console.log('Warnungen API:', 0);
            console.log('Fehler API:', 0);
          } else {
            setLoading(false);
          }
        } catch (error) {
          console.error('Fehler beim ID Token:', error);
          setIsAdmin(false);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchAdminStatusAndData();

    return () => {
      if (unsubscribeStatus) unsubscribeStatus();
    };
  }, []);

  // Nur anzeigen, wenn der Benutzer ein Administrator ist und die Daten geladen sind
  if (!isAdmin || loading) {
    return null;
  }

  // Funktionen zum Öffnen und Schließen des Drawers
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const adminContent = (
    <Box sx={{ width: 300, padding: theme.spacing(2), color: '#fff' }}>
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          <CloseIcon style={{ color: '#fff' }} />
        </IconButton>
      </DrawerHeader>
      {/* Zusätzlicher Abstand über der Überschrift */}
      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
        Admin Panel
      </Typography>

      {/* Benutzerbezogene Informationen */}
      <Typography variant="subtitle1" gutterBottom>
        Benutzer
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <InfoItem
        icon={<GroupIcon />}
        label="Registrierte Nutzer"
        count={totalUsers}
        tooltip="Gesamte registrierte Benutzer in der Datenbank"
      />
      <InfoItem
        icon={<PersonAddIcon />}
        label="Neuanmeldungen"
        count={newRegistrations}
        tooltip="Anzahl der neu angemeldeten Benutzer"
      />
      <InfoItem
        icon={<OnlinePredictionIcon />}
        label="Online Nutzer"
        count={onlineUsers}
        tooltip="Benutzer, die derzeit online sind"
      />

      {/* Videobezogene Informationen */}
      <Typography variant="subtitle1" gutterBottom sx={{ mt: 3 }}>
        Videos
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <InfoItem
        icon={<VideoLibraryIcon />}
        label="Anzahl Videos"
        count={totalVideos}
        tooltip="Gesamte Videos in der Datenbank"
      />

      {/* Fehler und Warnungen */}
      <Typography variant="subtitle1" gutterBottom sx={{ mt: 3 }}>
        Fehler & Warnungen
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <InfoItem
        icon={<WarningAmberIcon />}
        label="Warnungen UI"
        count={uiWarnings}
        tooltip="Anzahl der UI-Warnungen"
      />
      <InfoItem
        icon={<ErrorIcon />}
        label="Fehler UI"
        count={uiErrors}
        tooltip="Anzahl der UI-Fehler"
      />
      <InfoItem
        icon={<WarningAmberIcon />}
        label="Warnungen API"
        count={apiWarnings}
        tooltip="Anzahl der API-Warnungen"
      />
      <InfoItem
        icon={<ErrorIcon />}
        label="Fehler API"
        count={apiErrors}
        tooltip="Anzahl der API-Fehler"
      />

      {/* Anzeige der Build-Version mit Symbol am unteren Ende */}
      <Box sx={{ mt: 4, display: 'flex', alignItems: 'center' }}>
        <BuildIcon sx={{ mr: 1, color: '#ccc' }} />
        <Typography variant="body2" color="#ccc">
          {releaseType === 'beta'
            ? `Beta v${appVersion}`
            : `Version ${appVersion}`}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <>
      <StyledFab
        color="primary"
        aria-label="admin"
        onClick={handleDrawerOpen}
        isMobile={isMobile}
      >
        <AdminPanelSettingsIcon />
      </StyledFab>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        PaperProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.95)',
            color: '#fff',
          },
        }}
      >
        {adminContent}
      </Drawer>
    </>
  );
}

export default AdminInfoBox;
