// src/components/Administration/VideoManagement/AddVideoModal.jsx

import React, { useState, useRef, useEffect } from 'react';

// Material-UI Komponenten & Icons
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  LinearProgress,
  Snackbar,
  IconButton,
  Chip,
  OutlinedInput,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Slider,
  RadioGroup,
  FormControlLabel,
  Radio,
  Avatar,
  Tabs,
  Tab,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EditIcon from '@mui/icons-material/Edit';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

// Firebase-Konfiguration
import { db } from '../../../config/firebase';
import {
  collection,
  addDoc,
  getDocs,
  doc,
  updateDoc,
  getDoc,
} from 'firebase/firestore';

// Video-Player
import ReactPlayer from 'react-player';
import axios from 'axios';

// react-chartjs-2 & chart.js (Radar-Diagramm)
import { Radar as RadarChart } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  RadarController,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip as ChartTooltip,
  Legend,
} from 'chart.js';

// Eigene Komponenten
import CreateTrainerDialog from './CreateTrainerDialog';
import SimilarVideosList from './SimilarVideosList';
import SetThumbnailModal from './SetThumbnailModal';

// Dropdown-Optionen
import {
  WAS_GEFOERDET_OPTIONS,
  KOERPERLICHER_ANSPRUCH_OPTIONS,
  ELEMENTE_DER_UEBUNG_OPTIONS,
  ELEMENTE_DER_UEBUNG_REITEN_OPTIONS,
  ZIELGRUPPE_OPTIONS,
} from './dropdownOptions';

// Validierungsfunktionen (Falls vorhanden)
import {
  isEignungValid,
  isVoraussetzungenValid,
  isElementeValid,
} from './validation';

// ChartJS-Komponenten registrieren
ChartJS.register(
  RadarController,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  ChartTooltip,
  Legend
);

/**
 * AddVideoModal
 *
 * Props:
 * - open (bool)
 * - onClose (func)
 * - videoData (object) -> falls vorhanden, Daten zum Bearbeiten
 *   oder ein neu vorbereitetes Objekt (z. B. { themenbereich, kapitelID })
 *
 * Features:
 * - Tabs für Videopreview, Vimeo-ID und Video-Upload
 * - Speichert die Vimeo-ID als volle URL (z. B. "https://vimeo.com/952857049")
 * - Dropdowns mit Chips (X-Button löscht nur den jeweiligen Eintrag)
 * - Button zum Ändern des Thumbnails, der das SetThumbnailModal öffnet
 * - Kein Schließen bei Backdrop-Klick (man muss "Abbrechen" oder "Schließen" wählen)
 */

const AddVideoModal = ({ open, onClose, videoData }) => {
  // ---- Refs ----
  const playerRef = useRef(null);
  const inputFileRef = useRef(null);

  // ---- Upload-States ----
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStep, setUploadStep] = useState('');

  // ---- Tabs ----
  // 0 = Preview, 1 = Vimeo-ID, 2 = Upload
  const [activeTab, setActiveTab] = useState(0);

  // ---- Video-Infos ----
  const [videoURL, setVideoURL] = useState(''); // Wird dem Player übergeben
  const [vimeo_id, setVimeo_id] = useState(''); // Hier speichern wir die volle URL (z. B. "https://vimeo.com/952857049")
  const [vimeoLink, setVimeoLink] = useState(''); // Zeigt, was im Textfeld "Vimeo-ID/URL" steht
  const [thumbnailUrl, setThumbnailUrl] = useState(null);

  // ---- Snackbar & Close-Dialog ----
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  // ---- Form-Daten (Kategorien, etc.) ----
  const [formData, setFormData] = useState({
    video_titel: '',
    status: 'veröffentlicht',

    // KI- / Zielgruppen-Felder
    themenbereiche: [],
    zielgruppe: [],
    text: '',
    probleme_herausforderungen: '',
    wird_gefoerdert: [],
    p_koerperlicher_anspruch: [],
    p_elemente: [],
    p_elemente_reiten: [],
    p_sicherheit: 0,
    p_mindestalter: 0,
    level: 0,
    trainer: '',
  });

  // ---- Player-States ----
  const [playing, setPlaying] = useState(false);
  const [played, setPlayed] = useState(0);
  const [duration, setDuration] = useState(0);
  const [seeking, setSeeking] = useState(false);

  // ---- Accordion ----
  const [expandedAccordion, setExpandedAccordion] = useState(false);

  // ---- Trainer / Themenbereiche ----
  const [themenbereicheOptions, setThemenbereicheOptions] = useState([]);
  const [trainers, setTrainers] = useState([]);
  const [openTrainerDialog, setOpenTrainerDialog] = useState(false);
  const [newTrainerData, setNewTrainerData] = useState({
    vorname: '',
    nachname: '',
    avatar: null,
    avatarUrl: '',
  });
  const [trainerUploadProgress, setTrainerUploadProgress] = useState(0);

  // ---- Video-Empfehlungen ----
  const [selectedSimilarVideos, setSelectedSimilarVideos] = useState([]);
  const [openSimilarVideosSelector, setOpenSimilarVideosSelector] =
    useState(false);

  const [selectedHelpfulExercises, setSelectedHelpfulExercises] = useState([]);
  const [openHelpfulExercisesSelector, setOpenHelpfulExercisesSelector] =
    useState(false);

  const [selectedRequiredExercises, setSelectedRequiredExercises] = useState(
    []
  );
  const [openRequiredExercisesSelector, setOpenRequiredExercisesSelector] =
    useState(false);

  // ---- Thumbnail ----
  const [openSetThumbnailModal, setOpenSetThumbnailModal] = useState(false);

  // -----------------------------------------------------------
  // useEffect: Lade Themenbereiche & Trainer, wenn Modal öffnet
  // -----------------------------------------------------------
  useEffect(() => {
    if (!open) return;

    const fetchOptions = async () => {
      try {
        // 1) Themenbereiche abrufen
        const themenRef = collection(db, 'settings', 'themenbereiche', 'list');
        const themenSnap = await getDocs(themenRef);
        const themenNames = themenSnap.docs.map((d) => d.data().name);
        setThemenbereicheOptions(themenNames);

        // 2) Trainer abrufen
        const trainerRef = collection(db, 'trainers');
        const trainerSnap = await getDocs(trainerRef);
        const allTrainers = trainerSnap.docs.map((d) => ({
          id: d.id,
          ...d.data(),
        }));
        setTrainers(allTrainers);
      } catch (err) {
        console.error('Fehler beim Laden der Themen/Trainer:', err);
      }
    };

    fetchOptions();
  }, [open]);

  // -----------------------------------------------------------
  // useEffect: Formular befüllen, wenn videoData vorliegt
  // -----------------------------------------------------------
  useEffect(() => {
    if (!open) return;

    if (videoData && videoData.id) {
      // ---- Bearbeiten eines bestehenden Videos ----
      setFormData({
        video_titel:
          typeof videoData.video_titel === 'string'
            ? videoData.video_titel.trim()
            : '',
        status: videoData.status || 'veröffentlicht',
        themenbereiche: Array.isArray(videoData.ki_themenbereiche)
          ? videoData.ki_themenbereiche
          : [],
        zielgruppe: Array.isArray(videoData.zielgruppe)
          ? videoData.zielgruppe
          : [],
        text: typeof videoData.text === 'string' ? videoData.text.trim() : '',
        probleme_herausforderungen:
          typeof videoData.probleme_herausforderungen === 'string'
            ? videoData.probleme_herausforderungen.trim()
            : '',
        wird_gefoerdert: Array.isArray(videoData.wird_gefoerdert)
          ? videoData.wird_gefoerdert
          : [],
        p_koerperlicher_anspruch: Array.isArray(
          videoData.p_koerperlicher_anspruch
        )
          ? videoData.p_koerperlicher_anspruch
          : [],
        p_elemente: Array.isArray(videoData.p_elemente)
          ? videoData.p_elemente
          : [],
        p_elemente_reiten: Array.isArray(videoData.p_elemente_reiten)
          ? videoData.p_elemente_reiten
          : [],
        p_sicherheit:
          typeof videoData.p_sicherheit === 'number'
            ? videoData.p_sicherheit
            : 0,
        p_mindestalter:
          typeof videoData.p_mindestalter === 'number'
            ? videoData.p_mindestalter
            : 0,
        level: typeof videoData.level === 'number' ? videoData.level : 0,
        trainer: videoData.trainer || '',
      });

      // Vimeo-ID (vollständige URL) laden
      const vimeoStored =
        typeof videoData.vimeo_id === 'string' ? videoData.vimeo_id.trim() : '';
      setVimeo_id(vimeoStored);
      setVimeoLink(vimeoStored);

      // Player-Preview: extrahiere die reine ID, falls nötig
      const realId = parseVimeoId(vimeoStored);
      if (realId) {
        setVideoURL(`https://vimeo.com/${realId}`);
      } else {
        // Falls parse fehlschlägt, könnte es sein,
        // dass vimeoStored selbst schon "https://vimeo.com/xxx" ist – dann einfach übernehmen
        setVideoURL(vimeoStored);
      }

      // Tabs (wenn wir ein Video haben, zeigen wir die Preview)
      setActiveTab(realId ? 0 : 2);

      // Trainer ggf. von Name -> ID mappen (falls in DB als Name gespeichert)
      if (typeof videoData.trainer === 'string') {
        const matchedTrainer = trainers.find(
          (t) => `${t.vorname} ${t.nachname}` === videoData.trainer
        );
        if (matchedTrainer) {
          setFormData((prev) => ({ ...prev, trainer: matchedTrainer.id }));
        }
      }

      // Empfehlungen laden
      loadVideosWithTitle(
        videoData.a_aehnliche_uebung || [],
        setSelectedSimilarVideos
      );
      loadVideosWithTitle(
        videoData.a_hilfreiche_voruebung || [],
        setSelectedHelpfulExercises
      );
      loadVideosWithTitle(
        videoData.a_notwendige_uebung || [],
        setSelectedRequiredExercises
      );

      // Thumbnail
      setThumbnailUrl(videoData.thumbnailUrl || '');
    } else {
      // ---- Neues Video ----
      resetFormForNewVideo();

      // Falls wir Props themenbereich, kapitelID im "videoData" haben (z. B. vom Parent),
      // dann diese ggf. irgendwo merken, falls du sie brauchst.
      if (videoData?.themenbereich) {
        // ...
      }
    }
  }, [open, videoData, trainers]);

  // -----------------------------------------------------------
  // Lade Titel zu Video-IDs
  // -----------------------------------------------------------
  const loadVideosWithTitle = async (ids, setFunc) => {
    try {
      const results = [];
      for (const id of ids) {
        const ref = doc(db, 'videos', id);
        const snap = await getDoc(ref);
        if (snap.exists()) {
          const v = snap.data();
          results.push({
            id: snap.id,
            video_titel: v.video_titel || '(Unbenannt)',
          });
        } else {
          results.push({ id, video_titel: '(Nicht gefunden)' });
        }
      }
      setFunc(results);
    } catch (err) {
      console.error('Fehler beim Laden der Video-Titel:', err);
      setFunc([]);
    }
  };

  // -----------------------------------------------------------
  // resetFormForNewVideo
  // -----------------------------------------------------------
  const resetFormForNewVideo = () => {
    setFormData({
      video_titel: '',
      status: 'veröffentlicht',
      themenbereiche: [],
      zielgruppe: [],
      text: '',
      probleme_herausforderungen: '',
      wird_gefoerdert: [],
      p_koerperlicher_anspruch: [],
      p_elemente: [],
      p_elemente_reiten: [],
      p_sicherheit: 0,
      p_mindestalter: 0,
      level: 0,
      trainer: '',
    });
    setVideoURL('');
    setVimeo_id('');
    setVimeoLink('');
    setThumbnailUrl(null);
    setUploadProgress(0);
    setUploadStep('');
    setActiveTab(0);

    setSelectedSimilarVideos([]);
    setSelectedHelpfulExercises([]);
    setSelectedRequiredExercises([]);
  };

  // -----------------------------------------------------------
  // Form-Eingaben
  // -----------------------------------------------------------
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleMultipleSelectChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]:
        typeof value === 'string'
          ? value.split(',').filter((v) => v.trim() !== '')
          : value,
    }));
  };

  const handleSliderChange = (field, newValue) => {
    setFormData((prev) => ({ ...prev, [field]: newValue }));
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : false);
  };

  // -----------------------------------------------------------
  // VIDEO UPLOAD
  // -----------------------------------------------------------
  const handleVideoUpload = async (file) => {
    if (!file) return;

    const safeTitle =
      typeof formData.video_titel === 'string'
        ? formData.video_titel.trim()
        : '' || file.name;
    setUploadStep('Bereite Upload vor...');
    setUploadProgress(0);

    try {
      // 1) Hole Upload-Link
      const initRes = await fetch(
        'https://europe-west3-keytohorses-firebase.cloudfunctions.net/api/vimeo/initiate-upload',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            fileName: file.name,
            fileSize: file.size,
            title: safeTitle,
          }),
        }
      );
      if (!initRes.ok) {
        const errMsg = await initRes.json();
        throw new Error(errMsg.error || 'Fehler beim initiate-upload');
      }
      const { uploadLink, videoId } = await initRes.json();

      setUploadStep('Video zu Vimeo hochladen...');
      const localUrl = URL.createObjectURL(file);
      setVideoURL(localUrl);

      // 2) Patch in Chunks
      const chunkSize = 1024 * 1024 * 128; // 128MB
      let offset = 0;

      const uploadChunk = async (chunkFile, off) => {
        const { headers } = await axios.patch(uploadLink, chunkFile, {
          headers: {
            'Tus-Resumable': '1.0.0',
            'Upload-Offset': off,
            'Content-Type': 'application/offset+octet-stream',
          },
          onUploadProgress: (p) => {
            const totalUploaded = off + p.loaded;
            const percentage = Math.round((totalUploaded * 100) / file.size);
            setUploadProgress(percentage);
            setUploadStep(`Upload zu Vimeo: ${percentage}%`);
          },
        });
        return parseInt(headers['upload-offset'], 10);
      };

      while (offset < file.size) {
        const chunk = file.slice(offset, offset + chunkSize);
        offset = await uploadChunk(chunk, offset);
      }

      setUploadProgress(100);
      setUploadStep('Finalisiere Upload...');

      // 3) finalize
      const finRes = await fetch(
        'https://europe-west3-keytohorses-firebase.cloudfunctions.net/api/vimeo/finalize-upload',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ videoId }),
        }
      );
      if (!finRes.ok) {
        const finMsg = await finRes.json();
        throw new Error(finMsg.error || 'Fehler beim finalize-upload');
      }
      const { vimeoUrl, thumbnailUrl: thumb } = await finRes.json();

      // Wir erhalten "vimeoUrl" z. B. "https://vimeo.com/952857049"
      setVimeo_id(typeof vimeoUrl === 'string' ? vimeoUrl.trim() : '');
      setVimeoLink(typeof vimeoUrl === 'string' ? vimeoUrl.trim() : '');
      setThumbnailUrl(thumb || null);

      // Player-Preview
      const realId = parseVimeoId(vimeoUrl);
      setVideoURL(`https://vimeo.com/${realId}`);

      setSnackbarMessage('Video erfolgreich hochgeladen!');
      setOpenSnackbar(true);

      // Wechsle auf Preview-Tab
      setActiveTab(0);
    } catch (err) {
      console.error('Fehler beim Video-Upload:', err);
      setSnackbarMessage(err.message || 'Fehler beim Video-Upload');
      setOpenSnackbar(true);
      setUploadProgress(0);
      setUploadStep('');
    }
  };

  const handleVideoDelete = () => {
    setVideoURL('');
    setVimeo_id('');
    setVimeoLink('');
    setThumbnailUrl(null);
    setUploadProgress(0);
    setUploadStep('');
    setActiveTab(2);
    if (inputFileRef.current) {
      inputFileRef.current.value = null;
    }
  };

  // -----------------------------------------------------------
  // Video-Player-Kontrolle
  // -----------------------------------------------------------
  const handlePlayPause = () => setPlaying(!playing);

  const handleProgress = (state) => {
    if (!seeking) setPlayed(state.played);
  };

  const handleSeekMouseDown = () => setSeeking(true);

  const handleSeekChange = (e) => {
    setPlayed(parseFloat(e.target.value));
  };

  const handleSeekMouseUp = (e) => {
    setSeeking(false);
    if (playerRef.current) {
      playerRef.current.seekTo(parseFloat(e.target.value));
    }
  };

  const handleDuration = (dur) => setDuration(dur);

  // -----------------------------------------------------------
  // TRAINER
  // -----------------------------------------------------------
  const handleTrainerSelectChange = (e) => {
    const val = e.target.value;
    if (val === 'create_new') {
      handleOpenTrainerDialog();
      setFormData((prev) => ({ ...prev, trainer: '' }));
    } else {
      handleChange(e);
    }
  };

  const handleOpenTrainerDialog = () => {
    setNewTrainerData({
      vorname: '',
      nachname: '',
      avatar: null,
      avatarUrl: '',
    });
    setTrainerUploadProgress(0);
    setOpenTrainerDialog(true);
  };

  const handleCloseTrainerDialog = () => {
    setOpenTrainerDialog(false);
  };

  const handleTrainerInputChange = (e) => {
    const { name, value } = e.target;
    setNewTrainerData((prev) => ({ ...prev, [name]: value }));
  };

  const handleTrainerAvatarChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setNewTrainerData((prev) => ({ ...prev, avatar: e.target.files[0] }));
    }
  };

  const handleCreateTrainer = async () => {
    // Demo: Trainer anlegen
    // Implementiere hier dein Avatar-Upload etc.
    // Dann Trainer in Firestore speichern
    // Dann formData.trainer = docRef.id
    setSnackbarMessage('Beispiel: Trainer erstellt (Bitte implementieren)');
    setOpenSnackbar(true);
    setOpenTrainerDialog(false);
  };

  // -----------------------------------------------------------
  // Absenden / Speichern
  // -----------------------------------------------------------
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Sicherstellen, dass vimeo_id ein String ist
    const trimmedVimeoId = typeof vimeo_id === 'string' ? vimeo_id.trim() : '';

    if (!trimmedVimeoId) {
      setSnackbarMessage('Bitte Vimeo-URL eingeben oder Video hochladen.');
      setOpenSnackbar(true);
      return;
    }

    try {
      // Baue das Video-Dokument
      const videoDoc = {
        video_titel:
          typeof formData.video_titel === 'string'
            ? formData.video_titel.trim() || '(Unbenannt)'
            : '(Unbenannt)',
        status: formData.status || 'veröffentlicht',

        // KI-Felder
        ki_themenbereiche: formData.themenbereiche,
        zielgruppe: formData.zielgruppe,
        text:
          typeof formData.text === 'string' ? formData.text.trim() || '' : '',
        probleme_herausforderungen:
          typeof formData.probleme_herausforderungen === 'string'
            ? formData.probleme_herausforderungen.trim() || ''
            : '',
        wird_gefoerdert: formData.wird_gefoerdert,
        p_koerperlicher_anspruch: formData.p_koerperlicher_anspruch,
        p_elemente: formData.p_elemente,
        p_elemente_reiten: formData.p_elemente_reiten,
        p_sicherheit: formData.p_sicherheit,
        p_mindestalter: formData.p_mindestalter,
        level: formData.level,
        trainer: formData.trainer || null,

        // Vimeo
        vimeo_id: trimmedVimeoId, // Speichere immer die volle URL
        thumbnailUrl: thumbnailUrl || null,

        // Empfehlungen
        a_aehnliche_uebung: selectedSimilarVideos.map((v) => v.id),
        a_hilfreiche_voruebung: selectedHelpfulExercises.map((v) => v.id),
        a_notwendige_uebung: selectedRequiredExercises.map((v) => v.id),

        date_edited: new Date().toISOString(),
        ki_status: 'update geplant',
      };

      // Entscheiden: neues Video oder Update
      if (videoData && videoData.id) {
        // Update
        const docRef = doc(db, 'videos', videoData.id);
        await updateDoc(docRef, videoDoc);
        setSnackbarMessage('Video aktualisiert!');
      } else {
        // Neu
        // Falls themenbereich / kapitelID vom Parent kam:
        videoDoc.themenbereich = videoData?.themenbereich || '';
        videoDoc.kapitelID = videoData?.kapitelID || '';
        videoDoc.date_created = new Date().toISOString();

        await addDoc(collection(db, 'videos'), videoDoc);
        setSnackbarMessage('Neues Video angelegt!');
      }

      setOpenSnackbar(true);
      onClose();
      resetFormForNewVideo();
    } catch (err) {
      console.error('Fehler beim Speichern:', err);
      setSnackbarMessage(err.message || 'Fehler beim Speichern');
      setOpenSnackbar(true);
    }
  };

  // "Entwurf speichern" (ähnlich wie handleSubmit)
  const handleSaveDraft = async () => {
    // Sicherstellen, dass vimeo_id ein String ist
    const trimmedVimeoId = typeof vimeo_id === 'string' ? vimeo_id.trim() : '';

    try {
      const draftDoc = {
        video_titel:
          typeof formData.video_titel === 'string'
            ? formData.video_titel.trim() || '(Entwurf ohne Titel)'
            : '(Entwurf ohne Titel)',
        status: 'Entwurf',

        ki_themenbereiche: formData.themenbereiche,
        zielgruppe: formData.zielgruppe,
        text:
          typeof formData.text === 'string' ? formData.text.trim() || '' : '',
        probleme_herausforderungen:
          typeof formData.probleme_herausforderungen === 'string'
            ? formData.probleme_herausforderungen.trim() || ''
            : '',
        wird_gefoerdert: formData.wird_gefoerdert,
        p_koerperlicher_anspruch: formData.p_koerperlicher_anspruch,
        p_elemente: formData.p_elemente,
        p_elemente_reiten: formData.p_elemente_reiten,
        p_sicherheit: formData.p_sicherheit,
        p_mindestalter: formData.p_mindestalter,
        level: formData.level,
        trainer: formData.trainer || null,

        vimeo_id: trimmedVimeoId,
        thumbnailUrl: thumbnailUrl || null,

        a_aehnliche_uebung: selectedSimilarVideos.map((v) => v.id),
        a_hilfreiche_voruebung: selectedHelpfulExercises.map((v) => v.id),
        a_notwendige_uebung: selectedRequiredExercises.map((v) => v.id),

        date_edited: new Date().toISOString(),
        ki_status: 'update geplant',
      };

      if (videoData && videoData.id) {
        const refDb = doc(db, 'videos', videoData.id);
        await updateDoc(refDb, draftDoc);
        setSnackbarMessage('Entwurf aktualisiert!');
      } else {
        draftDoc.themenbereich = videoData?.themenbereich || '';
        draftDoc.kapitelID = videoData?.kapitelID || '';
        draftDoc.date_created = new Date().toISOString();

        await addDoc(collection(db, 'videos'), draftDoc);
        setSnackbarMessage('Entwurf gespeichert!');
      }

      setOpenSnackbar(true);
      onClose();
      resetFormForNewVideo();
    } catch (err) {
      console.error('Fehler beim Speichern als Entwurf:', err);
      setSnackbarMessage('Fehler beim Speichern als Entwurf');
      setOpenSnackbar(true);
    }
  };

  // -----------------------------------------------------------
  // Modal-Schließen
  // -----------------------------------------------------------
  const handleCloseModal = (_, reason) => {
    if (reason === 'backdropClick') {
      // Verhindern, dass man durch Klick auf den Hintergrund schließt
      return;
    }

    // Falls schon Eingaben oder Upload
    if (
      formData.video_titel ||
      formData.text ||
      formData.probleme_herausforderungen ||
      videoURL ||
      selectedSimilarVideos.length > 0 ||
      selectedHelpfulExercises.length > 0 ||
      selectedRequiredExercises.length > 0
    ) {
      setOpenConfirmDialog(true);
    } else {
      onClose();
      resetFormForNewVideo();
    }
  };

  const handleConfirmClose = () => {
    // Wirklich alles verwerfen
    setOpenConfirmDialog(false);
    onClose();
    resetFormForNewVideo();
  };

  const handleCancelClose = () => {
    setOpenConfirmDialog(false);
  };

  // -----------------------------------------------------------
  // Thumbnail
  // -----------------------------------------------------------
  const handleThumbnailSelected = (newUrl) => {
    setThumbnailUrl(newUrl);
    setSnackbarMessage('Thumbnail aktualisiert!');
    setOpenSnackbar(true);
  };

  // -----------------------------------------------------------
  // parseVimeoId - extrahiert die reine ID aus einer evtl. langen URL
  // -----------------------------------------------------------
  const parseVimeoId = (value) => {
    const trimmed = (value || '').trim();
    // Falls komplett nur Ziffern
    if (/^\d+$/.test(trimmed)) {
      return trimmed;
    }
    // Falls https://vimeo.com/1234567
    const match = trimmed.match(/vimeo\.com\/(\d+)/);
    if (match && match[1]) {
      return match[1];
    }
    return '';
  };

  // -----------------------------------------------------------
  // TABS Rendern
  // -----------------------------------------------------------
  const renderTabContent = () => {
    switch (activeTab) {
      case 0: // Preview
        return (
          <Box sx={{ p: 2 }}>
            {videoURL ? (
              <Box sx={{ position: 'relative', height: 300 }}>
                <ReactPlayer
                  url={videoURL}
                  playing={playing}
                  ref={playerRef}
                  width="100%"
                  height="100%"
                  controls
                  onProgress={handleProgress}
                  onDuration={handleDuration}
                />
                {/* Button zum Löschen des Videos */}
                <IconButton
                  onClick={handleVideoDelete}
                  size="small"
                  sx={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    bgcolor: 'rgba(255,255,255,0.7)',
                    '&:hover': { bgcolor: 'rgba(255,255,255,1)' },
                  }}
                >
                  <DeleteIcon />
                </IconButton>

                {/* Button zum Ändern des Thumbnails */}
                <IconButton
                  onClick={() => setOpenSetThumbnailModal(true)}
                  size="small"
                  sx={{
                    position: 'absolute',
                    bottom: 10,
                    right: 10,
                    bgcolor: 'rgba(0,0,0,0.6)',
                    color: '#fff',
                    '&:hover': { bgcolor: 'rgba(0,0,0,0.8)' },
                  }}
                >
                  <CameraAltIcon />
                </IconButton>
              </Box>
            ) : (
              <Typography variant="body2">
                Keine Vorschau verfügbar. Bitte ein Video hochladen oder eine
                Vimeo-URL eingeben.
              </Typography>
            )}

            {/* Anzeige des aktuellen Thumbnails */}
            {thumbnailUrl && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Aktuelles Thumbnail:
                </Typography>
                <img
                  src={thumbnailUrl}
                  alt="Aktuelles Thumbnail"
                  style={{ maxWidth: '200px', borderRadius: '4px' }}
                />
              </Box>
            )}
          </Box>
        );

      case 1: // Vimeo-ID
        return (
          <Box sx={{ p: 2 }}>
            <TextField
              label="Vimeo-ID oder URL"
              value={vimeoLink}
              onChange={(e) => {
                setVimeoLink(e.target.value);
                setVimeo_id(
                  typeof e.target.value === 'string'
                    ? e.target.value.trim()
                    : ''
                ); // Im State immer die volle Eingabe
                const realId = parseVimeoId(e.target.value);
                if (realId) {
                  setVideoURL(`https://vimeo.com/${realId}`);
                } else {
                  setVideoURL(''); // Falls parse fehlschlägt
                }
              }}
              fullWidth
              margin="normal"
            />
            {videoURL && (
              <Box sx={{ mt: 2, position: 'relative', height: 300 }}>
                <ReactPlayer
                  url={videoURL}
                  playing={false}
                  width="100%"
                  height="100%"
                  controls
                />
                {/* Button zum Löschen des Videos */}
                <IconButton
                  onClick={handleVideoDelete}
                  size="small"
                  sx={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    bgcolor: 'rgba(255,255,255,0.7)',
                    '&:hover': { bgcolor: 'rgba(255,255,255,1)' },
                  }}
                >
                  <DeleteIcon />
                </IconButton>

                {/* Button zum Ändern des Thumbnails */}
                <IconButton
                  onClick={() => setOpenSetThumbnailModal(true)}
                  size="small"
                  sx={{
                    position: 'absolute',
                    bottom: 10,
                    right: 10,
                    bgcolor: 'rgba(0,0,0,0.6)',
                    color: '#fff',
                    '&:hover': { bgcolor: 'rgba(0,0,0,0.8)' },
                  }}
                >
                  <CameraAltIcon />
                </IconButton>
              </Box>
            )}

            {/* Anzeige des aktuellen Thumbnails */}
            {thumbnailUrl && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Aktuelles Thumbnail:
                </Typography>
                <img
                  src={thumbnailUrl}
                  alt="Aktuelles Thumbnail"
                  style={{ maxWidth: '200px', borderRadius: '4px' }}
                />
              </Box>
            )}
          </Box>
        );

      case 2: // Upload
      default:
        return (
          <Box sx={{ p: 2 }}>
            <Typography variant="body2" sx={{ mb: 1 }}>
              Lade ein neues Video hoch:
            </Typography>
            {!videoURL && (
              <Tooltip
                title={
                  typeof formData.video_titel === 'string' &&
                  formData.video_titel.trim() === ''
                    ? 'Bitte zuerst Videotitel eingeben.'
                    : 'Video hochladen'
                }
              >
                <span>
                  <IconButton
                    color="primary"
                    component="label"
                    disabled={
                      typeof formData.video_titel === 'string'
                        ? formData.video_titel.trim() === ''
                        : true
                    }
                    sx={{
                      width: 80,
                      height: 80,
                      bgcolor: 'white',
                      boxShadow: 3,
                      borderRadius: '50%',
                      '&:hover': { bgcolor: 'primary.light' },
                    }}
                  >
                    <input
                      type="file"
                      accept="video/*"
                      hidden
                      ref={inputFileRef}
                      onChange={(e) => {
                        if (e.target.files && e.target.files[0]) {
                          handleVideoUpload(e.target.files[0]);
                        }
                      }}
                    />
                    <UploadIcon sx={{ fontSize: 40 }} />
                  </IconButton>
                </span>
              </Tooltip>
            )}

            {uploadProgress > 0 && uploadProgress < 100 && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2">{uploadStep}</Typography>
                <LinearProgress
                  variant="determinate"
                  value={uploadProgress}
                  sx={{ height: 10, borderRadius: 5 }}
                />
              </Box>
            )}

            {videoURL && (
              <Box sx={{ mt: 2, position: 'relative', height: 300 }}>
                <ReactPlayer
                  url={videoURL}
                  playing={playing}
                  ref={playerRef}
                  width="100%"
                  height="100%"
                  controls
                  onProgress={handleProgress}
                  onDuration={handleDuration}
                />
                {/* Button zum Löschen des Videos */}
                <IconButton
                  onClick={handleVideoDelete}
                  size="small"
                  sx={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    bgcolor: 'rgba(255,255,255,0.7)',
                    '&:hover': { bgcolor: 'rgba(255,255,255,1)' },
                  }}
                >
                  <DeleteIcon />
                </IconButton>

                {/* Button zum Ändern des Thumbnails */}
                <IconButton
                  onClick={() => setOpenSetThumbnailModal(true)}
                  size="small"
                  sx={{
                    position: 'absolute',
                    bottom: 10,
                    right: 10,
                    bgcolor: 'rgba(0,0,0,0.6)',
                    color: '#fff',
                    '&:hover': { bgcolor: 'rgba(0,0,0,0.8)' },
                  }}
                >
                  <CameraAltIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        );
    }
  };

  // -----------------------------------------------------------
  // Radar-Chart
  // -----------------------------------------------------------
  const radarLabels = [
    'Zielgruppe',
    'Gefördert',
    'Körperlicher Anspruch',
    'Elemente Boden',
    'Elemente Reiten',
  ];
  const radarDataValues = [
    formData.zielgruppe.length,
    formData.wird_gefoerdert.length,
    formData.p_koerperlicher_anspruch.length,
    formData.p_elemente.length,
    formData.p_elemente_reiten.length,
  ];

  const dataRadar = {
    labels: radarLabels,
    datasets: [
      {
        label: 'Verteilung Aktuelles Video',
        data: radarDataValues,
        backgroundColor: 'rgba(213, 188, 139, 0.2)',
        borderColor: 'rgba(213, 188, 139, 1)',
        borderWidth: 2,
        pointBackgroundColor: 'rgba(213, 188, 139, 1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(213, 188, 139, 1)',
      },
    ],
  };

  const optionsRadar = {
    responsive: true,
    scales: {
      r: {
        grid: { circular: true },
        angleLines: { display: true },
        suggestedMin: 0,
        suggestedMax: 8,
        ticks: {
          backdropColor: 'transparent',
          color: '#666',
          stepSize: 1,
        },
        pointLabels: {
          font: { size: 12 },
          color: '#333',
        },
      },
    },
    plugins: {
      legend: { position: 'top' },
      tooltip: { enabled: true },
    },
  };

  // -----------------------------------------------------------
  // Render
  // -----------------------------------------------------------
  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseModal}
        // disableEscapeKeyDown // (falls du ESC ebenfalls blockieren willst)
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '95%', md: '80%', lg: '70%' },
            maxHeight: '95vh',
            overflowY: 'auto',
            bgcolor: 'background.paper',
            borderRadius: '12px',
            boxShadow: 24,
            p: 4,
          }}
        >
          {/* Header */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Typography variant="h5" component="h2">
              {videoData && videoData.id ? 'Video bearbeiten' : 'Neues Video'}
            </Typography>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Grid container spacing={4}>
            {/* Linke Spalte */}
            <Grid item xs={12} md={6}>
              <form onSubmit={handleSubmit}>
                {/* Videotitel */}
                <TextField
                  label="Videotitel"
                  name="video_titel"
                  value={formData.video_titel}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />

                {/* Beschreibung */}
                <TextField
                  label="Videobeschreibung"
                  name="text"
                  value={formData.text}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  multiline
                  rows={3}
                />

                {/* Probleme & Herausforderungen */}
                <TextField
                  label="Probleme und Herausforderungen"
                  name="probleme_herausforderungen"
                  value={formData.probleme_herausforderungen}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  multiline
                  rows={3}
                />

                {/* Accordion: Eignung */}
                <Accordion
                  expanded={expandedAccordion === 'angabenZurEignung'}
                  onChange={handleAccordionChange('angabenZurEignung')}
                  sx={{ mt: 2 }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Angaben zur Eignung</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* Themenbereiche */}
                    <FormControl fullWidth margin="normal">
                      <InputLabel id="themenbereiche-label">
                        Themenbereiche
                      </InputLabel>
                      <Select
                        labelId="themenbereiche-label"
                        name="themenbereiche"
                        multiple
                        value={formData.themenbereiche}
                        onChange={handleMultipleSelectChange}
                        input={<OutlinedInput label="Themenbereiche" />}
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                          >
                            {selected.map((val) => (
                              <Chip
                                key={val}
                                label={val}
                                onDelete={() =>
                                  setFormData((prev) => ({
                                    ...prev,
                                    themenbereiche: prev.themenbereiche.filter(
                                      (v) => v !== val
                                    ),
                                  }))
                                }
                              />
                            ))}
                          </Box>
                        )}
                      >
                        {themenbereicheOptions.map((name) => (
                          <MenuItem key={name} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {/* Zielgruppe */}
                    <FormControl fullWidth margin="normal">
                      <InputLabel id="zielgruppe-label">Zielgruppe</InputLabel>
                      <Select
                        labelId="zielgruppe-label"
                        name="zielgruppe"
                        multiple
                        value={formData.zielgruppe}
                        onChange={handleMultipleSelectChange}
                        input={<OutlinedInput label="Zielgruppe" />}
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                          >
                            {selected.map((val) => (
                              <Chip
                                key={val}
                                label={val}
                                onDelete={() =>
                                  setFormData((prev) => ({
                                    ...prev,
                                    zielgruppe: prev.zielgruppe.filter(
                                      (v) => v !== val
                                    ),
                                  }))
                                }
                              />
                            ))}
                          </Box>
                        )}
                      >
                        {ZIELGRUPPE_OPTIONS.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {/* Wird gefördert */}
                    <FormControl fullWidth margin="normal">
                      <InputLabel id="wird-gefoerdert-label">
                        Was wird gefördert
                      </InputLabel>
                      <Select
                        labelId="wird-gefoerdert-label"
                        name="wird_gefoerdert"
                        multiple
                        value={formData.wird_gefoerdert}
                        onChange={handleMultipleSelectChange}
                        input={
                          <OutlinedInput label="Was wird durch die Übung gefördert" />
                        }
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                          >
                            {selected.map((val) => (
                              <Chip
                                key={val}
                                label={val}
                                onDelete={() =>
                                  setFormData((prev) => ({
                                    ...prev,
                                    wird_gefoerdert:
                                      prev.wird_gefoerdert.filter(
                                        (v) => v !== val
                                      ),
                                  }))
                                }
                              />
                            ))}
                          </Box>
                        )}
                      >
                        {WAS_GEFOERDET_OPTIONS.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {/* Körperlicher Anspruch */}
                    <FormControl fullWidth margin="normal">
                      <InputLabel id="koerperlicher-anspruch-label">
                        Körperlicher Anspruch
                      </InputLabel>
                      <Select
                        labelId="koerperlicher-anspruch-label"
                        name="p_koerperlicher_anspruch"
                        multiple
                        value={formData.p_koerperlicher_anspruch}
                        onChange={handleMultipleSelectChange}
                        input={<OutlinedInput label="Körperlicher Anspruch" />}
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                          >
                            {selected.map((val) => (
                              <Chip
                                key={val}
                                label={val}
                                onDelete={() =>
                                  setFormData((prev) => ({
                                    ...prev,
                                    p_koerperlicher_anspruch:
                                      prev.p_koerperlicher_anspruch.filter(
                                        (x) => x !== val
                                      ),
                                  }))
                                }
                              />
                            ))}
                          </Box>
                        )}
                      >
                        {KOERPERLICHER_ANSPRUCH_OPTIONS.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>

                {/* Accordion: Voraussetzungen */}
                <Accordion
                  expanded={expandedAccordion === 'voraussetzungen'}
                  onChange={handleAccordionChange('voraussetzungen')}
                  sx={{ mt: 2 }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Voraussetzungen</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* p_sicherheit */}
                    <Box sx={{ mt: 2 }}>
                      <Typography gutterBottom>
                        Sicherheit (0 - gar nicht sicher, 4 - immer sicher)
                      </Typography>
                      <Slider
                        name="p_sicherheit"
                        value={formData.p_sicherheit}
                        onChange={(e, val) =>
                          handleSliderChange('p_sicherheit', val)
                        }
                        step={1}
                        marks={[
                          { value: 0, label: '0' },
                          { value: 2, label: '2' },
                          { value: 4, label: '4' },
                        ]}
                        min={0}
                        max={4}
                        valueLabelDisplay="auto"
                      />
                    </Box>

                    {/* p_mindestalter */}
                    <Box sx={{ mt: 3 }}>
                      <Typography gutterBottom>
                        Mindestalter (0-20 Jahre)
                      </Typography>
                      <Slider
                        name="p_mindestalter"
                        value={formData.p_mindestalter}
                        onChange={(e, val) =>
                          handleSliderChange('p_mindestalter', val)
                        }
                        step={1}
                        marks={[
                          { value: 0, label: '0' },
                          { value: 5, label: '5' },
                          { value: 10, label: '10' },
                          { value: 15, label: '15' },
                          { value: 20, label: '20' },
                        ]}
                        min={0}
                        max={20}
                        valueLabelDisplay="auto"
                      />
                    </Box>

                    {/* Level */}
                    <Box sx={{ mt: 3 }}>
                      <Typography gutterBottom>Level (0 - 3)</Typography>
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          name="level"
                          value={formData.level}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value={0}
                            control={<Radio />}
                            label="0"
                          />
                          <FormControlLabel
                            value={1}
                            control={<Radio />}
                            label="1"
                          />
                          <FormControlLabel
                            value={2}
                            control={<Radio />}
                            label="2"
                          />
                          <FormControlLabel
                            value={3}
                            control={<Radio />}
                            label="3"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </AccordionDetails>
                </Accordion>

                {/* Accordion: Elemente */}
                <Accordion
                  expanded={expandedAccordion === 'elemente'}
                  onChange={handleAccordionChange('elemente')}
                  sx={{ mt: 2 }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Elemente</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* p_elemente */}
                    <FormControl fullWidth margin="normal">
                      <InputLabel id="p_elemente-label">
                        Elemente der Übung
                      </InputLabel>
                      <Select
                        labelId="p_elemente-label"
                        name="p_elemente"
                        multiple
                        value={formData.p_elemente}
                        onChange={handleMultipleSelectChange}
                        input={<OutlinedInput label="Elemente der Übung" />}
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                          >
                            {selected.map((val) => (
                              <Chip
                                key={val}
                                label={val}
                                onDelete={() =>
                                  setFormData((prev) => ({
                                    ...prev,
                                    p_elemente: prev.p_elemente.filter(
                                      (x) => x !== val
                                    ),
                                  }))
                                }
                              />
                            ))}
                          </Box>
                        )}
                      >
                        {ELEMENTE_DER_UEBUNG_OPTIONS.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {/* p_elemente_reiten */}
                    <FormControl fullWidth margin="normal">
                      <InputLabel id="p_elemente_reiten-label">
                        Elemente der Übung - Reiten
                      </InputLabel>
                      <Select
                        labelId="p_elemente_reiten-label"
                        name="p_elemente_reiten"
                        multiple
                        value={formData.p_elemente_reiten}
                        onChange={handleMultipleSelectChange}
                        input={
                          <OutlinedInput label="Elemente der Übung - Reiten" />
                        }
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                          >
                            {selected.map((val) => (
                              <Chip
                                key={val}
                                label={val}
                                onDelete={() =>
                                  setFormData((prev) => ({
                                    ...prev,
                                    p_elemente_reiten:
                                      prev.p_elemente_reiten.filter(
                                        (x) => x !== val
                                      ),
                                  }))
                                }
                              />
                            ))}
                          </Box>
                        )}
                      >
                        {ELEMENTE_DER_UEBUNG_REITEN_OPTIONS.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>

                {/* Accordion: Video-Empfehlungen & Vorübungen */}
                <Accordion
                  expanded={expandedAccordion === 'videoempfehlungen'}
                  onChange={handleAccordionChange('videoempfehlungen')}
                  sx={{ mt: 2 }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Video-Empfehlungen & Vorübungen</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* Ähnliche */}
                    <Typography variant="subtitle1" sx={{ mt: 1 }}>
                      Ähnliche Videos:
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        mb: 3,
                      }}
                    >
                      <TextField
                        label="Ausgewählt"
                        value={selectedSimilarVideos
                          .map((v) => v.video_titel)
                          .join(', ')}
                        fullWidth
                        margin="normal"
                        InputProps={{ readOnly: true }}
                      />
                      <Button
                        variant="outlined"
                        onClick={() => setOpenSimilarVideosSelector(true)}
                      >
                        Auswahl
                      </Button>
                    </Box>

                    {/* Hilfreiche */}
                    <Typography variant="subtitle1" sx={{ mt: 1 }}>
                      Hilfreiche Vorübungen:
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        mb: 3,
                      }}
                    >
                      <TextField
                        label="Ausgewählt"
                        value={selectedHelpfulExercises
                          .map((v) => v.video_titel)
                          .join(', ')}
                        fullWidth
                        margin="normal"
                        InputProps={{ readOnly: true }}
                      />
                      <Button
                        variant="outlined"
                        onClick={() => setOpenHelpfulExercisesSelector(true)}
                      >
                        Auswahl
                      </Button>
                    </Box>

                    {/* Notwendige */}
                    <Typography variant="subtitle1" sx={{ mt: 1 }}>
                      Notwendige Vorübungen:
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <TextField
                        label="Ausgewählt"
                        value={selectedRequiredExercises
                          .map((v) => v.video_titel)
                          .join(', ')}
                        fullWidth
                        margin="normal"
                        InputProps={{ readOnly: true }}
                      />
                      <Button
                        variant="outlined"
                        onClick={() => setOpenRequiredExercisesSelector(true)}
                      >
                        Auswahl
                      </Button>
                    </Box>
                  </AccordionDetails>
                </Accordion>

                {/* Buttons: Speichern / Entwurf */}
                <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
                  <Button variant="contained" type="submit">
                    {videoData && videoData.id
                      ? 'Änderungen speichern'
                      : 'Hinzufügen'}
                  </Button>
                  <Button variant="outlined" onClick={handleSaveDraft}>
                    Als Entwurf speichern
                  </Button>
                </Box>
              </form>
            </Grid>

            {/* Rechte Spalte: TABS + Trainer + Radar */}
            <Grid item xs={12} md={6}>
              <Tabs
                value={activeTab}
                onChange={(e, val) => setActiveTab(val)}
                textColor="primary"
                indicatorColor="primary"
              >
                <Tab label="Videopreview" />
                <Tab label="Vimeo-ID" />
                <Tab label="Upload" />
              </Tabs>

              <Box sx={{ mt: 2 }}>{renderTabContent()}</Box>

              {/* Trainer */}
              <FormControl fullWidth margin="normal" sx={{ mt: 2 }}>
                <InputLabel id="trainer-label">Trainer</InputLabel>
                <Select
                  labelId="trainer-label"
                  name="trainer"
                  value={formData.trainer}
                  onChange={handleTrainerSelectChange}
                  input={<OutlinedInput label="Trainer" />}
                  renderValue={(selected) => {
                    const found = trainers.find((t) => t.id === selected);
                    if (found) {
                      return (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar
                            src={found.avatar || ''}
                            alt={`${found.vorname} ${found.nachname}`}
                            sx={{ mr: 1, width: 24, height: 24 }}
                          />
                          {found.vorname} {found.nachname}
                        </Box>
                      );
                    }
                    return '';
                  }}
                >
                  {trainers.map((t) => (
                    <MenuItem key={t.id} value={t.id}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar
                          src={t.avatar || ''}
                          alt={`${t.vorname} ${t.nachname}`}
                          sx={{ mr: 1, width: 24, height: 24 }}
                        />
                        {`${t.vorname} ${t.nachname}`}
                      </Box>
                    </MenuItem>
                  ))}
                  <MenuItem value="create_new">
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <PersonAddIcon sx={{ mr: 1 }} />
                      <Typography>Neuen Trainer erstellen</Typography>
                    </Box>
                  </MenuItem>
                </Select>
              </FormControl>

              {/* Radar Chart */}
              <Card sx={{ mt: 4 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Kontext: Verteilung Algorithmus
                  </Typography>
                  <RadarChart data={dataRadar} options={optionsRadar} />
                </CardContent>
              </Card>

              {/* Falls es sich um ein neues Video handelt, zeige die themenbereich + kapitelID */}
              {!videoData?.id &&
                (videoData?.themenbereich || videoData?.kapitelID) && (
                  <Card sx={{ mt: 2 }}>
                    <CardContent>
                      <Typography variant="subtitle1" color="text.secondary">
                        <strong>Themenbereich:</strong>{' '}
                        {videoData.themenbereich}
                      </Typography>
                      <Typography variant="subtitle1" color="text.secondary">
                        <strong>Kapitel-ID:</strong> {videoData.kapitelID}
                      </Typography>
                    </CardContent>
                  </Card>
                )}
            </Grid>
          </Grid>

          {/* Snackbar */}
          <Snackbar
            open={openSnackbar}
            autoHideDuration={5000}
            onClose={() => setOpenSnackbar(false)}
            message={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <CheckCircleIcon style={{ marginRight: 8 }} />
                {snackbarMessage || 'Aktion erfolgreich!'}
              </span>
            }
            action={
              <IconButton
                size="small"
                color="inherit"
                onClick={() => setOpenSnackbar(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />

          {/* Bestätigungs-Dialog beim Schließen */}
          <Dialog
            open={openConfirmDialog}
            onClose={handleCancelClose}
            aria-labelledby="confirm-dialog-title"
            aria-describedby="confirm-dialog-description"
          >
            <DialogTitle id="confirm-dialog-title">
              Modal schließen?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="confirm-dialog-description">
                Sind Sie sicher, dass Sie das Modal schließen möchten? Alle
                eingegebenen Informationen gehen verloren.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelClose} color="primary">
                Abbrechen
              </Button>
              <Button onClick={handleConfirmClose} color="secondary" autoFocus>
                Schließen
              </Button>
            </DialogActions>
          </Dialog>

          {/* Trainer erstellen */}
          <CreateTrainerDialog
            open={openTrainerDialog}
            onClose={handleCloseTrainerDialog}
            onCreate={handleCreateTrainer}
            newTrainerData={newTrainerData}
            setNewTrainerData={setNewTrainerData}
            onInputChange={handleTrainerInputChange}
            onAvatarChange={handleTrainerAvatarChange}
            uploadProgress={trainerUploadProgress}
          />

          {/* SimilarVideosList: Ähnliche */}
          <Modal
            open={openSimilarVideosSelector}
            onClose={() => setOpenSimilarVideosSelector(false)}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: { xs: '90%', md: '60%', lg: '50%' },
                maxHeight: '80vh',
                overflowY: 'auto',
                bgcolor: 'background.paper',
                borderRadius: 2,
                boxShadow: 24,
                p: 3,
              }}
            >
              <Box
                sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}
              >
                <Typography variant="h6">Ähnliche Videos auswählen</Typography>
                <IconButton onClick={() => setOpenSimilarVideosSelector(false)}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <SimilarVideosList
                criteria={formData}
                open={openSimilarVideosSelector}
                onClose={() => setOpenSimilarVideosSelector(false)}
                onSelect={(selected) => {
                  setSelectedSimilarVideos(selected);
                }}
              />
            </Box>
          </Modal>

          {/* SimilarVideosList: Hilfreiche */}
          <Modal
            open={openHelpfulExercisesSelector}
            onClose={() => setOpenHelpfulExercisesSelector(false)}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: { xs: '90%', md: '60%', lg: '50%' },
                maxHeight: '80vh',
                overflowY: 'auto',
                bgcolor: 'background.paper',
                borderRadius: 2,
                boxShadow: 24,
                p: 3,
              }}
            >
              <Box
                sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}
              >
                <Typography variant="h6">
                  Hilfreiche Vorübungen auswählen
                </Typography>
                <IconButton
                  onClick={() => setOpenHelpfulExercisesSelector(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <SimilarVideosList
                criteria={formData}
                open={openHelpfulExercisesSelector}
                onClose={() => setOpenHelpfulExercisesSelector(false)}
                onSelect={(selected) => {
                  setSelectedHelpfulExercises(selected);
                }}
              />
            </Box>
          </Modal>

          {/* SimilarVideosList: Notwendige */}
          <Modal
            open={openRequiredExercisesSelector}
            onClose={() => setOpenRequiredExercisesSelector(false)}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: { xs: '90%', md: '60%', lg: '50%' },
                maxHeight: '80vh',
                overflowY: 'auto',
                bgcolor: 'background.paper',
                borderRadius: 2,
                boxShadow: 24,
                p: 3,
              }}
            >
              <Box
                sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}
              >
                <Typography variant="h6">
                  Notwendige Vorübungen auswählen
                </Typography>
                <IconButton
                  onClick={() => setOpenRequiredExercisesSelector(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <SimilarVideosList
                criteria={formData}
                open={openRequiredExercisesSelector}
                onClose={() => setOpenRequiredExercisesSelector(false)}
                onSelect={(selected) => {
                  setSelectedRequiredExercises(selected);
                }}
              />
            </Box>
          </Modal>
        </Box>
      </Modal>

      {/* SetThumbnailModal */}
      <SetThumbnailModal
        open={openSetThumbnailModal}
        onClose={() => setOpenSetThumbnailModal(false)}
        videoURL={videoURL}
        videoId={parseVimeoId(vimeo_id)}
        currentThumbnailUrl={thumbnailUrl}
        onThumbnailSelected={handleThumbnailSelected}
      />
    </>
  );
};

// -----------------------------------------------------------
// parseVimeoId-Hilfsfunktion, extrahiert numerische ID aus:
// z. B. "https://vimeo.com/952857049" -> "952857049"
// -----------------------------------------------------------
function parseVimeoId(value) {
  const trimmed = (value || '').trim();
  // Falls komplett nur Ziffern
  if (/^\d+$/.test(trimmed)) {
    return trimmed;
  }
  // Falls https://vimeo.com/1234567
  const match = trimmed.match(/vimeo\.com\/(\d+)/);
  if (match && match[1]) {
    return match[1];
  }
  return '';
}

export default AddVideoModal;
