// src/components/context/AuthContext.js

import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth, database } from '../../config/firebase'; // Firebase Auth & RTDB
import { unregister } from '../../serviceWorkerRegistration';
import { initializeActivityTracker } from '../../utils/activityTracker'; // Dein Activity Tracker
import { ref, set, onDisconnect, get, update } from 'firebase/database';
import { v4 as uuidv4 } from 'uuid'; // Für eindeutige Session-IDs

const AuthContext = createContext();

/**
 * Custom Hook zum Zugriff auf den AuthContext
 */
export const useAuth = () => {
  return useContext(AuthContext);
};

/**
 * Generiert den Schlüssel für localStorage pro Benutzer:
 * z. B. "sessionId_userUidAbc".
 */
function getLocalStorageKey(uid) {
  return `sessionId_${uid}`;
}

/**
 * AuthProvider-Komponente, die den AuthContext bereitstellt.
 * - Pro Benutzer+Browser wird nur 1 Session-ID verwendet
 * - Speichert die Session-ID in localStorage, damit Tabs diese ID teilen
 */
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userRole, setUserRole] = useState('user');
  const [loading, setLoading] = useState(true);

  const cleanupRef = useRef(null); // Ref für die Activity-Tracker-Cleanup
  const sessionIdRef = useRef(null); // Ref für die aktuelle Session-ID (gerätübergreifend)

  useEffect(() => {
    // Überwache den Authentifizierungsstatus
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);

      if (user) {
        try {
          // (1) Rolle aus dem ID Token ermitteln
          const idTokenResult = await user.getIdTokenResult();
          setUserRole(idTokenResult.claims.role || 'user');
        } catch (error) {
          console.error('Fehler beim Abrufen der Benutzerrolle:', error);
          setUserRole('user');
        }

        // (2) Prüfen, ob in RTDB ein Datensatz existiert
        const userRootRef = ref(database, `/users/${user.uid}`);
        try {
          const userSnap = await get(userRootRef);
          if (!userSnap.exists()) {
            // Kein Eintrag -> lege Grundstruktur an
            await set(userRootRef, {
              status: {
                state: 'offline',
                last_active: Date.now(),
                last_changed: Date.now(),
              },
              sessions: {},
            });
            console.log('Neuer User-Datensatz in RTDB angelegt:', user.uid);
          } else {
            console.log('User existiert bereits in RTDB:', user.uid);
          }
        } catch (err) {
          console.error('Fehler beim Überprüfen/Anlegen des Users:', err);
        }

        // (3) Session-ID aus localStorage lesen oder neu erzeugen
        const localKey = getLocalStorageKey(user.uid);
        let existingSessionId = localStorage.getItem(localKey);

        if (!existingSessionId) {
          // Keine Session in localStorage -> neu anlegen
          existingSessionId = uuidv4();
          localStorage.setItem(localKey, existingSessionId);
          console.log(
            'Neue Session-ID erstellt & in localStorage gespeichert:',
            existingSessionId
          );
        } else {
          console.log(
            'Bestehende Session-ID aus localStorage gefunden:',
            existingSessionId
          );
        }

        sessionIdRef.current = existingSessionId;

        // (4) Session in RTDB anlegen/aktualisieren (falls noch nicht existiert)
        const userSessionsRef = ref(
          database,
          `/users/${user.uid}/sessions/${existingSessionId}`
        );
        const deviceInfo = {
          userAgent: navigator.userAgent,
          createdAt: Date.now(),
          lastActive: Date.now(),
          isActive: true,
          state: 'online',
          last_changed: Date.now(),
        };

        try {
          // "set" überschreibt, du könntest hier "update" verwenden,
          // um vorhandene Felder zu behalten:
          await set(userSessionsRef, deviceInfo);
          console.log('Session angelegt/aktualisiert:', existingSessionId);

          // onDisconnect -> offline
          onDisconnect(userSessionsRef).update({
            isActive: false,
            state: 'offline',
            last_changed: Date.now(),
          });
        } catch (error) {
          console.error(
            'Fehler beim Erstellen/Aktualisieren der Session:',
            error
          );
        }

        // (5) Activity Tracker initialisieren (einmalig)
        // Falls bereits existiert, nicht nochmal starten
        if (!cleanupRef.current) {
          const cleanup = initializeActivityTracker();
          // In deinem activityTracker könntest du "initializeActivityTracker(existingSessionId)" aufrufen
          // wenn du dort einen Parameter verwendest.
          cleanupRef.current = cleanup;
          console.log('Activity Tracker initialisiert.');
        }
      } else {
        // Kein User eingeloggt
        setUserRole('user');

        // Activity Tracker bereinigen
        if (cleanupRef.current) {
          cleanupRef.current();
          cleanupRef.current = null;
          console.log('Activity Tracker bereinigt.');
        }

        // Session offline setzen (falls noch vorhanden)
        if (sessionIdRef.current && currentUser) {
          const userSessionsRef = ref(
            database,
            `/users/${currentUser.uid}/sessions/${sessionIdRef.current}`
          );
          set(userSessionsRef, {
            isActive: false,
            state: 'offline',
            last_changed: Date.now(),
          })
            .then(() => {
              console.log(
                'Session als offline markiert:',
                sessionIdRef.current
              );
              sessionIdRef.current = null;
            })
            .catch((error) => {
              console.error('Fehler beim Markieren als offline:', error);
            });
        }
      }

      setLoading(false);
    });

    // Cleanup beim Unmount
    return () => {
      unsubscribe();

      // Activity Tracker bereinigen
      if (cleanupRef.current) {
        cleanupRef.current();
        console.log('Activity Tracker bereinigt beim Unmount.');
      }

      // Session offline markieren (falls vorhanden)
      if (sessionIdRef.current && currentUser) {
        const userSessionsRef = ref(
          database,
          `/users/${currentUser.uid}/sessions/${sessionIdRef.current}`
        );
        set(userSessionsRef, {
          isActive: false,
          state: 'offline',
          last_changed: Date.now(),
        })
          .then(() => {
            console.log(
              'Session als offline markiert beim Unmount:',
              sessionIdRef.current
            );
          })
          .catch((error) => {
            console.error(
              'Fehler beim Markieren der Session als offline beim Unmount:',
              error
            );
          });
      }
    };
  }, [currentUser]);

  /**
   * Logout-Funktion
   */
  const logout = async () => {
    try {
      // Benutzer abmelden
      await signOut(auth);
      console.log('Benutzer erfolgreich abgemeldet.');

      // Service Worker unregistrieren
      await unregister();
      console.log('Service Worker unregistriert.');

      // Alle Caches leeren
      if ('caches' in window) {
        const cacheNames = await caches.keys();
        await Promise.all(
          cacheNames.map((cacheName) => caches.delete(cacheName))
        );
        console.log('Alle Caches erfolgreich gelöscht.');
      }

      // Seite neu laden
      window.location.reload();
    } catch (error) {
      console.error('Fehler beim Logout:', error);
    }
  };

  const value = {
    currentUser,
    userRole,
    loading,
    sessionIdRef, // falls du sie woanders brauchst
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
