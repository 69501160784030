import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Snackbar,
  Alert,
} from '@mui/material';
import {
  Delete as CancelIcon,
  Description as InvoiceIcon,
} from '@mui/icons-material';
import { styled } from '@mui/system';
import { auth, db, functions } from '../../../../config/firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { onAuthStateChanged } from 'firebase/auth';

// Styled Box für die Abonnementinformationen
const SubscriptionBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: '#f9f9f9',
  borderRadius: '8px',
  boxShadow: theme.shadows[1],
}));

const MySubscription = () => {
  const [user, setUser] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  // Auth-Listener, um den aktuellen Benutzer zu erhalten
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  // Funktion zum Abrufen der Abonnementdaten des Benutzers
  const fetchSubscriptionData = async (uid) => {
    setLoading(true);
    try {
      const userDocRef = doc(db, 'users', uid);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        setSubscription(userData.subscription);
      } else {
        setSubscription(null);
      }
    } catch (error) {
      console.error('Fehler beim Abrufen der Abonnementdaten:', error);
      setSnackbar({
        open: true,
        message: 'Fehler beim Laden der Abonnementdaten.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  // useEffect zum Abrufen der Daten, sobald der Benutzer gesetzt ist
  useEffect(() => {
    if (user) {
      fetchSubscriptionData(user.uid);
    }
  }, [user]);

  // Funktion zum Kündigen des Abonnements
  const handleCancelSubscription = async () => {
    if (!user) return;

    setLoading(true);
    try {
      const cancelSubscription = httpsCallable(functions, 'cancelSubscription');
      const result = await cancelSubscription({ uid: user.uid });

      if (result.data.success) {
        setSubscription(result.data.subscription);
        setSnackbar({
          open: true,
          message: 'Abonnement erfolgreich gekündigt.',
          severity: 'success',
        });
      } else {
        setSnackbar({
          open: true,
          message: 'Fehler beim Kündigen des Abonnements.',
          severity: 'error',
        });
      }
    } catch (error) {
      console.error('Fehler beim Kündigen des Abonnements:', error);
      setSnackbar({
        open: true,
        message: 'Fehler beim Kündigen des Abonnements.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
      setCancelDialogOpen(false);
    }
  };

  // Funktion zum Schließen des Snackbars
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!subscription) {
    return (
      <Box sx={{ padding: 3 }}>
        <Typography variant="h6">
          Keine Abonnementinformationen gefunden.
        </Typography>
      </Box>
    );
  }

  return (
    <SubscriptionBox>
      <Typography variant="h4" gutterBottom>
        Mein Abonnement
      </Typography>
      <Typography variant="body1">
        <strong>Produktname:</strong>{' '}
        {subscription.product_name || 'Nicht verfügbar'}
      </Typography>
      <Typography variant="body1">
        <strong>Startdatum:</strong>{' '}
        {subscription.startDate
          ? new Date(subscription.startDate.seconds * 1000).toLocaleDateString()
          : 'Nicht verfügbar'}
      </Typography>
      <Typography variant="body1">
        <strong>Zugriff endet am:</strong>{' '}
        {subscription.subscriptionEndDate
          ? new Date(
              subscription.subscriptionEndDate.seconds * 1000
            ).toLocaleDateString()
          : 'Nicht verfügbar'}
      </Typography>
      <Typography variant="body1">
        <strong>Status:</strong> {subscription.status || 'Nicht verfügbar'}
      </Typography>
      <Typography variant="body1">
        <strong>Billing-Status:</strong>{' '}
        {subscription.billing_status ? (
          <Typography
            component="span"
            color={
              subscription.billing_status.toLowerCase() === 'paid'
                ? 'green'
                : subscription.billing_status.toLowerCase() === 'pending'
                  ? 'orange'
                  : 'red'
            }
          >
            {subscription.billing_status.charAt(0).toUpperCase() +
              subscription.billing_status.slice(1)}
          </Typography>
        ) : (
          'Nicht verfügbar'
        )}
      </Typography>

      <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
        {/* Button zum Anzeigen der letzten Rechnung */}
        {subscription.invoice_url && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<InvoiceIcon />}
            onClick={() => setInvoiceDialogOpen(true)}
          >
            Letzte Rechnung anzeigen
          </Button>
        )}

        {/* Button zum Kündigen des Abonnements */}
        <Button
          variant="outlined"
          color="error"
          startIcon={<CancelIcon />}
          onClick={() => setCancelDialogOpen(true)}
        >
          Abonnement kündigen
        </Button>
      </Box>

      {/* Dialog zum Bestätigen der Kündigung */}
      <Dialog
        open={cancelDialogOpen}
        onClose={() => setCancelDialogOpen(false)}
        aria-labelledby="cancel-dialog-title"
      >
        <DialogTitle id="cancel-dialog-title">Abonnement kündigen</DialogTitle>
        <DialogContent>
          <Typography>
            Bist du sicher, dass du dein Abonnement kündigen möchtest? Diese
            Aktion kann nicht rückgängig gemacht werden.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCancelDialogOpen(false)} color="primary">
            Abbrechen
          </Button>
          <Button onClick={handleCancelSubscription} color="error">
            Kündigen
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog zum Anzeigen der letzten Rechnung */}
      <Dialog
        open={invoiceDialogOpen}
        onClose={() => setInvoiceDialogOpen(false)}
        aria-labelledby="invoice-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="invoice-dialog-title">Letzte Rechnung</DialogTitle>
        <DialogContent>
          <Button
            variant="contained"
            color="primary"
            startIcon={<InvoiceIcon />}
            onClick={() => window.open(subscription.invoice_url, '_blank')}
            sx={{ mb: 2 }}
          >
            Rechnung öffnen
          </Button>
          <Typography variant="body2">
            Falls die Rechnung nicht angezeigt wird, klicke bitte auf den Button
            oben, um sie in einem neuen Tab zu öffnen.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setInvoiceDialogOpen(false)} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar für Benachrichtigungen */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </SubscriptionBox>
  );
};

export default MySubscription;
