/**
 * src/components/pages/Community/UserProfile_components/SecurityAndPrivacy.js
 */

import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  Divider,
  useMediaQuery,
  CircularProgress,
  TextField,
  Alert,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { styled } from '@mui/system';
import {
  auth,
  database,
  db as firestore,
  messaging,
} from '../../../../config/firebase'; // Lokaler Alias
import { ref as dbRef, onValue, update } from 'firebase/database';
import {
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
} from 'firebase/auth';
import { getToken, onMessage } from 'firebase/messaging';
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';

// Importiere Icons aus react-icons
import {
  FaApple,
  FaAndroid,
  FaWindows,
  FaChrome,
  FaFirefox,
} from 'react-icons/fa';
import { UAParser } from 'ua-parser-js';

// Styled Components
const SecurityPrivacyContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  width: '100%',
  maxWidth: '800px',
  margin: '0 auto',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
}));

const SecurityPrivacyWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: '#252833',
  borderRadius: '8px',
  padding: theme.spacing(3),
  opacity: 0.95, // Leicht erhöht für bessere Sichtbarkeit
  marginTop: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}));

const SessionItemContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: '#3f4b54',
  borderRadius: '6px',
  padding: theme.spacing(3), // Erhöhte Padding für mehr Platz
  marginBottom: theme.spacing(2),
  opacity: 0.95, // Leicht erhöht für bessere Sichtbarkeit
  alignItems: 'center',
}));

const IconSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginRight: theme.spacing(4), // Erhöhter horizontaler Abstand
  gap: theme.spacing(2), // Beibehaltung des vertikalen Abstands
}));

const SessionTextContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
}));

const SessionTitle = styled(Typography)(({ theme }) => ({
  color: '#d5bc8b',
  fontWeight: 'bold',
}));

const SessionText = styled(Typography)(({ theme }) => ({
  color: '#ffffff',
}));

const PasswordChangeContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(3),
  backgroundColor: '#3f4b54',
  borderRadius: '6px',
  opacity: 0.95, // Leicht erhöht für bessere Sichtbarkeit
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Hinzugefügter Schatten
}));

const PrivacySettingsContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(3),
  backgroundColor: '#3f4b54',
  borderRadius: '6px',
  opacity: 0.95,
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
}));

const CookieSettingsContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  backgroundColor: '#4a5660',
  borderRadius: '6px',
  opacity: 0.95,
}));

/**
 * Utility-Funktion, um aus session.osName ein passendes Icon zu holen.
 */
function getOSIcon(osName = '') {
  const lowerOS = osName.toLowerCase();
  if (lowerOS.includes('ios') || lowerOS.includes('mac')) {
    return <FaApple size={40} style={{ color: '#d5bc8b' }} />;
  }
  if (lowerOS.includes('android')) {
    return <FaAndroid size={40} style={{ color: '#d5bc8b' }} />;
  }
  if (lowerOS.includes('windows')) {
    return <FaWindows size={40} style={{ color: '#d5bc8b' }} />;
  }
  // Fallback
  return null;
}

/**
 * Utility-Funktion, um aus session.browserName ein passendes Icon zu holen.
 */
function getBrowserIcon(browserName = '') {
  const lowerBrowser = browserName.toLowerCase();
  if (lowerBrowser.includes('chrome')) {
    return <FaChrome size={40} style={{ color: '#d5bc8b' }} />;
  }
  if (lowerBrowser.includes('firefox')) {
    return <FaFirefox size={40} style={{ color: '#d5bc8b' }} />;
  }
  // Fallback
  return null;
}

/**
 * Seite für Sicherheits- und Datenschutzfunktionen.
 * - Zeigt die Sessions an mit OS/Browser-Icons
 * - Ermöglicht das Ändern des Passworts
 * - Bietet Datenschutzeinstellungen über Switches
 * - Integriert Push-Benachrichtigungseinstellungen
 */
const SecurityAndPrivacy = () => {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  // Zustände für Passwortänderung
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [passwordChangeError, setPasswordChangeError] = useState('');
  const [passwordChangeSuccess, setPasswordChangeSuccess] = useState('');

  // Zustände für Datenschutzeinstellungen
  const [privacySettings, setPrivacySettings] = useState({
    showPinOnMap: false,
    notificationsEnabled: false,
    // Weitere Datenschutzeinstellungen hier hinzufügen
  });
  const [privacyLoading, setPrivacyLoading] = useState(true);
  const [privacyError, setPrivacyError] = useState('');
  const [privacySuccess, setPrivacySuccess] = useState('');

  // Zustände für Cookie-Einstellungen
  const [cookieSettings, setCookieSettings] = useState({
    essentialCookies: true, // Immer aktiviert, nicht änderbar
    analyticsCookies: false,
    marketingCookies: false,
  });
  const [cookieLoading, setCookieLoading] = useState(true);
  const [cookieError, setCookieError] = useState('');
  const [cookieSuccess, setCookieSuccess] = useState('');

  useEffect(() => {
    console.log('SecurityAndPrivacy mounted');
    const user = auth.currentUser;
    if (!user) {
      console.log('Kein Benutzer eingeloggt');
      setLoading(false);
      setPrivacyLoading(false);
      setCookieLoading(false);
      return;
    }

    // Laden der aktiven Sessions
    const sessionsRef = dbRef(database, `/users/${user.uid}/sessions`);
    const unsubscribeSessions = onValue(sessionsRef, (snapshot) => {
      console.log('Sessions-Daten empfangen:', snapshot.val());
      const data = snapshot.val();
      if (data) {
        // Objekt -> Array
        const arr = Object.entries(data).map(([sessionId, obj]) => ({
          sessionId,
          ...obj,
        }));
        setSessions(arr);
      } else {
        setSessions([]);
      }
      setLoading(false);
    });

    // Laden der Datenschutzeinstellungen
    const privacyRef = dbRef(database, `/users/${user.uid}/privacySettings`);
    const unsubscribePrivacy = onValue(privacyRef, (snapshot) => {
      console.log('Privacy-Daten empfangen:', snapshot.val());
      const data = snapshot.val();
      if (data) {
        setPrivacySettings({
          showPinOnMap: data.showPinOnMap ?? false,
          notificationsEnabled: data.notificationsEnabled ?? false,
          // Weitere Datenschutzeinstellungen hier initialisieren
        });
      } else {
        setPrivacySettings({
          showPinOnMap: false,
          notificationsEnabled: false,
          // Weitere Datenschutzeinstellungen hier initialisieren
        });
      }
      setPrivacyLoading(false);
    });

    // Laden der Cookie-Einstellungen
    const cookieRef = dbRef(database, `/users/${user.uid}/cookieSettings`);
    const unsubscribeCookie = onValue(cookieRef, (snapshot) => {
      console.log('Cookie-Daten empfangen:', snapshot.val());
      const data = snapshot.val();
      if (data) {
        setCookieSettings({
          essentialCookies: true, // Immer true
          analyticsCookies: data.analyticsCookies ?? false,
          marketingCookies: data.marketingCookies ?? false,
          // Weitere Cookie-Kategorien hier initialisieren
        });
      } else {
        setCookieSettings({
          essentialCookies: true,
          analyticsCookies: false,
          marketingCookies: false,
          // Weitere Cookie-Kategorien hier initialisieren
        });
      }
      setCookieLoading(false);
    });

    // Listener für eingehende Nachrichten (optional)
    const unsubscribeOnMessage = onMessage(messaging, (payload) => {
      console.log('Nachricht erhalten:', payload);
      // Hier kannst du die Nachricht im UI anzeigen oder anderweitig verarbeiten
      // Beispiel: Anzeige einer Snackbar oder einer Benachrichtigung
    });

    return () => {
      console.log('SecurityAndPrivacy unmounted');
      unsubscribeSessions();
      unsubscribePrivacy();
      unsubscribeCookie();
      unsubscribeOnMessage();
    };
  }, []);

  /**
   * Sitzung beenden
   */
  const handleEndSession = async (sessionId) => {
    try {
      const user = auth.currentUser;
      if (!user) {
        console.warn('handleEndSession: Kein Benutzer eingeloggt.');
        return;
      }

      // Setze die Sitzung als inaktiv und offline
      const sessionRef = dbRef(
        database,
        `/users/${user.uid}/sessions/${sessionId}`
      );
      await update(sessionRef, {
        isActive: false,
        state: 'offline',
        last_changed: Date.now(),
      });
      console.log(`Session ${sessionId} als inaktiv markiert.`);
    } catch (error) {
      console.error('Fehler beim Beenden der Session:', error);
    }
  };

  /**
   * Passwort ändern
   */
  const handleChangePassword = async (e) => {
    e.preventDefault();
    setPasswordChangeError('');
    setPasswordChangeSuccess('');

    // Validierung
    if (!currentPassword || !newPassword || !confirmNewPassword) {
      setPasswordChangeError('Bitte fülle alle Felder aus.');
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setPasswordChangeError(
        'Das neue Passwort stimmt nicht mit der Bestätigung überein.'
      );
      return;
    }

    if (newPassword.length < 6) {
      setPasswordChangeError(
        'Das neue Passwort muss mindestens 6 Zeichen lang sein.'
      );
      return;
    }

    try {
      const user = auth.currentUser;
      if (!user) {
        setPasswordChangeError('Kein Benutzer eingeloggt.');
        return;
      }

      // Anmeldeinformationen erstellen
      const credential = EmailAuthProvider.credential(
        user.email,
        currentPassword
      );

      // Benutzer reauthentifizieren
      await reauthenticateWithCredential(user, credential);
      console.log('Benutzer erfolgreich reauthentifiziert.');

      // Passwort aktualisieren
      await updatePassword(user, newPassword);
      console.log('Passwort erfolgreich aktualisiert.');

      setPasswordChangeSuccess('Passwort erfolgreich geändert.');
      // Formularfelder zurücksetzen
      setCurrentPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
    } catch (error) {
      console.error('Fehler beim Ändern des Passworts:', error);
      // Firebase-Fehlercodes behandeln
      switch (error.code) {
        case 'auth/wrong-password':
          setPasswordChangeError('Das aktuelle Passwort ist falsch.');
          break;
        case 'auth/weak-password':
          setPasswordChangeError('Das neue Passwort ist zu schwach.');
          break;
        default:
          setPasswordChangeError(
            'Fehler beim Ändern des Passworts. Bitte versuche es erneut.'
          );
      }
    }
  };

  /**
   * Funktion zum Speichern des FCM-Tokens in Firestore
   */
  const saveFcmToken = async (userId, token) => {
    try {
      const userRef = doc(firestore, `users/${userId}`);
      await updateDoc(userRef, {
        fcmTokens: arrayUnion(token),
      });
      console.log('FCM-Token gespeichert:', token);
    } catch (error) {
      console.error('Fehler beim Speichern des FCM-Tokens:', error);
    }
  };

  /**
   * Funktion zum Entfernen aller FCM-Tokens aus Firestore
   */
  const removeAllFcmTokens = async (userId) => {
    try {
      const userRef = doc(firestore, `users/${userId}`);
      await updateDoc(userRef, {
        fcmTokens: [], // Setze das fcmTokens-Array auf leer
      });
      console.log('Alle FCM-Tokens entfernt.');
    } catch (error) {
      console.error('Fehler beim Entfernen der FCM-Tokens:', error);
    }
  };

  /**
   * Datenschutzeinstellungen ändern
   */
  const handlePrivacyChange = async (e) => {
    const { name, checked } = e.target;
    setPrivacySettings((prevSettings) => ({
      ...prevSettings,
      [name]: checked,
    }));

    try {
      const user = auth.currentUser;
      if (!user) {
        setPrivacyError('Kein Benutzer eingeloggt.');
        console.warn('handlePrivacyChange: Kein Benutzer eingeloggt.');
        return;
      }

      // Aktualisiere die Datenschutzeinstellungen in Firebase Realtime Database
      const privacyRef = dbRef(database, `/users/${user.uid}/privacySettings`);
      await update(privacyRef, {
        [name]: checked,
      });

      setPrivacySuccess('Datenschutzeinstellungen erfolgreich aktualisiert.');
      setPrivacyError('');

      // Zusätzliche Logik für Benachrichtigungen
      if (name === 'notificationsEnabled') {
        if (checked) {
          // Anfrage der Benachrichtigungserlaubnis
          const permission = await Notification.requestPermission();
          console.log('Benachrichtigungsberechtigung:', permission);
          if (permission === 'granted') {
            // Abrufen des FCM-Tokens
            const vapidKey =
              'BFVxdghFdHMqYZJLxwrkOdMMuzJapZ8Js5KQ8dfN7zg4UUH2jRnjcTNt0cyFT3jnX2a9tqpnKjT0fiLnNY6pzMw';
            console.log('VAPID Key:', vapidKey);
            if (!vapidKey) {
              console.error('VAPID Key ist nicht definiert.');
              setPrivacyError('Fehler beim Abrufen des VAPID Keys.');
              return;
            }

            // Holen der Service Worker-Registrierung
            const registration = await navigator.serviceWorker.ready;
            console.log('Service Worker Registrierung erhalten:', registration);

            try {
              const currentToken = await getToken(messaging, {
                vapidKey: vapidKey,
                serviceWorkerRegistration: registration,
              });

              console.log('FCM Token:', currentToken);

              if (currentToken) {
                await saveFcmToken(user.uid, currentToken);
                console.log('FCM-Token erfolgreich abgerufen und gespeichert.');
              } else {
                console.warn('Kein FCM-Token verfügbar.');
                setPrivacyError('Kein FCM-Token verfügbar.');
              }
            } catch (tokenError) {
              console.error('Fehler beim Abrufen des FCM-Tokens:', tokenError);
              setPrivacyError(
                `Fehler beim Abrufen des FCM-Tokens: ${tokenError.message}`
              );
            }
          } else {
            console.warn('Benachrichtigungen wurden nicht genehmigt.');
            setPrivacyError('Benachrichtigungen wurden nicht genehmigt.');
            setPrivacySettings((prevSettings) => ({
              ...prevSettings,
              [name]: false,
            }));
            await update(privacyRef, {
              [name]: false,
            });
          }
        } else {
          // Entfernen aller FCM-Tokens des Benutzers
          try {
            await removeAllFcmTokens(user.uid);
            console.log('Alle FCM-Tokens erfolgreich entfernt.');
          } catch (error) {
            console.error('Fehler beim Entfernen der FCM-Tokens:', error);
            setPrivacyError('Fehler beim Entfernen der FCM-Tokens.');
          }
        }
      }
    } catch (error) {
      console.error(
        'Fehler beim Aktualisieren der Datenschutzeinstellungen:',
        error
      );
      setPrivacyError(`Fehler: ${error.message}`);
      setPrivacySuccess('');
    }
  };

  /**
   * Cookie-Einstellungen ändern
   */
  const handleCookieChange = async (e) => {
    const { name, checked } = e.target;

    // Wesentliche Cookies können nicht deaktiviert werden
    if (name === 'essentialCookies') {
      console.warn(
        'Versuch, essenzielle Cookies zu ändern, was nicht erlaubt ist.'
      );
      return;
    }

    setCookieSettings((prevSettings) => ({
      ...prevSettings,
      [name]: checked,
    }));

    try {
      const user = auth.currentUser;
      if (!user) {
        setCookieError('Kein Benutzer eingeloggt.');
        console.warn('handleCookieChange: Kein Benutzer eingeloggt.');
        return;
      }

      // Aktualisiere die Cookie-Einstellungen in Firebase Realtime Database
      const cookieRef = dbRef(database, `/users/${user.uid}/cookieSettings`);
      await update(cookieRef, {
        [name]: checked,
      });

      setCookieSuccess('Cookie-Einstellungen erfolgreich aktualisiert.');
      setCookieError('');
    } catch (error) {
      console.error(
        'Fehler beim Aktualisieren der Cookie-Einstellungen:',
        error
      );
      setCookieError(
        'Fehler beim Aktualisieren der Cookie-Einstellungen. Bitte versuche es erneut.'
      );
      setCookieSuccess('');
    }
  };

  if (loading || privacyLoading || cookieLoading) {
    return (
      <SecurityPrivacyContainer>
        <Box
          sx={{
            display: 'flex',
            height: '60vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress sx={{ color: '#d5bc8b' }} />
        </Box>
      </SecurityPrivacyContainer>
    );
  }

  return (
    <SecurityPrivacyContainer>
      <SecurityPrivacyWrapper>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 'bold',
            mb: 2,
            color: 'white',
            textAlign: 'center',
            fontSize: isMobile ? '1.5rem' : '2rem',
          }}
        >
          Sicherheits- und Datenschutz-Einstellungen
        </Typography>
        <Divider sx={{ backgroundColor: '#3f4b54', mb: 4 }} />

        {/* Passwortänderungsformular */}
        <PasswordChangeContainer>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 'bold',
              mb: 2,
              color: 'white',
              textAlign: 'left',
              fontSize: isMobile ? '1.2rem' : '1.5rem',
            }}
          >
            Passwort ändern
          </Typography>
          <Divider sx={{ backgroundColor: '#3f4b54', mb: 2 }} />

          {passwordChangeError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {passwordChangeError}
            </Alert>
          )}
          {passwordChangeSuccess && (
            <Alert severity="success" sx={{ mb: 2 }}>
              {passwordChangeSuccess}
            </Alert>
          )}

          <form onSubmit={handleChangePassword}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  type="password"
                  label="Aktuelles Passwort"
                  variant="filled" // Ändere auf 'filled' für besseren Kontrast
                  fullWidth
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  required
                  InputProps={{
                    style: {
                      backgroundColor: '#3f4b54', // Dunkler Hintergrund
                      color: '#ffffff', // Weißer Text
                    },
                  }}
                  InputLabelProps={{
                    style: { color: '#d5bc8b' }, // Angepasste Label-Farbe
                  }}
                  sx={{
                    '& .MuiFilledInput-root:hover': {
                      backgroundColor: '#4a5660', // Leicht hellerer Hintergrund beim Hover
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="password"
                  label="Neues Passwort"
                  variant="filled" // Ändere auf 'filled' für besseren Kontrast
                  fullWidth
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                  helperText="Mindestens 6 Zeichen."
                  InputProps={{
                    style: {
                      backgroundColor: '#3f4b54', // Dunkler Hintergrund
                      color: '#ffffff', // Weißer Text
                    },
                  }}
                  InputLabelProps={{
                    style: { color: '#d5bc8b' }, // Angepasste Label-Farbe
                  }}
                  sx={{
                    '& .MuiFilledInput-root:hover': {
                      backgroundColor: '#4a5660', // Leicht hellerer Hintergrund beim Hover
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="password"
                  label="Neues Passwort bestätigen"
                  variant="filled" // Ändere auf 'filled' für besseren Kontrast
                  fullWidth
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                  required
                  InputProps={{
                    style: {
                      backgroundColor: '#3f4b54', // Dunkler Hintergrund
                      color: '#ffffff', // Weißer Text
                    },
                  }}
                  InputLabelProps={{
                    style: { color: '#d5bc8b' }, // Angepasste Label-Farbe
                  }}
                  sx={{
                    '& .MuiFilledInput-root:hover': {
                      backgroundColor: '#4a5660', // Leicht hellerer Hintergrund beim Hover
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: 'right' }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  sx={{
                    fontSize: isMobile ? '0.8rem' : '1rem',
                  }}
                >
                  Passwort ändern
                </Button>
              </Grid>
            </Grid>
          </form>
        </PasswordChangeContainer>

        {/* Zusätzlicher Abstand zwischen Passwort ändern und Datenschutz */}
        <Box sx={{ height: (theme) => theme.spacing(4) }} />

        {/* Datenschutz Einstellungen */}
        <PrivacySettingsContainer>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 'bold',
              mb: 2,
              color: 'white',
              textAlign: 'left',
              fontSize: isMobile ? '1.2rem' : '1.5rem',
            }}
          >
            Datenschutz
          </Typography>
          <Divider sx={{ backgroundColor: '#3f4b54', mb: 2 }} />

          {privacyError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {privacyError}
            </Alert>
          )}
          {privacySuccess && (
            <Alert severity="success" sx={{ mb: 2 }}>
              {privacySuccess}
            </Alert>
          )}

          <Grid container spacing={2}>
            {/* "Meinen Pin auf der Karte anzeigen" */}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={privacySettings.showPinOnMap}
                    onChange={handlePrivacyChange}
                    name="showPinOnMap"
                    color="secondary"
                  />
                }
                label="Meinen Pin auf der Karte anzeigen"
              />
            </Grid>

            {/* "Benachrichtigungen aktivieren" */}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={privacySettings.notificationsEnabled}
                    onChange={handlePrivacyChange}
                    name="notificationsEnabled"
                    color="secondary"
                  />
                }
                label="Benachrichtigungen aktivieren"
              />
            </Grid>
          </Grid>

          {/* Cookie-Einstellungen */}
          <CookieSettingsContainer>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                mb: 2,
                color: 'white',
                textAlign: 'left',
                fontSize: isMobile ? '1rem' : '1.2rem',
              }}
            >
              Cookie-Einstellungen
            </Typography>
            <Divider sx={{ backgroundColor: '#5a6572', mb: 2 }} />

            {cookieError && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {cookieError}
              </Alert>
            )}
            {cookieSuccess && (
              <Alert severity="success" sx={{ mb: 2 }}>
                {cookieSuccess}
              </Alert>
            )}

            <Grid container spacing={2}>
              {/* "Notwendige Cookies" - immer aktiviert und nicht deaktivierbar */}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={cookieSettings.essentialCookies}
                      disabled // Immer aktiviert, nicht änderbar
                      name="essentialCookies"
                      color="secondary"
                    />
                  }
                  label="Notwendige Cookies (immer aktiviert)"
                />
              </Grid>

              {/* "Statistische Cookies" */}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={cookieSettings.analyticsCookies}
                      onChange={handleCookieChange}
                      name="analyticsCookies"
                      color="secondary"
                    />
                  }
                  label="Statistische Cookies"
                />
              </Grid>

              {/* "Marketing-Cookies" */}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={cookieSettings.marketingCookies}
                      onChange={handleCookieChange}
                      name="marketingCookies"
                      color="secondary"
                    />
                  }
                  label="Marketing-Cookies"
                />
              </Grid>

              {/* Weitere Cookie-Kategorien können hier hinzugefügt werden */}
              {/* Beispiel:
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={cookieSettings.preferencesCookies}
                      onChange={handleCookieChange}
                      name="preferencesCookies"
                      color="secondary"
                    />
                  }
                  label="Präferenz-Cookies"
                />
              </Grid>
              */}
            </Grid>
          </CookieSettingsContainer>
        </PrivacySettingsContainer>

        {/* Zusätzlicher Abstand zwischen Datenschutz und aktive Logins */}
        <Box sx={{ height: (theme) => theme.spacing(4) }} />

        {/* Neue Überschrift für aktive Logins */}
        <Typography
          variant="h5"
          sx={{
            fontWeight: 'bold',
            mb: 2,
            color: 'white',
            textAlign: 'left',
            fontSize: isMobile ? '1.2rem' : '1.5rem',
          }}
        >
          Aktive Logins verwalten
        </Typography>

        {sessions.length === 0 ? (
          <Typography sx={{ color: 'white' }}>
            Derzeit sind keine aktiven Sitzungen vorhanden.
          </Typography>
        ) : (
          <Grid container spacing={2}>
            {sessions.map((session) => {
              // Parsing des User-Agent-Strings
              const parser = new UAParser(session.userAgent);
              const result = parser.getResult();
              const osName = result.os?.name || 'Unbekanntes OS';
              const osVersion = result.os?.version || '';
              const browserName = result.browser?.name || 'Unbekannter Browser';
              const browserVersion = result.browser?.version || '';

              return (
                <Grid item xs={12} key={session.sessionId}>
                  <SessionItemContainer>
                    {/* Linke Seite: Große Icons */}
                    <IconSection>
                      {getOSIcon(osName)}
                      {getBrowserIcon(browserName)}
                    </IconSection>

                    {/* Rechte Seite: Textinformationen */}
                    <SessionTextContainer>
                      <SessionTitle variant="h6">
                        {osName} {osVersion && `v${osVersion}`} – {browserName}{' '}
                        {browserVersion && `v${browserVersion}`}
                      </SessionTitle>
                      <SessionText variant="body2">
                        Session-ID: {session.sessionId}
                      </SessionText>
                      <SessionText variant="body2">
                        Zustand: {session.state ?? 'unbekannt'}
                      </SessionText>
                      {session.isActive !== undefined && (
                        <SessionText variant="body2">
                          Aktiv: {session.isActive ? 'Ja' : 'Nein'}
                        </SessionText>
                      )}

                      <Box sx={{ textAlign: 'right', mt: 1 }}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleEndSession(session.sessionId)}
                          sx={{
                            fontSize: isMobile ? '0.8rem' : '1rem',
                          }}
                        >
                          Sitzung beenden
                        </Button>
                      </Box>
                    </SessionTextContainer>
                  </SessionItemContainer>
                </Grid>
              );
            })}
          </Grid>
        )}

        {/* Evtl. weitere Sicherheitsfunktionen (Passwort zurücksetzen usw.) */}
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" sx={{ color: 'white', mb: 2 }}>
            Passwort zurücksetzen
          </Typography>
          <Divider sx={{ backgroundColor: '#3f4b54', mb: 2 }} />
          <Typography sx={{ color: 'white', mb: 2 }}>
            Hier könnte ein Button oder Formular zum Zurücksetzen des Passworts
            stehen.
          </Typography>
          <Button variant="contained" color="secondary" disabled>
            Passwort zurücksetzen (Demo)
          </Button>
        </Box>
      </SecurityPrivacyWrapper>
    </SecurityPrivacyContainer>
  );
};

export default SecurityAndPrivacy;
