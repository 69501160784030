import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  TextField,
  Button,
  Typography,
  Link as MUILink,
  Card,
  CardContent,
} from '@mui/material';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const auth = getAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/dashboard');
    } catch (err) {
      setError('Fehler bei der Anmeldung. Überprüfe bitte deine Eingaben.');
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: 'url("/images/backgrounds/login_background.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Card
        sx={{
          maxWidth: 400,
          width: '100%',
          bgcolor: 'rgba(37,40,51,0.7)',
          color: '#fff',
          borderRadius: 2,
          boxShadow: 3,
          backdropFilter: 'blur(5px)',
          mx: 2,
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 4,
          }}
        >
          {/* Logo oben, etwas größer */}
          <Box sx={{ mb: 4, width: '100%', textAlign: 'center' }}>
            <img
              src="/images/logos/kth_logo_horse.png"
              alt="Logo"
              style={{ maxWidth: '200px', height: 'auto' }}
            />
          </Box>

          {/* Formular */}
          <form onSubmit={handleLogin} style={{ width: '100%' }}>
            <Typography
              variant="h6"
              component="div"
              sx={{ mb: 2, textAlign: 'center' }}
            >
              Bitte einloggen
            </Typography>

            <TextField
              variant="outlined"
              type="email"
              label="E-Mail"
              fullWidth
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ mb: 2, bgcolor: '#fff', borderRadius: 1 }}
              inputProps={{ style: { color: '#000' } }}
            />

            <TextField
              variant="outlined"
              type="password"
              label="Passwort"
              fullWidth
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{ mb: 2, bgcolor: '#fff', borderRadius: 1 }}
              inputProps={{ style: { color: '#000' } }}
            />

            {error && (
              <Typography variant="body2" color="error" sx={{ mb: 2 }}>
                {error}
              </Typography>
            )}

            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}
            >
              <Box></Box>
              <MUILink
                href="/forgot-password"
                underline="hover"
                sx={{ color: '#d5bc8b' }}
              >
                Passwort vergessen?
              </MUILink>
            </Box>

            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                bgcolor: '#d5bc8b',
                color: '#000',
                fontWeight: 'bold',
                '&:hover': { bgcolor: '#ccb189' },
              }}
            >
              Login
            </Button>

            <Typography variant="body2" sx={{ mt: 3, textAlign: 'center' }}>
              Noch keinen Account?{' '}
              <MUILink
                href="/register"
                underline="hover"
                sx={{ color: '#d5bc8b' }}
              >
                Hier registrieren
              </MUILink>
            </Typography>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Login;
