// src/components/widgets/ShortWidget.js

import React from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const ShortWidget = ({ imageUrl, title, shortText }) => (
  <Box
    sx={{
      backgroundColor: '#252833', // Blaue Hintergrundfarbe
      borderRadius: '12px',
      overflow: 'hidden',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
      transition: 'transform 0.3s, box-shadow 0.3s',
      '&:hover': {
        transform: 'scale(1.02)',
        boxShadow: '0 6px 16px rgba(0, 0, 0, 0.3)',
      },
    }}
  >
    <img
      src={imageUrl || '/images/default-widget.jpg'}
      alt={title}
      style={{
        width: '100%',
        height: '150px',
        objectFit: 'cover',
      }}
    />
    <Box sx={{ padding: '16px' }}>
      <Typography variant="h6" gutterBottom sx={{ color: '#ffffff' }}>
        {title}
      </Typography>
      <Typography variant="body2" sx={{ color: '#d5bc8b' }}>
        {shortText}
      </Typography>
    </Box>
  </Box>
);

ShortWidget.propTypes = {
  imageUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
  shortText: PropTypes.string.isRequired,
};

export default ShortWidget;
