// src/components/pages/Community/UserProfile.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  useMediaQuery,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { ReactComponent as HorseIcon } from '../../../assets/icons/horse.svg';
import PersonIcon from '@mui/icons-material/Person';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import ForumIcon from '@mui/icons-material/Forum';

import EditUserProfile from './UserProfile_components/EditUserProfile';
import MyHorses from './UserProfile_components/MyHorses';
import MySubscription from './UserProfile_components/MySubscription';
import ProfileImageUploader from './UserProfile_components/ProfileImageUploader';
import SecurityAndPrivacy from './UserProfile_components/SecurityAndPrivacy';

import { useLocation } from 'react-router-dom';
import { useTheme as useMuiTheme } from '@mui/material/styles'; // Falls du theme aus MUI brauchst
import { useAuth } from '../../context/AuthContext';

// Konstanten für die Breite
const DESKTOP_DRAWER_WIDTH = 220;
const MOBILE_DRAWER_WIDTH = 60;

// Animiertes ListItem
const AnimatedListItem = styled(({ selected, ...other }) => (
  <ListItem {...other} />
))(({ selected }) => ({
  marginBottom: '12px',
  padding: '8px',
  borderRadius: '8px',
  transition: '0.3s',
  backgroundColor: selected ? 'rgba(213, 188, 139, 0.1)' : 'transparent',
  color: selected ? '#d5bc8b' : 'inherit',
  display: 'flex',
  alignItems: 'center',
  minHeight: 48,
  '&:hover': {
    color: '#d5bc8b',
    backgroundColor: 'rgba(213, 188, 139, 0.05)',
  },
}));

const UserProfile = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [content, setContent] = useState(<EditUserProfile />);
  const [avatarUrl, setAvatarUrl] = useState(
    '/path/to/default/profile/picture.jpg'
  );
  const [imageUploaderOpen, setImageUploaderOpen] = useState(false);

  const [drawerOpen, setDrawerOpen] = useState(true);

  const location = useLocation();
  const muiTheme = useMuiTheme(); // Falls du das MUI-Theme brauchst
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('md'));

  // === Import aus AuthContext ===
  const { currentUser, sessionIdRef } = useAuth();
  // sessionIdRef ist ein useRef, also:
  const sessionId = sessionIdRef?.current || null;
  // ==================================

  // Drawer immer offen lassen (für Desktop + Mobile)
  useEffect(() => {
    setDrawerOpen(true);
  }, []);

  // Tabs per URL-Param
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab');
    if (tab === 'horses') {
      setSelectedIndex(1);
      setContent(<MyHorses />);
    } else if (tab === 'account') {
      setSelectedIndex(2);
      setContent(<MySubscription />);
    } else {
      // Standard: Profil-Einstellungen
      setSelectedIndex(0);
      setContent(<EditUserProfile />);
    }
  }, [location]);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    switch (index) {
      case 0:
        setContent(
          <EditUserProfile
            avatarUrl={avatarUrl}
            onImageUploaderOpen={() => setImageUploaderOpen(true)}
          />
        );
        break;
      case 1:
        setContent(<MyHorses />);
        break;
      case 2:
        setContent(<MySubscription />);
        break;
      case 3:
        // Datenschutz => Hier SecurityAndPrivacy mit SessionID übergeben
        setContent(<SecurityAndPrivacy currentSessionId={sessionId} />);
        break;
      case 4:
        // Communitybeiträge oder Ähnliches
        // setContent(<CommunityPosts />); // Beispiel
        break;
      default:
        setContent(
          <EditUserProfile
            avatarUrl={avatarUrl}
            onImageUploaderOpen={() => setImageUploaderOpen(true)}
          />
        );
    }
  };

  // Profilbild hochladen
  const handleImageUpload = (url) => {
    setAvatarUrl(url);
    setImageUploaderOpen(false);
  };

  // Seitenleisten-Inhalt (Drawer-Liste)
  const drawerList = (
    <List sx={{ pt: 2 }}>
      {/* Mein Profil */}
      <AnimatedListItem
        button
        selected={selectedIndex === 0}
        onClick={(event) => handleListItemClick(event, 0)}
      >
        <ListItemIcon
          sx={{
            width: isMobile ? '100%' : 'auto',
            display: 'flex',
            justifyContent: isMobile ? 'center' : 'flex-start',
            alignItems: 'center',
            ...(isMobile ? { pl: 0.75, pr: 2 } : {}),
          }}
        >
          <Tooltip title="Mein Profil" placement="right">
            <PersonIcon sx={{ color: '#d5bc8b' }} />
          </Tooltip>
        </ListItemIcon>
        {!isMobile && drawerOpen && <ListItemText primary="Mein Profil" />}
      </AnimatedListItem>

      {/* Meine Pferde */}
      <AnimatedListItem
        button
        selected={selectedIndex === 1}
        onClick={(event) => handleListItemClick(event, 1)}
      >
        <ListItemIcon
          sx={{
            width: isMobile ? '100%' : 'auto',
            display: 'flex',
            justifyContent: isMobile ? 'center' : 'flex-start',
            alignItems: 'center',
            ...(isMobile ? { pl: 0.75, pr: 2 } : {}),
          }}
        >
          <Tooltip title="Meine Pferde" placement="right">
            <HorseIcon style={{ fill: '#d5bc8b' }} />
          </Tooltip>
        </ListItemIcon>
        {!isMobile && drawerOpen && <ListItemText primary="Meine Pferde" />}
      </AnimatedListItem>

      {/* Mein Abo */}
      <AnimatedListItem
        button
        selected={selectedIndex === 2}
        onClick={(event) => handleListItemClick(event, 2)}
      >
        <ListItemIcon
          sx={{
            width: isMobile ? '100%' : 'auto',
            display: 'flex',
            justifyContent: isMobile ? 'center' : 'flex-start',
            alignItems: 'center',
            ...(isMobile ? { pl: 0.75, pr: 2 } : {}),
          }}
        >
          <Tooltip title="Mein Abonnement" placement="right">
            <SubscriptionsIcon sx={{ color: '#d5bc8b' }} />
          </Tooltip>
        </ListItemIcon>
        {!isMobile && drawerOpen && <ListItemText primary="Mein Abonnement" />}
      </AnimatedListItem>

      {/* Datenschutz */}
      <AnimatedListItem
        button
        selected={selectedIndex === 3}
        onClick={(event) => handleListItemClick(event, 3)}
      >
        <ListItemIcon
          sx={{
            width: isMobile ? '100%' : 'auto',
            display: 'flex',
            justifyContent: isMobile ? 'center' : 'flex-start',
            alignItems: 'center',
            ...(isMobile ? { pl: 0.75, pr: 2 } : {}),
          }}
        >
          <Tooltip title="Datenschutz" placement="right">
            <PrivacyTipIcon sx={{ color: '#d5bc8b' }} />
          </Tooltip>
        </ListItemIcon>
        {!isMobile && drawerOpen && <ListItemText primary="Datenschutz" />}
      </AnimatedListItem>

      {/* Communitybeiträge (Index 4) */}
      <AnimatedListItem
        button
        selected={selectedIndex === 4}
        onClick={(event) => handleListItemClick(event, 4)}
      >
        <ListItemIcon
          sx={{
            width: isMobile ? '100%' : 'auto',
            display: 'flex',
            justifyContent: isMobile ? 'center' : 'flex-start',
            alignItems: 'center',
            ...(isMobile ? { pl: 0.75, pr: 2 } : {}),
          }}
        >
          <Tooltip title="Communitybeiträge" placement="right">
            <ForumIcon sx={{ color: '#d5bc8b' }} />
          </Tooltip>
        </ListItemIcon>
        {!isMobile && drawerOpen && (
          <ListItemText primary="Communitybeiträge" />
        )}
      </AnimatedListItem>
    </List>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        backgroundImage: `url('/images/backgrounds/kth_fasern.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {/* Seitliche Navigation (Drawer) */}
      <Drawer
        variant="persistent"
        open={drawerOpen}
        sx={{
          '& .MuiDrawer-paper': {
            position: 'fixed',
            top: 64, // unter der fixen Navbar
            left: 0,
            height: 'calc(100vh - 64px)',
            width: isMobile ? MOBILE_DRAWER_WIDTH : DESKTOP_DRAWER_WIDTH,
            overflowY: 'auto',
            backgroundColor: '#252833',
            color: '#fff',
            boxSizing: 'border-box',
            transition: muiTheme.transitions.create(['width'], {
              easing: muiTheme.transitions.easing.sharp,
              duration: muiTheme.transitions.duration.enteringScreen,
            }),
            zIndex: 1200,
          },
        }}
      >
        <Divider />
        {drawerList}
        <Divider />
      </Drawer>

      {/* Haupt-Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          marginLeft: {
            xs: `${MOBILE_DRAWER_WIDTH}px`,
            md: `${DESKTOP_DRAWER_WIDTH}px`,
          },
          marginTop: '64px',
          marginBottom: '56px',
          p: 2,
          transition: 'margin-left 0.3s ease',
        }}
      >
        {content}
      </Box>

      {/* Profilbild-Upload */}
      <ProfileImageUploader
        open={imageUploaderOpen}
        onClose={() => setImageUploaderOpen(false)}
        onImageUpload={handleImageUpload}
      />
    </Box>
  );
};

export default UserProfile;
