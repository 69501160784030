import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from 'react-router-dom';
import { AuthProvider, useAuth } from './components/context/AuthContext';
import CircularProgress from '@mui/material/CircularProgress';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import theme from './theme';

// Seiten und Komponenten
import Login from './components/pages/Login';
import Dashboard from './components/pages/Community/Dashboard';
import AdminDashboard from './components/pages/Administrator/AdminDashboard';
import UserProfile from './components/pages/Community/UserProfile';
import JsonImport from './components/pages/Administrator/Jsonimport';
import VideoStage from './components/pages/Lernplattform/VideoStage';
import VimeoAuthorization from './components/pages/VimeoAuthorization';
import OAuthCallback from './components/pages/OAuthCallback';
import ThumbnailsUpdate from './components/Administration/ThumnailUpdate';
import MapComponent from './components/pages/Community/User_map/MapComponent';
import ProtectedRoute from './components/ProtectedRoute';
import UserManagement from './components/Administration/UserManagement';
import AdminRoute from './components/Administration/AdminRoute';
import MultiStepForm from './components/Einschätzungstest/test_neu';
import HostArea from './components/Administration/Livestream/HostArea';
import { UpdateDurationsButton } from './components/pages/Administrator/UpdateDurationsButton';
import MeetingOverview from './components/pages/Livemeetings/MeetingOverview';
import MeetingManagement from './components/Administration/Livestream/MeetingManagement';
import CommunityPage from './components/pages/Community/CommunityPage';
import VideoDetail from './components/pages/Lernplattform/VideoDetail';
import ErrorPage from './components/pages/ErrorPage';
import VideoEditor from './components/pages/VideoEditor';

// Material UI Komponenten für BottomNavigation
import { Paper, BottomNavigation, BottomNavigationAction } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeIcon from '@mui/icons-material/Home';

// Importiere die SVGs als React-Komponenten
import { ReactComponent as HorseLogo } from './assets/logos/kth_horse_single.svg';
import { ReactComponent as CommunityLogo } from './assets/logos/kth_community_logo.svg';

// Importiere ResponsiveAppBar und AdminInfoBox
import ResponsiveAppBar from './components/layout/navbar';
import AdminInfoBox from './components/Administration/AdminInfoBox';

// Importiere die usePresence-Hook
import usePresence from './hooks/usePresence';

const AppContent = () => {
  const { currentUser, loading } = useAuth();
  const navigate = useNavigate();
  const [value, setValue] = useState(1);

  if (loading) {
    return (
      <div
        className="loading-spinner"
        style={{
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress style={{ color: '#d5bc8b' }} />
      </div>
    );
  }

  const shouldShowNavbar =
    window.location.pathname !== '/login' &&
    window.location.pathname !== '/CheckoutPrepare';

  const shouldShowBottomNav = shouldShowNavbar;

  const handleChange = (event, newValue) => {
    setValue(newValue);

    if (newValue === 1) {
      navigate('/dashboard');
    } else if (newValue === 2) {
      navigate('/community');
    } else if (newValue === 3) {
      navigate('/profile');
    }
  };

  const iconColor = (index) => (value === index ? '#d5bc8b' : '#3f4b54');

  return (
    <>
      {shouldShowNavbar && <ResponsiveAppBar />}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/authorize" element={<VimeoAuthorization />} />
        <Route path="/callback" element={<OAuthCallback />} />
        <Route path="/update-thumbnails" element={<ThumbnailsUpdate />} />

        {/* Meetings */}
        <Route path="/meeting-overview" element={<MeetingOverview />} />
        <Route
          path="/admin/meeting-management"
          element={
            <AdminRoute>
              <MeetingManagement />
            </AdminRoute>
          }
        />

        {/* Community */}
        <Route
          path="/community"
          element={
            <ProtectedRoute>
              <CommunityPage />
            </ProtectedRoute>
          }
        />

        {/* Geschützte Routen */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />

        <Route
          path="/Einschaetzungstest"
          element={
            <ProtectedRoute>
              <MultiStepForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Videoeditor"
          element={
            <ProtectedRoute>
              <VideoEditor />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin"
          element={
            <ProtectedRoute>
              <AdminDashboard />
            </ProtectedRoute>
          }
        />
        <Route
        // path="/dbtest"
        // element={
        //   <ProtectedRoute>
        //     <HostArea />
        //   </ProtectedRoute>
        // }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <UserProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/jsonimport"
          element={
            <ProtectedRoute>
              <JsonImport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/videostage"
          element={
            <ProtectedRoute>
              <VideoStage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/test"
          element={
            <ProtectedRoute>
              <UpdateDurationsButton />
            </ProtectedRoute>
          }
        />

        {/* Video Detail */}
        <Route
          path="/video/:id"
          element={
            <ProtectedRoute>
              <VideoDetail />
            </ProtectedRoute>
          }
        />

        {/* Admin User Management */}
        <Route
          path="/admin/user-management"
          element={
            <AdminRoute>
              <UserManagement />
            </AdminRoute>
          }
        />

        {/* Kartenkomponente */}
        <Route
          path="/map"
          element={
            <AdminRoute>
              <MapComponent />
            </AdminRoute>
          }
        />

        {/* Fehlerseite */}
        <Route path="/error" element={<ErrorPage />} />

        {/* Test-Route */}
        <Route
          path="/test-route"
          element={<div>Test Route funktioniert!</div>}
        />

        {/* Standardpfad */}
        <Route
          path="/"
          element={
            currentUser ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        {/* Fallback */}
        <Route
          path="*"
          element={<Navigate to={currentUser ? '/dashboard' : '/login'} />}
        />
      </Routes>

      {shouldShowBottomNav && (
        <Paper
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            bgcolor: '#252833',
            zIndex: 1300,
          }}
          elevation={3}
        >
          <BottomNavigation
            value={value}
            onChange={handleChange}
            showLabels={false}
            sx={{ bgcolor: '#252833' }}
          >
            <BottomNavigationAction
              icon={<ArrowBackIcon sx={{ color: '#3f4b54' }} />}
              onClick={() => navigate(-1)}
            />
            <BottomNavigationAction
              value={1}
              icon={<HomeIcon sx={{ color: iconColor(1) }} />}
            />
            <BottomNavigationAction
              value={2}
              icon={
                <CommunityLogo
                  style={{
                    color: iconColor(2),
                    width: 24,
                    height: 24,
                  }}
                />
              }
            />
            <BottomNavigationAction
              value={3}
              icon={
                <HorseLogo
                  style={{
                    color: iconColor(3),
                    width: 24,
                    height: 24,
                  }}
                />
              }
            />
          </BottomNavigation>
        </Paper>
      )}

      {/* AdminInfoBox hinzufügen */}
      <AdminInfoBox />
    </>
  );
};

const App = () => {
  // Integriere die usePresence-Hook
  usePresence();

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <DndProvider backend={HTML5Backend}>
          <Router>
            <AppContent />
          </Router>
        </DndProvider>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;
