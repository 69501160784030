import React, { useRef, useState } from 'react';
import { Modal, Box, Typography, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ReactPlayer from 'react-player';

// Importiere die Firebase Functions
import { getFunctions, httpsCallable } from 'firebase/functions';

/**
 * SetThumbnailModal Komponente
 *
 * Props:
 * - open (bool): Steuert, ob das Modal geöffnet ist
 * - onClose (func): Funktion, die aufgerufen wird, um das Modal zu schließen
 * - videoURL (string): URL des Videos (z.B. Vimeo-Link)
 * - videoId (string): Reine Vimeo-Video-ID (z.B. "123456789")
 * - currentThumbnailUrl (string): Aktuelle Thumbnail-URL (optional)
 * - onThumbnailSelected (func): Callback-Funktion, um das neue Thumbnail an den Parent zu übermitteln
 */
function SetThumbnailModal({
  open,
  onClose,
  videoURL,
  videoId,
  currentThumbnailUrl,
  onThumbnailSelected,
}) {
  const [currentTime, setCurrentTime] = useState(0);
  const [isSetting, setIsSetting] = useState(false);
  const playerRef = useRef(null);

  // Initialisiere Firebase Functions mit der Region 'europe-west3'
  const functions = getFunctions(undefined, 'europe-west3');
  // Definiere den Callable für das Setzen des Custom-Thumbnails
  const setCustomVimeoThumbnail = httpsCallable(
    functions,
    'setCustomVimeoThumbnail'
  );

  // Handler für den Fortschritt des Players
  const handleProgress = (state) => {
    setCurrentTime(state.playedSeconds);
  };

  // Handler zum Festlegen des Thumbnails
  const handleSetThumbnail = async () => {
    try {
      setIsSetting(true);

      // Erhöhe die Genauigkeit auf drei Dezimalstellen (Millisekunden)
      const floatTimeCode = currentTime.toFixed(3); // z.B. "123.456"

      const response = await setCustomVimeoThumbnail({
        videoId: videoId,
        timeCode: floatTimeCode,
      });

      // Überprüfe die Antwort der Cloud Function
      const { thumbnailUrl: newThumbnailUrl } = response.data;
      if (!newThumbnailUrl) {
        throw new Error(
          'Keine thumbnailUrl in der Cloud Function Antwort gefunden!'
        );
      }

      // Aktualisiere das Thumbnail im Parent-Komponenten
      onThumbnailSelected(newThumbnailUrl);

      // Schließe das Modal
      onClose();
    } catch (error) {
      console.error('Fehler beim Festlegen des Thumbnails:', error);
      // Optional: Zeige eine Snackbar oder einen Error-Dialog an
    } finally {
      setIsSetting(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: 900,
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        {/* Header */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6">Thumbnail festlegen</Typography>
          <IconButton onClick={onClose} disabled={isSetting}>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Video-Player */}
        <Box sx={{ mt: 2, mb: 2 }}>
          <ReactPlayer
            url={videoURL}
            ref={playerRef}
            width="100%"
            height="60vh"
            controls
            onProgress={handleProgress}
          />
        </Box>

        {/* Aktuelle Zeit im Video */}
        <Typography variant="body2" sx={{ mb: 2 }}>
          Aktuelle Zeit: {currentTime.toFixed(3)} Sekunden
        </Typography>

        {/* Anzeige des aktuellen Thumbnails */}
        {currentThumbnailUrl && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Bisheriges Thumbnail:
            </Typography>
            <img
              src={currentThumbnailUrl}
              alt="Aktuelles Thumbnail"
              style={{ maxWidth: '200px', borderRadius: '4px' }}
            />
          </Box>
        )}

        {/* Action Buttons */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Button variant="outlined" onClick={onClose} disabled={isSetting}>
            Abbrechen
          </Button>
          <Button
            variant="contained"
            onClick={handleSetThumbnail}
            disabled={isSetting}
          >
            {isSetting ? 'Setze Thumbnail...' : 'Thumbnail setzen'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default SetThumbnailModal;
