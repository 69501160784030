// src/config/firebase.js

import { initializeApp, getApps, getApp } from 'firebase/app';
import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage'; // Importiere Firebase Storage
import { getMessaging } from 'firebase/messaging'; // Importiere Firebase Messaging

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL:
    process.env.REACT_APP_FIREBASE_DATABASE_URL ||
    'https://keytohorses-firebase.europe-west1.firebasedatabase.app',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialisiere Firebase nur einmal
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();

// Initialisiere Firebase-Dienste
const auth = getAuth(app);
const db = getFirestore(app);
const database = getDatabase(app); // Konsistenter Name
const functions = getFunctions(app, 'europe-west3'); // **Korrekte Region angeben**
const storage = getStorage(app); // Speicher hinzufügen
const messaging = getMessaging(app); // Initialisiere Messaging

// Setze Persistenzmodus auf 'local', um eingeloggt zu bleiben
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Persistenzmodus ist 'local', der Login bleibt gespeichert.");
  })
  .catch((error) => {
    console.error('Fehler beim Setzen des Persistenzmodus: ', error);
  });

// Exportiere alle Firebase-Dienste unter konsistenten Namen
export { auth, db, database, functions, storage, messaging, db as firestore };
