// userService.js
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../../config/firebase';

export const getUserData = async (uid) => {
  const userDocRef = doc(db, 'users', uid);
  const userDoc = await getDoc(userDocRef);
  if (userDoc.exists()) {
    return userDoc.data();
  } else {
    console.error('Benutzer nicht gefunden');
    return null;
  }
};
