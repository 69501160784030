// src/components/pages/Community/UserDashboard_components/CommentSection.js

import React, { useState, useEffect, useCallback } from 'react';
import { db, storage } from '../../../../config/firebase';
import {
  Box,
  TextField,
  Button,
  Typography,
  List,
  ListItem,
  Snackbar,
  IconButton,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import {
  collection,
  addDoc,
  serverTimestamp,
  onSnapshot,
  query,
  orderBy,
  getDoc,
  doc,
  where,
  updateDoc,
  arrayUnion,
} from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage';
import { useAuth } from '../../../context/AuthContext';
import { getUserData } from './userService';
import Comment from './Comment';

const AlertComponent = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Angepasste Styled-Komponente für das Kommentar-Eingabefeld
const CommentInputBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#2c2f3a', // Leicht abweichende Farbe für bessere Abgrenzung
  borderRadius: '8px',
  marginBottom: theme.spacing(2), // Verringerter Abstand nach unten
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Leichter Schatten
}));

const CommentSection = ({ postId, parentId = null }) => {
  const { currentUser } = useAuth();
  const [comments, setComments] = useState([]);
  const [commentInput, setCommentInput] = useState('');
  const [userData, setUserData] = useState(null);
  const [isCommentBoxExpanded, setIsCommentBoxExpanded] = useState(false);
  const [commentError, setCommentError] = useState('');
  const [commentSuccess, setCommentSuccess] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  // User-Daten laden
  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        const data = await getUserData(currentUser.uid);
        setUserData(data);
      }
    };
    fetchUserData();
  }, [currentUser]);

  // Kommentare in Echtzeit abrufen
  useEffect(() => {
    const commentsRef = collection(db, 'posts', postId, 'comments');
    const q = query(
      commentsRef,
      orderBy('timestamp', 'asc'),
      parentId
        ? where('parentId', '==', parentId)
        : where('parentId', '==', null)
    );

    const unsubscribe = onSnapshot(
      q,
      async (snapshot) => {
        try {
          const commentsList = await Promise.all(
            snapshot.docs.map(async (docSnap) => {
              const data = docSnap.data();
              let profilePictureURL = '';

              if (data.profilePicturePath) {
                try {
                  const profilePicRef = ref(storage, data.profilePicturePath);
                  profilePictureURL = await getDownloadURL(profilePicRef);
                } catch (error) {
                  console.error('Fehler beim Abrufen des Profilbildes:', error);
                }
              }

              return {
                id: docSnap.id,
                ...data,
                profilePictureURL,
              };
            })
          );
          setComments(commentsList);
        } catch (error) {
          console.error('Fehler beim Laden der Kommentare:', error);
        }
      },
      (error) => {
        console.error('Snapshot Listener Fehler:', error);
      }
    );

    return () => unsubscribe();
  }, [postId, parentId]);

  // -----------------------------------------
  // Notification-Erstellung
  // -----------------------------------------
  const createCommentNotifications = async (postDoc, commentAuthorName) => {
    const postData = postDoc.data();
    const postAuthorId = postData.authorId;
    const commentUserIds = postData.commentUserIds || []; // alle, die kommentiert haben

    // Alle Empfänger:
    const recipients = new Set([postAuthorId, ...commentUserIds]);
    // Sich selbst ausschließen:
    recipients.delete(currentUser.uid);

    if (recipients.size === 0) return; // niemanden benachrichtigen

    const notePayload = {
      type: 'COMMENT',
      postId: postDoc.id,
      createdAt: serverTimestamp(),
      read: false,
      message: `${commentAuthorName} hat einen neuen Kommentar hinterlassen.`,
    };

    // Pro Empfänger => /users/{uid}/notifications
    recipients.forEach(async (userId) => {
      await addDoc(
        collection(db, 'users', userId, 'notifications'),
        notePayload
      );
    });
  };

  // -----------------------------------------
  // Kommentar hinzufügen
  // -----------------------------------------
  const handleAddComment = useCallback(async () => {
    if (!currentUser) {
      setCommentError('Bitte melde dich an, um zu kommentieren.');
      return;
    }
    if (commentInput.trim() === '') {
      setCommentError('Kommentar darf nicht leer sein.');
      return;
    }

    try {
      // 1) Nutzer-Daten
      const userDoc = doc(db, 'users', currentUser.uid);
      const userSnap = await getDoc(userDoc);

      let profilePictureURL = '';
      let firstName = '';
      let lastName = '';

      if (userSnap.exists()) {
        const userData = userSnap.data();
        profilePictureURL = userData.profilePictureURL || '';
        firstName = userData.firstName || 'Anonym';
        lastName = userData.lastName || '';
      }

      // 2) Kommentar speichern
      const commentsRef = collection(db, 'posts', postId, 'comments');
      await addDoc(commentsRef, {
        text: commentInput.trim(),
        timestamp: serverTimestamp(),
        userId: currentUser.uid,
        firstName,
        lastName,
        profilePictureURL,
        parentId: parentId || null,
      });

      // 3) postDoc laden, lastCommentTimestamp updaten & commentUserIds erweitern
      const postRef = doc(db, 'posts', postId);
      const postSnap = await getDoc(postRef);
      if (postSnap.exists()) {
        // -> lastCommentTimestamp
        await updateDoc(postRef, {
          lastCommentTimestamp: serverTimestamp(),
          // commentUserIds: arrayUnion(currentUser.uid)
          commentUserIds: arrayUnion(currentUser.uid),
        });

        // 4) Notification erzeugen (für Post-Autor & Kommentatoren)
        const authorName = `${firstName} ${lastName}`.trim();
        await createCommentNotifications(postSnap, authorName);
      }

      setCommentInput('');
      setCommentSuccess('Kommentar hinzugefügt.');
      setCommentError('');
      setIsCommentBoxExpanded(false);
    } catch (err) {
      console.error('Fehler beim Hinzufügen des Kommentars:', err);
      setCommentError('Kommentar konnte nicht hinzugefügt werden.');
    }
  }, [commentInput, currentUser, parentId, postId]);

  useEffect(() => {
    if (commentError || commentSuccess) {
      setSnackbarMessage(commentError || commentSuccess);
      setSnackbarSeverity(commentError ? 'error' : 'success');
      setSnackbarOpen(true);
    }
  }, [commentError, commentSuccess]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ padding: 2, width: '100%' }}>
      {/* Eingabefeld nur in der Hauptebene */}
      {!parentId && (
        <CommentInputBox>
          {!isCommentBoxExpanded ? (
            <Button
              variant="outlined"
              startIcon={<SendIcon />}
              onClick={() => setIsCommentBoxExpanded(true)}
              sx={{
                color: '#d5bc8b',
                borderColor: '#d5bc8b',
                width: '100%',
                textTransform: 'none',
                fontWeight: 'bold',
              }}
            >
              Kommentar hinzufügen
            </Button>
          ) : (
            <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
              <TextField
                multiline
                minRows={2}
                maxRows={6}
                fullWidth
                value={commentInput}
                onChange={(e) => setCommentInput(e.target.value)}
                placeholder="Dein Kommentar..."
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    handleAddComment();
                  }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: '#3a3f4c', // Etwas helleres Grau für bessere Lesbarkeit
                    color: '#fff',
                  },
                }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                <IconButton
                  onClick={() => {
                    setIsCommentBoxExpanded(false);
                    setCommentInput('');
                  }}
                >
                  <CancelIcon sx={{ color: '#d5bc8b' }} />
                </IconButton>
                <Button
                  variant="contained"
                  endIcon={<SendIcon />}
                  onClick={handleAddComment}
                  sx={{
                    backgroundColor: '#d5bc8b',
                    color: '#000',
                    textTransform: 'none',
                    fontWeight: 'bold',
                  }}
                >
                  Senden
                </Button>
              </Box>
            </Box>
          )}
        </CommentInputBox>
      )}

      {/* Kommentare */}
      {comments.length === 0 ? (
        !parentId && (
          <Typography
            variant="body2"
            sx={{ color: '#fff', textAlign: 'center' }}
          >
            Keine Kommentare vorhanden.
          </Typography>
        )
      ) : (
        <List sx={{ width: '100%', padding: 0 }}>
          {comments.map((comment) => (
            <React.Fragment key={comment.id}>
              <ListItem
                alignItems="flex-start"
                sx={{
                  p: 0,
                  mb: 1, // Verringerter Abstand zwischen den Kommentaren
                  ml: comment.parentId ? 4 : 0, // Deutlichere Einrückung für Unterkommentare
                  width: '100%',
                }}
              >
                <Comment comment={comment} postId={postId} />
              </ListItem>

              {/* Sub-Kommentare */}
              <Box sx={{ ml: comment.parentId ? 4 : 0, width: '100%' }}>
                <CommentSection postId={postId} parentId={comment.id} />
              </Box>
            </React.Fragment>
          ))}
        </List>
      )}

      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <AlertComponent
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </AlertComponent>
      </Snackbar>
    </Box>
  );
};

export default CommentSection;
