// src/components/pages/Community/CommunityPage.js

import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import NewsFeed from './UserDashboard_components/NewsFeed';

// **Farbkonstanten zur besseren Wartbarkeit**
const COLORS = {
  darkBlue: '#252833',
  lightBrown: '#d5bc8b',
};

// **Styled Components mit responsiven Anpassungen**
const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  backgroundImage: `url('/images/backgrounds/kth_fasern.png')`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'scroll', // Standardmäßig scrollen lassen
  padding: theme.spacing(2), // Leichtes Padding für kleinen Abstand
  alignItems: 'center',

  // Hintergrund-Anhang auf größeren Bildschirmen fixieren
  [theme.breakpoints.up('sm')]: {
    backgroundAttachment: 'fixed',
  },
}));

const MainContent = styled(Box)(({ theme }) => ({
  flex: '1',
  maxWidth: '1400px', // Maximale Breite von 1400px
  width: '100%',
  padding: theme.spacing(0), // Entfernt das Padding komplett
  boxSizing: 'border-box',
}));

const Title = styled(Typography)(({ theme }) => ({
  color: COLORS.lightBrown,
  marginBottom: theme.spacing(2), // Angepasster Abstand
  padding: theme.spacing(0, 1), // Optional: Leichtes Padding zur Begrenzung
  textAlign: 'center',
  fontWeight: 'bold',
  fontSize: { xs: '1.5rem', sm: '2rem' }, // Responsive Schriftgröße
}));

const CommunityPage = () => {
  return (
    <Container>
      <Title variant="h4">Community</Title>
      <MainContent>
        <NewsFeed />
      </MainContent>
    </Container>
  );
};

export default CommunityPage;
