// src/components/UserProfile_components/MyHorses.js

import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Avatar,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Snackbar,
  IconButton,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/system';
import { getAuth } from 'firebase/auth';
import {
  getFirestore,
  doc,
  setDoc,
  getDocs,
  collection,
  updateDoc,
} from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import HorseImageUploader from './utils/HorseImageUploader';
import MuiAlert from '@mui/material/Alert';

const auth = getAuth();
const db = getFirestore();
const storage = getStorage();

// Snackbar Alert Component
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Styled Komponenten
const HorsesContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: theme.spacing(4),
  backgroundColor: '#252833',
  padding: theme.spacing(4),
  borderRadius: '12px',
  opacity: 0.95,
}));

const HorseCard = styled(Card)(({ theme }) => ({
  maxWidth: 345,
  margin: 'auto',
  backgroundColor: '#252833', // Vordergrundfarbe #252833
  color: '#ffffff', // Weißer Text für besseren Kontrast
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
  transition: 'transform 0.2s, box-shadow 0.2s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.4)',
  },
}));

const AddHorseCard = styled(Card)(({ theme }) => ({
  maxWidth: 345,
  margin: 'auto',
  backgroundColor: '#252833', // Vordergrundfarbe #252833
  color: '#ffffff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  cursor: 'pointer',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
  transition: 'transform 0.2s, box-shadow 0.2s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.4)',
  },
}));

const ActionIconButton = styled(IconButton)(({ theme }) => ({
  color: '#d5bc8b',
  '&:hover': {
    color: '#ff4d4f',
  },
}));

const MyHorses = () => {
  const [horses, setHorses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [newHorse, setNewHorse] = useState({ name: '', breed: '', age: '' });
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [selectedHorseId, setSelectedHorseId] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [deletedHorse, setDeletedHorse] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [horseToDelete, setHorseToDelete] = useState(null);

  // Funktion zum Abrufen der Download-URL
  const getProfilePictureURL = async (profilePicturePath) => {
    try {
      const storageRef = ref(storage, profilePicturePath);
      const downloadURL = await getDownloadURL(storageRef);
      return downloadURL;
    } catch (error) {
      console.error('Fehler beim Abrufen der Download-URL:', error);
      return '/path/to/default/horse.jpg'; // Platzhalterbild
    }
  };

  // Funktion zum Abrufen der Pferdedaten
  const fetchHorses = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        const horsesRef = collection(db, 'users', user.uid, 'horses');
        const snapshot = await getDocs(horsesRef);
        const horsesData = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const horse = { id: doc.id, ...doc.data() };
            if (horse.profilePicturePath) {
              horse.profilePictureURL = await getProfilePictureURL(
                horse.profilePicturePath
              );
            } else {
              horse.profilePictureURL = '/path/to/default/horse.jpg'; // Platzhalterbild
            }
            return horse;
          })
        );
        setHorses(horsesData.filter((horse) => !horse.horse_deleted));
      } catch (err) {
        console.error('Fehler beim Abrufen der Pferdedaten:', err);
        setError('Fehler beim Laden der Pferdedaten.');
      } finally {
        setLoading(false);
      }
    } else {
      setError('Benutzer nicht authentifiziert.');
      setLoading(false);
    }
  };

  // Auth-Listener und Datenabruf beim Laden der Komponente
  useEffect(() => {
    fetchHorses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Funktion zum Löschen eines Pferdes (Öffnen des Bestätigungsdialogs)
  const handleOpenConfirmDelete = (horse) => {
    setHorseToDelete(horse);
    setConfirmDeleteOpen(true);
  };

  const handleCloseConfirmDelete = () => {
    setConfirmDeleteOpen(false);
    setHorseToDelete(null);
  };

  // Funktion zum Bestätigen des Löschens
  const confirmDeleteHorse = async () => {
    if (horseToDelete) {
      const user = auth.currentUser;
      if (user) {
        try {
          const horseRef = doc(
            db,
            'users',
            user.uid,
            'horses',
            horseToDelete.id
          );
          await updateDoc(horseRef, { horse_deleted: true });
          setHorses((prevHorses) =>
            prevHorses.filter((horse) => horse.id !== horseToDelete.id)
          );
          setDeletedHorse(horseToDelete);
          setSnackbar({
            open: true,
            message: `Das Pferd "${horseToDelete.name}" wurde gelöscht.`,
            severity: 'info',
          });
          setHorseToDelete(null);
        } catch (err) {
          console.error('Fehler beim Löschen des Pferdes:', err);
          setSnackbar({
            open: true,
            message: 'Fehler beim Löschen des Pferdes.',
            severity: 'error',
          });
        } finally {
          setConfirmDeleteOpen(false);
        }
      }
    }
  };

  // Funktion zum Rückgängig machen des Löschens
  const handleUndoDelete = async () => {
    if (deletedHorse) {
      const user = auth.currentUser;
      if (user) {
        try {
          const horseRef = doc(
            db,
            'users',
            user.uid,
            'horses',
            deletedHorse.id
          );
          await updateDoc(horseRef, { horse_deleted: false });
          // Aktualisiere die Pferdeliste mit dem wiederhergestellten Pferd
          setHorses((prevHorses) => [
            ...prevHorses,
            { ...deletedHorse, horse_deleted: false },
          ]);
          setSnackbar({
            open: true,
            message: `Das Pferd "${deletedHorse.name}" wurde wiederhergestellt.`,
            severity: 'success',
          });
          setDeletedHorse(null);
        } catch (err) {
          console.error('Fehler beim Wiederherstellen des Pferdes:', err);
          setSnackbar({
            open: true,
            message: 'Fehler beim Wiederherstellen des Pferdes.',
            severity: 'error',
          });
        }
      }
    }
  };

  // Funktion zum Hinzufügen eines neuen Pferdes
  const handleAddHorse = async () => {
    const user = auth.currentUser;
    if (user && horses.length < 3) {
      try {
        const horseId = `horse_${Date.now()}`;
        await setDoc(doc(db, 'users', user.uid, 'horses', horseId), {
          ...newHorse,
          horse_deleted: false,
          created_at: new Date(),
        });
        setNewHorse({ name: '', breed: '', age: '' });
        setModalOpen(false);
        // Aktualisiere die Pferdeanzeige
        fetchHorses();
        setSnackbar({
          open: true,
          message: 'Pferd erfolgreich hinzugefügt.',
          severity: 'success',
        });
      } catch (err) {
        console.error('Fehler beim Hinzufügen des Pferdes:', err);
        setSnackbar({
          open: true,
          message: 'Fehler beim Hinzufügen des Pferdes.',
          severity: 'error',
        });
      }
    }
  };

  // Funktion zum Öffnen des Pferdebild-Uploaders
  const handleOpenImageUploader = (id) => {
    setSelectedHorseId(id);
    setImageDialogOpen(true);
  };

  // Funktion zum Schließen des Snackbars
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <HorsesContainer>
      <Typography variant="h4" sx={{ color: '#d5bc8b', mb: 4 }}>
        Meine Pferde
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        {horses.length < 3 && (
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setModalOpen(true)}
            sx={{ backgroundColor: '#d5bc8b', color: '#000' }}
          >
            Pferd hinzufügen
          </Button>
        )}
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : horses.length === 0 ? (
        <Typography variant="body1" sx={{ color: '#ffffff' }}>
          Es sind noch keine Pferde angelegt. Bitte füge ein Pferd hinzu.
        </Typography>
      ) : (
        <Grid container spacing={4}>
          {horses.map((horse) => (
            <Grid item xs={12} sm={6} md={4} key={horse.id}>
              <HorseCard>
                {/* Entfernt: CardMedia-Komponente, um das doppelte Profilbild zu eliminieren */}
                {/* <CardMedia
                  component="img"
                  height="250"
                  image={horse.profilePictureURL || '/path/to/default/horse.jpg'} // Verwende die Profilbild-URL
                  alt={`Bild von ${horse.name}`} // Beschreibender Alt-Text
                  sx={{ cursor: 'pointer', objectFit: 'cover' }}
                  onClick={() => handleOpenImageUploader(horse.id)}
                /> */}

                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      mb: 2,
                    }}
                  >
                    <Avatar
                      alt={horse.name}
                      src={
                        horse.profilePictureURL || '/path/to/default/horse.jpg'
                      }
                      sx={{ width: 120, height: 120, mb: 2, cursor: 'pointer' }} // Größeres und zentriertes Avatar
                      onClick={() => handleOpenImageUploader(horse.id)}
                    />
                    <Typography
                      variant="h5"
                      sx={{ textAlign: 'center', color: '#ffffff' }}
                    >
                      {horse.name}
                    </Typography>
                  </Box>
                  <Typography
                    variant="body2"
                    color="#ffffff"
                    sx={{ textAlign: 'center' }}
                  >
                    <strong>Rasse:</strong> {horse.breed || 'Nicht verfügbar'}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="#ffffff"
                    sx={{ textAlign: 'center' }}
                  >
                    <strong>Alter:</strong> {horse.age || 'Nicht verfügbar'}{' '}
                    Jahre
                  </Typography>
                </CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
                  <Tooltip title="Bearbeiten">
                    <IconButton
                      color="primary"
                      onClick={() => {
                        /* Implementiere Bearbeitungsfunktion */
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Löschen">
                    <ActionIconButton
                      onClick={() => handleOpenConfirmDelete(horse)}
                    >
                      <DeleteIcon />
                    </ActionIconButton>
                  </Tooltip>
                </Box>
              </HorseCard>
            </Grid>
          ))}

          {/* Karte zum Hinzufügen eines neuen Pferdes */}
          {horses.length < 3 && (
            <Grid item xs={12} sm={6} md={4}>
              <AddHorseCard onClick={() => setModalOpen(true)}>
                <Typography variant="h5">+ Pferd hinzufügen</Typography>
              </AddHorseCard>
            </Grid>
          )}
        </Grid>
      )}

      {/* Dialog zum Hinzufügen eines neuen Pferdes */}
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Pferd hinzufügen</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}>
            <TextField
              label="Pferdename"
              variant="outlined"
              fullWidth
              value={newHorse.name}
              onChange={(e) =>
                setNewHorse({ ...newHorse, name: e.target.value })
              }
            />
            <TextField
              label="Rasse"
              variant="outlined"
              fullWidth
              value={newHorse.breed}
              onChange={(e) =>
                setNewHorse({ ...newHorse, breed: e.target.value })
              }
            />
            <TextField
              label="Alter (Jahre)"
              variant="outlined"
              fullWidth
              type="number"
              value={newHorse.age}
              onChange={(e) =>
                setNewHorse({ ...newHorse, age: e.target.value })
              }
            />
            {/* Entfernte Bild-URL-Feld */}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalOpen(false)} color="primary">
            Abbrechen
          </Button>
          <Button
            onClick={handleAddHorse}
            variant="contained"
            sx={{ backgroundColor: '#d5bc8b', color: '#000' }}
          >
            Hinzufügen
          </Button>
        </DialogActions>
      </Dialog>

      {/* Pferdebild-Upload-Dialog */}
      <HorseImageUploader
        open={imageDialogOpen}
        onClose={() => setImageDialogOpen(false)}
        horseId={selectedHorseId}
        refreshHorses={fetchHorses} // Stelle sicher, dass die Pferdedaten nach dem Upload aktualisiert werden
      />

      {/* Bestätigungsdialog zum Löschen eines Pferdes */}
      <Dialog
        open={confirmDeleteOpen}
        onClose={handleCloseConfirmDelete}
        aria-labelledby="confirm-delete-dialog-title"
      >
        <DialogTitle id="confirm-delete-dialog-title">
          Pferd löschen
        </DialogTitle>
        <DialogContent>
          <Typography>
            Bist du sicher, dass du das Pferd "{horseToDelete?.name}" löschen
            möchtest? Diese Aktion kann nicht rückgängig gemacht werden.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDelete} color="primary">
            Abbrechen
          </Button>
          <Button
            onClick={confirmDeleteHorse}
            color="error"
            variant="contained"
          >
            Löschen
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar für Benachrichtigungen */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          action={
            deletedHorse && (
              <Button color="inherit" size="small" onClick={handleUndoDelete}>
                Rückgängig
              </Button>
            )
          }
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </HorsesContainer>
  );
};

// Komponente für das Hinzufügen/Bearbeiten von Pferden (nicht mehr benötigt, da Dialog direkt in Main-Komponente integriert)
export default MyHorses;
