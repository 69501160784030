import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import {
  Box,
  Tabs,
  Tab,
  Typography,
  CircularProgress,
  Button,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  Grid,
  Avatar,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { styled, useTheme } from '@mui/material/styles';

import {
  getFirestore,
  collection,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  query,
  where,
} from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as HorseIcon } from '../../../assets/icons/horse.svg';
import VideoCard from './VideoCard';
import TabAllVideos from './TabAllVideos'; // <--- Deine ausgelagerte Tab-0-Komponente

/* ------------------------------
   Platzhalter & Hilfsfunktionen
------------------------------- */
const PLACEHOLDER_THUMBNAIL = 'https://via.placeholder.com/160x120';

const sortByOrder = (a, b) => {
  const orderA =
    typeof a.order === 'number' && !isNaN(a.order) ? a.order : Infinity;
  const orderB =
    typeof b.order === 'number' && !isNaN(b.order) ? b.order : Infinity;
  return orderA - orderB;
};

const capitalizeFirstLetter = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1);

/* ------------------------------
   Gestylte Komponenten
------------------------------- */
const HorsesContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  overflowX: 'auto',
  overflowY: 'hidden',
  gap: '20px',
  padding: '20px 0',
  scrollBehavior: 'smooth',
  justifyContent: 'center',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    gap: '15px',
    padding: '15px 0',
  },
}));

const HorseAvatarWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  flexShrink: 0,
  cursor: 'pointer',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& .horse-image': {
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    position: 'relative',
    overflow: 'hidden',
    transition: 'all 0.5s ease',
    [theme.breakpoints.down('sm')]: {
      width: '60px',
      height: '60px',
    },
  },
  '& .overlay': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    top: 0,
    left: 0,
    transition: 'opacity 0.5s ease',
    opacity: 1,
  },
  '& .horse-name': {
    marginTop: '8px',
    color: '#ffffff',
    opacity: 0,
    transition: 'opacity 0.3s ease',
    fontWeight: 'bold',
    fontSize: '0.9rem',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
    },
  },
  '&:hover .horse-name': {
    opacity: 1,
  },
  '&:hover .overlay': {
    opacity: 0,
  },
  '&.selected': {
    '& .horse-image': {
      width: '100px',
      height: '100px',
      boxShadow: '0 0 10px #d5bc8b',
      border: '3px solid #d5bc8b',
      [theme.breakpoints.down('sm')]: {
        width: '80px',
        height: '80px',
      },
    },
    '& .overlay': {
      opacity: 0,
    },
  },
}));

const StyledCard = styled('div')(({ theme }) => ({
  padding: theme.spacing(6),
  textAlign: 'center',
  backgroundColor: '#252833',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: theme.shadows[6],
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(4),
  color: '#FFFFFF',
  maxWidth: 500,
  margin: '0 auto',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
    maxWidth: '90%',
  },
}));

const GoldHorseIcon = styled(HorseIcon)(({ theme }) => ({
  width: 80,
  height: 80,
  fill: 'none',
  stroke: '#d5bc8b',
  strokeWidth: 2,
  [theme.breakpoints.down('sm')]: {
    width: 60,
    height: 60,
  },
}));

/* Tab-Panel Utility */
function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}
const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: { xs: 2, md: 3 }, maxWidth: '1400px', margin: '0 auto' }}>
          {children}
        </Box>
      )}
    </div>
  );
};

/* ----------------------------------------
   Hauptkomponente: VideoStage
---------------------------------------- */
export default function VideoStage() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const db = getFirestore();
  const storage = getStorage();
  const { currentUser } = useAuth();

  const [value, setValue] = useState(0);

  // Tab 0: Alle Videos
  const [themenbereiche, setThemenbereiche] = useState([]);
  const [kapitelList, setKapitelList] = useState([]);
  const [videosByKapitel, setVideosByKapitel] = useState({});
  const [loadingThemenbereiche, setLoadingThemenbereiche] = useState(false);
  const [loadingKapitel, setLoadingKapitel] = useState(false);
  const [loadingVideos, setLoadingVideos] = useState(false);
  const [errorThemenbereiche, setErrorThemenbereiche] = useState(null);
  const [errorKapitel, setErrorKapitel] = useState(null);
  const [errorVideos, setErrorVideos] = useState(null);

  // Auswahl: Themenbereich
  const [selectedThemenbereichId, setSelectedThemenbereichId] = useState(null);

  // Allgemeiner Error / Snackbar
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Tab 1: Meine Videos
  const [horses, setHorses] = useState([]);
  const [loadingHorses, setLoadingHorses] = useState(true);
  const [selectedHorse, setSelectedHorse] = useState('');
  const [tests, setTests] = useState([]);
  const [loadingTests, setLoadingTests] = useState(false);
  const [selectedTest, setSelectedTest] = useState('');
  const [videoRecommendations, setVideoRecommendations] = useState({});

  // Lesezeichen
  const [bookmarkedVideos, setBookmarkedVideos] = useState([]);

  // Pferde-Bilder Cache
  const horseProfilePictureCache = useRef({});
  // Tab 1 ContainerRef
  const horsesContainerRef = useRef(null);

  // Vollständige Video-Liste (für Tab 1 und Tab 2)
  const [allVideos, setAllVideos] = useState([]);

  // Fortschritt pro Video
  const [videoProgress, setVideoProgress] = useState({});

  // Memo: Map VideoID -> Video-Daten
  const videoIdToVideoMap = useMemo(() => {
    const map = {};
    allVideos.forEach((v) => {
      map[v.id] = v;
    });
    return map;
  }, [allVideos]);

  /* Tabs wechseln */
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /* ----------------------------------------
     1) Tab 0: Themenbereiche, Kapitel, Videos laden
  ---------------------------------------- */
  useEffect(() => {
    fetchThemenbereiche();
    fetchKapitel();
    fetchVideos();
  }, []);

  const fetchThemenbereiche = async () => {
    setLoadingThemenbereiche(true);
    setErrorThemenbereiche(null);
    try {
      const refTB = collection(db, 'settings', 'themenbereiche', 'list');
      const snapTB = await getDocs(refTB);
      let list = snapTB.docs.map((docItem) => {
        const d = docItem.data();
        return {
          id: docItem.id,
          name: d?.name?.trim() || '',
          order: typeof d.order === 'number' ? d.order : undefined,
          imageUrl: d?.imageUrl || '',
          kapitelIDs: Array.isArray(d.kapitelIDs) ? d.kapitelIDs : [],
        };
      });
      list.sort(sortByOrder);
      setThemenbereiche(list);
    } catch (err) {
      console.error('Fehler beim Abrufen der Themenbereiche:', err);
      setErrorThemenbereiche('Fehler beim Laden der Themenbereiche.');
    } finally {
      setLoadingThemenbereiche(false);
    }
  };

  const fetchKapitel = async () => {
    setLoadingKapitel(true);
    setErrorKapitel(null);
    try {
      const refK = collection(db, 'settings', 'kapitel', 'list');
      const snapK = await getDocs(refK);
      let list = snapK.docs.map((docItem) => {
        const d = docItem.data();
        return {
          id: docItem.id,
          name: d?.name?.trim() || '',
          order: typeof d.order === 'number' ? d.order : undefined,
          themenbereich: d?.themenbereich?.trim() || '',
          imageUrl: d?.imageUrl || '',
          typ: d?.typ?.trim() || '',
        };
      });
      list.sort(sortByOrder);
      setKapitelList(list);
    } catch (err) {
      console.error('Fehler beim Abrufen der Kapitel:', err);
      setErrorKapitel('Fehler beim Laden der Kapitel.');
    } finally {
      setLoadingKapitel(false);
    }
  };

  // Fortschritt laden
  const fetchUserVideoProgress = async (videoIds) => {
    if (!currentUser) return;
    try {
      const newProgress = {};
      for (const vid of videoIds) {
        const progressDocRef = doc(
          db,
          'videos',
          vid,
          'progress',
          currentUser.uid
        );
        const docSnap = await getDoc(progressDocRef);
        if (docSnap.exists()) {
          newProgress[vid] = docSnap.data().progress || 0;
        } else {
          newProgress[vid] = 0;
        }
      }
      setVideoProgress(newProgress);
    } catch (err) {
      console.error('Fehler beim Abrufen des Video-Fortschritts:', err);
      setError('Fehler beim Abrufen des Video-Fortschritts.');
    }
  };

  const fetchVideos = async () => {
    setLoadingVideos(true);
    setErrorVideos(null);
    try {
      const refV = collection(db, 'videos');
      const snapV = await getDocs(refV);
      const grouped = {};
      const allVTemp = [];

      snapV.forEach((docItem) => {
        const data = docItem.data();
        const kapitelId = data?.kapitelID;
        const order = typeof data?.order === 'number' ? data.order : undefined;

        allVTemp.push({
          id: docItem.id,
          ...data,
        });

        if (!kapitelId) return;
        if (!grouped[kapitelId]) grouped[kapitelId] = { videos: [] };

        grouped[kapitelId].videos.push({
          id: docItem.id,
          video_titel: data?.video_titel || '',
          order,
          thumbnailUrl: data?.thumbnailUrl || PLACEHOLDER_THUMBNAIL,
          status: data?.status || 'draft',
          kapitelId,
          duration: data?.duration || 0,
        });
      });

      // Sortieren
      for (const kid of Object.keys(grouped)) {
        grouped[kid].videos.sort(sortByOrder);
      }

      allVTemp.sort((a, b) =>
        (a.video_titel || '').localeCompare(b.video_titel || '')
      );

      setVideosByKapitel(grouped);
      setAllVideos(allVTemp);

      // Fortschritt laden
      if (currentUser) {
        await fetchUserVideoProgress(allVTemp.map((v) => v.id));
      }
    } catch (err) {
      console.error('Fehler beim Abrufen der Videos:', err);
      setErrorVideos('Fehler beim Laden der Videos.');
    } finally {
      setLoadingVideos(false);
    }
  };

  /* ----------------------------------------
     2) Lesezeichen laden
  ---------------------------------------- */
  useEffect(() => {
    const fetchBookmarks = async () => {
      if (currentUser) {
        try {
          const userDocRef = doc(db, 'users', currentUser.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setBookmarkedVideos(userData.bookmarks || []);
          }
        } catch (err) {
          console.error('Fehler beim Abrufen der Lesezeichen:', err);
          setError('Fehler beim Abrufen der Lesezeichen.');
        }
      }
    };
    fetchBookmarks();
  }, [currentUser, db]);

  const handleBookmarkToggle = useCallback(
    async (video) => {
      if (!currentUser) {
        setError('Bitte melde dich an, um Lesezeichen zu verwenden.');
        return;
      }
      const userDocRef = doc(db, 'users', currentUser.uid);
      let updated = [...bookmarkedVideos];
      const already = updated.includes(video.id);

      if (already) {
        updated = updated.filter((v) => v !== video.id);
      } else {
        updated.push(video.id);
      }

      try {
        await updateDoc(userDocRef, { bookmarks: updated });
        setBookmarkedVideos(updated);
      } catch (error) {
        console.error('Fehler beim Aktualisieren der Lesezeichen:', error);
        setError('Fehler beim Aktualisieren der Lesezeichen.');
      }
    },
    [currentUser, bookmarkedVideos, db]
  );

  const handleVideoClick = useCallback(
    (videoId) => {
      navigate(`/video/${videoId}`);
    },
    [navigate]
  );

  /* ----------------------------------------
     3) Tab 1: Meine Videos, Horses, Tests
     WICHTIG: Reihenfolge 
       - zuerst enrichVideoDataByIds
       - dann fetchTestResult
       - dann fetchTests
  ---------------------------------------- */

  const enrichVideoDataByIds = useCallback(
    (videoIdsByCategory) => {
      if (!videoIdsByCategory || typeof videoIdsByCategory !== 'object') {
        console.error('videoIdsByCategory ungültig:', videoIdsByCategory);
        return {};
      }
      const res = {};
      for (const [cat, ids] of Object.entries(videoIdsByCategory)) {
        if (!Array.isArray(ids)) continue;
        res[cat] = ids
          .map((vidId) => {
            const v = videoIdToVideoMap[vidId];
            if (!v) return null;
            return {
              id: vidId,
              video_titel: v.video_titel || 'Unbekannter Titel',
              text: v.text || '',
              vimeo_id: v.vimeo_id || '',
              thumbnailUrl: v.thumbnailUrl || PLACEHOLDER_THUMBNAIL,
              duration: v.duration || 0,
            };
          })
          .filter(Boolean);
      }
      return res;
    },
    [videoIdToVideoMap]
  );

  const fetchTestResult = useCallback(
    async (testId) => {
      setLoadingRecommendations(true);
      try {
        const docRef = doc(
          db,
          'users',
          currentUser.uid,
          'horses',
          selectedHorse,
          'test_result',
          testId
        );
        const snap = await getDoc(docRef);
        if (snap.exists()) {
          const testResult = snap.data();
          const videoIdsByCat = testResult.videoIds;
          if (
            videoIdsByCat &&
            typeof videoIdsByCat === 'object' &&
            Object.keys(videoIdsByCat).length > 0
          ) {
            const enriched = enrichVideoDataByIds(videoIdsByCat);
            setVideoRecommendations(enriched);
          } else {
            setError('Testergebnis ohne gültige Videos gefunden.');
          }
        } else {
          setError('Kein Testergebnis vorhanden.');
        }
      } catch (err) {
        console.error('Fehler beim Abrufen des Testergebnisses:', err);
        setError('Fehler beim Abrufen des Testergebnisses.');
      } finally {
        setLoadingRecommendations(false);
      }
    },
    [db, currentUser, selectedHorse, enrichVideoDataByIds]
  );

  const fetchTests = useCallback(async () => {
    if (!currentUser || !selectedHorse) {
      setTests([]);
      setSelectedTest('');
      return;
    }
    setLoadingTests(true);
    try {
      const testsRef = collection(
        db,
        'users',
        currentUser.uid,
        'horses',
        selectedHorse,
        'test'
      );
      const snapT = await getDocs(testsRef);
      const testsArr = snapT.docs.map((d) => ({
        id: d.id,
        ...d.data(),
      }));

      const completed = testsArr.filter((t) => t.status === 'completed');
      completed.sort((a, b) => b.date.seconds - a.date.seconds);
      setTests(completed);

      if (completed.length > 0 && !selectedTest) {
        setSelectedTest(completed[0].id);
        await fetchTestResult(completed[0].id);
      }
    } catch (err) {
      console.error('Fehler beim Abrufen der Tests:', err);
      setError('Fehler beim Abrufen der Tests.');
    } finally {
      setLoadingTests(false);
    }
  }, [currentUser, selectedHorse, db, selectedTest, fetchTestResult]);

  // Tests abrufen, wenn selectedHorse sich ändert
  useEffect(() => {
    fetchTests();
  }, [fetchTests]);

  // Pferde laden
  useEffect(() => {
    const fetchHorses = async () => {
      if (!currentUser) {
        setLoadingHorses(false);
        return;
      }
      try {
        const horsesRef = collection(db, 'users', currentUser.uid, 'horses');
        const qH = query(horsesRef, where('horse_deleted', '==', false));
        const snapH = await getDocs(qH);
        const list = await Promise.all(
          snapH.docs.map(async (docItem) => {
            const data = docItem.data();
            let profilePictureURL = '';

            if (horseProfilePictureCache.current[data.id]) {
              profilePictureURL = horseProfilePictureCache.current[data.id];
            } else if (data.profilePicturePath) {
              try {
                profilePictureURL = await getDownloadURL(
                  ref(storage, data.profilePicturePath)
                );
                horseProfilePictureCache.current[data.id] = profilePictureURL;
              } catch (error) {
                console.error(
                  `Fehler beim Profilbild für Pferd ${docItem.id}:`,
                  error
                );
              }
            }
            return { id: docItem.id, ...data, profilePictureURL };
          })
        );
        setHorses(list);
      } catch (err) {
        console.error('Fehler beim Abrufen der Pferde:', err);
        setError('Fehler beim Abrufen der Pferde.');
      } finally {
        setLoadingHorses(false);
      }
    };
    fetchHorses();
  }, [currentUser, db, storage]);

  const handleHorseClick = useCallback((horseId) => {
    setSelectedHorse(horseId);
    setSelectedTest('');
    setVideoRecommendations({});
  }, []);

  // Sobald wir Horses haben, standardmäßig das erste auswählen
  useEffect(() => {
    if (!selectedHorse && horses.length > 0) {
      setSelectedHorse(horses[0].id);
    }
  }, [horses, selectedHorse]);

  // Scroll zur ausgewählten Pferde-Position
  useEffect(() => {
    if (horsesContainerRef.current && selectedHorse) {
      const container = horsesContainerRef.current;
      const idx = horses.findIndex((h) => h.id === selectedHorse);
      if (idx >= 0) {
        const horseElements = container.querySelectorAll('.horse-avatar');
        const selectedElement = horseElements[idx];
        if (selectedElement) {
          const containerRect = container.getBoundingClientRect();
          const elementRect = selectedElement.getBoundingClientRect();
          const offset =
            elementRect.left -
            containerRect.left +
            elementRect.width / 2 -
            containerRect.width / 2;
          container.scrollBy({ left: offset, behavior: 'smooth' });
        }
      }
    }
  }, [selectedHorse, horses]);

  const handleTestChange = useCallback(
    async (event) => {
      const value = event.target.value;
      if (value === 'new') {
        const selectedHorseObj = horses.find((h) => h.id === selectedHorse);
        const horseNameValue = selectedHorseObj ? selectedHorseObj.name : '';
        navigate(
          `/Einschaetzungstest?horseId=${encodeURIComponent(
            selectedHorse
          )}&horseName=${encodeURIComponent(horseNameValue)}`
        );
      } else {
        setSelectedTest(value);
        await fetchTestResult(value);
      }
    },
    [horses, selectedHorse, navigate, fetchTestResult]
  );

  const [loadingRecommendations, setLoadingRecommendations] = useState(false);

  const getCategoryDescription = useCallback((category) => {
    return `Hilfreiche Videos zum Thema "${capitalizeFirstLetter(
      category.replace('_', ' ')
    )}".`;
  }, []);

  /* ----------------------------------------
     4) Tab 2: Lesezeichen
  ---------------------------------------- */

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  /* ----------------------------------------
     RENDER
  ---------------------------------------- */
  return (
    <Box
      sx={{
        backgroundColor: 'transparent',
        backgroundImage: 'url(/images/backgrounds/kth_fasern.png)',
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        minHeight: '100vh',
        padding: { xs: '10px', sm: '20px' },
        overflowY: 'auto',
      }}
    >
      <Typography
        variant="h3"
        sx={{
          textAlign: 'center',
          color: '#d5bc8b', // Gold
          mb: { xs: 2, md: 8 },
          fontSize: { xs: '1.8rem', sm: '2.4rem', md: '3rem' },
        }}
      ></Typography>

      {/* Tabs */}
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="Video Tabs"
        variant={isSmallScreen ? 'scrollable' : 'standard'}
        scrollButtons="auto"
        sx={{
          borderBottom: '2px solid #3f4b54',
          mb: 4,
          '& .MuiTabs-indicator': {
            backgroundColor: '#d5bc8b !important',
          },
        }}
        TabIndicatorProps={{ style: { height: '3px' } }}
      >
        <Tab
          label="Alle Videos"
          {...a11yProps(0)}
          sx={{
            color: '#c0c0c0',
            '&.Mui-selected': { color: '#d5bc8b' },
            '&:hover': { color: '#ffffff' },
            fontWeight: 'bold',
            minWidth: 100,
            px: { xs: 1, sm: 2 },
          }}
        />
        <Tab
          label="Meine Videos"
          {...a11yProps(1)}
          sx={{
            color: '#c0c0c0',
            '&.Mui-selected': { color: '#d5bc8b' },
            '&:hover': { color: '#ffffff' },
            fontWeight: 'bold',
            minWidth: 100,
            px: { xs: 1, sm: 2 },
          }}
        />
        <Tab
          label="Lesezeichen"
          {...a11yProps(2)}
          sx={{
            color: '#c0c0c0',
            '&.Mui-selected': { color: '#d5bc8b' },
            '&:hover': { color: '#ffffff' },
            fontWeight: 'bold',
            minWidth: 100,
            px: { xs: 1, sm: 2 },
          }}
        />
      </Tabs>

      {/* Snackbar / Error */}
      {error && (
        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={() => setError(null)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setError(null)}
            severity="error"
            sx={{ width: '100%' }}
          >
            {error}
          </Alert>
        </Snackbar>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="warning"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* ----------------------------------------
         TAB 0: Alle Videos -> TabAllVideos
         Übergabe des videoProgress
      ---------------------------------------- */}
      <TabPanel value={value} index={0}>
        {(loadingThemenbereiche || loadingKapitel || loadingVideos) && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <CircularProgress />
          </Box>
        )}

        {errorThemenbereiche && (
          <Typography color="error" sx={{ mb: 2 }}>
            {errorThemenbereiche}
          </Typography>
        )}
        {errorKapitel && (
          <Typography color="error" sx={{ mb: 2 }}>
            {errorKapitel}
          </Typography>
        )}
        {errorVideos && (
          <Typography color="error" sx={{ mb: 2 }}>
            {errorVideos}
          </Typography>
        )}

        <TabAllVideos
          themenbereiche={themenbereiche}
          selectedThemenbereichId={selectedThemenbereichId}
          setSelectedThemenbereichId={setSelectedThemenbereichId}
          kapitelList={kapitelList}
          videosByKapitel={videosByKapitel}
          bookmarkedVideos={bookmarkedVideos}
          handleBookmarkToggle={handleBookmarkToggle}
          handleVideoClick={handleVideoClick}
          // Hier übergeben wir den Fortschritt
          videoProgress={videoProgress}
        />
      </TabPanel>

      {/* ----------------------------------------
         TAB 1: Meine Videos (Horses, Tests, Empfehlungen)
      ---------------------------------------- */}
      <TabPanel value={value} index={1}>
        {loadingHorses ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <CircularProgress size={24} />
          </Box>
        ) : horses.length > 0 ? (
          <Box sx={{ width: '100%', maxWidth: '100%' }}>
            {/* Pferde horizontal scroll */}
            <HorsesContainer ref={horsesContainerRef}>
              {horses.map((horse) => {
                const isSelected = horse.id === selectedHorse;
                return (
                  <HorseAvatarWrapper
                    key={horse.id}
                    className={`horse-avatar ${isSelected ? 'selected' : ''}`}
                    onClick={() => handleHorseClick(horse.id)}
                  >
                    <div className="horse-image">
                      <img
                        src={horse.profilePictureURL || ''}
                        alt={horse.name}
                        style={{
                          width: '100%',
                          height: '100%',
                          borderRadius: '50%',
                          objectFit: 'cover',
                        }}
                      />
                      <div className="overlay"></div>
                    </div>
                    <div className="horse-name">{horse.name}</div>
                  </HorseAvatarWrapper>
                );
              })}
            </HorsesContainer>

            {/* Tests + Videoempfehlungen */}
            {selectedHorse && (
              <Box sx={{ mt: 4 }}>
                {loadingTests ? (
                  <Box
                    sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}
                  >
                    <CircularProgress size={24} />
                  </Box>
                ) : tests.length > 0 ? (
                  <>
                    <Box
                      sx={{
                        color: '#ffffff',
                        backgroundColor: '#3f4b54',
                        padding: { xs: '8px', sm: '10px' },
                        borderRadius: '8px',
                        mb: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: {
                          xs: 'center',
                          sm: 'space-between',
                        },
                        flexDirection: { xs: 'column', sm: 'row' },
                      }}
                    >
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          display: 'inline-block',
                          mr: { sm: 2 },
                          fontSize: { xs: '1rem', sm: '1.2rem' },
                        }}
                      >
                        Videoempfehlungen für{' '}
                        {horses.find((h) => h.id === selectedHorse)?.name ||
                          '...'}
                        :
                      </Typography>
                      <Select
                        value={selectedTest}
                        onChange={handleTestChange}
                        sx={{
                          backgroundColor: '#ffffff',
                          borderRadius: '8px',
                          height: '40px',
                          width: { xs: '100%', sm: 'auto' },
                        }}
                      >
                        {tests.map((test) => {
                          const dateStr = new Date(
                            test.date.seconds * 1000
                          ).toLocaleDateString('de-DE', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                          });
                          return (
                            <MenuItem key={test.id} value={test.id}>
                              {`${test.testName} (${dateStr})`}
                            </MenuItem>
                          );
                        })}
                        <MenuItem value="new">
                          Neuen Einschätzungstest starten
                        </MenuItem>
                      </Select>
                    </Box>

                    {/* Accordion je Kategorie */}
                    {Object.keys(videoRecommendations).length > 0 ? (
                      Object.entries(videoRecommendations).map(
                        ([category, vids]) => (
                          <Box
                            key={category}
                            sx={{
                              backgroundColor: '#3f4b54',
                              color: '#ffffff',
                              mb: 2,
                              borderRadius: '8px',
                              overflow: 'hidden',
                              p: 2,
                            }}
                          >
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: 'bold',
                                color: '#d5bc8b',
                                mb: 1,
                                fontSize: { xs: '1rem', sm: '1.1rem' },
                              }}
                            >
                              {capitalizeFirstLetter(
                                category.replace('_', ' ')
                              )}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                color: '#d0d0d0',
                                fontSize: { xs: '0.8rem', sm: '0.9rem' },
                                mb: 2,
                              }}
                            >
                              {getCategoryDescription(category)}
                            </Typography>
                            <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
                              {vids.map((video) => (
                                <Grid item xs={12} key={video.id}>
                                  <VideoCard
                                    video={video}
                                    // Fortschritt anzeigen
                                    progress={videoProgress[video.id] || 0}
                                    onClick={() => handleVideoClick(video.id)}
                                    isBookmarked={bookmarkedVideos.includes(
                                      video.id
                                    )}
                                    onBookmarkToggle={() =>
                                      handleBookmarkToggle(video)
                                    }
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </Box>
                        )
                      )
                    ) : (
                      <Typography
                        sx={{
                          color: '#ffffff',
                          textAlign: 'center',
                          mt: 2,
                          fontSize: { xs: '1rem', sm: '1.2rem' },
                        }}
                      >
                        Keine Empfehlungen für diesen Test.
                      </Typography>
                    )}
                  </>
                ) : (
                  <Typography
                    sx={{
                      color: '#ffffff',
                      textAlign: 'center',
                      mt: 2,
                      fontSize: { xs: '1rem', sm: '1.2rem' },
                    }}
                  >
                    Keine abgeschlossenen Tests vorhanden oder kein Pferd
                    ausgewählt.
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        ) : (
          <StyledCard>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <GoldHorseIcon />
            </Box>
            <Typography
              variant="h5"
              sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }}
            >
              Keine aktiven Pferde verfügbar
            </Typography>
            <Typography
              variant="body1"
              sx={{ mb: 3, fontSize: { xs: '0.9rem', sm: '1rem' } }}
            >
              Bitte füge mindestens ein Pferd unter „Einstellungen → Meine
              Pferde“ hinzu, um Tests durchzuführen und Videos zu sehen.
            </Typography>
            <Button
              variant="contained"
              onClick={() => navigate('/profile?tab=horses')}
              sx={{
                backgroundColor: '#d5bc8b',
                color: '#3f4b54',
                '&:hover': {
                  backgroundColor: '#c0a57b',
                },
                px: { xs: 2, sm: 3 },
                py: { xs: 1, sm: 1.5 },
                fontSize: { xs: '0.8rem', sm: '0.9rem' },
              }}
            >
              Pferd hinzufügen
            </Button>
          </StyledCard>
        )}
      </TabPanel>

      {/* ----------------------------------------
         TAB 2: Lesezeichen
      ---------------------------------------- */}
      <TabPanel value={value} index={2}>
        {bookmarkedVideos.length > 0 ? (
          <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
            {allVideos
              .filter((v) => bookmarkedVideos.includes(v.id))
              .map((video) => (
                <Grid item xs={12} key={video.id}>
                  <VideoCard
                    video={video}
                    progress={videoProgress[video.id] || 0}
                    onClick={() => handleVideoClick(video.id)}
                    isBookmarked
                    onBookmarkToggle={() => handleBookmarkToggle(video)}
                  />
                </Grid>
              ))}
          </Grid>
        ) : (
          <Typography
            sx={{
              color: '#d5bc8b',
              textAlign: 'center',
              mt: 2,
              fontSize: { xs: '1rem', sm: '1.2rem' },
            }}
          >
            Keine Lesezeichen vorhanden.
          </Typography>
        )}
      </TabPanel>
    </Box>
  );
}
