// src/components/System.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  Alert,
  Switch,
  FormControlLabel,
  Modal,
  LinearProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { getAuth } from 'firebase/auth';
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  getDocs,
  collection,
  updateDoc,
} from 'firebase/firestore';
import axios from 'axios'; // Für API-Aufrufe
import { getFunctions, httpsCallable } from 'firebase/functions'; // Importiere Firebase Functions

// Zugriff auf die Auth-Instanz und Firestore
const auth = getAuth();
const db = getFirestore();

const System = () => {
  // --------------------------------------------------------
  // 1) State für Benutzererstellung (bestehender Code)
  // --------------------------------------------------------
  const [email, setEmail] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [userLoading, setUserLoading] = useState(false);
  const [userSuccessMsg, setUserSuccessMsg] = useState('');
  const [userErrorMsg, setUserErrorMsg] = useState('');

  // --------------------------------------------------------
  // 2) State für E-Mail-Einstellungen
  // --------------------------------------------------------
  const [settingsLoading, setSettingsLoading] = useState(false);
  const [settingsSuccessMsg, setSettingsSuccessMsg] = useState('');
  const [settingsErrorMsg, setSettingsErrorMsg] = useState('');

  // Testmodus (alle E-Mails gehen an testEmail)
  const [testMode, setTestMode] = useState(false);
  // AWS vs. Eigenes SMTP
  const [useAws, setUseAws] = useState(false);

  // Wohin sollen E-Mails im Testmodus gehen?
  const [testEmail, setTestEmail] = useState('');
  // Absenderadresse
  const [fromEmail, setFromEmail] = useState('');

  // AWS-spezifische Settings
  const [awsRegion, setAwsRegion] = useState('');

  // SMTP-spezifische Settings
  const [smtpHost, setSmtpHost] = useState('');
  const [smtpPort, setSmtpPort] = useState('');
  const [smtpSecure, setSmtpSecure] = useState(false);
  const [smtpUser, setSmtpUser] = useState('');
  const [smtpPass, setSmtpPass] = useState('');

  // --------------------------------------------------------
  // NEU: State fürs direkte Testen der Willkommens-E-Mail
  // --------------------------------------------------------
  const [testSendLoading, setTestSendLoading] = useState(false);
  const [testSendSuccessMsg, setTestSendSuccessMsg] = useState('');
  const [testSendErrorMsg, setTestSendErrorMsg] = useState('');

  // --------------------------------------------------------
  // NEU: State für das Laden der E-Mail-Einstellungen
  // --------------------------------------------------------
  const [loadSettingsLoading, setLoadSettingsLoading] = useState(true);
  const [loadSettingsErrorMsg, setLoadSettingsErrorMsg] = useState('');

  // --------------------------------------------------------
  // NEU: State für das Aktualisieren aller Thumbnails
  // --------------------------------------------------------
  const [updateThumbnailsLoading, setUpdateThumbnailsLoading] = useState(false);
  const [updateThumbnailsSuccessMsg, setUpdateThumbnailsSuccessMsg] =
    useState('');
  const [updateThumbnailsErrorMsg, setUpdateThumbnailsErrorMsg] = useState('');
  const [updateThumbnailsSummary, setUpdateThumbnailsSummary] = useState(null);
  const [progress, setProgress] = useState(0);
  const [errorDetails, setErrorDetails] = useState([]);

  // --------------------------------------------------------
  // 3) useEffect zum Laden der E-Mail-Einstellungen
  // --------------------------------------------------------
  useEffect(() => {
    const fetchEmailSettings = async () => {
      setLoadSettingsLoading(true);
      setLoadSettingsErrorMsg('');

      try {
        const settingsDocRef = doc(db, 'settings', 'emailSettings');
        const settingsDoc = await getDoc(settingsDocRef);

        if (settingsDoc.exists()) {
          const data = settingsDoc.data();

          setTestMode(data.testMode || false);
          setTestEmail(data.testEmail || '');
          setUseAws(data.useAws || false);
          setFromEmail(data.fromEmail || '');

          if (data.aws && data.aws.region) {
            setAwsRegion(data.aws.region);
          } else {
            setAwsRegion('');
          }

          if (data.smtp) {
            setSmtpHost(data.smtp.host || '');
            setSmtpPort(data.smtp.port || '');
            setSmtpSecure(data.smtp.secure || false);
            setSmtpUser(data.smtp.auth?.user || '');
            setSmtpPass(data.smtp.auth?.pass || '');
          } else {
            setSmtpHost('');
            setSmtpPort('');
            setSmtpSecure(false);
            setSmtpUser('');
            setSmtpPass('');
          }
        } else {
          console.log(
            'Keine E-Mail-Einstellungen gefunden. Verwende Standardwerte.'
          );
        }

        // **Zusätzlich: Überprüfe die Admin-Claims im Frontend**
        const currentUser = auth.currentUser;
        if (currentUser) {
          const idTokenResult = await currentUser.getIdTokenResult();
          console.log('Admin-Claim im Frontend:', idTokenResult.claims.admin);
        }
      } catch (error) {
        console.error('Fehler beim Laden der E-Mail-Einstellungen:', error);
        setLoadSettingsErrorMsg(
          error.message || 'Fehler beim Laden der E-Mail-Einstellungen.'
        );
      } finally {
        setLoadSettingsLoading(false);
      }
    };

    fetchEmailSettings();
  }, [db]);

  // --------------------------------------------------------
  // 4) Bestehende Funktionen
  // --------------------------------------------------------

  // Funktion zum Erstellen eines neuen Benutzers (bestehend)
  const handleCreateUser = async () => {
    setUserLoading(true);
    setUserSuccessMsg('');
    setUserErrorMsg('');

    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        setUserErrorMsg('Keine authentifizierte Sitzung gefunden.');
        setUserLoading(false);
        return;
      }

      // ID-Token holen
      const idToken = await currentUser.getIdToken();

      // =========================================
      // LÖSUNG B: URL MIT DOPPELTER "/api"
      // =========================================
      const createUserUrl =
        'https://europe-west3-keytohorses-firebase.cloudfunctions.net/api/api/createUser';

      // POST-Anfrage an die Route /createUser (die in index.js mit "/api" gemountet ist)
      const response = await axios.post(
        createUserUrl,
        {
          email,
          displayName,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      const data = response.data;
      if (response.status === 200 && data.success) {
        setUserSuccessMsg(
          'Benutzer erfolgreich erstellt und Willkommens-E-Mail gesendet.'
        );
        setEmail('');
        setDisplayName('');
      } else {
        setUserErrorMsg(data.error || 'Fehler beim Erstellen des Benutzers.');
      }
    } catch (error) {
      console.error('Fehler beim Erstellen des Benutzers:', error);
      if (error.response && error.response.data && error.response.data.error) {
        setUserErrorMsg(error.response.data.error);
      } else {
        setUserErrorMsg(
          error.message || 'Es gab einen Fehler beim Erstellen des Benutzers.'
        );
      }
    } finally {
      setUserLoading(false);
    }
  };

  // Funktion zum Speichern der E-Mail-Einstellungen in Firestore
  const handleSaveEmailSettings = async () => {
    setSettingsLoading(true);
    setSettingsSuccessMsg('');
    setSettingsErrorMsg('');

    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        setSettingsErrorMsg('Keine authentifizierte Sitzung gefunden.');
        setSettingsLoading(false);
        return;
      }

      // ID-Token holen
      const idToken = await currentUser.getIdToken();

      // Backend-API-Endpunkt zum Speichern der E-Mail-Einstellungen (falls nötig)
      // Falls du die Einstellungen direkt in Firestore speichern möchtest, ist kein API-Aufruf nötig
      // Hier wird direkt in Firestore geschrieben

      // Dokument in 'settings' -> 'emailSettings' anlegen/aktualisieren:
      await setDoc(
        doc(db, 'settings', 'emailSettings'),
        {
          testMode,
          testEmail,
          useAws,
          fromEmail,
          aws: {
            region: awsRegion,
          },
          smtp: {
            host: smtpHost,
            port: smtpPort,
            secure: smtpSecure,
            auth: {
              user: smtpUser,
              pass: smtpPass,
            },
          },
        },
        { merge: true }
      );

      setSettingsSuccessMsg('E-Mail-Einstellungen erfolgreich gespeichert.');
    } catch (error) {
      console.error('Fehler beim Speichern der E-Mail-Einstellungen:', error);
      setSettingsErrorMsg(
        error.message || 'Fehler beim Speichern der E-Mail-Einstellungen.'
      );
    } finally {
      setSettingsLoading(false);
    }
  };

  // Funktion zum Senden einer Test-Willkommens-E-Mail
  const handleSendTestWelcomeEmail = async () => {
    setTestSendLoading(true);
    setTestSendSuccessMsg('');
    setTestSendErrorMsg('');

    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        setTestSendErrorMsg('Keine authentifizierte Sitzung gefunden.');
        setTestSendLoading(false);
        return;
      }

      const idToken = await currentUser.getIdToken();

      // Backend-Endpoint für Test-Willkommens-E-Mail
      const testSendUrl =
        'https://europe-west3-keytohorses-firebase.cloudfunctions.net/api/testWelcomeEmail';

      const response = await axios.post(
        testSendUrl,
        {
          email: testEmail || 'test@example.com',
          displayName: 'TestUser',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      const data = response.data;
      if (response.status === 200 && data.success) {
        setTestSendSuccessMsg('Test-Willkommens-E-Mail erfolgreich gesendet.');
      } else {
        setTestSendErrorMsg(data.error || 'Fehler beim Senden der Test-Mail.');
      }
    } catch (error) {
      console.error('Fehler beim Test-Mail-Versand:', error);
      if (error.response && error.response.data && error.response.data.error) {
        setTestSendErrorMsg(error.response.data.error);
      } else {
        setTestSendErrorMsg(
          error.message || 'Es gab einen Fehler beim Test-Mail-Versand.'
        );
      }
    } finally {
      setTestSendLoading(false);
    }
  };

  // --------------------------------------------------------
  // NEU: Handler für das Aktualisieren aller Thumbnails
  // --------------------------------------------------------
  const handleUpdateAllThumbnails = async () => {
    // Bestätigungsdialog anzeigen
    const confirmUpdate = window.confirm(
      'Möchtest du wirklich alle Thumbnails aktualisieren? Dieser Vorgang kann einige Zeit in Anspruch nehmen.'
    );
    if (!confirmUpdate) return;

    setUpdateThumbnailsLoading(true);
    setUpdateThumbnailsSuccessMsg('');
    setUpdateThumbnailsErrorMsg('');
    setUpdateThumbnailsSummary(null);
    setProgress(0);
    setErrorDetails([]);

    try {
      // 1) Hole alle Videos aus Firestore
      const videosSnapshot = await getDocs(collection(db, 'videos'));
      const videos = videosSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const totalVideos = videos.length;

      if (totalVideos === 0) {
        setUpdateThumbnailsErrorMsg(
          'Keine Videos gefunden, um Thumbnails zu aktualisieren.'
        );
        setUpdateThumbnailsLoading(false);
        return;
      }

      // 2) Initialisiere Firebase Functions mit der richtigen Region
      const functionsInstance = getFunctions(undefined, 'europe-west3'); // Stelle sicher, dass die Region korrekt ist
      const vimeoThumbnailCallable = httpsCallable(
        functionsInstance,
        'vimeoThumbnail'
      );

      let successCount = 0;
      let skippedCount = 0;
      let errorCount = 0;
      let errors = [];

      for (let i = 0; i < videos.length; i++) {
        const video = videos[i];
        const { id: videoDocId, vimeo_id } = video;

        if (!vimeo_id) {
          console.warn(`Video ${videoDocId} hat keine vimeo_id. Überspringe.`);
          skippedCount++;
          setProgress(((i + 1) / totalVideos) * 100);
          continue;
        }

        // Extrahiere die numerische Vimeo-ID aus der URL
        let extractedVimeoId = null;

        try {
          const regex = /vimeo\.com\/(\d+)/;
          const match = vimeo_id.match(regex);
          if (match && match[1]) {
            extractedVimeoId = match[1];
          } else {
            throw new Error('Keine gültige numerische Vimeo-ID gefunden.');
          }
        } catch (extractionError) {
          console.warn(
            `Video ${videoDocId} hat eine ungültige vimeo_id: ${vimeo_id}. Überspringe.`
          );
          skippedCount++;
          setProgress(((i + 1) / totalVideos) * 100);
          continue;
        }

        try {
          // Rufe die Cloud Function auf mit der extrahierten Vimeo-ID
          const result = await vimeoThumbnailCallable({
            videoId: extractedVimeoId,
          });
          const { thumbnailUrl } = result.data;

          if (!thumbnailUrl) {
            throw new Error(
              'Keine thumbnailUrl in der Antwort der Cloud Function.'
            );
          }

          // Aktualisiere das Video-Dokument mit der neuen Thumbnail-URL
          await updateDoc(doc(db, 'videos', videoDocId), {
            thumbnailUrl: thumbnailUrl,
            date_thumbnail_updated: new Date(),
          });

          console.log(
            `Thumbnail für Video ${videoDocId} erfolgreich aktualisiert.`
          );
          successCount++;
        } catch (error) {
          console.error(
            `Fehler beim Aktualisieren des Thumbnails für Video ${videoDocId}:`,
            error.message
          );
          errorCount++;
          errors.push({ videoId: videoDocId, reason: error.message });
        }

        // Aktualisiere den Fortschritt
        setProgress(((i + 1) / totalVideos) * 100);
      }

      // 3) Bereite eine Zusammenfassung vor
      const summary = {
        total: totalVideos,
        success: successCount,
        skipped: skippedCount,
        errors: errors,
      };

      setUpdateThumbnailsSummary(summary);

      if (errorCount === 0) {
        setUpdateThumbnailsSuccessMsg(
          'Alle Thumbnails wurden erfolgreich aktualisiert.'
        );
      } else {
        setUpdateThumbnailsSuccessMsg(
          `Thumbnails aktualisiert: ${successCount}, übersprungen: ${skippedCount}, Fehler: ${errorCount}`
        );
        setErrorDetails(errors);
      }
    } catch (error) {
      console.error('Fehler beim Aktualisieren der Thumbnails:', error);
      setUpdateThumbnailsErrorMsg(
        error.message || 'Fehler beim Aktualisieren der Thumbnails.'
      );
    } finally {
      setUpdateThumbnailsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 800,
        mx: 'auto',
        mt: 4,
        p: 3,
        boxShadow: 3,
        borderRadius: 2,
        backgroundColor: '#fff',
      }}
    >
      {/* -----------------------------------------
          Abschnitt: Benutzerverwaltung
      ----------------------------------------- */}
      <Typography variant="h5" gutterBottom>
        Benutzerverwaltung
      </Typography>
      <Box sx={{ mb: 6 }}>
        <Typography variant="h6" gutterBottom>
          Neuen Benutzer erstellen
        </Typography>
        <TextField
          label="E-Mail-Adresse"
          variant="outlined"
          fullWidth
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          label="Anzeigename"
          variant="outlined"
          fullWidth
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleCreateUser}
          disabled={userLoading || !email}
          startIcon={userLoading && <CircularProgress size={20} />}
          sx={{ mb: 2 }}
        >
          {userLoading ? 'Benutzer wird erstellt...' : 'Benutzer erstellen'}
        </Button>
        {userSuccessMsg && (
          <Alert severity="success" sx={{ mt: 2 }}>
            {userSuccessMsg}
          </Alert>
        )}
        {userErrorMsg && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {userErrorMsg}
          </Alert>
        )}
      </Box>

      {/* -----------------------------------------
          Abschnitt: E-Mail-Einstellungen
      ----------------------------------------- */}
      <Box sx={{ mb: 6 }}>
        <Typography variant="h5" gutterBottom>
          E-Mail-Einstellungen
        </Typography>

        {/* Anzeige, welcher Service gerade genutzt wird */}
        <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
          Aktueller E-Mail-Service:{' '}
          <strong>{useAws ? 'AWS SES' : 'SMTP'}</strong>
        </Typography>

        {/* Switch: Testmodus */}
        <FormControlLabel
          control={
            <Switch
              checked={testMode}
              onChange={(e) => setTestMode(e.target.checked)}
            />
          }
          label="Testmodus aktivieren"
        />
        <TextField
          label="Test-E-Mail-Adresse"
          variant="outlined"
          fullWidth
          value={testEmail}
          onChange={(e) => setTestEmail(e.target.value)}
          sx={{ mb: 2 }}
          helperText="Alle E-Mails gehen im Testmodus an diese Adresse."
        />

        {/* Switch: AWS vs. SMTP */}
        <FormControlLabel
          control={
            <Switch
              checked={useAws}
              onChange={(e) => setUseAws(e.target.checked)}
            />
          }
          label="AWS SES statt SMTP verwenden"
        />

        {/* From-E-Mail-Adresse */}
        <TextField
          label="Absenderadresse (From)"
          variant="outlined"
          fullWidth
          value={fromEmail}
          onChange={(e) => setFromEmail(e.target.value)}
          sx={{ mb: 2 }}
        />

        {/* AWS-Einstellungen (optional, wenn useAws = true) */}
        {useAws && (
          <>
            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              AWS-Einstellungen
            </Typography>
            <TextField
              label="AWS Region"
              variant="outlined"
              fullWidth
              value={awsRegion}
              onChange={(e) => setAwsRegion(e.target.value)}
              sx={{ mb: 2 }}
            />
          </>
        )}

        {/* SMTP-Einstellungen (optional, wenn useAws = false) */}
        {!useAws && (
          <>
            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              SMTP-Einstellungen
            </Typography>
            <TextField
              label="SMTP Host"
              variant="outlined"
              fullWidth
              value={smtpHost}
              onChange={(e) => setSmtpHost(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="SMTP Port"
              variant="outlined"
              fullWidth
              value={smtpPort}
              onChange={(e) => setSmtpPort(e.target.value)}
              sx={{ mb: 2 }}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={smtpSecure}
                  onChange={(e) => setSmtpSecure(e.target.checked)}
                />
              }
              label="SSL/TLS (Port 465) verwenden"
            />
            <TextField
              label="SMTP Benutzer"
              variant="outlined"
              fullWidth
              value={smtpUser}
              onChange={(e) => setSmtpUser(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="SMTP Passwort"
              type="password"
              variant="outlined"
              fullWidth
              value={smtpPass}
              onChange={(e) => setSmtpPass(e.target.value)}
              sx={{ mb: 2 }}
            />
          </>
        )}

        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSaveEmailSettings}
          disabled={settingsLoading}
          startIcon={settingsLoading && <CircularProgress size={20} />}
          sx={{ mb: 2 }}
        >
          {settingsLoading ? 'Speichere...' : 'E-Mail-Einstellungen speichern'}
        </Button>

        {settingsSuccessMsg && (
          <Alert severity="success" sx={{ mt: 2 }}>
            {settingsSuccessMsg}
          </Alert>
        )}
        {settingsErrorMsg && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {settingsErrorMsg}
          </Alert>
        )}

        {loadSettingsLoading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <CircularProgress />
          </Box>
        )}
        {loadSettingsErrorMsg && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {loadSettingsErrorMsg}
          </Alert>
        )}
      </Box>

      {/* -----------------------------------------
          Abschnitt: Test-Willkommens-E-Mail
      ----------------------------------------- */}
      <Box sx={{ mb: 6 }}>
        <Typography variant="h5" gutterBottom>
          Test-Willkommens-E-Mail versenden
        </Typography>
        <Button
          variant="outlined"
          color="secondary"
          fullWidth
          onClick={handleSendTestWelcomeEmail}
          disabled={testSendLoading || !testEmail}
          startIcon={testSendLoading && <CircularProgress size={20} />}
          sx={{ mb: 2 }}
        >
          {testSendLoading
            ? 'Versand läuft...'
            : 'Willkommens-E-Mail an Test-Adresse senden'}
        </Button>
        {testSendSuccessMsg && (
          <Alert severity="success" sx={{ mt: 2 }}>
            {testSendSuccessMsg}
          </Alert>
        )}
        {testSendErrorMsg && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {testSendErrorMsg}
          </Alert>
        )}
      </Box>

      {/* -----------------------------------------
          NEUER Abschnitt: Lernplattform-Funktionen
      ----------------------------------------- */}
      <Box sx={{ mb: 6 }}>
        <Typography variant="h5" gutterBottom>
          Lernplattform-Funktionen
        </Typography>
        <Typography variant="body1" gutterBottom>
          Hier kannst du verschiedene systemweite Funktionen verwalten.
        </Typography>

        {/* Button zum Aktualisieren aller Thumbnails */}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleUpdateAllThumbnails}
          disabled={updateThumbnailsLoading}
          startIcon={updateThumbnailsLoading && <CircularProgress size={20} />}
          sx={{ mt: 2 }}
        >
          {updateThumbnailsLoading
            ? 'Thumbnails werden aktualisiert...'
            : 'Alle Thumbnails aktualisieren'}
        </Button>

        {/* Modal für den Fortschritt */}
        <Modal
          open={updateThumbnailsLoading}
          onClose={() => {
            /* Blockiere das Schließen während des Vorgangs */
          }}
          aria-labelledby="thumbnail-update-modal"
          aria-describedby="modal-for-thumbnail-updates"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { xs: '90%', md: '60%', lg: '40%' },
              bgcolor: 'background.paper',
              borderRadius: 2,
              boxShadow: 24,
              p: 4,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                id="thumbnail-update-modal"
                variant="h6"
                component="h2"
              >
                Thumbnails aktualisieren
              </Typography>
              <IconButton
                onClick={() => {
                  /* Blockiere das Schließen während des Vorgangs */
                }}
                disabled
              >
                <CloseIcon />
              </IconButton>
            </Box>

            <Box sx={{ mt: 4 }}>
              <Typography variant="body1" gutterBottom>
                Aktualisiere Thumbnails: {progress.toFixed(2)}%
              </Typography>
              <LinearProgress variant="determinate" value={progress} />
            </Box>

            {errorDetails.length > 0 && (
              <Box sx={{ mt: 4 }}>
                <Typography variant="body1" color="error">
                  Fehler beim Aktualisieren einiger Thumbnails:
                </Typography>
                {errorDetails.map((err) => (
                  <Typography key={err.videoId} variant="body2" color="error">
                    - Video ID: {err.videoId} | Grund: {err.reason}
                  </Typography>
                ))}
              </Box>
            )}
          </Box>
        </Modal>

        {/* Erfolgsmeldung */}
        {updateThumbnailsSuccessMsg && (
          <Alert severity="success" sx={{ mt: 2 }}>
            {updateThumbnailsSuccessMsg}
          </Alert>
        )}

        {/* Fehlermeldung */}
        {updateThumbnailsErrorMsg && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {updateThumbnailsErrorMsg}
          </Alert>
        )}

        {/* Zusammenfassung der Aktualisierung */}
        {updateThumbnailsSummary && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6">Zusammenfassung:</Typography>
            <Typography variant="body2">
              Gesamtzahl der Videos: {updateThumbnailsSummary.total}
            </Typography>
            <Typography variant="body2" color="success.main">
              Erfolgreich aktualisiert: {updateThumbnailsSummary.success}
            </Typography>
            <Typography variant="body2" color="warning.main">
              Übersprungen: {updateThumbnailsSummary.skipped}
            </Typography>
            {updateThumbnailsSummary.errors.length > 0 && (
              <Box sx={{ mt: 1 }}>
                <Typography variant="body2" color="error.main">
                  Fehler bei {updateThumbnailsSummary.errors.length} Videos:
                </Typography>
                {updateThumbnailsSummary.errors.map((err) => (
                  <Typography
                    key={err.videoId}
                    variant="body2"
                    color="error.main"
                  >
                    - Video ID: {err.videoId} | Grund: {err.reason}
                  </Typography>
                ))}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default System;
