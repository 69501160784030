// src/components/pages/Community/Dashboard.js

import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  onSnapshot,
  doc,
} from 'firebase/firestore';
import { db } from '../../../config/firebase';
import {
  Box,
  Typography,
  useMediaQuery,
  Drawer,
  IconButton,
  CircularProgress,
  Grid,
  Snackbar,
  Alert,
  Avatar, // Hinzugefügt
} from '@mui/material';
import { styled, useTheme } from '@mui/system';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, Link } from 'react-router-dom'; // Link hinzugefügt
import { motion } from 'framer-motion';
import { format } from 'date-fns';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import MapComponent from './User_map/MapComponent';
import LivestreamWidget from '../../Livemeetings/LivestreamWidget'; // Aktualisierter Import-Pfad

import LongWidget from '../../widgets/LongWidget';
import ShortWidget from '../../widgets/ShortWidget';
import WidgetsManager from '../../Administration/dashboard_editor/WidgetsManager';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  minHeight: '100vh', // Ermöglicht, dass der Container größer als der Viewport werden kann
  backgroundImage: `url('/images/backgrounds/kth_fasern.png')`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed',
  // Entferne overflow: 'hidden' um Scrollen zu ermöglichen
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    overflow: 'auto',
  },
}));

const MainContent = styled(Box)(({ theme }) => ({
  flex: '1',
  padding: '20px',
  paddingBottom: '100px', // Hinzugefügtes Padding für zusätzlichen Platz
  backgroundImage: `url('/images/backgrounds/muster.png')`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  margin: '0px',
  marginRight: '300px', // Platz für das permanente Drawer auf Desktop
  // Scrollen nur im MainContent erlauben
  [theme.breakpoints.down('md')]: {
    marginRight: 0,
    margin: '20px',
    paddingBottom: '60px', // Sicherstellen, dass das Padding auch für mobile Geräte gilt
  },
  [theme.breakpoints.up('md')]: {
    marginTop: '120px', // Erhöhter Abstand auf Desktop
    height: 'calc(100vh - 120px)', // Höhe anpassen, um Platz zu schaffen
    overflowY: 'auto', // Scrollen innerhalb des MainContent
    paddingBottom: '60px', // Zusätzlicher Platz am unteren Rand
  },
}));

const Overlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'hsla(240, 59.60%, 18.40%, 0.99)0.00%, 0.99)',
  transition: 'opacity 0.3s ease',
  pointerEvents: 'none',
  zIndex: 10,
}));

const MapWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  borderRadius: '8px',
  overflow: 'hidden',
}));

const Title = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  fontSize: '1.5rem',
  fontFamily: 'Jost, sans-serif',
  color: '#d5bc8b',
  fontWeight: 400,
  position: 'relative',
  paddingBottom: '4px',
  '&::after': {
    content: '""',
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    height: '1px',
    backgroundColor: '#d5bc8b',
  },
}));

const DrawerContainer = styled(Box)(({ theme }) => ({
  width: 300,
  padding: theme.spacing(2),
  backgroundColor: '#252833',
  color: '#d5bc8b',
  height: '100%',
  overflowY: 'auto',
  boxSizing: 'border-box',
  [theme.breakpoints.down('md')]: {
    width: 250,
  },
}));

const WidgetPaper = styled(Box)(({ theme }) => ({
  position: 'relative',
  marginBottom: '20px',
  borderRadius: '8px',
  overflow: 'hidden',
  cursor: 'pointer',
  boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
  transition: 'box-shadow 0.3s',
  '&:hover': {
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
  },
}));

const ImageBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '150px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '50%',
    background: 'linear-gradient(to bottom, transparent, #2c2c2c)',
  },
}));

const TextOverlay = styled(Box)(({ theme }) => ({
  position: 'relative',
  marginTop: '-30px',
  padding: '10px 15px',
  background:
    'linear-gradient(to bottom, rgba(44, 44, 44, 0.8), rgba(44, 44, 44, 1))',
  borderBottomLeftRadius: '8px',
  borderBottomRightRadius: '8px',
}));

const NoMeetingBox = styled(Box)(({ theme }) => ({
  padding: '20px',
  textAlign: 'center',
  backgroundColor: '#2c2c2c',
  color: '#d5bc8b',
  borderRadius: '8px',
  marginTop: '20px',
  boxShadow: '0 4px 12px rgba(0,0,0,0.3)',
}));

const SidebarTitle = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  marginBottom: '20px',
  textAlign: 'center',
  color: '#d5bc8b',
}));

const Dashboard = () => {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [nextMeeting, setNextMeeting] = useState(null);
  const [loadingMeeting, setLoadingMeeting] = useState(true);
  const [errorMeeting, setErrorMeeting] = useState(null);

  // Zustand für Widgets (nur zum Anzeigen, keine Drag-and-Drop)
  const [widgets, setWidgets] = useState([]);
  const [loadingWidgets, setLoadingWidgets] = useState(true);
  const [errorWidgets, setErrorWidgets] = useState(null);

  const auth = getAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Zustand für Snackbar
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        const unsubscribeUser = fetchUserData(currentUser.uid);
        return () => {
          if (unsubscribeUser) unsubscribeUser();
        };
      } else {
        setUser(null);
        setUserData(null);
      }
    });

    const now = new Date();
    const meetingQuery = query(
      collection(db, 'meetings'),
      where('start_time', '>=', now),
      orderBy('start_time', 'asc'),
      limit(1)
    );

    const unsubscribeMeeting = onSnapshot(
      meetingQuery,
      (snapshot) => {
        if (!snapshot.empty) {
          const docSnap = snapshot.docs[0];
          const data = docSnap.data();
          const imageUrl = data.headerImageUrl
            ? data.headerImageUrl
            : '/images/default-meeting.jpg';
          setNextMeeting({
            id: docSnap.id,
            title: data.title,
            imageUrl: imageUrl,
            start_time: data.start_time,
            trainerName: data.trainerName || 'Nicht zugewiesen',
            trainerThumbnailUrl:
              data.trainerThumbnailUrl || '/images/default-avatar.png', // Ersetzt avatarUrl
          });
        } else {
          setNextMeeting(null);
        }
        setLoadingMeeting(false);
      },
      (error) => {
        console.error('Fehler beim Abrufen des nächsten Meetings:', error);
        setErrorMeeting('Fehler beim Abrufen des nächsten Meetings.');
        setLoadingMeeting(false);
      }
    );

    // Widgets abrufen (nur zum Anzeigen)
    const widgetsQuery = query(
      collection(db, 'widgets'),
      orderBy('order', 'asc')
    );

    const unsubscribeWidgets = onSnapshot(
      widgetsQuery,
      (snapshot) => {
        const fetchedWidgets = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setWidgets(fetchedWidgets);
        setLoadingWidgets(false);
      },
      (error) => {
        console.error('Fehler beim Abrufen der Widgets:', error);
        setErrorWidgets('Fehler beim Laden der Widgets.');
        setLoadingWidgets(false);
      }
    );

    return () => {
      unsubscribeAuth();
      unsubscribeMeeting();
      unsubscribeWidgets();
    };
  }, [auth, db]);

  const fetchUserData = (uid) => {
    try {
      const userDocRef = doc(db, 'users', uid); // Direkter Zugriff auf das Dokument mit der UID
      const unsubscribe = onSnapshot(userDocRef, (docSnap) => {
        if (docSnap.exists()) {
          const userData = docSnap.data();
          setUserData(userData);
        } else {
          setUserData(null);
          console.log(`Keine Benutzerdaten für UID: ${uid} gefunden.`);
        }
      });
      return unsubscribe;
    } catch (error) {
      console.error('Fehler beim Abrufen der Benutzerdaten:', error);
    }
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleWidgetClick = () => {
    navigate('/meeting-overview');
  };

  const drawerContent = (
    <DrawerContainer>
      <SidebarTitle variant="h5" gutterBottom>
        Nächster Livestream
      </SidebarTitle>

      {loadingMeeting ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <CircularProgress sx={{ color: '#d5bc8b' }} />
        </Box>
      ) : errorMeeting ? (
        <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>
          {errorMeeting}
        </Typography>
      ) : nextMeeting ? (
        <motion.div
          key={nextMeeting.id}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <WidgetPaper onClick={handleWidgetClick}>
            <ImageBox
              sx={{ backgroundImage: `url(${nextMeeting.imageUrl})` }}
            />
            <TextOverlay>
              <Typography
                variant="h6"
                sx={{ color: '#d5bc8b', fontWeight: '600' }}
              >
                {nextMeeting.title}
              </Typography>
              <Typography variant="body2" sx={{ color: '#d5bc8b' }}>
                Datum:{' '}
                {nextMeeting.start_time
                  ? format(
                      new Date(nextMeeting.start_time.toDate()),
                      'dd.MM.yyyy'
                    )
                  : 'N/A'}
              </Typography>
              <Typography variant="body2" sx={{ color: '#d5bc8b' }}>
                Uhrzeit:{' '}
                {nextMeeting.start_time
                  ? `${format(new Date(nextMeeting.start_time.toDate()), 'HH:mm')} Uhr`
                  : 'N/A'}
              </Typography>
              <Typography variant="body2" sx={{ color: '#d5bc8b' }}>
                Trainer: {nextMeeting.trainerName}
              </Typography>
            </TextOverlay>
            {/* Trainer-Avatar im Widget */}
            <Box
              sx={{
                position: 'absolute',
                bottom: 8,
                left: 8,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Avatar
                src={
                  nextMeeting.trainerThumbnailUrl ||
                  '/images/default-avatar.png'
                } // Ersetzt avatarUrl
                alt={nextMeeting.trainerName}
                sx={{ width: 32, height: 32, mr: 0.5 }}
              />
              <Typography
                variant="body2"
                sx={{
                  color: '#d5bc8b',
                  fontWeight: '500',
                }}
              >
                {nextMeeting.trainerName}
              </Typography>
            </Box>
          </WidgetPaper>
        </motion.div>
      ) : (
        <NoMeetingBox>
          <Typography variant="body1">
            Keine kommenden Livestreams verfügbar.
          </Typography>
        </NoMeetingBox>
      )}

      {/* Nutzerstandorte im Drawer */}
      <Box
        sx={{
          mt: 4,
          cursor: 'pointer',
          position: 'relative',
          '&:hover .overlay': {
            opacity: 0,
          },
        }}
        onClick={() => navigate('/map')}
      >
        <Typography variant="h6" sx={{ color: '#d5bc8b', mb: 1 }}>
          Nutzerstandorte
        </Typography>
        <MapWrapper
          sx={{
            width: '100%',
            aspectRatio: '1 / 1',
            borderRadius: '8px',
            overflow: 'hidden',
            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
            position: 'relative',
          }}
        >
          <MapComponent smallMap />
          <Overlay className="overlay" />
        </MapWrapper>
      </Box>
    </DrawerContainer>
  );

  return (
    <Container id="dashboardContainer">
      {isMobile ? (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '10px',
              backgroundColor: '#252833',
            }}
          >
            <Typography variant="h6" sx={{ color: '#d5bc8b' }}>
              Dashboard
            </Typography>
            <IconButton onClick={toggleDrawer} sx={{ color: '#d5bc8b' }}>
              <MenuIcon />
            </IconButton>
          </Box>
          <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={toggleDrawer}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              '& .MuiDrawer-paper': {
                width: 250,
                boxSizing: 'border-box',
                top: '64px',
                height: 'calc(100vh - 64px)',
              },
            }}
          >
            {drawerContent}
          </Drawer>
        </>
      ) : (
        <Drawer
          variant="permanent"
          anchor="right"
          sx={{
            width: 300,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: 300,
              boxSizing: 'border-box',
              top: '64px',
              height: 'calc(100vh - 64px)',
            },
          }}
        >
          {drawerContent}
        </Drawer>
      )}

      <MainContent>
        {/* Begrüßung: mit Vorname falls vorhanden, sonst Log in die Konsole */}
        {user && userData ? (
          userData.firstName ? (
            <Typography variant="h5" sx={{ color: '#d5bc8b', mb: 2 }}>
              Willkommen, {userData.firstName}!
            </Typography>
          ) : (
            <>
              {console.log(
                `Kein firstName für den Benutzer ${user.uid} in der Datenbank.`
              )}
              <Typography variant="h5" sx={{ color: '#d5bc8b', mb: 2 }}>
                Willkommen!
              </Typography>
            </>
          )
        ) : (
          <Typography variant="h5" sx={{ color: '#d5bc8b', mb: 2 }}>
            Willkommen!
          </Typography>
        )}

        {/* Überschrift: Neueste Videos */}
        <Title>Neueste Videos</Title>
        <Swiper
          modules={[Navigation, Pagination]}
          navigation
          pagination={{ clickable: true }}
          spaceBetween={20}
          slidesPerView={isMobile ? 1 : 3}
          style={{ padding: '20px 0' }}
        >
          {userData &&
            userData.latestVideos &&
            userData.latestVideos.map((video, index) => (
              <SwiperSlide key={index}>
                <Box
                  sx={{
                    background: `url(${video.thumbnailUrl}) center/cover`,
                    borderRadius: 2,
                    height: 200,
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                  }}
                />
              </SwiperSlide>
            ))}
        </Swiper>

        {/* Überschrift und LivestreamWidget nur für Mobile */}
        {isMobile && nextMeeting && (
          <>
            {/* Überschrift: Nächster LiveCall */}
            <Title sx={{ mt: 4 }}>Nächster LiveCall</Title>
            <Box
              component={Link} // Verwende Link für bessere Integration mit react-router
              to="/meeting-overview"
              sx={{
                marginBottom: '20px',
                cursor: 'pointer',
                textDecoration: 'none',
              }}
            >
              <LivestreamWidget meetingId={nextMeeting.id} />
            </Box>
          </>
        )}

        {/* Überschrift: Finde Pferdefreunde! und MapComponent nur dann anzeigen, wenn die Map angezeigt wird */}
        {isMobile && (
          <>
            <Title>Finde Pferdefreunde!</Title>
            <Box
              sx={{
                marginTop: '20px',
                marginBottom: '40px', // Abstand zwischen MapComponent und Neuigkeiten
              }}
            >
              <MapWrapper
                sx={{
                  width: '100%',
                  aspectRatio: '1 / 1',
                  borderRadius: '8px',
                  overflow: 'hidden',
                  boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                  position: 'relative',
                }}
              >
                <MapComponent />
              </MapWrapper>
            </Box>
          </>
        )}

        {/* Überschrift: Neuigkeiten */}
        <Box sx={{ mt: 4 }}>
          <Title>Neuigkeiten</Title>

          {loadingWidgets ? (
            <Box display="flex" justifyContent="center" mt={4}>
              <CircularProgress />
            </Box>
          ) : errorWidgets ? (
            <Typography
              variant="body1"
              color="error"
              sx={{ textAlign: 'center', mt: 2 }}
            >
              {errorWidgets}
            </Typography>
          ) : (
            <Grid container spacing={3}>
              {widgets.map((widget) => (
                <Grid
                  item
                  key={widget.id}
                  xs={12}
                  sm={6}
                  md={widget.size === 'lang' ? 12 : 6}
                >
                  <Box
                    sx={{
                      cursor: 'pointer',
                      transition: 'transform 0.3s, box-shadow 0.3s',
                      '&:hover': {
                        transform: 'scale(1.02)',
                        boxShadow: '0 6px 16px rgba(0, 0, 0, 0.3)',
                      },
                    }}
                  >
                    {widget.size === 'lang' ? (
                      <LongWidget
                        imageUrl={widget.imageUrl}
                        title={widget.title}
                        shortText={widget.shortText}
                        longText={widget.longText}
                      />
                    ) : (
                      <ShortWidget
                        imageUrl={widget.imageUrl}
                        title={widget.title}
                        shortText={widget.shortText}
                      />
                    )}
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>

        {/* WidgetsManager nur für Admins anzeigen */}
        {user && userData && userData.isAdmin && (
          <Box sx={{ mt: 4 }}>
            <WidgetsManager />
          </Box>
        )}
      </MainContent>

      {/* Snackbar für Benachrichtigungen */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Dashboard;
