// src/components/layout/navbar.jsx

import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import { ReactComponent as HorseIcon } from '../../assets/icons/horse.svg';

import { useNavigate } from 'react-router-dom';
import { auth, db, storage, database } from '../../config/firebase';
import {
  getDoc,
  doc,
  collection,
  query,
  orderBy,
  onSnapshot,
  updateDoc,
} from 'firebase/firestore';
import { ref as storageRef, getDownloadURL } from 'firebase/storage';
import { ref as dbRef, onValue } from 'firebase/database';

// Importiere Version und Release-Typ aus package.json
import packageJson from '../../../package.json'; // Passe den Pfad entsprechend deiner Projektstruktur an

const getStatusColor = (status) => {
  switch (status) {
    case 'online':
      return '#6DA15C';
    case 'inactive':
      return '#CAB55F';
    case 'offline':
      return '#8F8F8F';
    default:
      return '#8F8F8F';
  }
};

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState('/path/to/default/picture.jpg');
  const [isAdmin, setIsAdmin] = useState(false);
  const [userStatus, setUserStatus] = useState('offline');

  // NOTIFICATIONS-STATE
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [anchorElNotifications, setAnchorElNotifications] = useState(null);

  const [isShaking, setIsShaking] = useState(false);

  const navigate = useNavigate();

  // Versions- und ReleaseType aus package.json
  const appVersion = packageJson.version;
  const releaseType = packageJson.releaseType;

  // ------------------------------------------
  // Lade User-Profil, Rolle, Avatar und setze Online-Status
  // ------------------------------------------
  useEffect(() => {
    let unsubscribeRTDB = null;

    const fetchUserProfileAndRole = async () => {
      const user = auth.currentUser;

      if (user) {
        try {
          // Admin-Check
          const idTokenResult = await user.getIdTokenResult();
          setIsAdmin(idTokenResult.claims.role === 'admin');
        } catch (error) {
          console.error('Fehler beim ID Token:', error);
        }

        // Avatar laden
        if (user.photoURL) {
          setAvatarUrl(user.photoURL);
        } else {
          try {
            const docRef = doc(db, 'users', user.uid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              const data = docSnap.data();
              if (data.profilePicturePath) {
                const storageReference = storageRef(
                  storage,
                  data.profilePicturePath
                );
                const url = await getDownloadURL(storageReference);
                setAvatarUrl(url);
              }
            }
          } catch (error) {
            console.error('Fehler beim Laden des Profilbildes:', error);
          }
        }

        // Online-Status (Realtime DB)
        const statusReference = dbRef(database, `/users/${user.uid}/status`);
        unsubscribeRTDB = onValue(statusReference, (snapshot) => {
          const val = snapshot.val();
          setUserStatus(val && val.state ? val.state : 'offline');
        });
      }
    };

    fetchUserProfileAndRole();
    return () => {
      if (unsubscribeRTDB) unsubscribeRTDB();
    };
  }, []);

  // ------------------------------------------
  // NOTIFICATIONS -> EIN Realtime-Listener
  // ------------------------------------------
  useEffect(() => {
    const user = auth.currentUser;
    if (!user) return;

    const notificationsRef = collection(db, 'users', user.uid, 'notifications');
    const q = query(notificationsRef, orderBy('createdAt', 'desc'));

    const unsub = onSnapshot(q, (snapshot) => {
      const allNotes = snapshot.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data(),
      }));
      setNotifications(allNotes);
      const unread = allNotes.filter((n) => n.read === false).length;
      setUnreadCount(unread);
    });

    return () => unsub();
  }, []);

  // ------------------------------------------
  // Shake-Animation bei vorhandenen Benachrichtigungen
  // ------------------------------------------
  useEffect(() => {
    if (unreadCount > 0) {
      const interval = setInterval(() => {
        setIsShaking(true);
        setTimeout(() => setIsShaking(false), 500);
      }, 4000);
      return () => clearInterval(interval);
    }
  }, [unreadCount]);

  // ------------------------------------------
  //  NAVIGATIONSLINKS
  // ------------------------------------------
  const pages = [
    { label: 'Startseite', path: '/' },
    { label: 'Lernplattform', path: '/videostage' },
    { label: 'Community', path: '/community' },
    ...(isAdmin ? [{ label: 'Administrator', path: '/admin' }] : []),
  ];

  const userSettings = [
    {
      label: 'Mein Profil',
      icon: <PersonIcon sx={{ color: '#d5bc8b' }} />,
      path: '/profile',
    },
    {
      label: 'Meine Pferde',
      icon: (
        <HorseIcon sx={{ color: '#d5bc8b', width: '24px', height: '24px' }} />
      ),
      path: '/profile?tab=horses',
    },
    {
      label: 'Mein Account',
      icon: <SettingsIcon sx={{ color: '#d5bc8b' }} />,
      path: '/profile?tab=account',
    },
  ];

  const logoutSetting = {
    label: 'Logout',
    icon: <LogoutIcon sx={{ color: '#d5bc8b' }} />,
    path: '/logout',
  };

  // ------------------------------------------
  // Handlers
  // ------------------------------------------
  const handleOpenNavMenu = (event) => setAnchorElNav(event.currentTarget);
  const handleCloseNavMenu = () => setAnchorElNav(null);
  const handleOpenUserMenu = (event) => setAnchorElUser(event.currentTarget);
  const handleCloseUserMenu = () => setAnchorElUser(null);

  const handleMenuClick = async (path) => {
    if (path === '/logout') {
      try {
        await auth.signOut();
        navigate('/');
      } catch (error) {
        console.error('Fehler beim Logout:', error);
      }
    } else {
      navigate(path);
    }
    handleCloseUserMenu();
  };

  const handleOpenNotifications = (event) => {
    setAnchorElNotifications(event.currentTarget);
  };

  const handleCloseNotifications = () => setAnchorElNotifications(null);

  const handleNotificationClick = async (note) => {
    if (note.postId) {
      navigate(`/community/post/${note.postId}`);
    }
    await updateDoc(
      doc(db, 'users', auth.currentUser.uid, 'notifications', note.id),
      { read: true }
    );
    handleCloseNotifications();
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        width: '100%',
        borderBottom: '1px solid #d5bc8b',
        zIndex: (theme) => theme.zIndex.drawer + 2,
        overflowX: 'hidden',
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: 2,
        }}
      >
        {/* Links: Logo & Mobile-Burger */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            size="large"
            aria-label="menu"
            onClick={handleOpenNavMenu}
            color="inherit"
            sx={{ mr: 1, display: { xs: 'block', md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>

          {/* Logo Desktop */}
          <Box
            component="img"
            src="/images/logos/logo-kth.webp"
            alt="Logo"
            sx={{
              display: { xs: 'none', md: 'block' },
              height: 40,
              maxWidth: '100%',
              mr: 2,
            }}
          />

          {/* Logo Mobile */}
          <Box
            component="img"
            src="/images/logos/logo-kth.webp"
            alt="Logo Mobile"
            sx={{
              display: { xs: 'block', md: 'none' },
              height: 30,
              maxWidth: '100%',
            }}
          />

          {/* Versionsanzeige - Nur auf Desktop */}
          <Box sx={{ display: { xs: 'none', md: 'block' }, ml: 2 }}>
            <Typography variant="body2" sx={{ color: '#fff' }}>
              {releaseType === 'beta'
                ? `Beta v${appVersion}`
                : `Version ${appVersion}`}
            </Typography>
          </Box>
        </Box>

        {/* Mobile Menu */}
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{ display: { xs: 'block', md: 'none' } }}
        >
          {pages.map((page) => (
            <MenuItem
              key={page.label}
              onClick={() => {
                handleCloseNavMenu();
                navigate(page.path);
              }}
            >
              <Typography sx={{ textAlign: 'center', fontSize: '0.9rem' }}>
                {page.label}
              </Typography>
            </MenuItem>
          ))}
        </Menu>

        {/* Desktop Navigation */}
        <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
          {pages.map((page) => (
            <Button
              key={page.label}
              onClick={() => navigate(page.path)}
              sx={{
                color: 'white',
                fontSize: '1rem',
                mr: 2,
                textTransform: 'none',
              }}
            >
              {page.label}
            </Button>
          ))}
        </Box>

        {/* Rechts: Notifications, Avatar */}
        <Box sx={{ display: 'flex', alignItems: 'center', flexShrink: 0 }}>
          <IconButton
            size="large"
            color="inherit"
            onClick={handleOpenNotifications}
            sx={{ mr: 2 }}
          >
            {unreadCount > 0 ? (
              <Badge
                badgeContent={unreadCount}
                color="error"
                sx={{
                  '& .MuiBadge-badge': {
                    fontSize: '0.7rem',
                    height: '16px',
                    minWidth: '16px',
                    borderRadius: '8px',
                    padding: '0 4px',
                  },
                }}
              >
                <NotificationsIcon
                  sx={{
                    color: '#d5bc8b',
                    fontSize: '28px',
                    animation: isShaking ? 'shake 0.5s' : 'none',
                  }}
                />
              </Badge>
            ) : (
              <NotificationsIcon
                sx={{ color: '#d5bc8b', fontSize: '28px', animation: 'none' }}
              />
            )}
          </IconButton>

          <Menu
            id="notifications-menu"
            anchorEl={anchorElNotifications}
            open={Boolean(anchorElNotifications)}
            onClose={handleCloseNotifications}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            sx={{ mt: 1 }}
          >
            {notifications.length === 0 ? (
              <MenuItem
                onClick={handleCloseNotifications}
                sx={{ fontSize: '0.875rem' }}
              >
                Keine Benachrichtigungen.
              </MenuItem>
            ) : (
              notifications.map((note) => (
                <MenuItem
                  key={note.id}
                  onClick={() => handleNotificationClick(note)}
                  sx={{
                    fontSize: '0.875rem',
                    backgroundColor: note.read ? 'inherit' : '#3f4b54',
                    color: note.read ? '#ccc' : '#fff',
                  }}
                >
                  {note.message || 'Neue Benachrichtigung'}
                </MenuItem>
              ))
            )}
          </Menu>

          <Tooltip title="Einstellungen öffnen">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant="dot"
                sx={{
                  '& .MuiBadge-badge': {
                    backgroundColor: getStatusColor(userStatus),
                    color: getStatusColor(userStatus),
                  },
                }}
              >
                <Avatar
                  alt="Profilbild"
                  src={avatarUrl}
                  sx={{ width: 32, height: 32 }}
                />
              </Badge>
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px', minWidth: '150px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {userSettings.map((setting) => (
              <MenuItem
                key={setting.label}
                onClick={() => handleMenuClick(setting.path)}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ mr: 2 }}>{setting.icon}</Box>
                  <Typography sx={{ textAlign: 'left', fontSize: '0.9rem' }}>
                    {setting.label}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
            <Divider sx={{ my: 1 }} />
            <MenuItem
              key={logoutSetting.label}
              onClick={() => handleMenuClick(logoutSetting.path)}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ mr: 2 }}>{logoutSetting.icon}</Box>
                <Typography sx={{ textAlign: 'left', fontSize: '0.9rem' }}>
                  {logoutSetting.label}
                </Typography>
              </Box>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>

      <style>
        {`
          @keyframes shake {
            0% { transform: rotate(0deg); }
            15% { transform: rotate(15deg); }
            30% { transform: rotate(-15deg); }
            45% { transform: rotate(15deg); }
            60% { transform: rotate(-15deg); }
            75% { transform: rotate(15deg); }
            100% { transform: rotate(0deg); }
          }
        `}
      </style>
    </AppBar>
  );
}

export default ResponsiveAppBar;
