// src/hooks/usePresence.js

import { useEffect } from 'react';
import { auth, database } from '../config/firebase';
import { ref as dbRef, onDisconnect, set } from 'firebase/database';

function usePresence() {
  useEffect(() => {
    const user = auth.currentUser;
    if (!user) return;

    const userStatusRef = dbRef(database, `/users/${user.uid}/status`);

    const isOfflineForDatabase = {
      state: 'offline',
      last_changed: Date.now(),
    };

    const isOnlineForDatabase = {
      state: 'online',
      last_changed: Date.now(),
    };

    // Setze den Online-Status
    set(userStatusRef, isOnlineForDatabase).catch((err) => {
      console.error('Fehler beim Setzen des Online-Status:', err);
    });

    // Wenn die Verbindung verloren geht, setze den Status auf offline
    onDisconnect(userStatusRef)
      .set(isOfflineForDatabase)
      .catch((err) => {
        console.error('onDisconnect failed:', err);
      });

    // Optional: Aktualisiere den Status regelmäßig, um die Präsenz aufrechtzuerhalten
    const interval = setInterval(() => {
      set(userStatusRef, isOnlineForDatabase).catch((err) => {
        console.error('Fehler beim Aktualisieren des Online-Status:', err);
      });
    }, 30000); // Alle 30 Sekunden

    return () => {
      clearInterval(interval);
      set(userStatusRef, isOfflineForDatabase).catch((err) => {
        console.error('Fehler beim Setzen des Offline-Status:', err);
      });
    };
  }, []);
}

export default usePresence;
