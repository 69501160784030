// src/components/Livemeetings/LivestreamWidget.js

import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress, Avatar } from '@mui/material';
import { getDoc, doc } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import { db, storage } from '../../config/firebase'; // Passe den Pfad ggf. an
import { format } from 'date-fns';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const LivestreamWidget = ({ meetingId }) => {
  const [headerImageUrl, setHeaderImageUrl] = useState('');
  const [trainerImageUrl, setTrainerImageUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [meetingData, setMeetingData] = useState(null);

  useEffect(() => {
    const fetchMeetingData = async () => {
      try {
        const meetingDocRef = doc(db, 'meetings', meetingId);
        const meetingSnapshot = await getDoc(meetingDocRef);

        if (meetingSnapshot.exists()) {
          const data = meetingSnapshot.data();
          setMeetingData(data);

          // Laden des Header-Bildes
          if (data.headerImagePath) {
            const headerImageRef = ref(storage, data.headerImagePath);
            const url = await getDownloadURL(headerImageRef);
            setHeaderImageUrl(url);
          }

          // Laden des Trainer-Thumbnails
          if (data.trainerThumbnailUrl) {
            setTrainerImageUrl(data.trainerThumbnailUrl);
          } else {
            // Fallback-Avatar, falls trainerThumbnailUrl nicht vorhanden ist
            setTrainerImageUrl('/images/default-avatar.png');
          }
        } else {
          console.error('Meeting existiert nicht');
        }
      } catch (error) {
        console.error('Fehler beim Abrufen der Meeting-Daten:', error);
      } finally {
        setLoading(false);
      }
    };

    if (meetingId) {
      fetchMeetingData();
    }
  }, [meetingId]);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: { xs: '150px', sm: '180px', md: '200px' }, // Anpassbare Höhe für verschiedene Bildschirmgrößen
        }}
      >
        <CircularProgress sx={{ color: '#d5bc8b' }} />
      </Box>
    );
  }

  if (!meetingData) {
    return (
      <Typography
        variant="body2"
        sx={{ textAlign: 'center', color: '#252833' }}
      >
        Keine Meeting-Daten gefunden.
      </Typography>
    );
  }

  const formattedDate = meetingData.start_time
    ? format(meetingData.start_time.toDate(), 'dd.MM.yyyy')
    : 'N/A';

  const formattedTime = meetingData.start_time
    ? format(meetingData.start_time.toDate(), 'HH:mm')
    : 'N/A';

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '400px', // Begrenzung der maximalen Breite für mobile Ansicht
        aspectRatio: '16 / 9',
        backgroundImage: `url(${headerImageUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: '12px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
        position: 'relative',
        margin: 'auto',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center', // Zentriert den Inhalt vertikal
      }}
    >
      {/* Datum am linken oberen Rand */}
      <Box
        sx={{
          position: 'absolute',
          top: 8,
          left: 8,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <CalendarTodayIcon
          sx={{ color: '#252833', mr: 0.5, fontSize: '1rem' }}
        />
        <Typography
          variant="body2"
          sx={{
            color: '#252833',
            fontWeight: '500',
            textShadow: '0px 0px 3px rgba(255,255,255,0.7)', // Weißer Text-Schatten für dunklen Text
          }}
        >
          {formattedDate}
        </Typography>
      </Box>

      {/* Uhrzeit am rechten oberen Rand */}
      <Box
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <AccessTimeIcon sx={{ color: '#252833', mr: 0.5, fontSize: '1rem' }} />
        <Typography
          variant="body2"
          sx={{
            color: '#252833',
            fontWeight: '500',
            textShadow: '0px 0px 3px rgba(255,255,255,0.7)', // Weißer Text-Schatten für dunklen Text
          }}
        >
          {formattedTime} Uhr
        </Typography>
      </Box>

      {/* Titel zentriert */}
      <Typography
        variant="h6"
        sx={{
          zIndex: 2,
          color: '#fff',
          fontWeight: 'bold',
          textAlign: 'center',
          px: 2,
          mt: 2,
          textShadow: '0px 0px 5px rgba(0,0,0,0.7)', // Text-Schatten für bessere Lesbarkeit
        }}
      >
        {meetingData.title}
      </Typography>

      {/* Trainer am linken unteren Rand */}
      <Box
        sx={{
          position: 'absolute',
          bottom: 8,
          left: 8,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Avatar
          src={trainerImageUrl || '/images/default-avatar.png'}
          alt={meetingData.trainerName || 'Trainer'}
          sx={{ width: 32, height: 32, mr: 0.5 }}
        />
        <Typography
          variant="body2"
          sx={{
            color: '#252833',
            fontWeight: '500',
            textShadow: '0px 0px 3px rgba(255,255,255,0.7)', // Weißer Text-Schatten für dunklen Text
          }}
        >
          {meetingData.trainerName || 'Trainer nicht zugewiesen'}
        </Typography>
      </Box>
    </Box>
  );
};

export default LivestreamWidget;
