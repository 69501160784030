// src/components/Administration/dashboard_editor/WidgetsManager.js

import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import { styled } from '@mui/system';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  MeasuringStrategy,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import SortableWidget from './SortableWidget';
import { useForm, Controller } from 'react-hook-form';
import { db, storage } from '../../../config/firebase';
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  addDoc,
  updateDoc,
  doc,
  deleteDoc,
  getDocs,
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import PropTypes from 'prop-types';

// Angepasster Container für den WidgetsManager
const WidgetsManagerContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#252833',
  padding: theme.spacing(2), // Reduziertes Padding
  borderRadius: '12px',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
  color: '#d5bc8b',
}));

const WidgetsManager = () => {
  const [widgets, setWidgets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingWidget, setEditingWidget] = useState(null);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      size: '',
      title: '',
      shortText: '',
      longText: '',
    },
  });
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  // Sensoren für dnd-kit (Pointer und Keyboard)
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor)
  );

  // Lade Widgets aus Firestore
  useEffect(() => {
    const widgetsQuery = query(
      collection(db, 'widgets'),
      orderBy('order', 'asc')
    );
    const unsubscribe = onSnapshot(
      widgetsQuery,
      (snapshot) => {
        const fetchedWidgets = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setWidgets(fetchedWidgets);
        setLoading(false);
        console.log('Widgets geladen:', fetchedWidgets);
      },
      (error) => {
        console.error('Fehler beim Abrufen der Widgets:', error);
        setSnackbar({
          open: true,
          message: 'Fehler beim Laden der Widgets.',
          severity: 'error',
        });
        setLoading(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, []);

  // Handler für Drag & Drop
  const handleDragEnd = async (event) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = widgets.findIndex((widget) => widget.id === active.id);
      const newIndex = widgets.findIndex((widget) => widget.id === over.id);

      const newWidgets = arrayMove(widgets, oldIndex, newIndex);
      setWidgets(newWidgets);
      console.log(
        `Widgets verschoben: ${active.id} von ${oldIndex} nach ${newIndex}`
      );

      try {
        // Aktualisiere die Reihenfolge in Firestore
        await Promise.all(
          newWidgets.map((widget, index) =>
            updateDoc(doc(db, 'widgets', widget.id), { order: index })
          )
        );
        setSnackbar({
          open: true,
          message: 'Widgets erfolgreich neu angeordnet!',
          severity: 'success',
        });
        console.log('Widgets erfolgreich neu angeordnet.');
      } catch (error) {
        console.error(
          'Fehler beim Aktualisieren der Widget-Reihenfolge:',
          error
        );
        setSnackbar({
          open: true,
          message: 'Fehler beim Aktualisieren der Reihenfolge.',
          severity: 'error',
        });
      }
    }
  };

  // Handler zum Bearbeiten eines Widgets
  const handleEdit = (widget) => {
    console.log('handleEdit aufgerufen mit Widget:', widget);
    handleOpenDialog(widget);
  };

  // Handler zum Löschen eines Widgets
  const handleDelete = async (id) => {
    console.log('handleDelete aufgerufen mit ID:', id);
    try {
      await deleteDoc(doc(db, 'widgets', id));
      setSnackbar({
        open: true,
        message: 'Widget erfolgreich gelöscht!',
        severity: 'success',
      });
      console.log(`Widget mit ID ${id} erfolgreich gelöscht.`);
    } catch (error) {
      console.error('Fehler beim Löschen des Widgets:', error);
      setSnackbar({
        open: true,
        message: 'Fehler beim Löschen des Widgets.',
        severity: 'error',
      });
    }
  };

  // Öffne den Dialog zum Hinzufügen/Bearbeiten eines Widgets
  const handleOpenDialog = (widget = null) => {
    console.log('handleOpenDialog aufgerufen mit Widget:', widget);
    setEditingWidget(widget);
    if (widget) {
      setValue('size', widget.size);
      setValue('title', widget.title);
      setValue('shortText', widget.shortText);
      setValue('longText', widget.longText || '');
      setImagePreview(widget.imageUrl);
    } else {
      reset();
      setImagePreview(null);
    }
    setOpenDialog(true);
  };

  // Schließe den Dialog
  const handleCloseDialog = () => {
    console.log('handleCloseDialog aufgerufen');
    setOpenDialog(false);
    setEditingWidget(null);
    reset();
    setImagePreview(null);
    setImageFile(null);
  };

  // Handle Formular-Submit
  const onSubmit = async (data) => {
    console.log('Formular abgesendet mit Daten:', data);
    try {
      let imageUrl = imagePreview;

      if (imageFile) {
        console.log('Bild-Upload gestartet:', imageFile.name);
        const imageRef = ref(
          storage,
          `widget-images/${Date.now()}_${imageFile.name}`
        );
        await uploadBytes(imageRef, imageFile);
        imageUrl = await getDownloadURL(imageRef);
        console.log('Bild-Upload abgeschlossen, URL:', imageUrl);
      } else {
        console.log(
          'Kein Bild-Upload erforderlich, verwende vorhandene imageUrl:',
          imageUrl
        );
      }

      if (editingWidget) {
        console.log('Widget wird aktualisiert:', editingWidget.id);
        await updateDoc(doc(db, 'widgets', editingWidget.id), {
          size: data.size,
          title: data.title,
          shortText: data.shortText,
          longText: data.size === 'lang' ? data.longText : '',
          imageUrl,
        });
        setSnackbar({
          open: true,
          message: 'Widget erfolgreich aktualisiert!',
          severity: 'success',
        });
        console.log('Widget erfolgreich aktualisiert.');
      } else {
        console.log('Neues Widget wird hinzugefügt.');
        const widgetsSnapshot = await getDocs(collection(db, 'widgets'));
        const order = widgetsSnapshot.size;
        await addDoc(collection(db, 'widgets'), {
          size: data.size,
          title: data.title,
          shortText: data.shortText,
          longText: data.size === 'lang' ? data.longText : '',
          imageUrl,
          order,
        });
        setSnackbar({
          open: true,
          message: 'Widget erfolgreich hinzugefügt!',
          severity: 'success',
        });
        console.log('Neues Widget erfolgreich hinzugefügt.');
      }

      handleCloseDialog();
    } catch (error) {
      console.error('Fehler beim Speichern des Widgets:', error);
      setSnackbar({
        open: true,
        message: 'Fehler beim Speichern des Widgets.',
        severity: 'error',
      });
    }
  };

  // Handle Bild-Upload
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    console.log('handleImageChange aufgerufen mit Datei:', file);
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log('Bildvorschau erstellt.');
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Beobachte den Wert des 'size'-Feldes für bedingte Validierung
  const sizeValue = watch('size');

  return (
    <WidgetsManagerContainer>
      <Typography variant="h4" gutterBottom>
        Widgets verwalten
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpenDialog()}
        sx={{
          mb: 2, // Reduzierter Margin-Bottom
          backgroundColor: '#3f51b5',
          '&:hover': {
            backgroundColor: '#303f9f',
          },
          padding: '4px 8px', // Weiter reduziertes Padding
          fontSize: '0.75rem', // Kleinere Schriftgröße
        }}
      >
        Neues Widget hinzufügen
      </Button>

      {loading ? (
        <Box display="flex" justifyContent="center" mt={2}>
          <CircularProgress />
        </Box>
      ) : (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          measuring={{ droppable: { strategy: MeasuringStrategy.Always } }}
        >
          <SortableContext
            items={widgets.map((widget) => widget.id)}
            strategy={verticalListSortingStrategy}
          >
            <Grid container spacing={2}>
              {' '}
              {/* Weiter reduziertes Grid-Spacing */}
              {widgets.map((widget) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={widget.size === 'lang' ? 12 : 6}
                  key={widget.id}
                >
                  <SortableWidget
                    widget={widget}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                  />
                </Grid>
              ))}
            </Grid>
          </SortableContext>
        </DndContext>
      )}

      {/* Dialog für Widget-Erstellung/Bearbeitung */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            backgroundColor: '#252833', // Dunkler Hintergrund
            color: '#d5bc8b', // Helle Textfarbe
            borderRadius: '12px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: '#3f4b54',
            color: '#d5bc8b',
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
            fontSize: '1.25rem', // Kleinere Schriftgröße
          }}
        >
          {editingWidget ? 'Widget bearbeiten' : 'Neues Widget hinzufügen'}
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent
            sx={{
              backgroundColor: '#252833',
              color: '#d5bc8b',
            }}
          >
            <Box display="flex" flexDirection="column" gap={2}>
              {' '}
              {/* Reduziertes Gap */}
              <FormControl fullWidth required>
                <InputLabel id="size-label" sx={{ color: '#d5bc8b' }}>
                  Größe
                </InputLabel>
                <Controller
                  name="size"
                  control={control}
                  rules={{ required: 'Größe ist erforderlich' }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label="Größe"
                      sx={{
                        color: '#d5bc8b',
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#d5bc8b',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#ffffff',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#ffffff',
                        },
                        '& .MuiSvgIcon-root': {
                          color: '#d5bc8b',
                        },
                      }}
                      error={!!errors.size}
                      onChange={(e) => {
                        field.onChange(e);
                        if (e.target.value !== 'lang') {
                          setValue('longText', '');
                        }
                      }}
                    >
                      <MenuItem value="lang">Lang</MenuItem>
                      <MenuItem value="kurz">Kurz</MenuItem>
                    </Select>
                  )}
                />
                {errors.size && (
                  <Typography color="error" variant="caption">
                    {errors.size.message}
                  </Typography>
                )}
              </FormControl>
              <TextField
                label="Titel"
                fullWidth
                required
                {...register('title', { required: 'Titel ist erforderlich' })}
                error={!!errors.title}
                helperText={errors.title?.message}
                InputLabelProps={{
                  style: { color: '#d5bc8b', fontSize: '0.75rem' },
                }}
                InputProps={{
                  style: {
                    color: '#ffffff',
                    borderColor: '#ffffff',
                    fontSize: '0.75rem',
                  },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#d5bc8b',
                    },
                    '&:hover fieldset': {
                      borderColor: '#ffffff',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#ffffff',
                    },
                  },
                }}
              />
              <TextField
                label="Kurztext"
                fullWidth
                required
                multiline
                rows={2}
                {...register('shortText', {
                  required: 'Kurztext ist erforderlich',
                })}
                error={!!errors.shortText}
                helperText={errors.shortText?.message}
                InputLabelProps={{
                  style: { color: '#d5bc8b', fontSize: '0.75rem' },
                }}
                InputProps={{
                  style: {
                    color: '#ffffff',
                    borderColor: '#ffffff',
                    fontSize: '0.75rem',
                  },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#d5bc8b',
                    },
                    '&:hover fieldset': {
                      borderColor: '#ffffff',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#ffffff',
                    },
                  },
                }}
              />
              {sizeValue === 'lang' && (
                <TextField
                  label="Langtext"
                  fullWidth
                  required
                  multiline
                  rows={4}
                  {...register('longText', {
                    required:
                      sizeValue === 'lang'
                        ? 'Langtext ist erforderlich für lange Widgets'
                        : false,
                  })}
                  error={!!errors.longText}
                  helperText={errors.longText?.message}
                  InputLabelProps={{
                    style: { color: '#d5bc8b', fontSize: '0.75rem' },
                  }}
                  InputProps={{
                    style: {
                      color: '#ffffff',
                      borderColor: '#ffffff',
                      fontSize: '0.75rem',
                    },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#d5bc8b',
                      },
                      '&:hover fieldset': {
                        borderColor: '#ffffff',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#ffffff',
                      },
                    },
                  }}
                />
              )}
              <Button
                variant="contained"
                component="label"
                sx={{
                  backgroundColor: '#3f51b5',
                  '&:hover': {
                    backgroundColor: '#303f9f',
                  },
                  padding: '4px 8px', // Weiter reduziertes Padding
                  fontSize: '0.75rem', // Kleinere Schriftgröße
                }}
              >
                Bild hochladen
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </Button>
              {imagePreview && (
                <Box mt={1}>
                  <img
                    src={imagePreview}
                    alt="Vorschau"
                    style={{
                      width: '100%',
                      height: '150px', // Verkleinerte Höhe
                      objectFit: 'cover',
                      borderRadius: '6px', // Kleinere Border-Radius
                    }}
                  />
                </Box>
              )}
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              backgroundColor: '#3f4b54',
              borderBottomLeftRadius: '12px',
              borderBottomRightRadius: '12px',
            }}
          >
            <Button
              onClick={handleCloseDialog}
              sx={{ color: '#d5bc8b', fontSize: '0.75rem' }} // Kleinere Schriftgröße
            >
              Abbrechen
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              sx={{
                backgroundColor: '#3f51b5',
                '&:hover': {
                  backgroundColor: '#303f9f',
                },
                padding: '4px 8px', // Weiter reduziertes Padding
                fontSize: '0.75rem', // Kleinere Schriftgröße
              }}
            >
              Speichern
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Snackbar für Benachrichtigungen */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </WidgetsManagerContainer>
  );
};

WidgetsManager.propTypes = {
  // Falls WidgetsManager weitere Props erwartet
};

export default WidgetsManager;
