// src/components/Administration/VideoManagement/CreateTrainerDialog.jsx

import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Button,
  IconButton,
  LinearProgress,
  Box,
  Avatar,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CreateTrainerDialog = ({
  open,
  onClose,
  onCreate,
  newTrainerData,
  setNewTrainerData,
  onInputChange,
  onAvatarChange,
  uploadProgress,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Neuen Trainer erstellen
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            label="Vorname"
            name="vorname"
            value={newTrainerData.vorname}
            onChange={onInputChange}
            fullWidth
            required
          />
          <TextField
            label="Nachname"
            name="nachname"
            value={newTrainerData.nachname}
            onChange={onInputChange}
            fullWidth
            required
          />
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Avatar
              src={newTrainerData.avatarUrl}
              alt={`${newTrainerData.vorname} ${newTrainerData.nachname}`}
              sx={{ width: 56, height: 56 }}
            />
            <Button variant="outlined" component="label">
              Avatar hochladen
              <input
                type="file"
                accept="image/*"
                hidden
                onChange={onAvatarChange}
              />
            </Button>
          </Box>
          {uploadProgress > 0 && uploadProgress < 100 && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress variant="determinate" value={uploadProgress} />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Typography variant="body2">{`${uploadProgress}%`}</Typography>
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Abbrechen
        </Button>
        <Button onClick={onCreate} color="primary" variant="contained">
          Erstellen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateTrainerDialog;
