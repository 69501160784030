import React, { useState, useMemo } from 'react';
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  IconButton,
  Collapse,
  Chip,
  useMediaQuery,
  LinearProgress, // <-- NEU: um Progress anzuzeigen
} from '@mui/material';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { styled, useTheme } from '@mui/material/styles';

import { AnimatePresence, motion } from 'framer-motion';

const PLACEHOLDER_THEMENBEREICH = 'https://via.placeholder.com/400x300';
const PLACEHOLDER_KAPITEL = 'https://via.placeholder.com/400x300';
const PLACEHOLDER_THUMBNAIL = 'https://via.placeholder.com/400x300';

// Videolänge formatieren
const formatDuration = (sec) => {
  if (!sec || isNaN(sec)) return '0m';
  const h = Math.floor(sec / 3600);
  const m = Math.floor((sec % 3600) / 60);
  const s = Math.floor(sec % 60);
  return (
    (h > 0 ? `${h}h ` : '') +
    (m > 0 ? `${m}m ` : '') +
    (s > 0 ? `${s}s` : '')
  ).trim();
};

const StyledCard = styled(Card)(() => ({
  borderRadius: '8px',
  overflow: 'hidden',
  backgroundColor: '#252833',
  color: '#d5bc8b',
  boxShadow: '0 4px 12px rgba(0,0,0,0.3)',
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 6px 16px rgba(0,0,0,0.4)',
  },
}));

const VideoRow = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'stretch',
  borderRadius: '8px',
  overflow: 'hidden',
  backgroundColor: '#2f3136',
  marginBottom: '12px',
  cursor: 'pointer',
  transition: 'box-shadow 0.2s, transform 0.2s',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 12px rgba(0,0,0,0.3)',
  },
}));

/**
 * @param {Object} props
 * @param {Array} props.themenbereiche
 * @param {string|null} props.selectedThemenbereichId
 * @param {Function} props.setSelectedThemenbereichId
 * @param {Array} props.kapitelList
 * @param {Object} props.videosByKapitel - z.B. { [kapitelId]: { videos: [{id, ...}, ... ] }, ... }
 * @param {Array} props.bookmarkedVideos
 * @param {Function} props.handleBookmarkToggle
 * @param {Function} props.handleVideoClick
 *
 * // NEU:
 * @param {Object} props.videoProgress - z.B. { "videoId": 23, ... }
 */
export default function TabAllVideos({
  themenbereiche,
  selectedThemenbereichId,
  setSelectedThemenbereichId,
  kapitelList,
  videosByKapitel,
  bookmarkedVideos,
  handleBookmarkToggle,
  handleVideoClick,
  // NEU: Fortschritts-Objekt
  videoProgress = {},
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [expandedKapitel, setExpandedKapitel] = useState(null);

  const selectedTB = themenbereiche.find(
    (tb) => tb.id === selectedThemenbereichId
  );

  const kapitelForSelectedTB = useMemo(() => {
    if (!selectedTB) return [];
    return kapitelList.filter((k) => selectedTB.kapitelIDs.includes(k.id));
  }, [selectedTB, kapitelList]);

  const handleKapitelToggle = (kapitelId) => {
    setExpandedKapitel((prev) => (prev === kapitelId ? null : kapitelId));
  };

  // Framer-Motion Variants
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.35,
        when: 'beforeChildren',
        staggerChildren: 0.07,
      },
    },
    exit: {
      opacity: 0,
      y: -10,
      transition: { duration: 0.2 },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.25 },
    },
  };

  // ---------------------------------------
  // 1) Kein Themenbereich gewählt
  // ---------------------------------------
  if (!selectedThemenbereichId) {
    return (
      <AnimatePresence mode="wait">
        <motion.div
          key="themenbereiche-liste"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <Grid container spacing={3}>
            {themenbereiche.map((tb) => (
              <Grid item xs={12} sm={6} md={4} key={tb.id}>
                <motion.div variants={itemVariants}>
                  <StyledCard sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box
                      sx={{ cursor: 'pointer' }}
                      onClick={() => setSelectedThemenbereichId(tb.id)}
                    >
                      <Box
                        sx={{
                          width: '100%',
                          height: 180,
                          backgroundImage: `url(${
                            tb.imageUrl || PLACEHOLDER_THEMENBEREICH
                          })`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                        }}
                      />
                      <CardContent>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            fontWeight: 'bold',
                            color: '#d5bc8b',
                            fontSize: { xs: '1rem', sm: '1.2rem' },
                          }}
                        >
                          {tb.name || 'Unbenannter Themenbereich'}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: '#c0c0c0',
                            fontSize: { xs: '0.85rem', sm: '0.95rem' },
                          }}
                        >
                          {tb.kapitelIDs?.length || 0} Kapitel
                        </Typography>
                      </CardContent>
                    </Box>
                  </StyledCard>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </motion.div>
      </AnimatePresence>
    );
  }

  // ---------------------------------------
  // 2) EIN Themenbereich gewählt
  // ---------------------------------------
  return (
    <AnimatePresence mode="wait">
      <motion.div
        key="kapitel-liste"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <IconButton
          onClick={() => setSelectedThemenbereichId(null)}
          sx={{
            backgroundColor: '#d5bc8b',
            color: '#3f4b54',
            '&:hover': {
              backgroundColor: '#c0a57b',
            },
            mb: 3,
          }}
        >
          <ArrowBackIosNewIcon />
        </IconButton>

        {/* Header-Bild + Titel */}
        <Box
          sx={{
            width: '100%',
            height: 250,
            backgroundImage: `url(${
              selectedTB?.imageUrl || PLACEHOLDER_THEMENBEREICH
            })`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: '8px',
            overflow: 'hidden',
            position: 'relative',
            marginBottom: 3,
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              p: 2,
              background:
                'linear-gradient(to top, rgba(0,0,0,0.6), rgba(0,0,0,0))',
              color: '#d5bc8b',
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: 'bold',
                fontSize: { xs: '1.5rem', sm: '2rem' },
              }}
            >
              {selectedTB?.name || 'Themenbereich'}
            </Typography>
          </Box>
        </Box>

        {/* Kapitel-Liste */}
        {kapitelForSelectedTB.map((kap) => {
          const videos = videosByKapitel[kap.id]?.videos || [];
          const isExpanded = expandedKapitel === kap.id;

          return (
            <motion.div key={kap.id} variants={itemVariants}>
              <StyledCard
                sx={{
                  mb: 3,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {/* Kapitel-Header */}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'flex-start', sm: 'stretch' },
                    cursor: 'pointer',
                  }}
                  onClick={() => handleKapitelToggle(kap.id)}
                >
                  <Box
                    sx={{
                      width: { xs: '100%', sm: '200px' },
                      height: { xs: '150px', sm: '120px' },
                      backgroundImage: `url(${
                        kap.imageUrl || PLACEHOLDER_KAPITEL
                      })`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                  />
                  <Box
                    sx={{
                      flexGrow: 1,
                      p: 2,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Box sx={{ mr: 2 }}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: 600,
                          color: '#d5bc8b',
                          fontSize: { xs: '1rem', sm: '1.1rem' },
                        }}
                      >
                        {kap.name || 'Unbenanntes Kapitel'}
                      </Typography>
                      <Chip
                        label={`${videos.length} Videos`}
                        size="small"
                        sx={{
                          mt: 0.5,
                          backgroundColor: '#2f3136',
                          color: '#d5bc8b',
                          fontWeight: 'bold',
                        }}
                      />
                    </Box>
                    <motion.div
                      animate={{ rotate: isExpanded ? 180 : 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <ExpandMoreIcon sx={{ color: '#d5bc8b' }} />
                    </motion.div>
                  </Box>
                </Box>

                {/* Collapse: Videos-Liste */}
                <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                  <Box sx={{ p: 2 }}>
                    {videos.length > 0 ? (
                      videos.map((video) => {
                        // NEU: Fortschritt (0-100)
                        const progressValue = videoProgress[video.id] || 0;

                        return (
                          <VideoRow
                            key={video.id}
                            onClick={() => handleVideoClick(video.id)}
                          >
                            {/* Thumbnail */}
                            <Box
                              sx={{
                                width: { xs: '100px', sm: '160px' },
                                height: '90px',
                                backgroundImage: `url(${
                                  video.thumbnailUrl || PLACEHOLDER_THUMBNAIL
                                })`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                flexShrink: 0,
                              }}
                            />

                            {/* Inhalt (Titel, Bookmark, Dauer, Fortschritt) */}
                            <Box
                              sx={{
                                flexGrow: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                p: 2,
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'flex-start',
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  sx={{
                                    fontWeight: 'bold',
                                    color: '#d5bc8b',
                                    fontSize: {
                                      xs: '0.9rem',
                                      sm: '1rem',
                                    },
                                    mr: 2,
                                  }}
                                >
                                  {video.video_titel || 'Unbenanntes Video'}
                                </Typography>

                                <IconButton
                                  sx={{
                                    backgroundColor: 'rgba(0,0,0,0.2)',
                                    '&:hover': {
                                      backgroundColor: 'rgba(0,0,0,0.4)',
                                    },
                                  }}
                                  onClick={(ev) => {
                                    ev.stopPropagation();
                                    handleBookmarkToggle(video);
                                  }}
                                >
                                  {bookmarkedVideos.includes(video.id) ? (
                                    <BookmarkIcon sx={{ color: '#d5bc8b' }} />
                                  ) : (
                                    <BookmarkBorderIcon
                                      sx={{ color: '#d5bc8b' }}
                                    />
                                  )}
                                </IconButton>
                              </Box>

                              <Typography
                                variant="body2"
                                sx={{
                                  color: '#c0c0c0',
                                  fontSize: { xs: '0.8rem', sm: '0.9rem' },
                                  mt: 1,
                                }}
                              >
                                Dauer: {formatDuration(video.duration)}
                              </Typography>

                              {/* NEU: Fortschrittsbalken anzeigen (wenn > 0) */}
                              {progressValue > 0 && (
                                <Box sx={{ mt: 1, pr: 2 }}>
                                  <LinearProgress
                                    variant="determinate"
                                    value={progressValue}
                                    sx={{
                                      backgroundColor: '#3f4b54',
                                      '& .MuiLinearProgress-bar': {
                                        backgroundColor: '#d5bc8b',
                                      },
                                    }}
                                  />
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color: '#d5bc8b',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    Fortschritt: {progressValue}%
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </VideoRow>
                        );
                      })
                    ) : (
                      <Typography
                        variant="body2"
                        sx={{ color: '#c0c0c0', mt: 1 }}
                      >
                        Keine Videos in diesem Kapitel.
                      </Typography>
                    )}
                  </Box>
                </Collapse>
              </StyledCard>
            </motion.div>
          );
        })}
      </motion.div>
    </AnimatePresence>
  );
}
