// src/components/pages/Community/UserDashboard_components/NewsFeed.js

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { db, database } from '../../../../config/firebase';
import Post from './post';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Box,
  Chip,
  Stack,
  Typography,
  Snackbar,
  Alert,
  Skeleton,
  Button,
} from '@mui/material';
import {
  collection,
  query,
  orderBy,
  limit,
  startAfter,
  getDocs,
  where,
  doc,
  updateDoc,
} from 'firebase/firestore';
import CreatePost from './CreatePost';
import { styled } from '@mui/material/styles';
import { ref as dbRef, onValue, off } from 'firebase/database';

const NewsFeedBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(0),
  boxSizing: 'border-box',
}));

// Skeleton placeholder
const PostSkeleton = () => (
  <Box sx={{ mb: 2 }}>
    <Skeleton
      variant="rectangular"
      height={150}
      sx={{ mb: 1, borderRadius: '12px' }}
    />
    <Skeleton variant="text" height={18} width="80%" />
    <Skeleton variant="text" height={14} width="60%" />
  </Box>
);

const NewsFeed = () => {
  const [posts, setPosts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [mainTags, setMainTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [userStatuses, setUserStatuses] = useState({});

  // Pagination
  const lastDocRef = useRef(null);

  // --------------------------------------------------
  // 1) Haupt-Tags abrufen
  // --------------------------------------------------
  const fetchMainTags = useCallback(async () => {
    try {
      const snap = await getDocs(collection(db, 'mainTags'));
      const tags = snap.docs.map((doc) => ({
        label: doc.data().name,
        id: doc.id,
      }));
      setMainTags(tags);
    } catch (error) {
      console.error('Fehler beim Laden der Tags:', error);
      setSnackbar({
        open: true,
        message: 'Fehler beim Laden der Tags',
        severity: 'error',
      });
    }
  }, []);

  // --------------------------------------------------
  // 2) Online-Status (Realtime DB)
  // --------------------------------------------------
  const fetchUserStatus = useCallback((userId) => {
    const statusRef = dbRef(database, `/users/${userId}/status/state`);
    const listener = (snapshot) => {
      const status = snapshot.val() || 'offline';
      setUserStatuses((prev) => ({ ...prev, [userId]: status }));
    };
    onValue(statusRef, listener);
    return () => off(statusRef, listener);
  }, []);

  // --------------------------------------------------
  // 3) Posts laden (sortiert nach lastCommentTimestamp)
  // --------------------------------------------------
  const fetchPosts = useCallback(
    async (reset = false) => {
      if (reset) {
        setIsLoading(true);
        setHasMore(true);
        setPosts([]);
        lastDocRef.current = null;
      } else {
        setIsLoadingMore(true);
      }

      try {
        let q = query(
          collection(db, 'posts'),
          orderBy('lastCommentTimestamp', 'desc'),
          limit(20)
        );

        if (selectedTag) {
          q = query(
            collection(db, 'posts'),
            where('mainTags', 'array-contains-any', [selectedTag.label]),
            orderBy('lastCommentTimestamp', 'desc'),
            limit(20)
          );
        }

        if (!reset && lastDocRef.current) {
          q = query(q, startAfter(lastDocRef.current), limit(20));
        }

        const snap = await getDocs(q);
        const newPosts = snap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Wichtig: Wenn keine neuen Posts => hasMore = false
        if (newPosts.length < 20) {
          setHasMore(false);
        }

        setPosts((prev) => (reset ? newPosts : [...prev, ...newPosts]));

        // Letztes Dokument merken
        if (snap.docs.length > 0) {
          lastDocRef.current = snap.docs[snap.docs.length - 1];
        }

        // Online-Status
        newPosts.forEach((post) => {
          if (post.authorId && !userStatuses[post.authorId]) {
            fetchUserStatus(post.authorId);
          }
        });
      } catch (error) {
        console.error('Fehler beim Laden der Posts:', error);
        setSnackbar({
          open: true,
          message: 'Fehler beim Laden der Beiträge.',
          severity: 'error',
        });
      } finally {
        setIsLoading(false);
        setIsLoadingMore(false);
      }
    },
    [selectedTag, fetchUserStatus, userStatuses]
  );

  // --------------------------------------------------
  // 4) Erste Ladung
  // --------------------------------------------------
  useEffect(() => {
    const initialize = async () => {
      await fetchMainTags();
      await fetchPosts(true);
    };
    initialize();
  }, [fetchMainTags, fetchPosts]);

  // --------------------------------------------------
  // 5) Tag-Wechsel
  // --------------------------------------------------
  const handleTagClick = (tag) => {
    const sameTag = selectedTag && selectedTag.id === tag.id;
    setSelectedTag(sameTag ? null : tag);
    fetchPosts(true);
  };

  // --------------------------------------------------
  // 6) Post entfernen (z. B. bei Löschen)
  // --------------------------------------------------
  const removePost = useCallback((postId) => {
    setPosts((prev) => prev.filter((p) => p.id !== postId));
  }, []);

  // --------------------------------------------------
  // 7) Callback: Neuer Post erstellt
  //    -> KEIN full reload, sondern local push
  // --------------------------------------------------
  const handlePostCreated = useCallback(
    (newPostData) => {
      if (!newPostData) {
        // Falls du statt local push noch mal einen Reset machen willst:
        fetchPosts(true);
        return;
      }
      // Lokale Einfügung:
      // newPostData könnte die Felder enthalten, z.B. { id, title, lastCommentTimestamp, ... }
      setPosts((prev) => [newPostData, ...prev]);
    },
    [fetchPosts]
  );

  // --------------------------------------------------
  // 8) Snackbar schließen
  // --------------------------------------------------
  const handleSnackbarClose = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <Box
      sx={{
        width: '100%',
        mt: 2,
        // Mindesthöhe, damit InfiniteScroll bei wenig Posts nicht direkt triggert
        minHeight: '80vh',
      }}
    >
      <NewsFeedBox>
        {/* Tag-Filters */}
        <Box
          sx={{
            mb: 2,
            paddingTop: 2,
            paddingBottom: 2,
            borderRadius: '8px',
            backgroundColor: '#3f4b54',
          }}
        >
          <Stack direction="row" spacing={1} flexWrap="wrap">
            {mainTags.map((tag) => (
              <Chip
                key={tag.id}
                label={tag.label}
                clickable
                onClick={() => handleTagClick(tag)}
                sx={{
                  mb: 1,
                  backgroundColor:
                    selectedTag && selectedTag.id === tag.id
                      ? '#d5bc8b'
                      : '#252833',
                  color:
                    selectedTag && selectedTag.id === tag.id ? '#000' : '#fff',
                  fontSize: { xs: '0.75rem', sm: '0.875rem' },
                  '&:hover': {
                    backgroundColor:
                      selectedTag && selectedTag.id === tag.id
                        ? '#d5bc8b'
                        : '#3f4b54',
                  },
                }}
              />
            ))}
            {selectedTag && (
              <Chip
                label="Filter zurücksetzen"
                onClick={() => {
                  setSelectedTag(null);
                  fetchPosts(true);
                }}
                variant="outlined"
                sx={{
                  mb: 1,
                  borderColor: '#d5bc8b',
                  color: '#d5bc8b',
                  fontSize: { xs: '0.75rem', sm: '0.875rem' },
                  '&:hover': {
                    borderColor: '#c4a36f',
                    color: '#c4a36f',
                  },
                }}
              />
            )}
          </Stack>
        </Box>

        {/* Neue Beiträge erstellen */}
        <CreatePost
          onPostCreated={(maybeNewPost) => {
            // Option A: Full reload
            // fetchPosts(true);

            // Option B: Lokal einfügen
            handlePostCreated(maybeNewPost);
          }}
        />

        <InfiniteScroll
          dataLength={posts.length}
          next={() => fetchPosts(false)}
          hasMore={hasMore}
          loader={
            isLoadingMore ? (
              <>
                <PostSkeleton />
                <PostSkeleton />
              </>
            ) : null
          }
          endMessage={
            <Typography
              variant="body2"
              align="center"
              sx={{ mt: 2, color: '#fff' }}
            >
              <b>Keine weiteren Beiträge</b>
            </Typography>
          }
          scrollableTarget="dashboardContainer"
          style={{ overflow: 'hidden' }}
        >
          {/* Falls noch am Laden und nichts vorhanden */}
          {isLoading && posts.length === 0 ? (
            <>
              <PostSkeleton />
              <PostSkeleton />
            </>
          ) : (
            posts.map((post) => (
              <Post
                key={post.id}
                post={post}
                onDelete={removePost}
                onlineStatus={userStatuses[post.authorId] || 'offline'}
              />
            ))
          )}
        </InfiniteScroll>

        {/* Snackbar */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbar.severity}
            sx={{
              width: '100%',
              backgroundColor: '#252833',
              color: '#fff',
            }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </NewsFeedBox>
    </Box>
  );
};

export default NewsFeed;
