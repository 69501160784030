// src/utils/cropImage.js

export const getCroppedImg = (imageSrc, pixelCrop) => {
  const image = new Image();
  image.src = imageSrc;
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  return new Promise((resolve, reject) => {
    image.onload = () => {
      canvas.width = pixelCrop.width;
      canvas.height = pixelCrop.height;
      ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
      );

      canvas.toBlob((blob) => {
        if (!blob) {
          // Handle error
          console.error('Canvas is empty');
          return;
        }
        blob.name = 'cropped_avatar.jpeg';
        const croppedImageUrl = URL.createObjectURL(blob);
        resolve({ blob, croppedImageUrl });
      }, 'image/jpeg');
    };

    image.onerror = () => {
      reject(new Error('Failed to load image'));
    };
  });
};
