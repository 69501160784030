// src/components/pages/Community/User_map/MapComponent.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
  LinearProgress,
  Avatar,
  Paper,
  GlobalStyles,
} from '@mui/material';
import { styled } from '@mui/system';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import axios from 'axios';
import PQueue from 'p-queue';
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  updateDoc,
  setDoc,
} from 'firebase/firestore';
import { useAuth } from '../../../context/AuthContext';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';

// Fix für die fehlenden Marker-Symbole in Leaflet
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    'https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-shadow.png',
});

// Styling für das Popup mit MUI-Komponenten ohne Padding
const StyledPopup = styled(Paper)(({ theme }) => ({
  backgroundColor: '#252833',
  color: '#d5bc8b',
  borderRadius: theme.shape.borderRadius,
  transition: 'transform 0.3s ease, opacity 0.3s ease',
  animation: 'fadeIn 0.3s ease-out',
  '&:hover': {
    transform: 'scale(1.02)',
    opacity: 0.95,
  },
  boxShadow: 'none',
}));

const UserMap = () => {
  const [users, setUsers] = useState([]);
  const [pendingGeocodes, setPendingGeocodes] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentUser } = useAuth();

  // Rate Limiter: 1 Anfrage pro Sekunde
  const queue = new PQueue({ interval: 1000, intervalCap: 1 });

  useEffect(() => {
    const fetchUsers = async () => {
      const db = getFirestore();
      const usersCollection = collection(db, 'users');

      try {
        const usersSnapshot = await getDocs(usersCollection);
        const usersList = usersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const usersNeedingGeocode = usersList.filter(
          (user) =>
            (!user.latitude || !user.longitude) &&
            user.city &&
            typeof user.city === 'string'
        );

        setPendingGeocodes(usersNeedingGeocode.length);

        const cityCache = {};

        // Lade bestehende geokodierte Städte aus Firestore
        const citiesCollection = collection(db, 'cities');
        const citiesSnapshot = await getDocs(citiesCollection);
        citiesSnapshot.docs.forEach((doc) => {
          const cityName = decodeURIComponent(doc.id).toLowerCase();
          const data = doc.data();
          if (data.latitude && data.longitude) {
            cityCache[cityName] = {
              latitude: data.latitude,
              longitude: data.longitude,
            };
          }
        });

        const geocodeCity = async (user) => {
          const city = user.city.trim().toLowerCase();
          if (cityCache[city]) {
            return cityCache[city];
          }

          const apiKey = process.env.REACT_APP_OPENCAGE_API_KEY;
          if (!apiKey) {
            throw new Error(
              'OpenCage API key ist nicht in der .env Datei definiert'
            );
          }

          try {
            const response = await axios.get(
              'https://api.opencagedata.com/geocode/v1/json',
              {
                params: {
                  q: user.city,
                  key: apiKey,
                  limit: 1,
                },
              }
            );

            if (response.data.results.length === 0) {
              console.warn(
                `Keine Koordinaten für Stadt: ${user.city} gefunden.`
              );
              return null;
            }

            const { lat, lng } = response.data.results[0].geometry;
            const coordinates = { latitude: lat, longitude: lng };

            cityCache[city] = coordinates;

            await setDoc(
              doc(db, 'cities', encodeURIComponent(user.city)),
              coordinates
            );

            return coordinates;
          } catch (geocodeError) {
            console.error(
              `Fehler beim Geokodieren der Stadt: ${user.city}`,
              geocodeError
            );
            return null;
          }
        };

        const updateUsersWithGeocode = async () => {
          const updatePromises = usersNeedingGeocode.map((user) =>
            queue.add(async () => {
              const coordinates = await geocodeCity(user);
              if (coordinates) {
                const userDocRef = doc(db, 'users', user.id);
                await updateDoc(userDocRef, {
                  latitude: coordinates.latitude,
                  longitude: coordinates.longitude,
                });

                setUsers((prevUsers) =>
                  prevUsers.map((u) =>
                    u.id === user.id
                      ? {
                          ...u,
                          latitude: coordinates.latitude,
                          longitude: coordinates.longitude,
                        }
                      : u
                  )
                );
              }
              setPendingGeocodes((prev) => prev - 1);
            })
          );
          await Promise.all(updatePromises);
          await queue.onIdle();
        };

        const usersWithGeocode = usersList.filter(
          (user) => user.latitude && user.longitude
        );
        setUsers(usersWithGeocode);

        await updateUsersWithGeocode();
      } catch (err) {
        console.error('Fehler beim Abrufen der Nutzerdaten: ', err);
        setError(
          'Fehler beim Abrufen der Nutzerdaten. Bitte versuche es später erneut.'
        );
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  // Ladeindikator für ausstehende Geocodes
  const renderGeocodeProgress = () => {
    if (pendingGeocodes > 0) {
      return (
        <Box sx={{ width: '100%' }}>
          <Typography
            variant="body1"
            align="center"
            gutterBottom
            color="#d5bc8b"
          >
            Geokodierung läuft: {pendingGeocodes} ausstehend
          </Typography>
          <LinearProgress
            variant="determinate"
            value={
              pendingGeocodes + users.length > 0
                ? (1 - pendingGeocodes / (pendingGeocodes + users.length)) * 100
                : 0
            }
            sx={{
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#d5bc8b',
              },
            }}
          />
        </Box>
      );
    }
    return null;
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '80vh',
          flexDirection: 'column',
          backgroundColor: '#252833',
        }}
      >
        <CircularProgress />
        <Typography variant="h6" sx={{ color: '#d5bc8b' }}>
          Lade Nutzerdaten und Geokodierung...
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ backgroundColor: '#252833', minHeight: '100vh' }}>
      {/* Globale CSS-Stile innerhalb der Komponente */}
      <GlobalStyles
        styles={{
          /* Fade-in Animation */
          '@keyframes fadeIn': {
            from: {
              opacity: 0,
              transform: 'translateY(-10px)',
            },
            to: {
              opacity: 1,
              transform: 'translateY(0)',
            },
          },
          /* Anpassung der Popup-Hintergründe */
          '.leaflet-popup-content-wrapper': {
            backgroundColor: '#252833 !important',
            color: '#d5bc8b !important',
            borderRadius: '10px !important',
            boxShadow: 'none !important', // Schatten entfernen
            animation: 'fadeIn 0.3s ease-out',
          },
          '.leaflet-popup-tip': {
            backgroundColor: '#252833 !important',
          },
          /* Anpassung der Marker-Clustering-Circle */
          '.marker-cluster div': {
            backgroundColor: '#d5bc8b !important',
            border: 'none !important', // Rahmen entfernen
            color: '#252833 !important',
            fontWeight: 'bold',
            fontSize: '14px',
            borderRadius: '50% !important', // Runde Form
            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)', // Optional: leichter Schatten für mehr Tiefe
          },
          /* Optional: Anpassung der Marker-Symbole */
          '.leaflet-marker-icon': {
            filter: 'none', // Entfernen der Farbfilter, um Standardfarben zu verwenden
          },
        }}
      />

      {renderGeocodeProgress()}

      <MapContainer
        center={[51.1657, 10.4515]}
        zoom={6}
        zoomControl={false} // Zoom-Steuerelemente deaktivieren
        style={{
          height: '80vh',
          width: '100%',
        }}
      >
        {/* Verwende den Standard-OpenStreetMap TileLayer */}
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MarkerClusterGroup
          chunkedLoading
          // Verwenden Sie die Standard Cluster-Icons
        >
          {users.map((user) => (
            <Marker key={user.id} position={[user.latitude, user.longitude]}>
              <Popup>
                <StyledPopup elevation={0}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                      src={
                        user.profilePicture || 'https://via.placeholder.com/150'
                      }
                      alt={`${user.firstName} ${user.lastName}`}
                      sx={{ width: 60, height: 60 }}
                    />
                    <Box>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{ textDecoration: 'underline' }}
                      >
                        {user.firstName} {user.lastName}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: '#a0a0a0', lineHeight: 1.2 }}
                      >
                        {user.city}
                      </Typography>
                    </Box>
                  </Box>
                </StyledPopup>
              </Popup>
            </Marker>
          ))}
        </MarkerClusterGroup>
      </MapContainer>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setError(null)}
          severity="error"
          sx={{ width: '100%' }}
        >
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UserMap;
