// EditUserProfile.js
import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Divider,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/system';
import ProfileImageUploader from './ProfileImageUploader';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

// Styled Components
const ProfileContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%',
  padding: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1), // Minimierter Außenabstand für mobile Geräte
  },
}));

const UserProfileImageContainer = styled(Box)(({ theme }) => ({
  width: '150px',
  height: '150px',
  marginBottom: theme.spacing(2),
  backgroundColor: '#252833',
  borderRadius: '50%',
  padding: theme.spacing(1),
  textAlign: 'center',
  position: 'relative',
  opacity: 0.9,
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    width: '100px', // Kleinere Bildgröße auf mobilen Geräten
    height: '100px',
    marginBottom: theme.spacing(1),
  },
}));

const UserProfileDataContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '600px',
  backgroundColor: '#252833',
  borderRadius: '8px',
  padding: theme.spacing(3),
  opacity: 0.9,
  marginTop: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2), // Reduzierter Innenabstand für mobile Geräte
    marginTop: theme.spacing(1),
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': { borderColor: '#d5bc8b' },
    '&:hover fieldset': { borderColor: '#ffffff' },
    '&.Mui-focused fieldset': { borderColor: '#d5bc8b' },
  },
  '& .MuiInputBase-input': {
    color: 'white',
    fontSize: '0.9rem', // Standard-Schriftgröße
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem', // Kleinere Schriftgröße auf mobilen Geräten
    },
  },
  '& .MuiInputLabel-root': {
    color: 'white',
    fontSize: '0.9rem', // Standard-Schriftgröße
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem', // Kleinere Schriftgröße auf mobilen Geräten
    },
  },
}));

const EditUserProfile = ({ avatarUrl, onImageUploaderOpen }) => {
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [currentAvatarUrl, setCurrentAvatarUrl] = useState(avatarUrl);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm')); // Prüfen, ob die Ansicht mobil ist
  const auth = getAuth();
  const db = getFirestore();
  const storage = getStorage();

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const docRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();

          if (data.profilePicturePath) {
            const storageRef = ref(storage, data.profilePicturePath);
            try {
              const url = await getDownloadURL(storageRef);
              setCurrentAvatarUrl(url);
            } catch {
              setCurrentAvatarUrl('/path/to/default/profile/picture.jpg');
            }
          }
          setFirstName(data.firstName || '');
          setLastName(data.lastName || '');
        }
      }
    };
    fetchUserData();
  }, [auth, db, storage]);

  const handleSaveChanges = async () => {
    const user = auth.currentUser;
    if (user) {
      const docRef = doc(db, 'users', user.uid);
      await updateDoc(docRef, {
        firstName,
        lastName,
        // profilePicturePath wird bereits im ProfileImageUploader aktualisiert
      });
    }
  };

  const handleImageUpload = (url) => {
    setCurrentAvatarUrl(url);
    handleSaveChanges();
  };

  const handleImageClick = () => {
    setImageDialogOpen(true);
  };

  return (
    <ProfileContainer>
      <UserProfileImageContainer onClick={handleImageClick}>
        <Box
          component="img"
          alt="User Profile Picture"
          src={currentAvatarUrl}
          sx={{
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            objectFit: 'cover',
          }}
        />
      </UserProfileImageContainer>

      <UserProfileDataContainer>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 'bold',
            mb: 2,
            color: 'white',
            textAlign: 'center',
            fontSize: isMobile ? '1.5rem' : '2rem', // Schriftgröße anpassen
          }}
        >
          Benutzereinstellungen
        </Typography>
        <Divider sx={{ backgroundColor: '#3f4b54', mb: 2 }} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <StyledTextField
              fullWidth
              label="Vorname"
              variant="outlined"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextField
              fullWidth
              label="Nachname"
              variant="outlined"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              fullWidth
              label="Telefonnummer"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              fullWidth
              label="Erzähle etwas über Dich"
              variant="outlined"
              multiline
              rows={isMobile ? 3 : 4} // Weniger Zeilen bei mobiler Ansicht
            />
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSaveChanges}
            sx={{
              fontSize: isMobile ? '0.8rem' : '1rem', // Schriftgröße des Buttons anpassen
            }}
          >
            Änderungen speichern
          </Button>
        </Box>
      </UserProfileDataContainer>

      <ProfileImageUploader
        open={imageDialogOpen}
        onClose={() => setImageDialogOpen(false)}
        onImageUpload={handleImageUpload}
      />
    </ProfileContainer>
  );
};

export default EditUserProfile;
