import React, { useState, useEffect, useCallback } from 'react';
import { db, storage, database } from '../../../../config/firebase'; // <--- Achte darauf, dass du database hier importierst
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  IconButton,
  Typography,
  Box,
  AvatarGroup,
  Popover,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Tooltip,
  Collapse,
  Snackbar,
} from '@mui/material';
import {
  Favorite,
  Comment as CommentIcon,
  Edit,
  Delete,
  PhotoCamera,
  VideoCameraBack,
} from '@mui/icons-material';
import CommentSection from './CommentSection';
import {
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  deleteDoc,
  serverTimestamp,
  collection,
  getDocs,
} from 'firebase/firestore';
import {
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from 'firebase/storage';
import { ref as dbRef, onValue } from 'firebase/database'; // Realtime DB
import { useAuth } from '../../../context/AuthContext';
import { getUserData } from './userService';
import imageCompression from 'browser-image-compression'; // Für Bildkomprimierung

// Importiere date-fns für die Zeitformatierung
import { format } from 'date-fns';
import { de } from 'date-fns/locale';

// Importiere useTheme und styled von Material-UI
import { useTheme, styled } from '@mui/material/styles';

// Snackbar Alert-Komponente
import MuiAlert from '@mui/material/Alert';

// Importiere Badge für den Status-Indikator
import Badge from '@mui/material/Badge';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Definiere eine Funktion für die Statusfarben (online, inactive, offline)
const getStatusColor = (status) => {
  switch (status) {
    case 'online':
      return '#6DA15C'; // Erdiges Grün
    case 'inactive':
      return '#CAB55F'; // Erdiges Gelb
    case 'offline':
      return '#8F8F8F'; // Grauweißlich
    default:
      return '#8F8F8F';
  }
};

// Styled Component für die Card mit angepasstem Stil
const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%', // Volle Breite
  backgroundColor: '#252833', // Angepasste Hintergrundfarbe
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.5)', // Leichter Schatten zur Hervorhebung
  borderRadius: '20px', // Abgerundete Ecken standardmäßig
  border: '0.3pt solid #3f4b54', // Rahmen mit Farbe #3f4b54 und Dicke 0.3pt
  color: '#fff', // Weißer Text für bessere Lesbarkeit
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)',
  },
  // Entferne abgerundete Ecken in der mobilen Ansicht
  [theme.breakpoints.down('sm')]: {
    borderRadius: '0px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const Post = ({ post, onDelete }) => {
  const { currentUser } = useAuth();
  const theme = useTheme();

  const [likes, setLikes] = useState(
    Array.isArray(post.likes) ? post.likes : []
  );
  const [authorAvatarURL, setAuthorAvatarURL] = useState('');
  const [userHasLiked, setUserHasLiked] = useState(false);
  const [currentUserData, setCurrentUserData] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [likesWithProfilePics, setLikesWithProfilePics] = useState([]);
  const [commentCount, setCommentCount] = useState(0);

  // Status des Autors
  const [authorStatus, setAuthorStatus] = useState('offline');

  // Zustände für das Bearbeiten des Beitrags
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editedTitle, setEditedTitle] = useState(post.title);
  const [editedContent, setEditedContent] = useState(post.content);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [uploading, setUploading] = useState(false);

  // Zustände für das Expandieren der Kommentare
  const [expanded, setExpanded] = useState(false);

  // Snackbar Zustand
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success' | 'error' | 'warning' | 'info'

  const MAX_IMAGE_SIZE_MB = 20; // Max 20 MB für Bilder
  const MAX_VIDEO_SIZE_MB = 100; // Max 100 MB für Videos

  const isAuthor = currentUser && currentUser.uid === post.authorId;

  // Lade das Profilbild des Autors
  useEffect(() => {
    const fetchAuthorAvatar = async () => {
      if (post.authorAvatarPath) {
        try {
          const avatarRef = ref(storage, post.authorAvatarPath);
          const url = await getDownloadURL(avatarRef);
          setAuthorAvatarURL(url);
        } catch (error) {
          console.error('Fehler beim Abrufen des Profilbildes:', error);
        }
      }
    };
    fetchAuthorAvatar();
  }, [post.authorAvatarPath]);

  // Lade Daten des aktuell eingeloggten Nutzers
  useEffect(() => {
    const fetchCurrentUserData = async () => {
      if (currentUser) {
        const data = await getUserData(currentUser.uid);
        setCurrentUserData(data);
      }
    };
    fetchCurrentUserData();
  }, [currentUser]);

  // Prüfe, ob der aktuelle Nutzer bereits geliked hat
  useEffect(() => {
    if (currentUser) {
      if (Array.isArray(likes)) {
        const hasLiked = likes.some((like) => like.userId === currentUser.uid);
        setUserHasLiked(hasLiked);
      } else {
        setUserHasLiked(false);
      }
    }
  }, [likes, currentUser]);

  // Laden der Profilbilder der likenden Nutzer
  useEffect(() => {
    const fetchLikesProfilePictures = async () => {
      const updatedLikes = await Promise.all(
        likes.map(async (like) => {
          if (like.profilePicturePath) {
            try {
              const profilePictureRef = ref(storage, like.profilePicturePath);
              const profilePictureURL = await getDownloadURL(profilePictureRef);
              return { ...like, profilePictureURL };
            } catch (error) {
              console.error('Fehler beim Abrufen des Profilbildes:', error);
              return { ...like, profilePictureURL: '' };
            }
          } else {
            return { ...like, profilePictureURL: '' };
          }
        })
      );
      setLikesWithProfilePics(updatedLikes);
    };

    if (likes.length > 0) {
      fetchLikesProfilePictures();
    } else {
      setLikesWithProfilePics([]);
    }
  }, [likes]);

  // Anzahl der Kommentare abrufen
  useEffect(() => {
    const fetchCommentCount = async () => {
      const commentsRef = collection(db, 'posts', post.id, 'comments');
      const commentsSnapshot = await getDocs(commentsRef);
      setCommentCount(commentsSnapshot.size);
    };
    fetchCommentCount();
  }, [post.id]);

  // Status des Autors aus der Realtime Database abrufen
  useEffect(() => {
    if (post.authorId) {
      const statusRef = dbRef(database, `/users/${post.authorId}/status`);
      const unsubscribe = onValue(
        statusRef,
        (snapshot) => {
          const status = snapshot.val();
          if (status?.state) {
            setAuthorStatus(status.state);
          } else {
            setAuthorStatus('offline');
          }
        },
        (error) => {
          console.error('Fehler beim Abrufen des Benutzerstatus:', error);
        }
      );
      return () => unsubscribe();
    }
  }, [post.authorId]);

  const handleLike = useCallback(async () => {
    if (!currentUser) {
      alert('Bitte melde dich an, um Beiträge zu liken.');
      return;
    }

    if (!currentUserData) {
      console.error('Benutzerdaten nicht verfügbar.');
      return;
    }

    const postRef = doc(db, 'posts', post.id);

    const userLikeData = {
      userId: currentUser.uid,
      firstName: currentUserData.firstName,
      lastName: currentUserData.lastName,
      profilePicturePath: currentUserData.profilePicturePath || '',
    };

    try {
      if (userHasLiked) {
        // Like entfernen
        await updateDoc(postRef, {
          likes: arrayRemove(userLikeData),
        });
        setLikes(likes.filter((like) => like.userId !== currentUser.uid));
      } else {
        // Like hinzufügen
        await updateDoc(postRef, {
          likes: arrayUnion(userLikeData),
        });
        setLikes([...likes, userLikeData]);
      }
      setUserHasLiked(!userHasLiked);
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Likes:', error);
      setSnackbarMessage('Fehler beim Aktualisieren des Likes.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, [currentUser, currentUserData, userHasLiked, post.id, likes]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleAvatarClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const fullName =
    `${post.authorFirstName || ''} ${post.authorLastName || ''}`.trim();

  // Farbe für das Herzchen, wenn geliked
  const heartColor = userHasLiked ? '#e63946' : '#fff';

  // Zeitformatierung
  const getFormattedTimestamp = useCallback(() => {
    const now = new Date();
    const postDate = post.timestamp?.toDate();

    if (!postDate) return '';

    const diffInSeconds = (now - postDate) / 1000;

    if (diffInSeconds < 60) {
      return 'gepostet vor wenigen Sekunden';
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `gepostet vor ${minutes} Minute${minutes !== 1 ? 'n' : ''}`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `gepostet vor ${hours} Stunde${hours !== 1 ? 'n' : ''}`;
    } else {
      return format(postDate, 'Pp', { locale: de });
    }
  }, [post.timestamp]);

  // Funktionen für die Auswahl von Bild und Video
  const handleImageSelect = (file) => {
    if (file && file.size / 1024 / 1024 > MAX_IMAGE_SIZE_MB) {
      alert(
        `Bitte wähle ein Bild, das kleiner als ${MAX_IMAGE_SIZE_MB} MB ist.`
      );
      return;
    }
    setSelectedImage(file);
  };

  const handleVideoSelect = (file) => {
    if (file && file.size / 1024 / 1024 > MAX_VIDEO_SIZE_MB) {
      alert(
        `Bitte wähle ein Video, das kleiner als ${MAX_VIDEO_SIZE_MB} MB ist.`
      );
      return;
    }
    setSelectedVideo(file);
  };

  const handleImageUpload = async (imageFile) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      return compressedFile;
    } catch (error) {
      console.error('Fehler bei der Bildkomprimierung:', error);
      return null;
    }
  };

  const handleEditSubmit = async () => {
    if (!editedContent.trim() && !selectedImage && !selectedVideo) {
      alert(
        'Bitte füge Inhalt, ein Bild oder ein Video zu deinem Beitrag hinzu.'
      );
      return;
    }

    setUploading(true);

    try {
      const postRef = doc(db, 'posts', post.id);
      let imageUrl = post.imageUrl || '';
      let videoUrl = post.videoUrl || '';

      // Altes Bild löschen, wenn neues hochgeladen wird
      if (selectedImage && post.imageUrl) {
        const oldImageRef = ref(storage, post.imageUrl);
        await deleteObject(oldImageRef);
        imageUrl = '';
      }

      // Altes Video löschen, wenn neues hochgeladen wird
      if (selectedVideo && post.videoUrl) {
        const oldVideoRef = ref(storage, post.videoUrl);
        await deleteObject(oldVideoRef);
        videoUrl = '';
      }

      // Neues Bild hochladen
      if (selectedImage) {
        const compressedImage = await handleImageUpload(selectedImage);
        if (compressedImage) {
          const imageRef = ref(
            storage,
            `posts/${currentUser.uid}/images/${Date.now()}_${compressedImage.name}`
          );
          const snapshot = await uploadBytes(imageRef, compressedImage);
          imageUrl = await getDownloadURL(snapshot.ref);
        }
      }

      // Neues Video hochladen
      if (selectedVideo) {
        const videoRef = ref(
          storage,
          `posts/${currentUser.uid}/videos/${Date.now()}_${selectedVideo.name}`
        );
        const snapshot = await uploadBytes(videoRef, selectedVideo);
        videoUrl = await getDownloadURL(snapshot.ref);
      }

      // Beitrag aktualisieren
      await updateDoc(postRef, {
        title: editedTitle,
        content: editedContent,
        imageUrl: imageUrl,
        videoUrl: videoUrl,
        timestamp: serverTimestamp(), // Aktualisiere den Zeitstempel
      });

      // Zustände aktualisieren
      setEditDialogOpen(false);
      setSelectedImage(null);
      setSelectedVideo(null);
      setEditedTitle(editedTitle);
      setEditedContent(editedContent);

      // Beitrag im lokalen Zustand aktualisieren
      post.title = editedTitle;
      post.content = editedContent;
      post.imageUrl = imageUrl;
      post.videoUrl = videoUrl;

      setSnackbarMessage('Beitrag erfolgreich aktualisiert!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Beitrags:', error);
      setSnackbarMessage('Fehler beim Aktualisieren des Beitrags.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setUploading(false);
    }
  };

  const handleDelete = async () => {
    const confirmDelete = window.confirm(
      'Möchtest du diesen Beitrag wirklich löschen?'
    );
    if (!confirmDelete) return;

    try {
      const postRef = doc(db, 'posts', post.id);

      // Medien löschen
      if (post.imageUrl) {
        const imageRef = ref(storage, post.imageUrl);
        await deleteObject(imageRef);
      }

      if (post.videoUrl) {
        const videoRef = ref(storage, post.videoUrl);
        await deleteObject(videoRef);
      }

      // Beitrag löschen
      await deleteDoc(postRef);

      // Rufe die onDelete Callback-Funktion auf, um den Beitrag aus dem NewsFeed zu entfernen
      if (onDelete && typeof onDelete === 'function') {
        onDelete(post.id);
      }

      setSnackbarMessage('Beitrag erfolgreich gelöscht!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Fehler beim Löschen des Beitrags:', error);
      setSnackbarMessage('Fehler beim Löschen des Beitrags.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Box
      sx={{
        width: '100%',
        mb: 2,
        paddingLeft: { xs: '8px', sm: '16px' },
        paddingRight: { xs: '8px', sm: '16px' },
      }}
    >
      <StyledCard>
        <CardHeader
          avatar={
            // Status-Badge um den Avatar des Autors
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="dot"
              sx={{
                '& .MuiBadge-badge': {
                  backgroundColor: getStatusColor(authorStatus),
                  color: getStatusColor(authorStatus),
                  // boxShadow entfernt!
                },
              }}
            >
              <Avatar
                src={authorAvatarURL}
                alt={fullName}
                sx={{ bgcolor: '#d5bc8b' }}
              >
                {post.authorFirstName?.charAt(0)}
              </Avatar>
            </Badge>
          }
          action={
            isAuthor && (
              <>
                <Tooltip title="Bearbeiten">
                  <IconButton
                    onClick={() => setEditDialogOpen(true)}
                    aria-label="Bearbeiten"
                    sx={{
                      color: '#3f4b54', // Standardfarbe
                      '&:hover': {
                        color: '#d5bc8b', // Gold beim Hover
                      },
                    }}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Löschen">
                  <IconButton
                    onClick={handleDelete}
                    aria-label="Löschen"
                    sx={{
                      color: '#3f4b54', // Standardfarbe
                      '&:hover': {
                        color: '#d5bc8b', // Gold beim Hover
                      },
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </>
            )
          }
          title={
            <Typography
              variant="h6"
              sx={{
                color: '#d5bc8b',
                fontSize: { xs: '1rem', sm: '1.125rem' },
              }}
            >
              {fullName || 'Anonym'}
            </Typography>
          }
          subheader={
            <Typography
              variant="body2"
              sx={{
                color: '#fff',
                fontSize: { xs: '0.75rem', sm: '0.875rem' },
              }}
            >
              {getFormattedTimestamp()}
            </Typography>
          }
        />
        <CardContent>
          {post.title && (
            <Typography
              variant="h6"
              component="div"
              sx={{
                mb: 1,
                color: '#d5bc8b',
                fontSize: { xs: '1rem', sm: '1.125rem' },
              }}
            >
              {post.title}
            </Typography>
          )}
          <Typography
            variant="body1"
            sx={{
              mt: 0.5,
              fontSize: { xs: '0.875rem', sm: '1rem' },
            }}
          >
            {post.content}
          </Typography>

          {/* Bild anzeigen */}
          {post.imageUrl && (
            <Box sx={{ mt: 2 }}>
              <img
                src={post.imageUrl}
                alt="Post Bild"
                style={{
                  width: '100%',
                  maxHeight: '500px',
                  objectFit: 'contain',
                  borderRadius: '12px',
                }}
              />
            </Box>
          )}

          {/* Video anzeigen */}
          {post.videoUrl && (
            <Box sx={{ mt: 2 }}>
              <video
                controls
                src={post.videoUrl}
                style={{
                  width: '100%',
                  maxHeight: '500px',
                  objectFit: 'contain',
                  borderRadius: '12px',
                }}
              />
            </Box>
          )}
        </CardContent>
        <CardActions
          disableSpacing
          sx={{
            borderTop: '1px solid #d5bc8b',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column', // Ändere die Richtung auf Spalte
            alignItems: 'flex-start',
          }}
        >
          {/* Steuerelemente (Kommentare, Likes) */}
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            {/* Kommentarfunktion links von Like-Symbolen */}
            <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
              <Tooltip title="Kommentare anzeigen">
                <IconButton
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="Kommentare anzeigen"
                  sx={{ color: '#d5bc8b' }}
                >
                  <CommentIcon />
                </IconButton>
              </Tooltip>
              <Typography
                sx={{
                  fontSize: { xs: '0.75rem', sm: '0.875rem' },
                  color: '#fff',
                }}
              >
                {commentCount}
              </Typography>
            </Box>

            {/* Like-Elemente */}
            <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
              <Tooltip title="Beitrag liken">
                <IconButton
                  onClick={handleLike}
                  aria-label="Like"
                  sx={{ color: heartColor }}
                >
                  <Favorite />
                </IconButton>
              </Tooltip>
              <Typography
                sx={{
                  fontSize: { xs: '0.75rem', sm: '0.875rem' },
                  mr: 2,
                }}
              >
                {likes.length}
              </Typography>
              {/* AvatarGroup für die likenden Nutzer */}
              {likesWithProfilePics.length > 0 && (
                <AvatarGroup
                  max={3}
                  sx={{
                    '& .MuiAvatar-root': {
                      width: 24,
                      height: 24,
                      fontSize: 12,
                    },
                  }}
                  onClick={handleAvatarClick}
                >
                  {likesWithProfilePics.slice(0, 3).map((like) => (
                    <Avatar
                      key={like.userId}
                      src={like.profilePictureURL}
                      alt={`${like.firstName} ${like.lastName}`}
                      sx={{ cursor: 'pointer' }}
                    >
                      {like.firstName?.charAt(0)}
                    </Avatar>
                  ))}
                </AvatarGroup>
              )}
            </Box>
          </Box>

          {/* Popover für die Liste der likenden Nutzer */}
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <List>
              {likesWithProfilePics.map((like) => (
                <ListItem key={like.userId}>
                  <ListItemAvatar>
                    <Avatar
                      src={like.profilePictureURL}
                      alt={`${like.firstName} ${like.lastName}`}
                    >
                      {like.firstName?.charAt(0)}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${like.firstName} ${like.lastName}`}
                  />
                </ListItem>
              ))}
            </List>
          </Popover>

          {/* Kommentarsektion mit Expand/Collapse */}
          <Collapse
            in={expanded}
            timeout="auto"
            unmountOnExit
            sx={{ width: '100%' }}
          >
            <Box sx={{ width: '100%', mt: 2 }}>
              <CommentSection postId={post.id} />
            </Box>
          </Collapse>
        </CardActions>

        {/* Bearbeiten-Dialog */}
        <Dialog
          open={editDialogOpen}
          onClose={() => setEditDialogOpen(false)}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Beitrag bearbeiten</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Titel"
              type="text"
              fullWidth
              value={editedTitle}
              onChange={(e) => setEditedTitle(e.target.value)}
              sx={{
                input: {
                  color: '#fff',
                  fontSize: { xs: '0.75rem', sm: '0.875rem' },
                },
                label: {
                  color: '#d5bc8b',
                  fontSize: { xs: '0.75rem', sm: '0.875rem' },
                },
              }}
            />
            <TextField
              margin="dense"
              label="Inhalt"
              type="text"
              fullWidth
              multiline
              minRows={4}
              value={editedContent}
              onChange={(e) => setEditedContent(e.target.value)}
              sx={{
                input: {
                  color: '#fff',
                  fontSize: { xs: '0.75rem', sm: '0.875rem' },
                },
                label: {
                  color: '#d5bc8b',
                  fontSize: { xs: '0.75rem', sm: '0.875rem' },
                },
              }}
            />

            {/* Bild-Upload */}
            <Button
              variant="contained"
              component="label"
              startIcon={<PhotoCamera />}
              sx={{ mt: 2, mr: 2, fontSize: { xs: '0.75rem', sm: '0.875rem' } }}
            >
              Neues Bild hochladen
              <input
                type="file"
                accept="image/*"
                hidden
                onChange={(e) => handleImageSelect(e.target.files[0])}
              />
            </Button>

            {/* Video-Upload */}
            <Button
              variant="contained"
              component="label"
              startIcon={<VideoCameraBack />}
              sx={{ mt: 2, fontSize: { xs: '0.75rem', sm: '0.875rem' } }}
            >
              Neues Video hochladen
              <input
                type="file"
                accept="video/*"
                hidden
                onChange={(e) => handleVideoSelect(e.target.files[0])}
              />
            </Button>

            {/* Anzeige des ausgewählten Bildes */}
            {selectedImage && (
              <Typography
                variant="body2"
                sx={{
                  mt: 1,
                  fontSize: { xs: '0.75rem', sm: '0.875rem' },
                }}
              >
                Ausgewähltes Bild: {selectedImage.name}
              </Typography>
            )}

            {/* Anzeige des ausgewählten Videos */}
            {selectedVideo && (
              <Typography
                variant="body2"
                sx={{
                  mt: 1,
                  fontSize: { xs: '0.75rem', sm: '0.875rem' },
                }}
              >
                Ausgewähltes Video: {selectedVideo.name}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setEditDialogOpen(false)}
              sx={{
                color: '#d5bc8b',
                fontSize: { xs: '0.75rem', sm: '0.875rem' },
              }}
            >
              Abbrechen
            </Button>
            <Button
              onClick={handleEditSubmit}
              variant="contained"
              disabled={uploading}
              sx={{
                backgroundColor: '#d5bc8b',
                fontSize: { xs: '0.75rem', sm: '0.875rem' },
                '&:hover': { backgroundColor: '#c4a36f' },
              }}
            >
              {uploading ? 'Wird aktualisiert...' : 'Speichern'}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Snackbar für Benutzer-Feedback */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{
              width: '100%',
              backgroundColor: '#252833',
              color: '#fff',
            }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </StyledCard>
    </Box>
  );
};

export default Post;
