// src/components/pages/Community/UserDashboard_components/Comment.js

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Avatar,
  Box,
  IconButton,
  Tooltip,
  Snackbar,
  TextField,
  Divider,
  Button,
} from '@mui/material';
import {
  Favorite,
  Reply,
  Delete,
  Edit,
  Check,
  Cancel as CancelIcon,
  Send as SendIcon,
} from '@mui/icons-material';
import { db } from '../../../../config/firebase';
import {
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  deleteDoc,
  serverTimestamp,
  addDoc,
  collection,
} from 'firebase/firestore';
import { useAuth } from '../../../context/AuthContext';
import { getUserData } from './userService';
import MuiAlert from '@mui/material/Alert';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import { styled } from '@mui/material/styles';

// -------------------------------------------------
// Snackbar Alert-Komponente
// -------------------------------------------------
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// -------------------------------------------------
// Styled Component für das Kommentar-Kästchen
// -------------------------------------------------
const CommentBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#3a3f4c', // Ursprüngliches Blau für den Hintergrund
  borderLeft: '4px solid #d5bc8b', // Vertikale goldene Linie
  borderRadius: '8px',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(0.5), // Halbierter Abstand zwischen den Kommentaren
  color: '#fff', // Weiße Textfarbe für Kontrast
  width: '100%', // Volle Breite
  boxShadow: '0 1px 3px rgba(0,0,0,0.2)', // Leichter Schatten für Tiefe
  position: 'relative',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: '#4a505d', // Leicht hellere Farbe beim Hover
  },
}));

// -------------------------------------------------
// Haupt-Komponente: Comment
// -------------------------------------------------
const Comment = React.memo(({ comment, postId }) => {
  const { currentUser } = useAuth();

  // Zustand für Likes
  const [likes, setLikes] = useState(
    Array.isArray(comment.likes) ? comment.likes : []
  );
  const [userHasLiked, setUserHasLiked] = useState(false);

  // User-Daten des aktuellen Nutzers
  const [currentUserData, setCurrentUserData] = useState(null);

  // Bearbeitungszustand
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editedCommentText, setEditedCommentText] = useState('');

  // Snackbar-Zustände
  const [commentError, setCommentError] = useState('');
  const [commentSuccess, setCommentSuccess] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  // Zustand für Sub-Kommentar-Eingabe
  const [isReplyBoxOpen, setIsReplyBoxOpen] = useState(false);
  const [replyText, setReplyText] = useState('');

  // -------------------------------------------------
  // 1) Aktuellen User laden
  // -------------------------------------------------
  useEffect(() => {
    const fetchCurrentUserData = async () => {
      if (currentUser) {
        const data = await getUserData(currentUser.uid);
        setCurrentUserData(data);
      }
    };
    fetchCurrentUserData();
  }, [currentUser]);

  // -------------------------------------------------
  // 2) Prüfen, ob der aktuelle User schon geliked hat
  // -------------------------------------------------
  useEffect(() => {
    if (currentUser) {
      if (Array.isArray(likes)) {
        const hasLiked = likes.some((like) => like.userId === currentUser.uid);
        setUserHasLiked(hasLiked);
      } else {
        setUserHasLiked(false);
      }
    }
  }, [likes, currentUser]);

  // -------------------------------------------------
  // 3) Notification beim Liken
  // -------------------------------------------------
  const createLikeNotification = useCallback(
    async (commentAuthorId) => {
      // Keine Notification an sich selbst
      if (
        !commentAuthorId ||
        !currentUser ||
        commentAuthorId === currentUser.uid
      ) {
        return;
      }

      try {
        // Nachricht zusammenbauen
        const likerName = currentUserData?.firstName || 'Jemand';
        const notePayload = {
          type: 'LIKE',
          postId,
          commentId: comment.id,
          createdAt: serverTimestamp(),
          read: false,
          message: `${likerName} hat deinen Kommentar geliked.`,
        };

        // Document in notifications anlegen
        await addDoc(
          collection(db, 'users', commentAuthorId, 'notifications'),
          notePayload
        );
      } catch (err) {
        console.error('Fehler beim Anlegen der Like-Benachrichtigung:', err);
      }
    },
    [comment.id, postId, currentUser, currentUserData]
  );

  // -------------------------------------------------
  // 4) Like/Unlike
  // -------------------------------------------------
  const handleLike = useCallback(async () => {
    if (!currentUser) {
      alert('Bitte melde dich an, um Kommentare zu liken.');
      return;
    }

    if (!currentUserData) {
      console.error('Benutzerdaten nicht verfügbar.');
      return;
    }

    try {
      const commentRef = doc(db, 'posts', postId, 'comments', comment.id);

      const userLikeData = {
        userId: currentUser.uid,
        firstName: currentUserData.firstName || '',
        lastName: currentUserData.lastName || '',
        profilePicturePath: currentUserData.profilePicturePath || '',
      };

      if (userHasLiked) {
        // Like entfernen
        await updateDoc(commentRef, {
          likes: arrayRemove(userLikeData),
        });
        setLikes((prevLikes) =>
          prevLikes.filter((like) => like.userId !== currentUser.uid)
        );
        setUserHasLiked(false);
      } else {
        // Like hinzufügen
        await updateDoc(commentRef, {
          likes: arrayUnion(userLikeData),
        });
        setLikes((prevLikes) => [...prevLikes, userLikeData]);
        setUserHasLiked(true);

        // Notification an den Kommentar-Autor
        await createLikeNotification(comment.userId);
      }
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Likes:', error);
      setSnackbarMessage('Fehler beim Aktualisieren des Likes.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, [
    currentUser,
    currentUserData,
    userHasLiked,
    postId,
    comment,
    createLikeNotification,
  ]);

  // -------------------------------------------------
  // 5) Kommentar bearbeiten
  // -------------------------------------------------
  const handleEditComment = useCallback((commentId, currentText) => {
    setEditingCommentId(commentId);
    setEditedCommentText(currentText);
  }, []);

  const handleSaveEdit = useCallback(
    async (commentId) => {
      if (editedCommentText.trim() === '') {
        setCommentError('Kommentar darf nicht leer sein.');
        return;
      }
      try {
        const commentRef = doc(db, 'posts', postId, 'comments', commentId);
        await updateDoc(commentRef, {
          text: editedCommentText.trim(),
          editedAt: serverTimestamp(),
        });
        setEditingCommentId(null);
        setEditedCommentText('');
        setCommentSuccess('Kommentar erfolgreich bearbeitet.');
      } catch (err) {
        console.error('Fehler beim Bearbeiten des Kommentars:', err);
        setCommentError('Fehler beim Bearbeiten des Kommentars.');
      }
    },
    [editedCommentText, postId]
  );

  const handleCancelEdit = useCallback(() => {
    setEditingCommentId(null);
    setEditedCommentText('');
    setCommentError('');
  }, []);

  // -------------------------------------------------
  // 6) Kommentar löschen
  // -------------------------------------------------
  const handleDeleteComment = useCallback(
    async (commentId) => {
      const confirmDelete = window.confirm(
        'Möchtest du diesen Kommentar wirklich löschen?'
      );
      if (!confirmDelete) return;

      try {
        const commentRef = doc(db, 'posts', postId, 'comments', commentId);
        await deleteDoc(commentRef);
        setCommentSuccess('Kommentar erfolgreich gelöscht.');
      } catch (error) {
        console.error('Fehler beim Löschen des Kommentars:', error);
        setCommentError('Fehler beim Löschen des Kommentars.');
      }
    },
    [postId]
  );

  // -------------------------------------------------
  // 7) Sub-Kommentar abschicken
  // -------------------------------------------------
  const handleReplySubmit = useCallback(async () => {
    if (!replyText.trim()) return;

    if (!currentUser) {
      setCommentError('Bitte melde dich an, um zu antworten.');
      return;
    }

    try {
      // Sub-Kommentar in Firestore anlegen
      const commentsRef = collection(db, 'posts', postId, 'comments');
      await addDoc(commentsRef, {
        text: replyText.trim(),
        timestamp: serverTimestamp(),
        userId: currentUser.uid,
        firstName: currentUserData?.firstName || 'Anonym',
        lastName: currentUserData?.lastName || '',
        profilePictureURL: currentUserData?.profilePictureURL || '',
        parentId: comment.id, // Markiert diesen Kommentar als Sub-Kommentar
      });

      setReplyText('');
      setIsReplyBoxOpen(false);
      setCommentSuccess('Antwort erfolgreich hinzugefügt.');
    } catch (err) {
      console.error('Fehler beim Hinzufügen der Antwort:', err);
      setCommentError('Fehler beim Hinzufügen der Antwort.');
    }
  }, [replyText, currentUser, currentUserData, comment.id, postId]);

  // -------------------------------------------------
  // 8) Snackbar-Handling
  // -------------------------------------------------
  useEffect(() => {
    if (commentError || commentSuccess) {
      setSnackbarMessage(commentError || commentSuccess);
      setSnackbarSeverity(commentError ? 'error' : 'success');
      setSnackbarOpen(true);
    }
  }, [commentError, commentSuccess]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  // -------------------------------------------------
  // 9) Zeitformatierung
  // -------------------------------------------------
  const getFormattedTimestamp = () => {
    if (comment.editedAt?.toDate) {
      return (
        format(comment.editedAt.toDate(), 'Pp', { locale: de }) +
        ' (bearbeitet)'
      );
    } else if (comment.timestamp?.toDate) {
      return format(comment.timestamp.toDate(), 'Pp', { locale: de });
    }
    return 'Unbekannt';
  };

  // -------------------------------------------------
  // Render
  // -------------------------------------------------
  return (
    <CommentBox isReply={!!comment.parentId}>
      {/* Visueller Indikator für Replies */}
      {comment.parentId && <Box className="reply-icon" />}

      {/* Header mit Avatar & Titel */}
      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
        <Avatar
          src={comment.profilePictureURL || ''}
          alt={`${comment.firstName} ${comment.lastName}`}
          sx={{
            mr: 2,
            bgcolor: '#d5bc8b',
            width: 40,
            height: 40,
          }}
          onError={(e) => {
            e.target.onerror = null; // Verhindert unendliche Rekursion
            e.target.src = ''; // Setzt eine leere Quelle, damit der Platzhalter angezeigt wird
            console.error(
              `Fehler beim Laden des Avatars für Benutzer: ${comment.userId}`
            );
          }}
        >
          {comment.firstName?.charAt(0) || 'A'}
        </Avatar>
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          {/* Name und Timestamp untereinander */}
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 'bold',
                fontSize: { xs: '0.875rem', sm: '1rem' },
                color: '#fff',
              }}
            >
              {`${comment.firstName.trim()} ${comment.lastName}` || 'Anonym'}
            </Typography>
            <Typography variant="caption" sx={{ color: '#ccc' }}>
              {getFormattedTimestamp()}
            </Typography>
          </Box>

          {/* Kommentar Text und Action Icons in derselben Zeile */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              mt: 1,
              justifyContent: 'space-between',
            }}
          >
            {editingCommentId === comment.id ? (
              // Edit-Eingabe
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  width: '100%',
                }}
              >
                <TextField
                  variant="outlined"
                  multiline
                  minRows={1}
                  maxRows={4}
                  value={editedCommentText}
                  onChange={(e) => setEditedCommentText(e.target.value)}
                  placeholder="Dein Kommentar..."
                  fullWidth
                  sx={{
                    backgroundColor: '#fff',
                    borderRadius: '4px',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': { borderColor: '#ccc' },
                      '&:hover fieldset': { borderColor: '#d6bc8b' },
                      '&.Mui-focused fieldset': { borderColor: '#d6bc8b' },
                    },
                  }}
                />
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Tooltip title="Speichern">
                    <IconButton
                      color="primary"
                      aria-label="Kommentar speichern"
                      onClick={() => handleSaveEdit(comment.id)}
                    >
                      <Check />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Abbrechen">
                    <IconButton
                      color="secondary"
                      aria-label="Bearbeitung abbrechen"
                      onClick={handleCancelEdit}
                    >
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            ) : (
              <Typography
                variant="body1"
                sx={{
                  color: '#fff',
                  fontSize: { xs: '0.875rem', sm: '1rem' },
                  lineHeight: 1.6,
                  flexGrow: 1,
                }}
              >
                {comment.text}
              </Typography>
            )}

            {/* Action Icons: Like, Reply, Edit/Delete */}
            {!editingCommentId && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Tooltip title="Like">
                  <IconButton
                    onClick={handleLike}
                    aria-label="Like"
                    sx={{ color: userHasLiked ? '#e63946' : '#ccc' }}
                  >
                    <Favorite />
                  </IconButton>
                </Tooltip>
                <Typography
                  sx={{
                    fontSize: { xs: '0.75rem', sm: '0.875rem' },
                    color: '#ccc',
                  }}
                >
                  {likes.length}
                </Typography>

                <Tooltip title="Antworten">
                  <IconButton
                    onClick={() => setIsReplyBoxOpen((prev) => !prev)}
                    aria-label="Antworten"
                    sx={{ color: '#d5bc8b' }}
                  >
                    <Reply />
                  </IconButton>
                </Tooltip>

                {currentUser && currentUser.uid === comment.userId && (
                  <>
                    <Tooltip title="Bearbeiten">
                      <IconButton
                        size="small"
                        onClick={() =>
                          handleEditComment(comment.id, comment.text)
                        }
                        aria-label="Kommentar bearbeiten"
                        sx={{
                          color: '#d5bc8b',
                          '&:hover': { color: '#c4a36f' },
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Löschen">
                      <IconButton
                        size="small"
                        onClick={() => handleDeleteComment(comment.id)}
                        aria-label="Kommentar löschen"
                        sx={{
                          color: '#d5bc8b',
                          '&:hover': { color: '#e63946' },
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {/* Sub-Kommentar-Eingabefeld */}
      {isReplyBoxOpen && (
        <Box
          sx={{
            mt: 2,
            ml: '52px', // Anpassung an die Breite des Avatars und der Indentierung
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <TextField
            value={replyText}
            onChange={(e) => setReplyText(e.target.value)}
            placeholder="Antwort schreiben..."
            fullWidth
            size="small"
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: '#fff',
                color: '#333',
                '& fieldset': { borderColor: '#ccc' },
                '&:hover fieldset': { borderColor: '#d6bc8b' },
                '&.Mui-focused fieldset': { borderColor: '#d6bc8b' },
              },
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleReplySubmit();
              }
            }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
            <Button
              onClick={handleReplySubmit}
              variant="contained"
              endIcon={<SendIcon />}
              sx={{
                backgroundColor: '#d5bc8b',
                '&:hover': { backgroundColor: '#c4a36f' },
                textTransform: 'none',
                fontWeight: 'bold',
              }}
            >
              Senden
            </Button>
          </Box>
        </Box>
      )}

      {/* Trennlinie unten */}
      <Divider
        sx={{
          borderColor: '#ccc', // Einheitliche Farbe der Trennlinie
          mt: 2, // Angepasster Abstand nach oben
        }}
      />

      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{
            width: '100%',
            backgroundColor:
              snackbarSeverity === 'error' ? '#e63946' : '#4caf50',
            color: '#fff',
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </CommentBox>
  );
});

// -------------------------------------------------
// PropTypes
// -------------------------------------------------
Comment.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    timestamp: PropTypes.object.isRequired, // Firestore Timestamp
    editedAt: PropTypes.object, // Optional: Firestore Timestamp
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    profilePictureURL: PropTypes.string,
    parentId: PropTypes.string,
    likes: PropTypes.arrayOf(
      PropTypes.shape({
        userId: PropTypes.string.isRequired,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        profilePicturePath: PropTypes.string,
      })
    ),
  }).isRequired,
  postId: PropTypes.string.isRequired,
};

export default Comment;
