// src/components/Administration/VideoManagement/VideoManagement.jsx

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Avatar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Popover,
  Modal,
  Button,
  Slider,
  TextField,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ListIcon from '@mui/icons-material/List';
import SchoolIcon from '@mui/icons-material/School';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import WorkIcon from '@mui/icons-material/Work';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { db, storage } from '../../../config/firebase';
import {
  collection,
  getDocs,
  addDoc,
  setDoc,
  doc,
  writeBatch,
  updateDoc,
  deleteDoc,
  getDoc,
} from 'firebase/firestore';
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
import imageCompression from 'browser-image-compression';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from '../../../utils/cropImage';

import AddVideoModal from './AddVideoModal';
import AddChapterModal from './AddChapterModal';
import AddThemeModal from './AddThemeModal';

/**
 * --- KONSTANTEN ---
 */
const PLACEHOLDER_THEMENBEREICH = 'https://via.placeholder.com/400x300';
const PLACEHOLDER_KAPITEL = 'https://via.placeholder.com/400x300';
const PLACEHOLDER_THUMBNAIL = 'https://via.placeholder.com/160x120';

const STATUS_OPTIONS = [
  { value: 'Entwurf', color: '#007BFF' },
  { value: 'veröffentlicht', color: '#28a745' },
  { value: 'inaktiv', color: '#6c757d' },
];

const KAPITEL_TYPEN_WITH_ICONS = {
  'unsortierte Liste': <ListIcon />,
  Trainingsreihe: <SchoolIcon />,
  Serie: <OndemandVideoIcon />,
  Praxistransfer: <WorkIcon />,
};

// Hilfsfunktionen
const capitalizeFirstLetter = (string) => {
  if (typeof string !== 'string' || string.length === 0) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const sortByOrder = (a, b) => {
  const orderA =
    typeof a.order === 'number' && !isNaN(a.order) ? a.order : Infinity;
  const orderB =
    typeof b.order === 'number' && !isNaN(b.order) ? b.order : Infinity;
  return orderA - orderB;
};

const VideoManagement = () => {
  // ----- States für Daten -----
  const [themenbereiche, setThemenbereiche] = useState([]);
  const [kapitelList, setKapitelList] = useState([]);
  const [videosByKapitel, setVideosByKapitel] = useState({});

  // ----- States für UI/Feedback -----
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loadingThemenbereiche, setLoadingThemenbereiche] = useState(false);
  const [loadingKapitel, setLoadingKapitel] = useState(false);
  const [loadingVideos, setLoadingVideos] = useState(false);
  const [errorThemenbereiche, setErrorThemenbereiche] = useState(null);
  const [errorKapitel, setErrorKapitel] = useState(null);
  const [errorVideos, setErrorVideos] = useState(null);

  // ----- States für Modals -----
  const [openAddVideoModal, setOpenAddVideoModal] = useState(false);
  const [videoDataForModal, setVideoDataForModal] = useState(null);

  const [openAddChapterModal, setOpenAddChapterModal] = useState(false);
  const [selectedThemenbereichForChapter, setSelectedThemenbereichForChapter] =
    useState(null);

  const [openAddThemeModal, setOpenAddThemeModal] = useState(false);

  // ----- States für Popover -----
  const [anchorEl, setAnchorEl] = useState(null);
  // Enthält Infos wie type: 'video' | 'kapitel' | 'themenbereich', data: {...}, index, parentId...
  const [selectedItem, setSelectedItem] = useState(null);

  // ----- Umbenennen -----
  const [openRenameModal, setOpenRenameModal] = useState(false);
  const [renameType, setRenameType] = useState(null); // 'video' | 'themenbereich' | 'kapitel'
  const [itemToRename, setItemToRename] = useState(null);
  const [newName, setNewName] = useState('');

  // ----- Löschen -----
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteType, setDeleteType] = useState(null); // 'themenbereich' | 'kapitel' | 'video'
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState('');

  // ----- Verschieben -----
  const [openMoveModal, setOpenMoveModal] = useState(false);
  const [moveType, setMoveType] = useState(null); // 'kapitel' | 'video'
  const [itemToMove, setItemToMove] = useState(null);
  const [moveSelectedThemenbereich, setMoveSelectedThemenbereich] =
    useState('');
  const [moveSelectedKapitel, setMoveSelectedKapitel] = useState('');

  // ----- Image Cropping -----
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageToCrop, setImageToCrop] = useState(null);
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [imageType, setImageType] = useState(null); // 'themenbereiche' oder 'kapitel'
  const [imageId, setImageId] = useState(null);

  // =====================================================
  // Daten laden (Themenbereiche, Kapitel, Videos)
  // =====================================================
  useEffect(() => {
    fetchThemenbereiche();
    fetchKapitel();
    fetchVideos();
  }, []);

  const fetchThemenbereiche = async () => {
    setLoadingThemenbereiche(true);
    setErrorThemenbereiche(null);
    try {
      const themenRef = collection(db, 'settings', 'themenbereiche', 'list');
      const themenSnapshot = await getDocs(themenRef);
      let themenList = themenSnapshot.docs.map((docItem) => ({
        id: docItem.id,
        name: docItem.data()?.name?.trim() || '',
        order:
          typeof docItem.data()?.order === 'number'
            ? docItem.data().order
            : undefined,
        imageUrl: docItem.data()?.imageUrl || undefined,
        kapitelIDs: docItem.data()?.kapitelIDs || [],
      }));

      themenList.sort(sortByOrder);
      let needUpdate = false;
      themenList = themenList.map((bereich, idx) => {
        if (typeof bereich.order !== 'number' || isNaN(bereich.order)) {
          needUpdate = true;
          return { ...bereich, order: idx + 1 };
        }
        return bereich;
      });

      if (needUpdate) {
        const batch = writeBatch(db);
        themenList.forEach((bereich) => {
          const docRef = doc(
            db,
            'settings',
            'themenbereiche',
            'list',
            bereich.id
          );
          batch.update(docRef, { order: bereich.order });
        });
        await batch.commit();
      }

      themenList.sort((a, b) => a.order - b.order);
      setThemenbereiche(themenList);
    } catch (error) {
      console.error('Fehler beim Abrufen der Themenbereiche:', error);
      setErrorThemenbereiche('Fehler beim Laden der Themenbereiche.');
    } finally {
      setLoadingThemenbereiche(false);
    }
  };

  const fetchKapitel = async () => {
    setLoadingKapitel(true);
    setErrorKapitel(null);
    try {
      const kapitelRef = collection(db, 'settings', 'kapitel', 'list');
      const kapitelSnapshot = await getDocs(kapitelRef);
      let kapitelListFetched = kapitelSnapshot.docs.map((docItem) => ({
        id: docItem.id,
        name: docItem.data()?.name?.trim() || '',
        order:
          typeof docItem.data()?.order === 'number'
            ? docItem.data().order
            : undefined,
        themenbereich: docItem.data()?.themenbereich?.trim() || '',
        imageUrl: docItem.data()?.imageUrl || undefined,
        typ: docItem.data()?.typ?.trim() || '',
      }));

      kapitelListFetched.sort(sortByOrder);
      let needUpdate = false;
      kapitelListFetched = kapitelListFetched.map((kap, idx) => {
        if (typeof kap.order !== 'number' || isNaN(kap.order)) {
          needUpdate = true;
          return { ...kap, order: idx + 1 };
        }
        return kap;
      });

      if (needUpdate) {
        const batch = writeBatch(db);
        kapitelListFetched.forEach((kap) => {
          const docRef = doc(db, 'settings', 'kapitel', 'list', kap.id);
          batch.update(docRef, { order: kap.order });
        });
        await batch.commit();
      }

      kapitelListFetched.sort((a, b) => a.order - b.order);
      setKapitelList(kapitelListFetched);
    } catch (error) {
      console.error('Fehler beim Abrufen der Kapitel:', error);
      setErrorKapitel('Fehler beim Laden der Kapitel.');
    } finally {
      setLoadingKapitel(false);
    }
  };

  const fetchVideos = async () => {
    setLoadingVideos(true);
    setErrorVideos(null);
    try {
      const videosRef = collection(db, 'videos');
      const videosSnapshot = await getDocs(videosRef);
      const grouped = {};

      videosSnapshot.forEach((docItem) => {
        const videoData = docItem.data();
        const kapitelId = videoData?.kapitelID;
        const order =
          typeof videoData?.order === 'number' ? videoData.order : undefined;
        const status = videoData?.status || undefined;

        if (!kapitelId) {
          console.warn('Video ohne gültige Kapitel-ID gefunden:', {
            id: docItem.id,
            kapitelId,
          });
          return;
        }

        if (!grouped[kapitelId]) {
          grouped[kapitelId] = { videos: [] };
        }

        grouped[kapitelId].videos.push({
          id: docItem.id,
          video_titel: videoData?.video_titel || '',
          order,
          thumbnailUrl: videoData?.thumbnailUrl || PLACEHOLDER_THUMBNAIL,
          status,
          kapitelId,
        });
      });

      // Reihenfolgen ggf. korrigieren (wenn order nicht definiert)
      for (const kapitelID of Object.keys(grouped)) {
        const vids = grouped[kapitelID].videos;
        vids.sort(sortByOrder);
        let needUpdate = false;
        vids.forEach((v, idx) => {
          if (typeof v.order !== 'number' || isNaN(v.order)) {
            needUpdate = true;
          }
          v.order = idx + 1;
        });
        if (needUpdate) {
          const batch = writeBatch(db);
          vids.forEach((video) => {
            const videoDocRef = doc(db, 'videos', video.id);
            batch.update(videoDocRef, { order: video.order });
          });
          await batch.commit();
        }
      }

      setVideosByKapitel(grouped);
    } catch (error) {
      console.error('Fehler beim Abrufen der Videos:', error);
      setErrorVideos('Fehler beim Laden der Videos.');
    } finally {
      setLoadingVideos(false);
    }
  };

  // =====================================================
  // Hilfsfunktionen: Kapitel + Videos eines Themenbereichs
  // =====================================================
  const getKapitelWithVideos = (themenbereich) => {
    const kapitelIDs = themenbereich.kapitelIDs || [];
    const kapitelMitVideos = kapitelIDs.map((kapitelId) => {
      const kapitelInfo = kapitelList.find((k) => k.id === kapitelId);
      const videosData = videosByKapitel[kapitelId]?.videos || [];
      return {
        ...kapitelInfo,
        videoCount: videosData.length,
      };
    });
    return kapitelMitVideos;
  };

  // =====================================================
  // IMAGE UPLOAD (Kapitel/Themenbereich)
  // =====================================================
  const handleImageUpload = async (type, id, file) => {
    try {
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 0.3,
        maxWidthOrHeight: 800,
        useWebWorker: true,
      });
      const imageRefStorage = ref(storage, `${type}/${id}/image.jpg`);
      await uploadBytes(imageRefStorage, compressedFile);
      const downloadURL = await getDownloadURL(imageRefStorage);

      const docRef = doc(db, 'settings', type, 'list', id);
      await setDoc(docRef, { imageUrl: downloadURL }, { merge: true });

      if (type === 'themenbereiche') {
        setThemenbereiche((prev) =>
          prev.map((tb) =>
            tb.id === id ? { ...tb, imageUrl: downloadURL } : tb
          )
        );
      } else if (type === 'kapitel') {
        setKapitelList((prev) =>
          prev.map((kap) =>
            kap.id === id ? { ...kap, imageUrl: downloadURL } : kap
          )
        );
      }

      setMessage(`Bild erfolgreich für ${type} aktualisiert.`);
      setSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Fehler beim Hochladen des Bildes:', error);
      setMessage('Fehler beim Hochladen des Bildes.');
      setSeverity('error');
      setOpenSnackbar(true);
    }
  };

  // =====================================================
  // KAPITEL-TYP ÄNDERN
  // =====================================================
  const handleKapitelTypChange = (kapitelId) => async (e) => {
    const newTyp = e.target.value;
    try {
      const kapitelDocRef = doc(db, 'settings', 'kapitel', 'list', kapitelId);
      await setDoc(kapitelDocRef, { typ: newTyp }, { merge: true });
      setKapitelList((prev) =>
        prev.map((k) => (k.id === kapitelId ? { ...k, typ: newTyp } : k))
      );
      setMessage(`Kapiteltyp erfolgreich auf "${newTyp}" gesetzt.`);
      setSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Kapitel-Typs:', error);
      setMessage('Fehler beim Aktualisieren des Kapitel-Typs.');
      setSeverity('error');
      setOpenSnackbar(true);
    }
  };

  // =====================================================
  // VIDEO-STATUS ÄNDERN
  // =====================================================
  const handleStatusChange = (kapitelId, videoId) => async (e) => {
    const newStatus = e.target.value;
    try {
      const videoDocRef = doc(db, 'videos', videoId);
      await updateDoc(videoDocRef, { status: newStatus });

      setVideosByKapitel((prev) => {
        if (!prev[kapitelId]) return prev;
        const updatedVideos = prev[kapitelId].videos.map((vid) =>
          vid.id === videoId ? { ...vid, status: newStatus } : vid
        );
        return { ...prev, [kapitelId]: { videos: updatedVideos } };
      });

      setMessage(`Status des Videos erfolgreich auf "${newStatus}" gesetzt.`);
      setSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Video-Status:', error);
      setMessage('Fehler beim Aktualisieren des Video-Status.');
      setSeverity('error');
      setOpenSnackbar(true);
    }
  };

  // =====================================================
  // "NACH OBEN/UNTEN" VERSCHIEBEN (Themenbereich/Kapitel/Video)
  // =====================================================
  const moveThemenbereich = async (currentIndex, direction) => {
    const newIndex = currentIndex + direction;
    if (newIndex < 0 || newIndex >= themenbereiche.length) return;

    const updated = [...themenbereiche];
    [updated[currentIndex], updated[newIndex]] = [
      updated[newIndex],
      updated[currentIndex],
    ];
    setThemenbereiche(updated);

    try {
      const batch = writeBatch(db);
      updated.forEach((tb, idx) => {
        const docRef = doc(db, 'settings', 'themenbereiche', 'list', tb.id);
        batch.update(docRef, { order: idx + 1 });
      });
      await batch.commit();

      setMessage('Reihenfolge der Themenbereiche aktualisiert.');
      setSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Fehler beim Aktualisieren der Reihenfolge:', error);
      setMessage('Fehler beim Aktualisieren der Reihenfolge.');
      setSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const moveKapitel = async (
    parentThemenbereichId,
    kapitelIndex,
    direction
  ) => {
    const themenbereich = themenbereiche.find(
      (tb) => tb.id === parentThemenbereichId
    );
    if (!themenbereich) return;

    const newIndex = kapitelIndex + direction;
    if (newIndex < 0 || newIndex >= (themenbereich.kapitelIDs || []).length)
      return;

    const updatedKapitelIDs = [...themenbereich.kapitelIDs];
    [updatedKapitelIDs[kapitelIndex], updatedKapitelIDs[newIndex]] = [
      updatedKapitelIDs[newIndex],
      updatedKapitelIDs[kapitelIndex],
    ];

    // Lokal updaten
    setThemenbereiche((prev) =>
      prev.map((tb) =>
        tb.id === parentThemenbereichId
          ? { ...tb, kapitelIDs: updatedKapitelIDs }
          : tb
      )
    );

    try {
      // Reihenfolge in DB updaten
      const tbRef = doc(
        db,
        'settings',
        'themenbereiche',
        'list',
        parentThemenbereichId
      );
      await updateDoc(tbRef, { kapitelIDs: updatedKapitelIDs });

      // Kapitel-Order updaten
      const batch = writeBatch(db);
      updatedKapitelIDs.forEach((kapId, idx) => {
        const kapDocRef = doc(db, 'settings', 'kapitel', 'list', kapId);
        batch.update(kapDocRef, { order: idx + 1 });
      });
      await batch.commit();

      setMessage('Reihenfolge der Kapitel aktualisiert.');
      setSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error(
        'Fehler beim Aktualisieren der Kapitel-Reihenfolge:',
        error
      );
      setMessage('Fehler beim Aktualisieren der Kapitel-Reihenfolge.');
      setSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const moveVideo = async (kapitelId, videoIndex, direction) => {
    const videosGroup = videosByKapitel[kapitelId];
    if (!videosGroup) return;

    const vids = [...videosGroup.videos];
    const newIndex = videoIndex + direction;
    if (newIndex < 0 || newIndex >= vids.length) return;

    [vids[videoIndex], vids[newIndex]] = [vids[newIndex], vids[videoIndex]];

    setVideosByKapitel((prev) => ({
      ...prev,
      [kapitelId]: { videos: vids },
    }));

    try {
      const batch = writeBatch(db);
      vids.forEach((v, idx) => {
        const videoDocRef = doc(db, 'videos', v.id);
        batch.update(videoDocRef, { order: idx + 1 });
      });
      await batch.commit();

      setMessage('Reihenfolge der Videos aktualisiert.');
      setSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Fehler beim Aktualisieren der Video-Reihenfolge:', error);
      setMessage('Fehler beim Aktualisieren der Video-Reihenfolge.');
      setSeverity('error');
      setOpenSnackbar(true);
    }
  };

  // =====================================================
  // KAPITEL / THEMEN HINZUFÜGEN
  // =====================================================
  const handleAddChapter = async (data) => {
    try {
      const { name, typ, imageFile } = data;
      const themenbereichId = selectedThemenbereichForChapter;
      if (!themenbereichId) {
        setMessage('Themenbereich muss ausgewählt sein.');
        setSeverity('error');
        setOpenSnackbar(true);
        return;
      }

      const kapitelRef = collection(db, 'settings', 'kapitel', 'list');
      const newDoc = await addDoc(kapitelRef, {
        name: name?.trim() || '',
        typ: typ?.trim() || '',
        imageUrl: '',
        themenbereich: themenbereichId,
        order: kapitelList.length + 1,
      });
      const newId = newDoc.id;

      // Falls Bild hochgeladen wurde
      if (imageFile) {
        const compressedFile = await imageCompression(imageFile, {
          maxSizeMB: 0.3,
          maxWidthOrHeight: 800,
          useWebWorker: true,
        });
        const imageRefStorage = ref(storage, `kapitel/${newId}/image.jpg`);
        await uploadBytes(imageRefStorage, compressedFile);
        const downloadURL = await getDownloadURL(imageRefStorage);

        await setDoc(
          doc(db, 'settings', 'kapitel', 'list', newId),
          { imageUrl: downloadURL },
          { merge: true }
        );
      }

      // Kapitel in themenbereich.kapitelIDs aufnehmen
      const themenDocRef = doc(
        db,
        'settings',
        'themenbereiche',
        'list',
        themenbereichId
      );
      await updateDoc(themenDocRef, {
        kapitelIDs: [
          ...(themenbereiche.find((tb) => tb.id === themenbereichId)
            ?.kapitelIDs || []),
          newId,
        ],
      });

      setMessage(`Kapitel "${name}" erfolgreich hinzugefügt.`);
      setSeverity('success');
      setOpenSnackbar(true);

      await fetchKapitel();
      setOpenAddChapterModal(false);
    } catch (error) {
      console.error('Fehler beim Hinzufügen des Kapitels:', error);
      setMessage(error.message || 'Fehler beim Hinzufügen des Kapitels.');
      setSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleAddTheme = async (data) => {
    try {
      const { name, imageFile } = data;
      const themenRef = collection(db, 'settings', 'themenbereiche', 'list');
      const newDoc = await addDoc(themenRef, {
        name: name?.trim() || '',
        imageUrl: '',
        order: themenbereiche.length + 1,
        kapitelIDs: [],
      });
      const newId = newDoc.id;

      // Falls Bild hochgeladen wurde
      if (imageFile) {
        const compressedFile = await imageCompression(imageFile, {
          maxSizeMB: 0.3,
          maxWidthOrHeight: 800,
          useWebWorker: true,
        });
        const imageRefStorage = ref(
          storage,
          `themenbereiche/${newId}/image.jpg`
        );
        await uploadBytes(imageRefStorage, compressedFile);
        const downloadURL = await getDownloadURL(imageRefStorage);

        await setDoc(
          doc(db, 'settings', 'themenbereiche', 'list', newId),
          { imageUrl: downloadURL },
          { merge: true }
        );
      }

      setMessage(`Themenbereich "${name}" erfolgreich hinzugefügt.`);
      setSeverity('success');
      setOpenSnackbar(true);

      await fetchThemenbereiche();
      setOpenAddThemeModal(false);
    } catch (error) {
      console.error('Fehler beim Hinzufügen des Themenbereichs:', error);
      setMessage(error.message || 'Fehler beim Hinzufügen des Themenbereichs.');
      setSeverity('error');
      setOpenSnackbar(true);
    }
  };

  // =====================================================
  // Popover-Handler
  // =====================================================
  const handleMoreClick = (event, type, item, info = {}) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem({ type, data: item, ...info });
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setSelectedItem(null);
  };

  // =====================================================
  // VIDEO BEARBEITEN (AddVideoModal)
  // =====================================================
  const handleEditVideo = async () => {
    if (!selectedItem || selectedItem.type !== 'video') return;
    try {
      const { id: videoId } = selectedItem.data;
      const docRef = doc(db, 'videos', videoId);
      const snap = await getDoc(docRef);

      if (!snap.exists()) {
        console.warn('Video-Dokument nicht gefunden:', videoId);
        return;
      }

      const fullVideoData = { id: snap.id, ...snap.data() };
      setVideoDataForModal(fullVideoData);
      setOpenAddVideoModal(true);
    } catch (error) {
      console.error('Fehler beim Laden des Videos:', error);
      setMessage('Fehler beim Laden des Video-Dokuments.');
      setSeverity('error');
      setOpenSnackbar(true);
    } finally {
      handlePopoverClose();
    }
  };

  // =====================================================
  // MODAL-ÖFFNEN (AddVideo, AddChapter, AddTheme)
  // =====================================================
  const handleOpenAddVideoModal = (themenbereichId, kapitelId) => {
    setVideoDataForModal({
      themenbereich: themenbereichId,
      kapitelID: kapitelId,
    });
    setOpenAddVideoModal(true);
  };

  const handleCloseAddVideoModal = () => {
    setOpenAddVideoModal(false);
    setVideoDataForModal(null);
  };

  const handleOpenAddChapterModal = (themenbereichId) => {
    setSelectedThemenbereichForChapter(themenbereichId);
    setOpenAddChapterModal(true);
  };
  const handleCloseAddChapterModal = () => {
    setOpenAddChapterModal(false);
  };

  const handleOpenAddThemeModal = () => {
    setOpenAddThemeModal(true);
  };
  const handleCloseAddThemeModal = () => {
    setOpenAddThemeModal(false);
  };

  // =====================================================
  // SNACKBAR
  // =====================================================
  const handleCloseSnackbar = (_, reason) => {
    if (reason === 'clickaway') return;
    setOpenSnackbar(false);
  };

  // =====================================================
  // IMAGE CROPPING
  // =====================================================
  const handleCropComplete = useCallback((_, croppedAreaPixelsArg) => {
    setCroppedAreaPixels(croppedAreaPixelsArg);
  }, []);

  const handleCropConfirm = useCallback(async () => {
    try {
      if (!imageToCrop || !croppedAreaPixels) return;
      const croppedImageBlob = await getCroppedImg(
        imageToCrop.src,
        croppedAreaPixels
      );
      const croppedFile = new File([croppedImageBlob], 'croppedImage.jpeg', {
        type: 'image/jpeg',
      });
      await handleImageUpload(imageType, imageId, croppedFile);

      setCropModalOpen(false);
      setImageToCrop(null);
      setCrop({ x: 0, y: 0 });
      setZoom(1);
      setCroppedAreaPixels(null);
    } catch (error) {
      console.error('Fehler beim Zuschneiden des Bildes:', error);
      setMessage('Fehler beim Zuschneiden des Bildes.');
      setSeverity('error');
      setOpenSnackbar(true);
    }
  }, [imageToCrop, croppedAreaPixels, imageType, imageId]);

  // =====================================================
  // LÖSCHEN
  // =====================================================
  const handleDeleteClick = (type, item) => {
    setDeleteType(type);
    setItemToDelete(item);
    setDeleteConfirmation('');
    setOpenDeleteModal(true);
    handlePopoverClose();
  };

  const handleDeleteCancel = () => {
    setOpenDeleteModal(false);
    setDeleteType(null);
    setItemToDelete(null);
    setDeleteConfirmation('');
  };

  const handleDeleteConfirm = async () => {
    if (deleteConfirmation !== 'DELETE') {
      setMessage('Bitte tippen Sie "DELETE", um die Löschung zu bestätigen.');
      setSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    try {
      if (deleteType === 'themenbereich') {
        await deleteThemenbereich(itemToDelete);
      } else if (deleteType === 'kapitel') {
        await deleteKapitel(itemToDelete);
      } else if (deleteType === 'video') {
        await deleteVideo(itemToDelete);
      }

      setMessage(`${capitalizeFirstLetter(deleteType)} erfolgreich gelöscht.`);
      setSeverity('success');
      setOpenSnackbar(true);

      await fetchThemenbereiche();
      await fetchKapitel();
      await fetchVideos();
    } catch (error) {
      console.error(`Fehler beim Löschen des ${deleteType}:`, error);
      setMessage(
        `Fehler beim Löschen des ${deleteType}: ${error.message || ''}`
      );
      setSeverity('error');
      setOpenSnackbar(true);
    } finally {
      handleDeleteCancel();
    }
  };

  const deleteThemenbereich = async (themenbereich) => {
    const batch = writeBatch(db);
    const themenbereichRef = doc(
      db,
      'settings',
      'themenbereiche',
      'list',
      themenbereich.id
    );

    // Kapitel + Videos löschen
    for (const kapitelId of themenbereich.kapitelIDs || []) {
      const kapitel = kapitelList.find((k) => k.id === kapitelId);
      if (kapitel) {
        const vids = videosByKapitel[kapitelId]?.videos || [];
        for (const video of vids) {
          const videoRef = doc(db, 'videos', video.id);
          batch.delete(videoRef);

          // Thumbnail löschen
          if (video.thumbnailUrl) {
            try {
              const thumbRef = ref(storage, `videos/${video.id}/thumbnail.jpg`);
              await deleteObject(thumbRef);
            } catch (err) {
              console.warn('Fehler beim Löschen des Thumbnails:', err);
            }
          }
        }

        // Kapitelbild löschen
        if (kapitel.imageUrl) {
          try {
            const kapitelImageRef = ref(
              storage,
              `kapitel/${kapitel.id}/image.jpg`
            );
            await deleteObject(kapitelImageRef);
          } catch (err) {
            console.warn('Fehler beim Löschen des Kapitelbildes:', err);
          }
        }

        // Kapitel-Dokument
        const kapitelRef = doc(db, 'settings', 'kapitel', 'list', kapitelId);
        batch.delete(kapitelRef);
      }
    }

    // Themenbereich-Bild löschen
    if (themenbereich.imageUrl) {
      try {
        const themenImageRef = ref(
          storage,
          `themenbereiche/${themenbereich.id}/image.jpg`
        );
        await deleteObject(themenImageRef);
      } catch (err) {
        console.warn('Fehler beim Löschen des Themenbereichbildes:', err);
      }
    }

    // Themenbereich-Dokument
    batch.delete(themenbereichRef);

    await batch.commit();
  };

  const deleteKapitel = async (kapitel) => {
    const batch = writeBatch(db);
    const kapitelRef = doc(db, 'settings', 'kapitel', 'list', kapitel.id);

    // Alle Videos löschen
    const vids = videosByKapitel[kapitel.id]?.videos || [];
    for (const video of vids) {
      const videoRef = doc(db, 'videos', video.id);
      batch.delete(videoRef);

      if (video.thumbnailUrl) {
        try {
          const thumbRef = ref(storage, `videos/${video.id}/thumbnail.jpg`);
          await deleteObject(thumbRef);
        } catch (err) {
          console.warn('Fehler beim Löschen des Thumbnails:', err);
        }
      }
    }

    // Kapitelbild löschen
    if (kapitel.imageUrl) {
      try {
        const kapitelImageRef = ref(storage, `kapitel/${kapitel.id}/image.jpg`);
        await deleteObject(kapitelImageRef);
      } catch (err) {
        console.warn('Fehler beim Löschen des Kapitelbildes:', err);
      }
    }

    // Aus dem Themenbereich entfernen
    const tb = themenbereiche.find((t) => t.id === kapitel.themenbereich);
    if (tb) {
      const updatedKapitelIDs = (tb.kapitelIDs || []).filter(
        (kid) => kid !== kapitel.id
      );
      const tbRef = doc(db, 'settings', 'themenbereiche', 'list', tb.id);
      batch.update(tbRef, { kapitelIDs: updatedKapitelIDs });
    }

    // Kapitel-Dokument
    batch.delete(kapitelRef);

    await batch.commit();
  };

  const deleteVideo = async (video) => {
    const videoRef = doc(db, 'videos', video.id);
    await deleteDoc(videoRef);

    // Thumbnail aus Storage löschen
    if (video.thumbnailUrl) {
      try {
        const thumbRef = ref(storage, `videos/${video.id}/thumbnail.jpg`);
        await deleteObject(thumbRef);
      } catch (err) {
        console.warn('Fehler beim Löschen des Thumbnails:', err);
      }
    }
  };

  // =====================================================
  // UMBENENNEN
  // =====================================================
  const handleOpenRenameModal = (type, item) => {
    setRenameType(type);
    setItemToRename(item);
    setNewName(item.name || '');
    setOpenRenameModal(true);
    handlePopoverClose();
  };

  const handleRenameCancel = () => {
    setOpenRenameModal(false);
    setRenameType(null);
    setItemToRename(null);
    setNewName('');
  };

  const handleRenameConfirm = async () => {
    if (!newName.trim()) {
      setMessage('Der Name darf nicht leer sein.');
      setSeverity('error');
      setOpenSnackbar(true);
      return;
    }
    try {
      if (renameType === 'themenbereich') {
        await renameThemenbereich(itemToRename, newName.trim());
      } else if (renameType === 'kapitel') {
        await renameKapitel(itemToRename, newName.trim());
      } else if (renameType === 'video') {
        await renameVideo(itemToRename, newName.trim());
      }

      setMessage(`${capitalizeFirstLetter(renameType)} erfolgreich umbenannt.`);
      setSeverity('success');
      setOpenSnackbar(true);

      await fetchThemenbereiche();
      await fetchKapitel();
      await fetchVideos();
    } catch (error) {
      console.error(`Fehler beim Umbenennen des ${renameType}:`, error);
      setMessage(
        `Fehler beim Umbenennen des ${renameType}: ${error.message || ''}`
      );
      setSeverity('error');
      setOpenSnackbar(true);
    } finally {
      handleRenameCancel();
    }
  };

  const renameThemenbereich = async (themenbereich, newVal) => {
    const docRef = doc(
      db,
      'settings',
      'themenbereiche',
      'list',
      themenbereich.id
    );
    await setDoc(docRef, { name: newVal }, { merge: true });

    setThemenbereiche((prev) =>
      prev.map((tb) =>
        tb.id === themenbereich.id ? { ...tb, name: newVal } : tb
      )
    );
  };

  const renameKapitel = async (kapitel, newVal) => {
    const docRef = doc(db, 'settings', 'kapitel', 'list', kapitel.id);
    await setDoc(docRef, { name: newVal }, { merge: true });

    setKapitelList((prev) =>
      prev.map((k) => (k.id === kapitel.id ? { ...k, name: newVal } : k))
    );
  };

  const renameVideo = async (video, newVal) => {
    const docRef = doc(db, 'videos', video.id);
    await setDoc(docRef, { video_titel: newVal }, { merge: true });

    setVideosByKapitel((prev) => {
      const videosArr = prev[video.kapitelId]?.videos.map((vid) =>
        vid.id === video.id ? { ...vid, video_titel: newVal } : vid
      );
      return { ...prev, [video.kapitelId]: { videos: videosArr } };
    });
  };

  // =====================================================
  // VERSCHIEBEN (MODAL)
  // =====================================================
  const handleOpenMoveModal = () => {
    if (selectedItem.type === 'kapitel') {
      setMoveType('kapitel');
      setItemToMove(selectedItem.data);
    } else if (selectedItem.type === 'video') {
      setMoveType('video');
      setItemToMove(selectedItem.data);
    }
    setMoveSelectedThemenbereich('');
    setMoveSelectedKapitel('');
    setOpenMoveModal(true);
    handlePopoverClose();
  };

  const handleCloseMoveModal = () => {
    setOpenMoveModal(false);
    setMoveType(null);
    setItemToMove(null);
    setMoveSelectedThemenbereich('');
    setMoveSelectedKapitel('');
  };

  const handleMoveConfirm = async () => {
    if (moveType === 'kapitel') {
      if (!moveSelectedThemenbereich) {
        setMessage('Bitte wählen Sie einen Themenbereich aus.');
        setSeverity('error');
        setOpenSnackbar(true);
        return;
      }
      await moveKapitelToNewThemenbereich(
        itemToMove,
        moveSelectedThemenbereich
      );
    } else if (moveType === 'video') {
      if (!moveSelectedThemenbereich || !moveSelectedKapitel) {
        setMessage('Bitte wählen Sie Themenbereich und Kapitel aus.');
        setSeverity('error');
        setOpenSnackbar(true);
        return;
      }
      await moveVideoToNewKapitel(itemToMove, moveSelectedKapitel);
    }

    setOpenMoveModal(false);
  };

  const moveKapitelToNewThemenbereich = async (kapitel, newTbId) => {
    try {
      const oldTb = themenbereiche.find(
        (tb) => tb.id === kapitel.themenbereich
      );
      if (oldTb) {
        const updatedIDs = (oldTb.kapitelIDs || []).filter(
          (id) => id !== kapitel.id
        );
        const oldTbRef = doc(
          db,
          'settings',
          'themenbereiche',
          'list',
          oldTb.id
        );
        await updateDoc(oldTbRef, { kapitelIDs: updatedIDs });
      }
      const newTb = themenbereiche.find((tb) => tb.id === newTbId);
      if (newTb) {
        const newIDs = [...(newTb.kapitelIDs || []), kapitel.id];
        const newTbRef = doc(db, 'settings', 'themenbereiche', 'list', newTbId);
        await updateDoc(newTbRef, { kapitelIDs: newIDs });
      }
      const kapitelRef = doc(db, 'settings', 'kapitel', 'list', kapitel.id);
      await updateDoc(kapitelRef, { themenbereich: newTbId });

      setMessage('Kapitel erfolgreich verschoben.');
      setSeverity('success');
      setOpenSnackbar(true);

      await fetchThemenbereiche();
      await fetchKapitel();
      await fetchVideos();
    } catch (error) {
      console.error('Fehler beim Verschieben des Kapitels:', error);
      setMessage('Fehler beim Verschieben des Kapitels.');
      setSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const moveVideoToNewKapitel = async (video, newKapitelId) => {
    try {
      const videoRefDb = doc(db, 'videos', video.id);
      // Überschreibt das vorhandene 'kapitelID' mit dem neu gewählten Kapitel
      await updateDoc(videoRefDb, { kapitelID: newKapitelId });

      setMessage('Video erfolgreich verschoben.');
      setSeverity('success');
      setOpenSnackbar(true);

      await fetchThemenbereiche();
      await fetchKapitel();
      await fetchVideos();
    } catch (error) {
      console.error('Fehler beim Verschieben des Videos:', error);
      setMessage('Fehler beim Verschieben des Videos.');
      setSeverity('error');
      setOpenSnackbar(true);
    }
  };

  // =====================================================
  // RENDERING
  // =====================================================
  return (
    <Box sx={{ p: 4, backgroundColor: '#f0ebe1', minHeight: '100vh' }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ mb: 4, color: '#333', textAlign: 'center' }}
      >
        Video Management
      </Typography>

      {(loadingThemenbereiche || loadingKapitel || loadingVideos) && (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
          <CircularProgress />
        </Box>
      )}
      {errorThemenbereiche && (
        <Typography color="error" sx={{ mb: 2 }}>
          {errorThemenbereiche}
        </Typography>
      )}
      {errorKapitel && (
        <Typography color="error" sx={{ mb: 2 }}>
          {errorKapitel}
        </Typography>
      )}
      {errorVideos && (
        <Typography color="error" sx={{ mb: 2 }}>
          {errorVideos}
        </Typography>
      )}

      {themenbereiche.map((tb, index) => {
        const kapitelCount = (tb.kapitelIDs || []).length;
        const themenbereichId = tb.id;

        return (
          <Box key={themenbereichId} sx={{ mb: 3 }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-content-${themenbereichId}`}
                id={`panel-header-${themenbereichId}`}
                sx={{
                  backgroundColor: '#d5bc8b',
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  padding: 0,
                  margin: 0,
                  minHeight: '80px',
                  '& .MuiAccordionSummary-content': {
                    margin: 0,
                    alignItems: 'center',
                  },
                  '& .MuiAccordionSummary-expandIconWrapper': {
                    transform: 'none',
                    marginRight: '8px',
                  },
                }}
              >
                {/* Bild für Themenbereich */}
                <Box
                  sx={{
                    position: 'relative',
                    flex: '0 0 auto',
                    width: '120px',
                    height: '100%',
                    overflow: 'hidden',
                    borderRadius: '8px 0 0 8px',
                  }}
                >
                  <Avatar
                    src={tb.imageUrl || PLACEHOLDER_THEMENBEREICH}
                    alt={tb.name || 'Themenbereich ohne Name'}
                    variant="square"
                    sx={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                  {/* Overlay für Bild-Upload */}
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      bgcolor: 'rgba(0, 0, 0, 0.5)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      opacity: 0,
                      transition: 'opacity 0.3s ease',
                      cursor: 'pointer',
                      '&:hover': { opacity: 1 },
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      const fileInput = document.createElement('input');
                      fileInput.type = 'file';
                      fileInput.accept = 'image/*';
                      fileInput.onchange = (event) => {
                        if (event.target.files && event.target.files[0]) {
                          const reader = new FileReader();
                          reader.onload = () => {
                            setImageToCrop({ src: reader.result });
                            setImageType('themenbereiche');
                            setImageId(themenbereichId);
                            setCropModalOpen(true);
                          };
                          reader.readAsDataURL(event.target.files[0]);
                        }
                      };
                      fileInput.click();
                    }}
                  >
                    <EditIcon sx={{ color: '#fff' }} />
                  </Box>
                </Box>

                {/* Name, Info und Popover-Trigger */}
                <Box
                  sx={{
                    flexGrow: 1,
                    display: 'flex',
                    alignItems: 'center',
                    pl: 2,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: '#252833',
                      textAlign: 'left',
                      flexGrow: 1,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {tb.name || 'Unbenannter Themenbereich'}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: '#555',
                      textAlign: 'center',
                      mr: 1,
                    }}
                  >
                    {kapitelCount} Kapitel
                  </Typography>
                  <IconButton
                    onClick={(e) =>
                      handleMoreClick(e, 'themenbereich', tb, { index })
                    }
                  >
                    <MoreVertIcon />
                  </IconButton>
                </Box>
              </AccordionSummary>

              {/* Kapitel-Liste */}
              <AccordionDetails sx={{ padding: 0 }}>
                <Box
                  sx={{
                    ml: 4,
                    backgroundColor: '#f0f8ff',
                    padding: 2,
                    minHeight: 150,
                  }}
                >
                  {getKapitelWithVideos(tb).map((kapitel, kapIndex) => {
                    if (!kapitel?.id) return null;
                    const videos = videosByKapitel[kapitel.id]?.videos || [];
                    return (
                      <Accordion key={kapitel.id}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`kapitel-content-${kapitel.id}`}
                          id={`kapitel-header-${kapitel.id}`}
                          sx={{ padding: '8px 16px' }}
                        >
                          {/* Kapitelbild */}
                          <Box
                            sx={{
                              position: 'relative',
                              width: '60px',
                              height: '60px',
                              overflow: 'hidden',
                              borderRadius: '8px',
                              mr: 2,
                            }}
                          >
                            <Avatar
                              src={kapitel.imageUrl || PLACEHOLDER_KAPITEL}
                              alt={kapitel.name || 'Kapitel ohne Name'}
                              variant="square"
                              sx={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                              }}
                            />
                            {/* Overlay für Bild-Upload */}
                            <Box
                              sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                bgcolor: 'rgba(0, 0, 0, 0.5)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                opacity: 0,
                                transition: 'opacity 0.3s ease',
                                cursor: 'pointer',
                                '&:hover': { opacity: 1 },
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                const fileInput =
                                  document.createElement('input');
                                fileInput.type = 'file';
                                fileInput.accept = 'image/*';
                                fileInput.onchange = (ev) => {
                                  if (ev.target.files && ev.target.files[0]) {
                                    const reader = new FileReader();
                                    reader.onload = () => {
                                      setImageToCrop({ src: reader.result });
                                      setImageType('kapitel');
                                      setImageId(kapitel.id);
                                      setCropModalOpen(true);
                                    };
                                    reader.readAsDataURL(ev.target.files[0]);
                                  }
                                };
                                fileInput.click();
                              }}
                            >
                              <EditIcon sx={{ color: '#fff' }} />
                            </Box>
                          </Box>

                          {/* Kapitelname / Video-Anzahl */}
                          <Box sx={{ flexGrow: 1, mr: 1 }}>
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: 'bold' }}
                            >
                              {kapitel.name || 'Unbenanntes Kapitel'}
                            </Typography>
                            <Typography variant="body2" color="#555">
                              {videos.length} Videos
                            </Typography>
                          </Box>

                          {/* Popover-Trigger Kapitel */}
                          <IconButton
                            onClick={(e) =>
                              handleMoreClick(e, 'kapitel', kapitel, {
                                parentId: tb.id,
                                index: kapIndex,
                              })
                            }
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </AccordionSummary>
                        {/* Videos */}
                        <AccordionDetails>
                          <List>
                            {videos.map((video, vidIndex) => (
                              <ListItem
                                key={video.id}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  mb: 1,
                                  backgroundColor: '#fff',
                                  borderRadius: '4px',
                                  boxShadow: 1,
                                }}
                              >
                                <img
                                  src={
                                    video.thumbnailUrl || PLACEHOLDER_THUMBNAIL
                                  }
                                  alt={video.video_titel || 'Video ohne Titel'}
                                  style={{
                                    width: '50px',
                                    height: '50px',
                                    marginRight: '16px',
                                    objectFit: 'cover',
                                    borderRadius: '4px',
                                  }}
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = PLACEHOLDER_THUMBNAIL;
                                  }}
                                />

                                <ListItemText
                                  primary={
                                    video.video_titel || 'Unbenanntes Video'
                                  }
                                  sx={{
                                    fontSize: '16px',
                                    color: '#333',
                                    textAlign: 'left',
                                  }}
                                />

                                {/* Sortierpfeile Video */}
                                <IconButton
                                  onClick={() =>
                                    moveVideo(kapitel.id, vidIndex, -1)
                                  }
                                >
                                  <ArrowUpwardIcon />
                                </IconButton>
                                <IconButton
                                  onClick={() =>
                                    moveVideo(kapitel.id, vidIndex, 1)
                                  }
                                >
                                  <ArrowDownwardIcon />
                                </IconButton>

                                {/* Popover-Trigger Video */}
                                <IconButton
                                  onClick={(e) =>
                                    handleMoreClick(e, 'video', video, {
                                      parentKapitelId: kapitel.id,
                                      index: vidIndex,
                                    })
                                  }
                                >
                                  <MoreVertIcon />
                                </IconButton>

                                {/* Video-Status */}
                                <Box sx={{ ml: 'auto' }}>
                                  <FormControl variant="outlined" size="small">
                                    <InputLabel id={`status-label-${video.id}`}>
                                      Status
                                    </InputLabel>
                                    <Select
                                      labelId={`status-label-${video.id}`}
                                      id={`status-select-${video.id}`}
                                      value={video.status || ''}
                                      onChange={handleStatusChange(
                                        kapitel.id,
                                        video.id
                                      )}
                                      label="Status"
                                      sx={{ minWidth: 140 }}
                                    >
                                      {STATUS_OPTIONS.map((option) => (
                                        <MenuItem
                                          key={option.value}
                                          value={option.value}
                                        >
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center',
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                width: 12,
                                                height: 12,
                                                borderRadius: '50%',
                                                backgroundColor: option.color,
                                                mr: 1,
                                              }}
                                            />
                                            <Typography variant="body2">
                                              {option.value}
                                            </Typography>
                                          </Box>
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Box>
                              </ListItem>
                            ))}
                          </List>
                          {/* Video hinzufügen */}
                          <Box sx={{ textAlign: 'center', mt: 2 }}>
                            <IconButton
                              onClick={() =>
                                handleOpenAddVideoModal(tb.id, kapitel.id)
                              }
                              sx={{
                                backgroundColor: 'transparent',
                                '&:hover': {
                                  backgroundColor: 'transparent',
                                },
                                flexDirection: 'column',
                                color: '#757575',
                              }}
                            >
                              <AddCircleIcon sx={{ fontSize: 40 }} />
                              <Typography variant="caption">
                                Video hinzufügen
                              </Typography>
                            </IconButton>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                  {/* Kapitel hinzufügen */}
                  <Box sx={{ textAlign: 'center', mt: 2 }}>
                    <IconButton
                      onClick={() => handleOpenAddChapterModal(tb.id)}
                      sx={{
                        backgroundColor: 'transparent',
                        '&:hover': {
                          backgroundColor: 'transparent',
                        },
                        flexDirection: 'column',
                        color: '#757575',
                      }}
                    >
                      <AddCircleIcon sx={{ fontSize: 50 }} />
                      <Typography variant="caption">
                        Kapitel hinzufügen
                      </Typography>
                    </IconButton>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        );
      })}

      {/* Themenbereich hinzufügen */}
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <IconButton
          onClick={handleOpenAddThemeModal}
          sx={{
            backgroundColor: 'transparent',
            '&:hover': { backgroundColor: 'transparent' },
            flexDirection: 'column',
            color: '#757575',
          }}
        >
          <AddCircleIcon sx={{ fontSize: 60 }} />
          <Typography variant="caption">Themenbereich hinzufügen</Typography>
        </IconButton>
      </Box>

      {/* Popover für Aktionen */}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        {selectedItem && (
          <Box sx={{ p: 1 }}>
            {/* Verschieben: Nur für Kapitel oder Videos */}
            {(selectedItem.type === 'kapitel' ||
              selectedItem.type === 'video') && (
              <MenuItem onClick={handleOpenMoveModal}>
                <SwapHorizIcon sx={{ mr: 1 }} />
                Verschieben
              </MenuItem>
            )}

            {/* Nach oben/unten verschieben (Themenbereich & Kapitel) */}
            {(selectedItem.type === 'themenbereich' ||
              selectedItem.type === 'kapitel') && (
              <>
                <MenuItem
                  onClick={() => {
                    if (selectedItem.type === 'themenbereich') {
                      moveThemenbereich(selectedItem.index, -1);
                    } else if (selectedItem.type === 'kapitel') {
                      moveKapitel(
                        selectedItem.parentId,
                        selectedItem.index,
                        -1
                      );
                    }
                    handlePopoverClose();
                  }}
                >
                  <ArrowUpwardIcon sx={{ mr: 1 }} />
                  Nach oben verschieben
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    if (selectedItem.type === 'themenbereich') {
                      moveThemenbereich(selectedItem.index, 1);
                    } else if (selectedItem.type === 'kapitel') {
                      moveKapitel(selectedItem.parentId, selectedItem.index, 1);
                    }
                    handlePopoverClose();
                  }}
                >
                  <ArrowDownwardIcon sx={{ mr: 1 }} />
                  Nach unten verschieben
                </MenuItem>
              </>
            )}

            {/* Umbenennen */}
            {(selectedItem.type === 'themenbereich' ||
              selectedItem.type === 'kapitel' ||
              selectedItem.type === 'video') && (
              <MenuItem
                onClick={() =>
                  handleOpenRenameModal(selectedItem.type, selectedItem.data)
                }
              >
                <EditIcon sx={{ mr: 1 }} /> Umbenennen
              </MenuItem>
            )}

            {/* Löschen */}
            {(selectedItem.type === 'themenbereich' ||
              selectedItem.type === 'kapitel' ||
              selectedItem.type === 'video') && (
              <MenuItem
                onClick={() =>
                  handleDeleteClick(selectedItem.type, selectedItem.data)
                }
              >
                <DeleteIcon sx={{ mr: 1 }} /> Löschen
              </MenuItem>
            )}

            {/* Video bearbeiten (nur für Videos) */}
            {selectedItem.type === 'video' && (
              <MenuItem onClick={handleEditVideo}>
                <EditIcon sx={{ mr: 1 }} /> Video bearbeiten
              </MenuItem>
            )}
          </Box>
        )}
      </Popover>

      {/* AddVideoModal */}
      <AddVideoModal
        open={openAddVideoModal}
        onClose={handleCloseAddVideoModal}
        videoData={videoDataForModal}
      />

      {/* AddChapterModal */}
      <AddChapterModal
        open={openAddChapterModal}
        onClose={handleCloseAddChapterModal}
        onSubmit={handleAddChapter}
        themenbereich={selectedThemenbereichForChapter}
      />

      {/* AddThemeModal */}
      <AddThemeModal
        open={openAddThemeModal}
        onClose={handleCloseAddThemeModal}
        onSubmit={handleAddTheme}
      />

      {/* Rename Modal */}
      <Modal
        open={openRenameModal}
        onClose={handleRenameCancel}
        aria-labelledby="rename-modal-title"
        aria-describedby="rename-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            outline: 'none',
            borderRadius: '8px',
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            {`Umbenennen ${capitalizeFirstLetter(renameType)}`}
          </Typography>
          <TextField
            fullWidth
            label="Neuer Name"
            variant="outlined"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRenameConfirm}
              sx={{ mr: 2 }}
            >
              Bestätigen
            </Button>
            <Button variant="outlined" onClick={handleRenameCancel}>
              Abbrechen
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Verschieben Modal */}
      <Modal
        open={openMoveModal}
        onClose={handleCloseMoveModal}
        aria-labelledby="move-modal-title"
        aria-describedby="move-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            outline: 'none',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Typography variant="h6" id="move-modal-title">
            {moveType === 'kapitel'
              ? 'Kapitel verschieben'
              : 'Video verschieben'}
          </Typography>

          {moveType === 'kapitel' && itemToMove && (
            <FormControl fullWidth>
              <InputLabel>Neuer Themenbereich</InputLabel>
              <Select
                value={moveSelectedThemenbereich}
                label="Neuer Themenbereich"
                onChange={(e) => setMoveSelectedThemenbereich(e.target.value)}
              >
                {themenbereiche.map((tb) => (
                  <MenuItem key={tb.id} value={tb.id}>
                    {tb.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {moveType === 'video' && itemToMove && (
            <>
              <FormControl fullWidth>
                <InputLabel>Themenbereich</InputLabel>
                <Select
                  value={moveSelectedThemenbereich}
                  label="Themenbereich"
                  onChange={(e) => {
                    setMoveSelectedThemenbereich(e.target.value);
                    setMoveSelectedKapitel('');
                  }}
                >
                  {themenbereiche.map((tb) => (
                    <MenuItem key={tb.id} value={tb.id}>
                      {tb.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {moveSelectedThemenbereich && (
                <FormControl fullWidth>
                  <InputLabel>Kapitel</InputLabel>
                  <Select
                    value={moveSelectedKapitel}
                    label="Kapitel"
                    onChange={(e) => setMoveSelectedKapitel(e.target.value)}
                  >
                    {kapitelList
                      // Nur Kapitel anzeigen, die tatsächlich in kapitelIDs
                      // des gewählten Themenbereichs stehen:
                      .filter((k) =>
                        themenbereiche
                          .find((tb) => tb.id === moveSelectedThemenbereich)
                          ?.kapitelIDs.includes(k.id)
                      )
                      .map((k) => (
                        <MenuItem key={k.id} value={k.id}>
                          {k.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            </>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button variant="contained" onClick={handleMoveConfirm}>
              OK
            </Button>
            <Button variant="outlined" onClick={handleCloseMoveModal}>
              Abbrechen
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Crop Modal */}
      <Modal
        open={cropModalOpen}
        onClose={() => setCropModalOpen(false)}
        aria-labelledby="crop-modal-title"
        aria-describedby="crop-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            height: '80%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            outline: 'none',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Bild zuschneiden
          </Typography>
          {imageToCrop && (
            <Box sx={{ position: 'relative', flexGrow: 1, background: '#333' }}>
              <Cropper
                image={imageToCrop.src}
                crop={crop}
                zoom={zoom}
                aspect={16 / 9}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={handleCropComplete}
              />
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 20,
                  left: 20,
                  right: 20,
                }}
              >
                <Typography variant="body2" color="white">
                  Zoom
                </Typography>
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  onChange={(e, val) => setZoom(val)}
                  aria-labelledby="Zoom"
                />
              </Box>
            </Box>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCropConfirm}
              disabled={!croppedAreaPixels}
            >
              Bestätigen
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setCropModalOpen(false);
                setImageToCrop(null);
                setCrop({ x: 0, y: 0 });
                setZoom(1);
                setCroppedAreaPixels(null);
              }}
            >
              Abbrechen
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        open={openDeleteModal}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            outline: 'none',
            borderRadius: '8px',
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            {`Löschen ${capitalizeFirstLetter(deleteType)}`}
          </Typography>
          <Typography sx={{ mb: 2 }}>
            {`Sind Sie sicher, dass Sie den/die ausgewählte(n) ${
              deleteType === 'themenbereich'
                ? 'Themenbereich inklusive aller zugehörigen Kapitel und Videos'
                : deleteType === 'kapitel'
                  ? 'Kapitel inklusive aller zugehörigen Videos'
                  : 'Video'
            } löschen möchten?`}
          </Typography>
          <Typography sx={{ mb: 1 }}>
            Bitte tippen Sie <strong>DELETE</strong>, um zu bestätigen:
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            value={deleteConfirmation}
            onChange={(e) => setDeleteConfirmation(e.target.value)}
            placeholder='Geben Sie "DELETE" ein'
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              color="error"
              onClick={handleDeleteConfirm}
              sx={{ mr: 2 }}
            >
              Löschen
            </Button>
            <Button variant="outlined" onClick={handleDeleteCancel}>
              Abbrechen
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default VideoManagement;
