// src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import { AuthProvider } from './components/context/AuthContext'; // Korrigierter Importpfad
import * as serviceWorkerRegistration from './serviceWorkerRegistration'; // Importiere die SW-Registrierung
import { initializeApp, getApps } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// Firebase-Konfiguration aus Umgebungsvariablen
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialisiere Firebase nur, wenn noch keine App initialisiert wurde
const app = !getApps().length ? initializeApp(firebaseConfig) : getApps()[0];

// Firebase Messaging initialisieren
const messaging = getMessaging(app);

/**
 * Funktion zum Abrufen des FCM-Tokens
 */
const requestForToken = async (registration) => {
  console.log('Public VAPID Key:', process.env.REACT_APP_FIREBASE_VAPID_KEY);

  try {
    const currentToken = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
      serviceWorkerRegistration: registration,
    });
    if (currentToken) {
      console.log('FCM Token:', currentToken);
      // Sende den Token an deinen Server oder speichere ihn
      // Beispiel: await sendTokenToServer(currentToken);
    } else {
      console.log('Kein FCM Token erhalten. Bitte Berechtigungen prüfen.');
    }
  } catch (err) {
    console.error('Fehler beim Abrufen des FCM-Tokens:', err);
  }
};

/**
 * Funktion zur Behandlung eingehender Nachrichten im Vordergrund
 */
const handleMessage = (payload) => {
  console.log('Nachricht empfangen im Vordergrund:', payload);
  // Hier kannst du die Nachricht anzeigen oder anderweitig verarbeiten
  // Beispiel: Zeige eine Benachrichtigung an
  if (Notification.permission === 'granted') {
    new Notification(payload.notification.title, {
      body: payload.notification.body,
      icon: '/firebase-logo.png', // Passe das Icon nach Bedarf an
    });
  }
};

// Registriere den Service Worker
serviceWorkerRegistration.register({
  onSuccess: (registration) => {
    console.log('Service Worker erfolgreich registriert:', registration);
    // Abrufen des Tokens nach erfolgreicher Registrierung
    requestForToken(registration);
  },
  onUpdate: (registration) => {
    console.log('Neue Version des Service Workers gefunden:', registration);
    // Optional: Zeige einen Hinweis zur Aktualisierung an
    // Beispiel: displayUpdateNotification();
  },
});

// Setze einen Listener für Nachrichten im Vordergrund
onMessage(messaging, handleMessage);

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <CssBaseline />
        <App />
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>
);
