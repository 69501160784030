// src/utils/activityTracker.js

import { database, auth } from '../config/firebase';
import {
  ref,
  onValue,
  remove,
  update,
  serverTimestamp,
} from 'firebase/database';
// Du brauchst evtl. ein uuid-Tool, wenn du hier eine Session-ID generieren willst.
// import { v4 as uuidv4 } from 'uuid';

const LOCAL_STORAGE_KEY = (uid) => `sessionId_${uid}`;
/**
 * Activity Tracker:
 *  - Alle Tabs auf demselben Gerät verwenden dieselbe Session-ID.
 *  - Nach 5 Minuten Inaktivität -> offline
 *  - Offline -> Online: sofort
 *  - Online -> Online: kein weiterer Write
 *  - Beim Login direkt "online"
 *  - Keine Mausbewegungen als Aktivität
 */
export const initializeActivityTracker = () => {
  const user = auth.currentUser;
  if (!user) {
    console.log('Kein eingeloggter Benutzer – kein Tracker.');
    return;
  }

  // 1) SessionID aus localStorage lesen
  const localKey = LOCAL_STORAGE_KEY(user.uid);
  let existingSessionId = localStorage.getItem(localKey);

  // Wenn du hier eine neue Session-ID generieren willst, könntest du:
  // if (!existingSessionId) {
  //   existingSessionId = uuidv4();
  //   localStorage.setItem(localKey, existingSessionId);
  // }

  // INFO: Evtl. macht dein AuthContext das Generieren einer Session-ID bereits.
  //       Dann bekommst du sie direkt. In diesem Code zeige ich, wie du
  //       "ohne" übergebene sessionId" arbeiten könntest.

  // --- FALL 1: Keine Session ID gefunden => wir haben also eine neue "Geräte-Session"
  if (!existingSessionId) {
    // Hier entscheidest du, ob du selber die ID generierst oder z. B. vom AuthContext nimmst
    // existingSessionId = uuidv4();
    // localStorage.setItem(localKey, existingSessionId);

    console.warn(
      'Keine sessionId in localStorage gefunden. Du solltest hier eine neue ID anlegen und in localStorage speichern.'
    );
    return;
  }

  console.log(`ActivityTracker verwendet sessionId: ${existingSessionId}`);

  // 2) Referenzen auf Session und Status in der RTDB
  const sessionRef = ref(
    database,
    `/users/${user.uid}/sessions/${existingSessionId}`
  );
  const statusRef = ref(database, `/users/${user.uid}/status`);

  // 3) Lokaler Status
  let localState = 'offline';
  let inactivityTimeout;
  const OFFLINE_MS = 5 * 60 * 1000; // 5 Minuten

  /**
   * Volles Statusupdate
   */
  const writeFullState = async (desiredState) => {
    const sessionUpdates = {
      state: desiredState,
      last_changed: serverTimestamp(),
    };
    if (desiredState === 'offline') {
      sessionUpdates.lastActive = serverTimestamp();
    }

    const statusUpdates = {
      state: desiredState,
      last_changed: serverTimestamp(),
      last_active: serverTimestamp(), // wenn du bei online/offline immer updaten möchtest
    };

    try {
      await update(sessionRef, sessionUpdates);
      await update(statusRef, statusUpdates);
      localState = desiredState;
    } catch (error) {
      console.error(
        `Fehler beim Setzen des Status auf "${desiredState}":`,
        error
      );
    }
  };

  /**
   * Online (nur wenn wir nicht schon online sind)
   */
  const setOnline = () => {
    if (localState === 'online') {
      resetOfflineTimer();
      return;
    }
    writeFullState('online');
    resetOfflineTimer();
  };

  /**
   * Offline
   */
  const setOffline = () => {
    if (localState === 'offline') {
      return;
    }
    writeFullState('offline');
  };

  /**
   * Timer für 5 min
   */
  const resetOfflineTimer = () => {
    if (inactivityTimeout) {
      clearTimeout(inactivityTimeout);
    }
    inactivityTimeout = setTimeout(() => {
      console.log('5 Min Inaktiv – offline');
      setOffline();
    }, OFFLINE_MS);
  };

  /**
   * Aktivität -> "online"
   */
  const handleUserActivity = () => {
    setOnline();
  };

  /**
   * Starten
   */
  const init = async () => {
    // 1) Beim Start -> "online"
    await writeFullState('online');
    resetOfflineTimer();

    // Events ohne mousemove
    const events = ['click', 'keydown', 'scroll', 'touchstart'];
    events.forEach((evt) => {
      window.addEventListener(evt, handleUserActivity);
    });

    // Ggf. auf Session-Änderungen lauschen, um force logout zu triggern,
    // falls die Session in SecurityAndPrivacy entfernt wird
    // (Dazu: onValue(sessionRef, (snapshot) => { ... }))

    console.log('Activity Tracker initialisiert.');
  };
  init();

  /**
   * Cleanup
   */
  return () => {
    if (inactivityTimeout) {
      clearTimeout(inactivityTimeout);
      console.log('Offline-Timer gelöscht.');
    }
    const events = ['click', 'keydown', 'scroll', 'touchstart'];
    events.forEach((evt) => {
      window.removeEventListener(evt, handleUserActivity);
    });
    console.log('Activity Tracker bereinigt.');
  };
};
