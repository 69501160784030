// dropdownOptions.js

export const WAS_GEFOERDET_OPTIONS = [
  'feinere Hilfen',
  'mehr Sicherheit',
  'mehr Motivation',
  'mehr Entspannung',
  'mehr Abwechslung',
  'meine Körpersprache',
  'Einschätzen von Situationen',
  'klare Signale geben',
  'verbessertes Druckverständnis',
  'Sicher Stoppen',
  'Gelassenheit mit Gegenständen und Situationen',
  'mehr Neugierde',
  'Umgang mit Berührung',
  'Kontrollierbarkeit',
  'Hilfe zur Deeskalation',
  'Ansprechbarkeit',
  'Gymnastizierung des Pferdes',
  'Körpergefühl',
  'Wissen über Training',
  'Mentale Einstellung des Menschens',
  'mehr Impulsion',
  'Zuverlässige Freiarbeit',
  'Komplexere Kommunikation',
  'Spiel und Spaß',
  'Energie bündeln',
  'Sicheres Reitpferd',
  'Umgang mit Futter',
  'mein Timing',
];

export const KOERPERLICHER_ANSPRUCH_OPTIONS = [
  'Schritt',
  'Trab',
  'Galopp',
  'enge Wendung',
  'dynamische Bewegung',
  'anspruchsvoller Untergrund',
  'Biegung',
  'Sprung',
  'Seitliche Bewegung',
  'Freilauf',
  'Isolierte Aktivität der Vorderbeine',
  'Isolierte Aktivität der Hinterbeine',
  'Reiten (ausschließlich)',
  'Rückwärts',
  'Kreisbahn',
  'Direkter Stopp',
  'Reiten (teilweise)',
];

export const ELEMENTE_DER_UEBUNG_OPTIONS = [
  'Vorhand weichen',
  'Hinterhand weichen',
  'Stoppen',
  'Stehen bleiben',
  'Rückwärts gehen',
  'Vorhand herkommen',
  'Hinterhand herkommen',
  'Schneller innerhalb der Gangart',
  'Langsamer innerhalb der Gangart',
  'Einladen',
  'Seitlichen Abstand vergrößern',
  'Seitlichen Abstand verkleinern',
  'Gangart erhöhen',
  'Gangart verringern',
  'Tempo halten',
  'Mittelhand weichen',
  'Antreten',
  'Umgang mit Gegenständen, welche auf das Pferd zukommen',
  'Stellung oder Biegung im Hals',
  'Biegung um den inneren Schenkel',
  'Mittelhand herkommen',
  'Vorderbein anheben',
  'Hinterbein anheben',
];

export const ELEMENTE_DER_UEBUNG_REITEN_OPTIONS = [
  'Antreten',
  'Schulterkontrolle',
  'Hinterhandkontrolle',
  'Stoppen',
  'Stehen bleiben',
  'Rückwärts gehen',
  'Schneller innerhalb der Gangart',
  'Langsamer innerhalb der Gangart',
  'Gangart erhöhen',
  'Gangart verringern',
  'Tempo halten',
  'Schenkel weichen',
  'Stellung oder Biegung im Hals',
  'Biegung um den inneren Schenkel',
  'Unter das Gewicht treten',
];

export const ZIELGRUPPE_OPTIONS = [
  'Motivation & Abwechslung',
  'Timing',
  'Gedanken zum Pferdetraining',
  'Hilfenverständnis',
  'Raum & Abstand',
  'Lernverhalten',
  'Trainingsgestaltung',
  'Energie aufbauen',
  'Sicherheit',
  'Grundlagen Bodenarbeit',
  'Aufbau-Übung für weiterführende Bodenarbeit',
  'Longieren Grundlagen',
  'Fortgeschritteneres Longieren',
  'Gymnastizierung am Boden',
  'Rohes Pferd / Wenig bekanntes Pferd / Problemverhalten',
  'Desensibilisierung',
  'Losreißen',
  'Plötzliches Losrennen',
  'Grundlagen der Freiarbeit',
  'Variation in der Freiarbeit',
  'Klare Stimmsignale',
  'Verhalten verändern',
  'Komplexere Kommunikation',
  'Energie bündeln',
  'Durchlässigkeit',
  'Anspannung lösen',
  'Sicherer Stopp',
  'Spaziergang',
  'Fahren von hinten',
  'Untergründe',
  'Wasser',
  'Einreiten - Desensibilisierung und Gewöhnung',
  'Handarbeit',
  'Alltagshandling',
  'Training mit mehr als einem Pferd',
  'Einreiten - Vorübungen Hilfengebung',
  'Hilfengebung Reiten entwickeln',
  'Verladen',
  'Engpass',
  'Ausreiten',
  'Auf Gras arbeiten',
  'Gurt, Pad und Sattel kennenlernen',
  'Handpferd',
  'Einparken an der Aufstiegshilfe',
  'Ausrüstung',
  'Hindernisse',
  'Hintergründe zur Freiarbeit',
  'Tricks & Zirkuslektionen',
  'Kommunikationsaufbau',
  'Umgang mit Futter',
  'Pferd aus der Reserve holen',
];
