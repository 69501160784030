// src/components/context/useHorses.js

import { useState, useEffect } from 'react';
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { useAuth } from './AuthContext';

const useHorses = () => {
  const { currentUser } = useAuth();
  const [horses, setHorses] = useState([]);
  const [loadingHorses, setLoadingHorses] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchHorses = async () => {
      if (!currentUser) {
        setHorses([]);
        setLoadingHorses(false);
        return;
      }

      try {
        console.log('useHorses - Lade Pferde für Benutzer:', currentUser.uid);
        const db = getFirestore();
        const storage = getStorage();
        const horsesRef = collection(db, 'users', currentUser.uid, 'horses');
        const q = query(horsesRef, where('horse_deleted', '==', false));
        const snapshot = await getDocs(q);
        const horsesList = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const horseData = doc.data();
            let profilePictureURL = '';
            if (horseData.profilePicturePath) {
              try {
                profilePictureURL = await getDownloadURL(
                  ref(storage, horseData.profilePicturePath)
                );
              } catch (error) {
                console.error(
                  `useHorses - Fehler beim Abrufen des Profilbilds für Pferd ${doc.id}:`,
                  error
                );
              }
            }
            return { id: doc.id, ...horseData, profilePictureURL };
          })
        );
        setHorses(horsesList);
        console.log('useHorses - Pferde geladen:', horsesList);
      } catch (err) {
        console.error('useHorses - Fehler beim Laden der Pferde:', err);
        setError('Fehler beim Laden der Pferde.');
      } finally {
        setLoadingHorses(false);
      }
    };

    fetchHorses();
  }, [currentUser]);

  return { horses, loadingHorses, error };
};

export default useHorses;
