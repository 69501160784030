// ProfileImageUploader.js
import React, { useState, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slider,
} from '@mui/material';
import Cropper from 'react-easy-crop';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import getCroppedImg from './utils/cropImage'; // Hilfsfunktion zum Zuschneiden

const ProfileImageUploader = ({ open, onClose, onImageUpload }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const auth = getAuth();
  const db = getFirestore();
  const storage = getStorage();

  const handleFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImageSrc(reader.result);
      });
      reader.readAsDataURL(file);
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleUpload = async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      const resizedImage = await resizeImage(croppedImage, 300, 300); // Größe anpassen (z.B. 300x300)

      const user = auth.currentUser;
      if (user) {
        const imageRef = ref(storage, `users/${user.uid}/profilePicture.jpg`); // Beibehaltung des Speicherorts
        await uploadBytes(imageRef, resizedImage);
        const downloadURL = await getDownloadURL(imageRef);
        await updateDoc(doc(db, 'users', user.uid), {
          profilePicturePath: `users/${user.uid}/profilePicture.jpg`,
        });
        onImageUpload(downloadURL);
        onClose();
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const resizeImage = (file, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = file;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height = Math.round((height *= maxWidth / width));
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width = Math.round((width *= maxHeight / height));
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        canvas.toBlob((blob) => {
          if (!blob) {
            // Fehlerbehandlung
            console.error('Canvas is empty');
            return;
          }
          blob.name = 'resizedProfilePicture.jpg';
          resolve(blob);
        }, 'image/jpeg');
      };
      img.onerror = (error) => reject(error);
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Profilbild hochladen</DialogTitle>
      <DialogContent>
        {!imageSrc ? (
          <input type="file" accept="image/*" onChange={handleFileChange} />
        ) : (
          <>
            <div style={{ position: 'relative', width: '100%', height: 400 }}>
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={1}
                cropShape="round"
                showGrid={false}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </div>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              onChange={(e, zoom) => setZoom(zoom)}
              style={{ marginTop: '20px' }}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Abbrechen
        </Button>
        {imageSrc && (
          <Button onClick={handleUpload} color="primary" variant="contained">
            Hochladen
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ProfileImageUploader;
