// src/components/pages/Administrator/UpdateDurationsButton.js

import React, { useState, useEffect } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions, db } from '../../../config/firebase';
import { useAuth } from '../../context/AuthContext';
import { v4 as uuidv4 } from 'uuid';
import { doc, onSnapshot } from 'firebase/firestore';

export const UpdateDurationsButton = () => {
  const { currentUser } = useAuth(); // Verwende AuthContext, um den aktuellen Nutzer zu erhalten
  const [taskId, setTaskId] = useState(null);
  const [total, setTotal] = useState(0);
  const [completed, setCompleted] = useState(0);
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (taskId) {
      const taskRef = doc(db, 'tasks', taskId);
      const unsubscribe = onSnapshot(
        taskRef,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            setTotal(data.total);
            setCompleted(data.completed);
            setStatus(data.status);
            setError(data.error);
          }
        },
        (error) => {
          console.error('Error listening to task updates:', error);
        }
      );

      return () => unsubscribe();
    }
  }, [taskId]);

  const handleClick = async () => {
    if (!currentUser) {
      alert('You must be logged in to perform this action.');
      return;
    }

    const generateTaskId = uuidv4();
    setTaskId(generateTaskId);
    setTotal(0);
    setCompleted(0);
    setStatus('initiated');
    setError(null);

    const updateVideoDurations = httpsCallable(
      functions,
      'updateVideoDurations'
    );

    try {
      const result = await updateVideoDurations({ taskId: generateTaskId });
      console.log(result.data);
      // Die Funktion schreibt Fortschrittsupdates in Firestore, die wir abonnieren
    } catch (error) {
      console.error('Error updating video durations:', error);
      setError(error.message || 'Unknown error');
      setStatus('error');
    }
  };

  const progressPercentage =
    total > 0 ? Math.min((completed / total) * 100, 100) : 0;

  return (
    <div>
      <button onClick={handleClick} disabled={status === 'in-progress'}>
        Update Video Durations
      </button>

      {status && (
        <div style={{ marginTop: '20px' }}>
          <p>Status: {status}</p>
          {total > 0 && (
            <div>
              <p>
                Completed: {completed} / {total}
              </p>
              <div
                style={{
                  width: '100%',
                  backgroundColor: '#ccc',
                  height: '20px',
                  borderRadius: '5px',
                }}
              >
                <div
                  style={{
                    width: `${progressPercentage}%`,
                    backgroundColor: '#4caf50',
                    height: '100%',
                    borderRadius: '5px',
                    transition: 'width 0.5s',
                  }}
                ></div>
              </div>
              <p>{progressPercentage.toFixed(2)}%</p>
            </div>
          )}
          {error && (
            <div style={{ color: 'red' }}>
              <p>Error: {error}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
